import { defineMessages } from 'react-intl'

export const SORT_BY = defineMessages({
  feature: {
    id: 'feature',
    defaultMessage: 'Featured',
    value: '-shop_travel_featured_ratingrating',
  },
  cheapest: {
    id: 'cheapest',
    defaultMessage: 'Cheapest',
    value: 'price_sum',
  },
  guest_rating: {
    id: 'guest_rating',
    defaultMessage: 'Guest ratings',
    value: '-tripadvisor_rating',
  },
  hotel_category: {
    id: 'hotel_category',
    defaultMessage: 'Hotel category',
    value: '-stars',
  },
})

export const filtersKeys = [
  'dateStart',
  'dateTo',
  'nights',
  'totalGuests',
  'rating',
  'stars',
  'resort_size',
  'location',
  'courses',
  'ordering',
  'meal_plan',
  'marine_life_critters',
  'parameters',
  'themes',
  'harbours',
  'price',
  'sponsors',
  'view',
  'resort_amenities',
  'diving_facilities',
  'dive_center_affiliations',
  'diving_equipment',
  'shop',
  'tag',
  'shop_id',
  'organiserTitle',
]

export const valueFilter = [
  'nights',
  'view',
  'dateStart',
  'dateTo',
  'ordering',
  'shop',
]

export const filtersToClean = [
  'rating',
  'stars',
  'resort_size',
  'location',
  'courses',
  'meal_plan',
  'marine_life_critters',
  'parameters',
  'themes',
  'harbours',
  'price',
  'sponsors',
  'resort_amenities',
  'diving_facilities',
  'dive_center_affiliations',
  'diving_equipment',
  'total_number_of_rooms_max',
  'total_number_of_rooms_min',
  'tag',
  'shop_id',
  'organiserTitle',
]
