import { Fragment, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { createUrl } from '../../../localized-urls'
import { getTextFromInfo, target, roundPrice } from 'utils'
import { convertCurrency } from '../../helpers'
import store from 'store2'
import get from 'lodash/get'
import Proptypes from 'prop-types'
import { trackGA4Ecommerce } from '../../../gtm'

export default function TripStatus({
  product,
  currency,
  noSpacesForSelectedGuests,
  shopSlug,
  countrySlug,
  shopTitle,
  onSelect,
  isWidget,
  queryParams,
}) {
  const shareType = useMemo(
    () =>
      product.availability === 1 &&
      product.shareType &&
      product.shareType !== 30
        ? product.shareType
        : false,
    [product],
  )
  const isAvailable = useMemo(
    () =>
      !noSpacesForSelectedGuests &&
      (!product.promotion ||
        (product.promotion && product.promotionEndIn > 14)),
    [noSpacesForSelectedGuests, product],
  )
  const dealExpireSoon = useMemo(
    () =>
      product.promotion &&
      product.promotionEndIn > 0 &&
      product.promotionEndIn <= 14 &&
      product.availability > 0,
    [product],
  )
  const dealExpireToday = useMemo(
    () =>
      product.promotion &&
      product.promotionEndIn === 0 &&
      product.availability > 0,
    [product],
  )

  const tripUrl = useMemo(() => {
    const query = `?productId=${product.id}&${new URLSearchParams(queryParams).toString()}`
    return onSelect ? '' : createUrl(
      'checkout_la',
      {
        slug: shopSlug,
        location: countrySlug,
      },
      query,
    )
  }, [isWidget])

  const generateSearchLink = () => {
    if(product.startDate && product.startDate !== '') {
      return createUrl(
        'search_la',
        { slug: countrySlug },
        `?view=dates&departure_date=${moment(product.startDate)
          .locale('en')
          .format('MMM-YYYY')}`,
      )
    }
    return createUrl('search_la', { slug: countrySlug })
  }

  const trackAddToCart = (e) => {
    if(typeof onSelect === 'function') {
      e.preventDefault()
      onSelect(product)
      return
    }

    const roomParams = store('roomParams')
    const products = []
    const guestSplit = get(
      roomParams,
      'guests_split',
      get(roomParams, 'details'),
    )
    let totalValue = 0
    guestSplit &&
    guestSplit.forEach(() => {
      totalValue += product.price
      products.push({
        item_name: shopTitle,
        item_id: product.id.toString(),
        price: Number(roundPrice(convertCurrency(product.price, currency, 'USD'))),
        item_brand: 'PADI',
        item_category: 'PADI Travel',
        item_category2: 'Liveaboard',
        item_variant: `${product.itinerary.title} / ${moment(product.startDate)
          .utc()
          .locale('en')
          .format('DD MMM')}-${moment(product.endDate)
          .utc()
          .locale('en')
          .format('DD MMM')}`,
        quantity: 1,
        item_list_name: 'PADI Travel - Liveaboard Listing',
      })
    })
    trackGA4Ecommerce('add_to_cart', {
      currency: 'USD',
      value: Number(roundPrice(convertCurrency(totalValue, currency, 'USD'))),
      items: products,
    }, 'PADI Travel - Liveaboards')
  }

  return (
    <div className='product-status-wrapper'>
      {product.availability > 0 && (
        <a
          href={tripUrl}
          onClick={trackAddToCart}
          className='btn-red'
        >
          <FormattedMessage id='select_cabin' defaultMessage='Select cabin' />
        </a>
      )}
      <div className='product-status'>
        {product.availability === 0 && (
          <Fragment>
            <p className='text-center'>
              <span className='sold-out red'>
                <i className='font-icons fail-icon-new' />
                &nbsp;
                <FormattedMessage id='sold_out' defaultMessage='Sold out' />
              </span>
            </p>
            <a target={target} href={generateSearchLink()} className='alt-link'>
              <FormattedMessage
                id='other_boats'
                defaultMessage='Other boats on this date'
              />
            </a>
          </Fragment>
        )}
        {!!isAvailable && (
          <span>
            {product.availability <= 4 && product.availability > 0 && (
              <span className='red'>
                <FormattedMessage
                  id='spots_left'
                  values={{
                    qty: product.availability,
                  }}
                  defaultMessage='Only { qty, plural, one {# spot } other {# spots } } left'
                />
              </span>
            )}
            {product.availability > 4 && (
              <span className='color-green'>
                <i className='font-icons ok-icon-new' />
                &nbsp;
                <FormattedMessage id='available' defaultMessage='Available' />
              </span>
            )}
            {shareType && (
              <p className='color-green'>
                {getTextFromInfo(shareType, 'booking', 'PACKAGE_SHARE_TYPE')}
              </p>
            )}
          </span>
        )}
        {noSpacesForSelectedGuests && (
          <span className='red'>
            <FormattedMessage
              id='no_availability_for_your_number'
              defaultMessage='No availability for your # of guests'
            />
          </span>
        )}
        {!!dealExpireSoon && (
          <span className='red'>
            <FormattedMessage
              id='deal_expires_in'
              values={{
                qty: product.promotionEndIn,
              }}
              defaultMessage='Deal expires in { qty, plural, one {# day } other {# days } }'
            />
          </span>
        )}
        {!!dealExpireToday && (
          <span className='red'>
            <FormattedMessage
              id='deal_expires_today'
              defaultMessage='Deal expires today'
            />
          </span>
        )}
      </div>
    </div>
  )
}

TripStatus.propTypes = {
  product: Proptypes.object,
  currency: Proptypes.string,
  noSpacesForSelectedGuests: Proptypes.bool,
  shopSlug: Proptypes.string,
  countrySlug: Proptypes.string,
  shopTitle: Proptypes.string,
  onSelect: Proptypes.func,
  isWidget: Proptypes.bool,
}
