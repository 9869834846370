import { latLngBounds } from 'leaflet'
import { Fragment, useEffect, useMemo, useRef } from 'react'
import { TileLayer, useMap, useMapEvents, ZoomControl } from 'react-leaflet'
import Markers from './Markers'
import DsMarkers from './DsMarkers'
import { getMapTileUrl, tiandituTilesUrl, isMainLand } from '../../../config'
import useLocatorFilters from '../hooks/useLocatorFilters'
import { getLanguage, isAutosuggestInQuery } from '../../../utils'
import useIsMapMoved from '../hooks/useIsMapMoved'
import useBounds from '../hooks/useBounds'
import ConservationMarkers from './ConservationMarkers'
import ProMarkers from './ProMarkers'
import MapFullSizeToggle from './MapFullSizeToggle'
import CourseMarkers from './CourseMarkers'

const language = getLanguage()

const zoomToPins = (pins, map) => {
  const boundsData = latLngBounds()
  pins.forEach((coord) => {
    if(coord.latitude && coord.longitude) {
      boundsData.extend([coord.latitude, coord.longitude])
    }
  })
  map.fitBounds(boundsData, {
    maxZoom: 12,
    animate: false,
  })
}

const PrimaryMarkers = ({ pageType, pins }) => {
  switch (pageType) {
    case 'dive-sites':
      return <DsMarkers pins={pins} />
    case 'conservation':
      return <ConservationMarkers pins={pins} />
    case 'professional':
      return <ProMarkers pins={pins} />
    case 'courses':
      return <CourseMarkers pins={pins} pageType={pageType} />
    default:
      return <Markers pins={pins} />
  }
}

const SecondaryMarkers = ({ pageType, pins }) => {
  return pageType === 'dive-shops' ? (
    <DsMarkers pins={pins} />
  ) : (
    <Markers pins={pins} />
  )
}

let counter = 0
export default function Map({
  locationType,
  primaryPins = [],
  secondaryPins = [],
  waitingForCoords,
  pageType,
  countryCode,
}) {
  const map = useMap()
  const isFirstLoading = useRef(true)
  const { isMapMoved, setIsMapMoved } = useIsMapMoved()
  const { setBounds } = useBounds()
  const { isSateliteView, secondaryPinsVisible } = useLocatorFilters()
  const tileUrl = useMemo(
    () => getMapTileUrl({ isSateliteView, countryCode, language }),
    [isSateliteView, countryCode, language],
  )

  const onMapMove = () => {
    if(window.isNearBy && counter < 1) {
      counter++
      return
    }
    setIsMapMoved()
    const viewportBounds = {
      northeast: map.getBounds().getNorthEast(),
      southwest: map.getBounds().getSouthWest(),
    }
    setBounds(viewportBounds)

    if(!isMapMoved) {
      return null
    }
  }

  useMapEvents({
    moveend: onMapMove,
  })

  useEffect(() => {
    if(
      (isAutosuggestInQuery() || window.isNearBy) &&
      !isMapMoved &&
      primaryPins.length &&
      map &&
      !waitingForCoords
    ) {
      if(isFirstLoading.current) {
        zoomToPins(primaryPins, map)
        isFirstLoading.current = false
      }
    }
  }, [primaryPins, waitingForCoords, isMapMoved])

  useEffect(() => {
    if(locationType === 'gplace') {
      const viewportBounds = map.getBounds()
      setBounds({
        northeast: viewportBounds._northEast,
        southwest: viewportBounds._southWest,
      })
    }
  }, [])

  return (
    <Fragment>
      <MapFullSizeToggle map={map} />
      {isMainLand(countryCode) && <TileLayer url={tiandituTilesUrl} />}
      <TileLayer url={tileUrl} />
      <ZoomControl position='topright' />
      <PrimaryMarkers pins={primaryPins} pageType={pageType} />
      {secondaryPinsVisible && (
        <SecondaryMarkers pins={secondaryPins} pageType={pageType} />
      )}
    </Fragment>
  )
}
