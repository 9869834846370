import ItineraryDescription from './ItineraryDescription'
import ItineraryHighlights from './ItineraryHighlights'
import ItineraryMainInfo from './ItineraryMainInfo'
import ItineraryPromotion from './ItineraryPromotion'
import ItineraryLogisticHint from './ItineraryLogisticHint'
import ItineraryMarineLife from './ItineraryMarineLife'
import ItineraryLogisticDetails from './ItineraryLogisticDetails'
import ItineraryWhatsIncluded from './ItineraryWhatsIncluded'
import ItineraryWhatsNotIncluded from './ItineraryWhatsNotIncluded'
import ItineraryOptionalExtras from './ItineraryOptionalExtras'
import ItineraryGoodToKnow from './ItineraryGoodToKnow'
import ItineraryPaymentInfo from './ItineraryPaymentInfo'
import ItineraryModalFooter from './ItineraryModalFooter'

export default function ItineraryContent({
  itinerary,
  containerRef,
  isLoading,
}) {
  return (
    <div className='itinerary-popup-content' ref={containerRef}>
      <ItineraryMainInfo itinerary={itinerary} />
      <ItineraryPromotion />
      <ItineraryLogisticHint itinerary={itinerary} />
      <ItineraryHighlights description={itinerary.highlightsDescription} />
      <ItineraryDescription itinerary={itinerary} />
      <ItineraryMarineLife marineLife={itinerary.marineLife} />
      <ItineraryLogisticDetails itinerary={itinerary} />
      <ItineraryWhatsIncluded itinerary={itinerary} />
      <ItineraryWhatsNotIncluded itinerary={itinerary} />
      <ItineraryOptionalExtras itinerary={itinerary} />
      <ItineraryGoodToKnow itinerary={itinerary} />
      <ItineraryPaymentInfo itinerary={itinerary} />
      {!isLoading && <ItineraryModalFooter itinerary={itinerary} />}
    </div>
  )
}
