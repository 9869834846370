import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateFilters, setFilters } from '../../../actions/dive-guide-actions'

import setQuery from 'set-query-string'
import { parseQuery } from '../../../utils'
import usePagination from './usePagination'

import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'

export default function useLocatorFilters(withQuery, pageType, backendFilters) {
  const dispatch = useDispatch()
  const ref = useRef()
  const defaultCourseFilter = getDefaultCourseFilter(backendFilters)?.[0]
  const {
    page,
    setCurrentPage,
  } = usePagination()
  const {
    filters,
    isSateliteView,
    secondaryPinsVisible,
    selectedPin,
  } = useSelector(
    (state) => ({
      filters: state.mapFilters,
      isSateliteView: state.mapFilters.mapView === 'satelite',
      secondaryPinsVisible: Boolean(state.mapFilters.secondaryPinsVisible),
      selectedPin: state.opennedMarker,
    }),
    (a, b) => JSON.stringify(a) === JSON.stringify(b),
  )

  useEffect(() => {
    if(selectedPin && pageType === 'courses') {
      dispatch(updateFilters({ shop_id: selectedPin }))
    }
  }, [selectedPin])

  useEffect(() => {
    if(withQuery) {
      const queryParams = parseQuery()
      if(!isEmpty(queryParams)) {
        dispatch(setFilters(queryParams))
      }
      if(pageType === 'courses' && !queryParams.courses) {
        dispatch(updateFilters({ courses: defaultCourseFilter?.id }))
      }
    }
  }, [withQuery])

  useEffect(() => {
    if(withQuery) {
      setQuery(omit(filters, 'shop_id'), { clear: true })
    }
  }, [filters, withQuery])

  const setFiltersParams = (value) => {
    if(!value.ordering) {
      value.ordering = undefined
    }
    if(!value.bookable_online) {
      value.bookable_online = undefined
    }
    if(!value.instant_confirmation) {
      value.instant_confirmation = undefined
    }
    if(page !== 1 && ref.current === true) {
      setCurrentPage(1)
    }
    if(value.date) {
      value.date_from = value.date[0]
      value.date_to = value.date[1]
    }
    if(value.price) {
      value.price_from = value.price[0]
      value.price_to = value.price[1]
    }
    dispatch(updateFilters(omit(value, ['date', 'price'])))
    ref.current = true
  }

  const clearFilters = () => {
    dispatch(
      setFilters({
        source: filters.source || undefined,
        place_id: filters.place_id || undefined,
      }),
    )
  }

  return {
    filters,
    isSateliteView,
    secondaryPinsVisible,
    setFiltersParams,
    clearFilters,
    defaultCourseFilter,
  }
}

function getDefaultCourseFilter(filters) {
  const courses = filters?.find((filter) => filter.query_name === 'courses')
  return courses?.items?.reduce(
    (acc, item) => {
      return [...acc, item?.subitems?.find((subitem) => subitem.default === 'true')]
    }, []).filter(Boolean)
}
