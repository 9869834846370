import cx from 'classnames'
import styles from '../../styles/DropDown.module.scss'

export default function SearchInput({ onChange, icon, placeholder }) {
  return (
    <div className={styles.inputWrapper}>
      {icon && <i className={cx(styles.searchIcon, icon, 'dsl-icons')} />}
      <input
        type='text'
        onChange={onChange}
        placeholder={placeholder}
        className={styles.input}
      />
    </div>
  )
}
