import { FormattedMessage } from 'react-intl'

export default function SellingPropositionDr() {
  return (
    <div className='selling-proposition'>
      <div className='selling-proposition__item'>
        <div className='selling-proposition__icon'>
          <span className='circle-icon red'>
            <i className='font-icons discount-icon' />
          </span>
        </div>
        <div className='selling-proposition__item__content'>
          <FormattedMessage
            id='selling_propostion.best_price_dr'
            defaultMessage='<strong>10% CHEAPER</strong> on average than booking direct, plus exclusive deals.'
            values={{
              strong: (chunks) => (
                <strong className='red-text'>{chunks}</strong>
              ),
            }}
          />
        </div>
      </div>
      <div className='selling-proposition__item'>
        <div className='selling-proposition__icon'>
          <span className='circle-icon blue'>
            <i className='font-icons icon-insurance' />
          </span>
        </div>
        <div className='selling-proposition__item__content'>
          <FormattedMessage
            defaultMessage='<strong>EXCLUSIVE BENEFITS</strong> such as free DAN dive insurance - why book elsewhere?'
            values={{
              strong: (chunks) => (
                <strong className='blue-text'>{chunks}</strong>
              ),
            }}
          />
        </div>
      </div>
      <div className='selling-proposition__item'>
        <div className='selling-proposition__icon'>
          <span className='circle-icon green'>
            <i className='font-icons icon-information' />
          </span>
        </div>
        <div className='selling-proposition__item__content'>
          <FormattedMessage
            defaultMessage="<strong>DETAILED INFO</strong> on all packages, so you can pick what's right for you."
            values={{
              strong: (chunks) => (
                <strong className='green-text'>{chunks}</strong>
              ),
            }}
          />
        </div>
      </div>
      <div className='selling-proposition__item'>
        <div className='selling-proposition__icon'>
          <img
            src={window.staticPath + 'travel_dist/images/logo_padi_dark.svg'}
            className='hidden-xs hidden-sm'
          />
          <img
            src={window.staticPath + 'travel_dist/images/padi_logo.svg'}
            className='hidden-md hidden-lg'
          />
        </div>
        <div className='selling-proposition__item__content'>
          <FormattedMessage
            id='selling_propostion.secure_marketplace'
            defaultMessage="Secure, seamless online booking on the world's largest dive vacation marketplace."
          />
        </div>
      </div>
    </div>
  )
}
