import { Fragment, useMemo } from 'react'
import ActivityDetails from './ActivityDetails'
import DiveCenterDetails from './DiveCenterDetails'
import PlaceholderResort from '../search-dr/PlaceholderResort'
import NoSearchResults from './NoSearchResults'

const Loader = () => {
  return useMemo(
    () => (
      <Fragment>
        {[...new Array(10)].map((_, i) => {
          return <PlaceholderResort key={i} />
        })}
      </Fragment>
    ),
    []
  )
}

export default function AdventureItemView({
  loading,
  tripContainerRef,
  items = [],
  isDiveCenter,
  activityType,
}) {
  if(loading) {
    return <Loader />
  }

  return (
    <div ref={tripContainerRef} className='results'>
      {!items.length && <NoSearchResults />}
      {items.map((item) => {
        return (
          <div
            key={item.activityId || item.url}
            className='dive-resort search-page-item-card'
          >
            {isDiveCenter ? (
              <DiveCenterDetails diveCenter={item} />
            ) : (
              <ActivityDetails activity={item} activityType={activityType} />
            )}
          </div>
        )
      })}
    </div>
  )
}
