import DiveCenterDetails from '../DiveCenterDetails'
import useSearchPinInfo from './hooks/useSearchPinInfo'
import isEmpty from 'lodash/isEmpty'
import { Loader } from '../../helpers'
import { Fragment } from 'react'

export default function DcMapPopup({ shopId, closePopup, isOpen }) {
  const { pinInfo, isLoading } = useSearchPinInfo(
    shopId,
    'dive-centers',
    isOpen
  )

  if(!isOpen) {
    return null
  }

  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <div className='shop-popup-close' onClick={closePopup}>
        <span className='font-icons close-icon' />
      </div>
      <div className='dive-resort'>
        {!isEmpty(pinInfo) && <DiveCenterDetails diveCenter={pinInfo} />}
      </div>
    </Fragment>
  )
}
