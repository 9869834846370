import { useMemo, useState } from 'react'
import Select from 'react-select'
import DestinationTypeahead from '../helpers/DestinationTypeahead'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import FilterList from './FilterList'
import TrustPilotBlock from './TrustPilotBlock'
import { autosuggestStyles } from './helpers'

const message = defineMessages({
  placeholder: {
    id: 'homepage.search.placeholder',
    defaultMessage: 'Where do you want to go?',
  },
})

FiltersLa.propTypes = {
  pk: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  model: PropTypes.string.isRequired,
  updateParams: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired,
  months: PropTypes.arrayOf(PropTypes.object).isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  triggerApi: PropTypes.func.isRequired,
  clearDates: PropTypes.func.isRequired,
  clearSelectedFilters: PropTypes.func.isRequired,
  filtersCount: PropTypes.number,
  isWidget: PropTypes.bool,
  title: PropTypes.string.isRequired,
  trustpilotLocale: PropTypes.string,
}

export default function FiltersLa({
  model,
  pk,
  months,
  updateParams,
  title,
  selectedFilters,
  isWidget,
  triggerApi,
  clearDates,
  items,
  clearSelectedFilters,
  trustpilotLocale,
  filtersCount,
}) {
  const intl = useIntl()
  const [showMobileDestination, setShowMobileDestinations] = useState(false)
  const destinationSelectCallback = (suggestion) => {
    if(suggestion.modelType === 'PopupLink') {
      window.location.href = suggestion.url
      return true
    }
    if(suggestion.liveaboardSearchUrl) {
      window.location.href = suggestion.liveaboardSearchUrl
    }
    if(suggestion.travelAutosuggestFleetsUrl) {
      window.location.href = suggestion.travelAutosuggestFleetsUrl
    }
  }

  const toggleMobileDestinations = (state) => {
    setShowMobileDestinations(state)
  }

  const updateMonth = (selectedOption) => {
    if(!selectedOption) {
      clearDates()
    } else {
      updateParams({
        departure_date_month: selectedOption,
        departure_date: selectedOption.from,
      })
    }
  }
  const apiUrl = useMemo(
    () => `search/autosuggest/${model.toLowerCase()}/${pk}/liveaboards/`,
    [model, pk],
  )
  return (
    <div className='filters'>
      <div className='main-filters' id='la-main-filters'>
        <p className='title'>
          <FormattedMessage id='search' defaultMessage='Search' />
        </p>
        <div
          className={`search-block ${
            showMobileDestination ? ' mobile-destination' : ''
          }`}
        >
          <label className='w_icon'>
            <i className='font-icons field-icon location-icon' />
            <DestinationTypeahead
              onFocusClear
              placeholder={intl.formatMessage(message.placeholder)}
              isWidget={isWidget}
              kind={10}
              onSelect={destinationSelectCallback}
              inputValue={title}
              mobileDestination={toggleMobileDestinations}
              handlePopupItems
              isActiveMobile
              popupId='country-list-popup'
            />
          </label>
        </div>
        <div
          className={`form-group w_icon${
            !selectedFilters.departure_date_month ? ' has-error' : ''
          }`}
        >
          <i className='font-icons field-icon calendar-icon' />
          <Select
            onChange={updateMonth}
            value={selectedFilters.departure_date_month}
            options={months}
            isSearchable={false}
            valueKey='id'
            isClearable
            styles={autosuggestStyles}
            className='select-container'
            classNamePrefix='select-container'
          />
        </div>
        <button
          className='btn-red hidden-xs'
          id='search-btn'
          onClick={triggerApi}
        >
          <i className='font-icons find-icon' />
          <FormattedMessage id='search' defaultMessage='Search' />
        </button>
      </div>
      <FilterList
        updateParams={updateParams}
        selectedFilters={selectedFilters}
        items={items}
        clearSelectedFilters={clearSelectedFilters}
        filtersCount={filtersCount}
        showTripOrganiser
        hideRange
        organiserPlaceholder='type_a_name'
        url={apiUrl}
        label={
          <FormattedMessage
            id='liveaboardName'
            defaultMessage='Liveaboard name'
          />
        }
      />
      <TrustPilotBlock trustpilotLocale={trustpilotLocale} />
    </div>
  )
}
