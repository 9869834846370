import { useEffect, Fragment, useRef, useState } from 'react'
import sortBy from 'lodash/sortBy'
import each from 'lodash/each'
import find from 'lodash/find'
import get from 'lodash/get'
import { removeHtml } from 'utils'
import API from '../../api'
import BoatTrips from './BoatTrips'
import Pagination from 'react-js-pagination'
import SellingProposition from './SellingProposition'
import OperatorSwitch from './OperatorSwitch'
import PlaceholderBoat from './PlaceholderBoat'
import BoatDetails from './BoatDetails'
import { paginationHook } from './hooks'
import NoSearchResults from './NoSearchResults'

const PAGE_SIZE = 10

let controller
let signal

const Loader = () =>
  [...new Array(10)].map((_, i) => {
    return <PlaceholderBoat key={i} />
  })

const BoatCard = ({ shop, insurance, selectedParams, isWidget, model, pk }) => {
  return (
    <div
      className={`boat search-page-item-card ${
        shop.isHighlighted ? 'highlighted' : ''
      }`}
    >
      <BoatDetails shop={shop} insurance={insurance} isWidget={isWidget} />
      {!shop.bookingOnRequest && (
        <BoatTrips
          isWidget={isWidget}
          model={model}
          modelId={pk}
          selectedParams={selectedParams}
          id={shop.id}
          currency={shop.currency}
          shopSlug={shop.slug}
          shopTitle={shop.title}
          shopAffiliateId={shop.affiliateId}
          countrySlug={shop.referenceLocationCountrySlug}
          insurance={shop.includeFreeDiveInsurance && insurance}
        />
      )}
    </div>
  )
}

export default function BoatComponent({
  model,
  pk,
  insurance,
  resortDetails,
  parentElement,
  selectedParams,
  setNumberOfItems,
  isWidget,
}) {
  const { page, setPage } = paginationHook()
  const [isLoading, setLoading] = useState(true)
  const [shops, setShops] = useState([])
  const [numberOfTrips, setNumberOfTrips] = useState(0)
  const tripContainerRef = useRef()

  useEffect(() => {
    getTrips(page)
    return () => controller && controller.abort()
  }, [selectedParams, page])

  const getTrips = (currentPage) => {
    if('AbortController' in window) {
      controller = new window.AbortController()
      signal = controller.signal
    }

    setLoading(true)
    let highlightSlug = ''

    if(window.highlightedShopSlug) {
      highlightSlug = window.highlightedShopSlug + '/'
    }
    return API(
      `search/${model.toLowerCase()}/${pk}/liveaboards/${highlightSlug}`,
      signal
    )
      .get({
        ...selectedParams,
        page: currentPage || page,
        page_size: PAGE_SIZE,
      })
      .then((data) => {
        const shops = data.results

        each(shops, function(shop, index) {
          if(shop.teaser) {
            shop.teaser = removeHtml(shop.teaser)
          }

          if(shop.promotions) {
            if(find(shop.promotions, { isMain: true })) {
              shop.promotions = sortBy(shop.promotions, 'isMain').reverse()
            } else {
              shop.promotions = sortBy(shop.promotions, 'value').reverse()
            }
          }
        })

        setNumberOfItems(data.count, 'numberOfBoats')
        setShops(shops)
        setNumberOfTrips(data.count)
        setLoading(false)
      })
      .catch((error) => {
        if(
          window.DOMException &&
          error.code === window.DOMException.ABORT_ERR
        ) {
          console.warn('Request canceled.')
        } else if(
          error.status === 404 &&
          get(error, 'error.detail') === 'Invalid page.'
        ) {
          onPageChange(1)
        } else {
          setShops([])
          setNumberOfTrips(0)
          setLoading(false)
        }
      })
  }

  const onPageChange = (page) => {
    if(tripContainerRef.current) {
      window.scrollTo(0, tripContainerRef.current.offsetTop)
    }
    setPage(page)
  }

  if(isLoading) {
    return <Loader />
  }

  return (
    <div ref={tripContainerRef} className='results'>
      {!shops.length && <NoSearchResults />}
      {shops.map((shop, index) => {
        const showSellingProposition =
          !parentElement &&
          (index === 2 || (shops.length < 3 && shops.length === index + 1))
        return (
          <Fragment key={shop.id}>
            <BoatCard
              shop={shop}
              showSellingProposition={showSellingProposition}
              insurance={insurance}
              selectedParams={selectedParams}
              isWidget={isWidget}
              model={model}
              pk={pk}
            />
            {showSellingProposition && <SellingProposition />}
          </Fragment>
        )
      })}
      {!isLoading && !!resortDetails.quantity && (
        <OperatorSwitch {...resortDetails} />
      )}
      {numberOfTrips > 10 && (
        <div className='pagination-wrap'>
          <Pagination
            activePage={page}
            innerClass='custom-pagination'
            itemsCountPerPage={PAGE_SIZE}
            itemClassPrev='prev'
            itemClassNext='next'
            prevPageText=''
            nextPageText=''
            itemClassFirst='hidden'
            itemClassLast='hidden'
            totalItemsCount={numberOfTrips}
            pageRangeDisplayed={5}
            onChange={onPageChange}
          />
        </div>
      )}
    </div>
  )
}
