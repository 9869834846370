import styles from '../styles/DropDown.module.scss'
import { useForm } from 'react-final-form'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import AllFitlersDropdown from './dropdown/AllFitlersDropdown'
import { useMemo, useState } from 'react'

export default function AllFilters({
  filters,
  clearFilters,
  pageType,
  handleToggleClick,
  isMobile,
}) {
  const form = useForm()
  const formValues = form.getState().values
  const [isActive, setIsActive] = useState(false)
  const setFocus = () => {
    if(window.innerWidth <= 768) {
      document.getElementById('toggle-btn').hidden = true
      document.body.classList.add('body-hidden')
    }
    setIsActive(true)
  }
  const removeFocus = (e) => {
    e.stopPropagation()
    document.body.classList.remove('body-hidden')
    document.getElementById('toggle-btn').hidden = false
    setIsActive(false)
  }
  const isSelected = useMemo(() => {
    let active = 0
    Object.entries(formValues).forEach(([key, item]) => {
      if(
        [
          'ordering',
          'source',
          'browser',
          'place_id',
          'packageId',
          'shop_title_autosuggest',
          'ref',
          'date_from',
          'date_to',
          'date',
          'price_from',
          'price_to',
        ].includes(key) === false
      ) {
        if(Array.isArray(item)) {
          item.forEach(() => {
            active++
          })
        } else if(item) {
          active++
        }
      }
    })
    return active
  }, [formValues])

  const filteredFilters = filters.filter((filter) => {
    if(pageType === 'courses') {
      return filter.query_name !== 'courses'
    }
    return true
  })
  return (
    <div
      onClick={setFocus}
      className={cx(styles.filterContainer, {
        [styles.active]: isSelected,
      })}
      tabIndex={0}
    >
      <i className={cx(styles.filterIcon, 'dsl-icons dsl-icons--tune')} />
      <FormattedMessage id='all_filters' defaultMessage='All Filters' />
      &nbsp;
      {isSelected ? `(${isSelected})` : ''}
      <i
        className={cx(
          styles.dropDownIcon,
          'dsl-icons dsl-icons--arrow-dropdown',
        )}
      />
      <AllFitlersDropdown
        isActive={isActive}
        filters={isMobile ? filters : filteredFilters}
        pageType={pageType}
        removeFocus={removeFocus}
        clearFilters={clearFilters}
        handleToggleClick={handleToggleClick}
      />
    </div>
  )
}
