import Modal from 'react-modal'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { showItineraryPopup } from '../../../actions/main-actions'
import Loader from '../../helpers/Loader'
import ItineraryContent from './ItineraryContent'

function ItineraryModal({
  isLoading,
  isOpen,
  showItineraryPopup,
  itinerary,
  containerRef,
}) {
  const closeModal = () => {
    window.history.pushState({}, null, window.shopUrl)
    showItineraryPopup(false)
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      className='Modal react-itinerary-modal itinerary-modal'
      overlayClassName='Overlay'
      contentLabel='Itinerary popup'
    >
      <div className={`itinerary-page-new ${isLoading ? 'loading' : ''}`}>
        <div className='itinerary-page__modal-header'>
          <span className='btn-close' onClick={closeModal}>
            &times;
          </span>
        </div>
        <Loader />
        <ReactTooltip effect='solid' />
        <ItineraryContent
          itinerary={itinerary}
          containerRef={containerRef}
          isLoading={isLoading}
        />
      </div>
    </Modal>
  )
}
export default connect(
  (state) => ({
    isOpen: state.itineraryPopupOpen,
  }),
  { showItineraryPopup }
)(ItineraryModal)
