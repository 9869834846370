import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { checkOverlay } from './helpers'

import './styles/mobile-filters.scss'

const MobileTypesList = (props) => {
  const [isVisible, setVisible] = useState(false)
  const { onClose, id } = props

  useEffect(() => {
    toogleVisibility()
    return () => toogleVisibility()
  }, [])

  const toogleVisibility = () => {
    setVisible((prev) => !prev)
    checkOverlay(`#${id}`)
  }

  const handleClosing = () => {
    const animateVisibility = new Promise((resolve) => {
      toogleVisibility()
      setTimeout(() => {
        resolve()
      }, 300)
    })

    animateVisibility.then(() => {
      onClose()
    })
  }
  return (
    <div className='results-mobile-filters'>
      <div className={`select-list ${isVisible ? 'visible' : ''}`} id={id}>
        <p className='select-header'>
          <FormattedMessage id='display' defaultMessage='Display' />
          <button className='btn-close btn-borderless' onClick={handleClosing}>
            <i className='font-icons close-icon' />
          </button>
        </p>
        <div className='select-types'>{props.children}</div>
      </div>
    </div>
  )
}

export default MobileTypesList
