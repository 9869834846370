import { useEffect, useRef } from 'react'

export const useOutsideClick = (callback) => {
  const ref = useRef()
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if(ref.current && !ref.current.contains(e.target)) {
        callback()
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [ref])

  return { ref }
}
