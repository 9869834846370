import { FormattedMessage } from 'react-intl'

export default function OperatorSwitch({ quantity, title, searchUrl }) {
  return (<a className='operator-switch' href={searchUrl} >
    <span className='operator-switch__content'>
      <span className='circle-icon'>
        <i className='font-icons dive-resort-icon' />
      </span>
      <span className='operator-switch__text'>
        <FormattedMessage id='other_resorts' defaultMessage='See { qty, plural, one {# dive resort} other {# dive resorts}} { locationTitle }' values={{
          qty: quantity,
          locationTitle: title,
        }} />
      </span>
    </span>
    <button className='operator-switch__button'>
      <FormattedMessage id='other_resorts.button' defaultMessage='See { qty, plural, one {# dive resort} other {# dive resorts}}' values={{
        qty: quantity,
      }} /></button>
  </a>)
}
