import { target } from '../../../../utils'
import styles from './Cards.module.scss'

export default function CardHeader({courseTitle, shopTitle, shopUrl}) {
  return (
    <div className={styles.cardHeader}>
      <p className={styles.title}>{courseTitle}</p>
      <span className={styles.shopLink}>
        <i className='dsl-icons dsl-icons--store'/>
        <a href={shopUrl} target={target}>{shopTitle}</a>
      </span>
    </div>
  )
}
