import { connect } from 'react-redux'
import { useEffect, useRef } from 'react'
import { ItineraryModal } from './itinerary'
import { useItinerary } from '../../hooks'

function ItineraryPopup({ itineraryPopupData }) {
  const ref = useRef(null)
  const {
    countrySlug,
    shopSlug,
    itinerarySlug,
    tripId,
    isCharter,
    dateFrom,
    dateTo,
    duration,
    elementToScroll,
    updateUrl,
    isGroupTrip,
  } = itineraryPopupData
  const { isLoading, itinerary, getItineraryDetails } = useItinerary({
    countrySlug,
    shopSlug,
    itinerarySlug,
    tripId,
    isCharter,
    dateFrom,
    dateTo,
    duration,
    elementToScroll,
    updateUrl,
    isGroupTrip,
    ref,
  })

  useEffect(() => {
    if(updateUrl) {
      window.history.pushState(
        {},
        null,
        `${window.location.href}${itinerarySlug}/`
      )
    }
    if(countrySlug && shopSlug && itinerarySlug) {
      getItineraryDetails()
    }
  }, [elementToScroll, tripId, shopSlug, dateFrom, dateTo])

  return (
    <ItineraryModal
      isLoading={isLoading}
      itinerary={itinerary}
      containerRef={ref}
    />
  )
}

export default connect((state) => ({
  itineraryPopupData: state.itineraryPopupData,
}))(ItineraryPopup)
