import { useMemo, useCallback } from 'react'
import { triggerFilter } from './MobileFilterToggle'
import { FormattedMessage } from 'react-intl'
import TripOrganiser from '../search-group/TripOrganiser'
import FilterGroup from './filters/FilterGroup'
import { connect } from 'react-redux'

function FilterList({
  items,
  expandedFilters,
  updateParams,
  selectedFilters,
  hideRange,
  url,
  label,
  organiserPlaceholder,
  showTripOrganiser,
  filtersCount,
  clearSelectedFilters,
  diveResort,
  groupTrips,
}) {
  const defaultFiltersState = useMemo(
    () => ({
      price: true,
      duration: true,
      diving_intensity: true,
      experience: true,
      tag: true,
      ...expandedFilters,
    }),
    []
  )

  const updateCheckbox = useCallback(
    (filter, id, value, item = {}) => {
      let secondLevelItems = {}
      if(item.subitems && item.subitems.length > 0) {
        secondLevelItems = {
          marine_life_critters: { ...selectedFilters.marine_life_critters },
        }

        item.subitems.map(function(subitem) {
          secondLevelItems['marine_life_critters'][subitem.id] = value
        })
      }
      const key = item.query_name || filter
      updateParams({
        [key]: {
          ...selectedFilters[key],
          [id]: value,
        },
        ...secondLevelItems,
      })
    },
    [selectedFilters]
  )

  return (
    <div className='filter-list' id='filter-list'>
      <p>
        <FormattedMessage id='filter_by' defaultMessage='Filter by' />
        {!!filtersCount && (
          <span className='clear-filter' onClick={clearSelectedFilters}>
            <FormattedMessage
              id='clear_filters'
              defaultMessage='Clear all filters'
            />
          </span>
        )}
        <span className='close close-icon visible-xs' onClick={triggerFilter} />
      </p>

      {showTripOrganiser && (
        <div className='filter-row clearfix'>
          <p className='title hide-arrow'>{label}</p>
          <div className='form-group w_icon'>
            <i className='font-icons field-icon find-icon' />
            <TripOrganiser
              onSuggestionSelect={updateParams}
              inputValue={selectedFilters.organiserTitle}
              placeholder={organiserPlaceholder}
              url={url}
              hideRange={hideRange}
            />
          </div>
        </div>
      )}
      {items &&
        items.map((filter, key) => {
          return (
            <FilterGroup
              selectedFilters={selectedFilters}
              updateCheckbox={updateCheckbox}
              filter={filter}
              key={filter.query_name + filter.title}
              diveResort={diveResort}
              groupTrips={groupTrips}
              defaultFiltersState={defaultFiltersState}
            />
          )
        })}
      <button className='done btn-red visible-xs' onClick={triggerFilter}>
        <FormattedMessage id='done' defaultMessage='Done' />
      </button>
    </div>
  )
}

const mapStateToPros = ({ searchFilters }) => ({
  searchFilters,
})

export default connect(mapStateToPros)(FilterList)
