import useBounds from './hooks/useBounds'
import useSetLocatorFiltersQuery from './hooks/useSetLocatorFiltersQuery'
import useShopLocatorMap from './hooks/useShopLocatorMap'
import useUserCoordinates from './hooks/useUserCoordinates'
import Map from './map/Map'

import { Loader } from '../helpers'
import { MapContainer } from 'react-leaflet'
import { isMainLand } from '../../config'
import { isMobile } from '../../utils'
import { useMemo, useRef } from 'react'
import { latLngBounds } from 'leaflet'
import MapLayoutToggle from './map/MapLayoutToggle'

import styles from './styles/DiveShopLocatorMap.module.scss'

const ZOOM = 12
const isMobileDevice = isMobile()

export default function DiveShopLocatorMap({
  location,
  locationBounds,
  pageType,
  countryCode,
}) {
  const mapRef = useRef()
  useSetLocatorFiltersQuery()
  const { userCoordinates, waitingForCoords } = useUserCoordinates(true)
  const { bounds: customBounds } = useBounds()
  const { primaryPins, secondaryPins } = useShopLocatorMap(
    location,
    customBounds,
    userCoordinates,
    pageType,
    locationBounds,
  )

  const initBounds = useMemo(() => {
    const b = latLngBounds()
    if(!locationBounds) {
      primaryPins.forEach((coord) => {
        if(coord.latitude && coord.longitude) {
          b.extend([coord.latitude, coord.longitude])
        }
      })
    } else if(locationBounds) {
      b.extend([locationBounds.northeast.lat, locationBounds.northeast.lng])
      b.extend([locationBounds.southwest.lat, locationBounds.southwest.lng])
    }

    return b
  }, [locationBounds])

  return (
    <div className={styles.container}>
      <Loader isLoading={waitingForCoords} zIndex={9999} />
      {pageType !== 'courses' && <MapLayoutToggle pageType={pageType} />}
      <MapContainer
        zoom={ZOOM}
        style={{ height: '100%' }}
        zoomControl={false}
        minZoom={isMainLand(countryCode) ? 2 : 1}
        touchZoom={isMobileDevice}
        tap={!isMobileDevice}
        worldCopyJump
        preferCanvas
        bounds={initBounds}
        ref={mapRef}
      >
        <Map
          locationBounds={locationBounds}
          locationType={location?.type}
          primaryPins={primaryPins}
          secondaryPins={secondaryPins}
          pageType={pageType}
          countryCode={countryCode}
          waitingForCoords={waitingForCoords}
        />
      </MapContainer>
    </div>
  )
}
