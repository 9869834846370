import { Field } from 'react-final-form'
import { pushToDataLayer } from '../../../../gtm'
import styles from './RadioButtonSelect.module.scss'

export default function RadioButtonSelect({
  title,
  titleEn,
  sectionTitle,
  value,
  queryName,
}) {
  const handleChange = (value) => {
    if(value) {
      pushToDataLayer({
        event: 'interaction',
        eventCategory: 'Dive Shop',
        eventAction: 'Search - Filter',
        eventLabel: `${sectionTitle} - ${titleEn || title}`,
        eventValue: '0',
        eventNonInteraction: 'false',
      })
    }
  }
  return (
    <Field
      name={queryName || 'name'}
      component={Radio}
      type='radio'
      title={title}
      onChange={handleChange}
      value={`${value}`}
    />
  )
}

const Radio = ({ input, title, onChange }) => {
  const handleChange = (e) => {
    input.onChange(e)
    if(onChange) {
      onChange(e.target.checked)
    }
  }
  return (
    <label className={styles.radioWrap}>
      <input
        type='radio'
        className={styles.inputCheckbox}
        {...input}
        key={input.checked}
        onChange={handleChange}
      />
      <span className={styles.checkMark} />
      <p className={styles.title}>{title}</p>
    </label>
  )
}
