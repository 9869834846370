import { useLayoutEffect, useState } from 'react'
import API from 'api'
import useLocatorFilters from './useLocatorFilters'
import { useDispatch } from 'react-redux'
import { setShopInfo } from '../../../actions/dive-guide-actions'

const getPinUrl = (pinType, id) => {
  switch (pinType) {
    case 'conservations':
      return `/api/adventure/v1/search/conservation/${id}/map/`
    case 'professional':
      return `/api/adventure/v1/search/professional/${id}/map/`
    default:
      return `dsl/${pinType}/${id}/map/`
  }
}
const usePinInfo = (id, pinType, isOpen) => {
  const dispatch = useDispatch()
  const [pinInfo, setPinInfo] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { filters } = useLocatorFilters(false, window.pageType)

  useLayoutEffect(() => {
    if(!isOpen || !id) {
      return
    }
    setIsLoading(true)
    API(getPinUrl(pinType, id))
      .get(filters)
      .then((info) => {
        setPinInfo(info)
        dispatch(setShopInfo(info))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [isOpen, id])

  return {
    pinInfo,
    isLoading,
  }
}

export default usePinInfo
