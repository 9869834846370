exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".filters--2RMSR{display:flex;align-items:center;flex-wrap:wrap;row-gap:24px}@media (max-width:1024px){.filters--2RMSR{row-gap:16px;-moz-column-gap:20px;column-gap:20px}}@media (max-width:768px){.filters--2RMSR{row-gap:16px}}.separator--1Z8_t{height:32px;background:#e0e0e0;width:1px;margin:0 12px}.checkbox--1oDg_{margin-right:16px}.checkbox--1oDg_:last-of-type{margin-right:0}.additionalRow--14ZbA{display:flex;align-items:center;padding-left:8px}@media (max-width:1400px){.additionalRow--14ZbA{width:100%}.additionalRow--14ZbA .separator--1Z8_t{display:none}}@media (min-width:1024px) and (max-width:1150px){.additionalRow--14ZbA{width:auto}}@media (max-width:1024px){.additionalRow--14ZbA{width:auto;padding-left:8px}}@media (max-width:1024px){.desktopFilters--1GVzE{display:none}}@media (max-width:768px){.mobileFilters--30OeE{justify-content:space-between}}@media (min-width:1025px){.mobileFilters--30OeE{display:none}}.diveCourseAddFilters--2egjU{display:flex;align-items:center}", ""]);

// Exports
exports.locals = {
	"filters": "filters--2RMSR",
	"separator": "separator--1Z8_t",
	"checkbox": "checkbox--1oDg_",
	"additionalRow": "additionalRow--14ZbA",
	"desktopFilters": "desktopFilters--1GVzE",
	"mobileFilters": "mobileFilters--30OeE",
	"diveCourseAddFilters": "diveCourseAddFilters--2egjU"
};