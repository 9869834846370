import moment from 'moment'
import find from 'lodash/find'
import { FormattedMessage } from 'react-intl'
import Select from 'react-select'
import store from 'store2'
import TravelDatepicker from '../../helpers/TravelDatepicker'
import InlineMultiselect from '../../helpers/InlineMultiselect'
import LACheckbox from './LACheckbox'
import GuestPicker from '../../forms/GuestPicker'
import { autosuggestStyles } from '../../search/helpers'

const LATableHead = ({ itineraries, duration, setParams, params, setDepartureDateMonth, setDepartureDateLess, setTotalGuests, departureDateMonth, monthes, isCheckout }) => {
  const triggerCheckboxes = (name, val) => {
    setParams((prevParams) => ({
      ...prevParams,
      [name]: val,
    }))
  }

  const updateMonth = (selectedOption) => {
    setParams((prevParams) => ({
      ...prevParams,
      departure_date: selectedOption.from,
      page: 1,
    }))
    setDepartureDateMonth(selectedOption)
    setDepartureDateLess(false)
    store('departure_date', selectedOption)
    store('date', [selectedOption.from, selectedOption.to])
  }

  const handleGuestsChange = ({ totalGuests }) => {
    setTotalGuests(totalGuests)
  }

  const updateFilters = (item, value) => {
    setParams((prevParams) => ({
      ...prevParams,
      [item]: value,
      page: 1,
    }))
  }

  const datePickerCallback = (date) => {
    if(!date.dateFrom) {
      store.remove('departure_date')
      store.remove('date')
      return updateMonth(monthes[1])
    }

    let selectedDate = find(monthes, {
      from: moment(date.dateFrom).startOf('M').format('YYYY-MM-DD'),
    })

    if(!selectedDate) {
      selectedDate = {}
    }

    let clonedDate = { ...selectedDate }

    clonedDate.from = date.dateFrom

    updateMonth(clonedDate)
  }

  return (
    <div className='tr thead'>
      { !isCheckout && (<div className='pricing-filters'>
        <div className='pricing-filters__block'>
          <LACheckbox handler={(e) => triggerCheckboxes('is_available', e.target.checked)}
            value={params.is_available}
            label={
              <FormattedMessage
                id='pricing.all_available_trips'
                defaultMessage='All available trips'
              />
            }
          />
          <LACheckbox
            handler={(e) =>
              triggerCheckboxes('is_promotion', e.target.checked)
            }
            value={params.is_promtion}
            label={
              <FormattedMessage
                id='pricing.only_special_deals'
                defaultMessage='Only special deals'
              />
            }
          />
        </div>
        <div className='pricing-filters__block'>
          <div className='pricing-filters__field date-dropdown'>
            <i className='font-icons field-icon calendar-icon' />
            <Select
              onChange={(selectedOption) => updateMonth(selectedOption)}
              value={departureDateMonth}
              options={monthes}
              styles={autosuggestStyles}
              isSearchable={false}
              valueKey='id'
              className='select-container'
              classNamePrefix='select-container'
            />
          </div>
          <div className='pricing-filters__field'>
            <GuestPicker
              className='la-guest-picker'
              hideError
              showIcon
              isLiveaboard
              noStudentsPackages
              onSelect={handleGuestsChange}
            />
          </div>
        </div>
      </div>) }
      <div className='td td-date content-center'>
        <div className='multi-select white'>
          <span
            onClick={() => document.getElementById('dp-pricing').click()}
          >
            <FormattedMessage id='start_date' defaultMessage='Start date' />
          </span>
          <TravelDatepicker id='dp-pricing' callback={datePickerCallback} />
        </div>
      </div>
      <div className='td td-itinerary-information'>
        <div className='td-itinerary content-left'>
          <InlineMultiselect
            variants={itineraries}
            onSelect={(items) => updateFilters('itinerary', items)}
            title={
              <FormattedMessage id='itinerary' defaultMessage='Itinerary' />
            }
          />
        </div>
        <div className='td-duration content-left'>
          <InlineMultiselect
            variants={duration}
            onSelect={(items) => updateFilters('duration', items)}
            title={
              <FormattedMessage id='duration' defaultMessage='Duration' />
            }
          />
        </div>
      </div>
      <div className='td td-price content-right'>
        <span>
          <FormattedMessage
            id='price_per_trip_person'
            defaultMessage='Price per trip / person'
          />
        </span>
      </div>
      <div className='td td-actions'>&nbsp;</div>
    </div>
  )
}

export default LATableHead
