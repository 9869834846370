import { Fragment, useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get'

const Row = ({ allDaysOpened, index, day, isLast }) => {
  const [isOpened, setOpen] = useState(false)

  const toggleOpen = () => setOpen((isOpen) => !isOpen)
  const isLastDay = index !== 0 && isLast

  useEffect(() => {
    setOpen(allDaysOpened)
  }, [allDaysOpened])

  return (
    <div
      className={`font-icons${index === 0 ? ' first-day' : ''}${
        isLastDay ? ' last-day' : ''
      }${isOpened ? ' expanded' : ''}`}
    >
      <p className='title' onClick={toggleOpen}>
        <FormattedMessage id='day' defaultMessage='Day' />
        &nbsp;{day.dayStart}{' '}
        {day.dayStart !== day.dayFinish && ' - ' + day.dayFinish}
        <span className={`more ${isOpened ? 'active' : ''}`} />
      </p>
      {isOpened && (
        <div dangerouslySetInnerHTML={{ __html: day.description }} />
      )}
    </div>
  )
}

export default function ItineraryDescription({ itinerary }) {
  const [allDaysOpened, setAllDaysOpen] = useState(false)

  const toggleDays = () => {
    setAllDaysOpen((prevState) => !prevState)
  }

  if(!itinerary.isDayByDay) {
    return null
  }

  const daysLength = get(itinerary, 'days.length', [])
  return (
    <Fragment>
      <div className='itinerary-description-section itinerary-section'>
        <h4>
          <FormattedMessage
            id='itineraryDescription'
            defaultMessage='Itinerary description'
          />
        </h4>
        <span
          className={`more ${allDaysOpened ? 'active' : ''}`}
          onClick={toggleDays}
        >
          {allDaysOpened ? (
            <FormattedMessage
              id='hide_all_days'
              defaultMessage='Hide all days'
            />
          ) : (
            <FormattedMessage
              id='show_all_days'
              defaultMessage='Show every day'
            />
          )}
        </span>
        <div className='itinerary-description wysiwyg'>
          {daysLength ? (
            itinerary.days.map((day, index) => (
              <Row
                allDaysOpened={allDaysOpened}
                key={day.dayStart}
                index={index}
                day={day}
                isLast={daysLength === index + 1}
              />
            ))
          ) : (
            <div>
              <FormattedMessage
                id='itinerary_coming_soon'
                defaultMessage='The itinerary description is coming soon, in the meantime please do not hesitate to contact us.'
              />
            </div>
          )}
        </div>

        <div className='image-list'>
          {itinerary.photos.map((image) => {
            const imageUrl = get(image, 'thumbnails.DOSliderMobilePort')
            return (
              <div className='image-list__item' key={image.id}>
                <img
                  className='cabin-image'
                  src={imageUrl}
                  title={image.title ? image.title : 'Itinerary'}
                  width='100%'
                />
              </div>
            )
          })}
        </div>
      </div>
    </Fragment>
  )
}
