import { FormattedMessage } from 'react-intl'

export default function OperatorSwitchDr({ quantity, title, searchUrl }) {
  return (<a className='operator-switch' href={searchUrl} >
    <span className='operator-switch__content'>
      <span className='circle-icon'>
        <i className='font-icons liveaboard-icon' />
      </span>
      <span className='operator-switch__text'>
        <FormattedMessage id='other_liveaboards' defaultMessage='See { qty, plural, one {# liveaboard} other {# liveaboards}} { locationTitle }' values={{
          qty: quantity,
          locationTitle: title,
        }} />
      </span>
    </span>
    <button className='operator-switch__button'>
      <FormattedMessage id='other_liveaboards.button' defaultMessage='See { qty, plural, one {# liveaboard} other {# liveaboards}}' values={{
        qty: quantity,
      }} /></button>
  </a>)
}
