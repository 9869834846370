import { useState, useEffect } from 'react'
import { parseQuery } from '../utils'
import API from 'api'

const useLAItineraries = (slug) => {
  const [itineraries, setItineraries] = useState([])

  useEffect(() => {
    API(`shop/${window.shop.slug}/itineraries/`)
      .get({
        kind: 10,
      })
      .then((itineraries) => {
        const queryParams = parseQuery() || {}
        itineraries.results.forEach((itinerary) => {
          itinerary.checked = false
          if(queryParams.itinerary_id) {
            if(typeof queryParams.itinerary_id === 'string') {
              if(itinerary.id === Number(queryParams.itinerary_id)) {
                itinerary.checked = true
              }
            } else {
              if(queryParams.itinerary_id.includes(itinerary.id.toString())) {
                itinerary.checked = true
              }
            }
          }
        })
        setItineraries(itineraries.results)
      })
  }, [])

  return itineraries
}

export default useLAItineraries
