import { Fragment } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

const msg = defineMessages({
  customizeDives: {
    id: 'tooltip.customize_dives',
    defaultMessage:
      'You can customize the number of dives for each diver during the checkout process. The package price will be adjusted dynamically',
  },
})

export default function DiverAmenities({ shop, selectedParams }) {
  const intl = useIntl()
  return (
    <p className='dives'>
      {shop.divesInPackageKind !== 30 && !!shop.numberOfDives && (
        <Fragment>
          <i className='font-icons dive-center-icon' />
          <FormattedMessage
            id='incl_number_of_dive_dive_type'
            values={{
              numberOfDives: shop.numberOfDives / (selectedParams.divers || 1),
              diveType: shop.diveTypeTitle,
            }}
            defaultMessage='Incl. { numberOfDives } { diveType } { numberOfDives, plural, one {dive} other {dives}} per diver'
          />
          {shop.divesInPackageKind === 20 && (
            <Fragment>
              <br />
              +
              <FormattedMessage
                id='unlimited_shore_dives'
                defaultMessage='Unlimited shore dives'
              />
            </Fragment>
          )}
        </Fragment>
      )}
      {shop.divesInPackageKind === 30 && (
        <Fragment>
          <i className='font-icons dive-center-icon' />
          <FormattedMessage
            id='unlimited_shore_dives'
            defaultMessage='Unlimited shore dives'
          />
        </Fragment>
      )}
      {!shop.availablePackageKinds && (
        <span
          className='tooltip-wrapper-block pill green'
          data-for='global'
          data-tip={intl.formatMessage(msg.customizeDives)}
        >
          <FormattedMessage
            id='fully_flexible'
            defaultMessage='Fully flexible'
          />
          <i className='font-icons info-icon' />
        </span>
      )}
    </p>
  )
}
