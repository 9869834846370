import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { SearchTypesList, MobileTypesList } from './index'
import { handleEventDefault } from '../../dom-handlers'
import { setInputFocus } from '../../actions/search-actions'

function MobileStickyHeader({
  locationTitle,
  selectedDates,
  typeOfSearch,
  triggerDatepicker,
}) {
  const [isSelectingType, setSelectingType] = useState(false)
  const dispatch = useDispatch()

  const handleSelectorClick = () => {
    toggleSelectingView()
  }

  const toggleSelectingView = () => {
    setSelectingType((prev) => !prev)
  }

  const onTriggerLocationClick = (e) => {
    handleEventDefault(e)
    dispatch(setInputFocus(true))
  }

  const onTriggerDatepickerClick = (e) => {
    if(typeof triggerDatepicker === 'function') {
      handleEventDefault(e)
      triggerDatepicker(e)
    }
  }

  return (
    <Fragment>
      <div
        className='pt-sticky-top fixed'
        sticky-top='sticky-top'
        trigger='#la-main-filters'
      >
        <div className='mobile-sticky-wrapper'>
          <a
            onClick={onTriggerLocationClick}
            className='search-link'
            href='#la-main-filters'
            scroll-to='#la-main-filters'
            smooth-scroll='smooth-scroll'
          >
            <span className='search-link__title'>{locationTitle}</span>
            <i className='font-icons bottom-arrow-icon' />
          </a>
          {typeOfSearch !== 'group-trips' && (
            <a
              onClick={handleSelectorClick}
              className='search-link search-link--xs'
            >
              <span className='search-link__title'>{typeOfSearch}</span>
              <i className='font-icons bottom-arrow-icon' />
            </a>
          )}
          {typeOfSearch !== 'dive-centers' && (
            <a
              onClick={onTriggerDatepickerClick}
              className='search-link'
              scroll-to='#la-main-filters'
              smooth-scroll='smooth-scroll'
            >
              <span className='search-link__title'>
                {selectedDates || (
                  <FormattedMessage id='any_date' defaultMessage='any date' />
                )}
              </span>
              <i className='font-icons bottom-arrow-icon' />
            </a>
          )}
        </div>
      </div>
      {isSelectingType && (
        <MobileTypesList id='types' onClose={toggleSelectingView}>
          <SearchTypesList />
        </MobileTypesList>
      )}
    </Fragment>
  )
}

export default MobileStickyHeader
