import { useEffect, useState } from 'react'
import API from 'api'
import useLocatorFilters from './useLocatorFilters'
import useIsMapMoved from './useIsMapMoved'
import omit from 'lodash/omit'

let controller
let signal

const getPrimaryPinsAPI = (pageType) => {
  switch (pageType) {
    case 'dive-sites':
      return 'dsl/dive-sites/map/'
    case 'conservation':
      return `/api/adventure/v1/search/conservation/map/`
    case 'professional':
      return `/api/adventure/v1/search/professional/map/`
    case 'courses':
      return `dsl/courses/map/`
    default:
      return 'dsl/dive-shops/map/'
  }
}

const getSecondaryPinsAPI = (pageType) => {
  if(pageType === 'dive-sites') {
    return `dsl/dive-shops/map/`
  }
  if(pageType === 'conservation') {
    return `dsl/dive-shops/map/`
  }
  if(pageType === 'professional') {
    return `dsl/dive-shops/map/`
  }

  return 'dsl/dive-sites/map/'
}

export default function useShopLocatorMap(
  location = {},
  currentBounds,
  userCoordinates,
  pageType,
  locationBounds,
) {
  const { isMapMoved } = useIsMapMoved()
  const { filters, secondaryPinsVisible } = useLocatorFilters()
  const [primaryPins, setPrimaryPins] = useState([])
  const [secondaryPins, setSecondaryPins] = useState([])
  const { type, id } = location
  const isGoogleLocation = type === 'gplace'
  const { northeast, southwest } = currentBounds
  const leftBound = `${southwest.lat},${southwest.lng}`
  const rightBound = `${northeast.lat},${northeast.lng}`
  const coordinates = {
    top_right: rightBound,
    bottom_left: leftBound,
  }

  useEffect(() => {
    fetchPins()
    return () => controller && controller.abort()
  }, [currentBounds, JSON.stringify(filters), userCoordinates])

  const fetchPins = () => {
    if('AbortController' in window) {
      controller = new window.AbortController()
      signal = controller.signal
    }
    const filtersForMap = omit(filters, 'shop_id')

    let options =
      isMapMoved || isGoogleLocation
        ? {
          ...coordinates,
          ...filtersForMap,
        }
        : {
          [type]: id,
          ...filtersForMap,
        }

    if(window.isNearBy && !isMapMoved) {
      options.top_right = undefined
      options.bottom_left = undefined
      options.distance = window.distance
      if(pageType === 'courses') {
        const { southwest, northeast } = locationBounds
        options.top_right = `${northeast.lat},${northeast.lng}`
        options.bottom_left = `${southwest.lat},${southwest.lng}`
      }

      if(userCoordinates) {
        options.point = `${userCoordinates.lat},${userCoordinates.lng}`
      }
    }
    if(pageType === 'courses') {
      options = omit(options, 'continent')
    }

    const requests = [API(getPrimaryPinsAPI(pageType), signal).get(options)]
    if(secondaryPinsVisible) {
      requests.push(API(getSecondaryPinsAPI(pageType), signal).get(options))
    }

    Promise.all(requests).then(([primaryItems, secondaryItems]) => {
      setPrimaryPins(primaryItems)
      if(secondaryPinsVisible) {
        setSecondaryPins(secondaryItems)
      }
    })
  }

  return { primaryPins, secondaryPins, fetchPins, filters }
}
