import { Marker } from 'react-leaflet'
import {
  dslConservationPin,
  dslConservationPinSelected,
} from '../../../config'
import useMarkerHover from '../hooks/useMarkerHover'
import { memo } from 'react'
import isEqual from 'lodash/isEqual'
import ConservationPopup from './ConservationPopup'

const getIcon = (pin, activeMarker) => {
  return activeMarker === pin.id
    ? dslConservationPinSelected
    : dslConservationPin
}

function Markers({ pins }) {
  const {
    activeMarker,
    handleMouseOut,
    handleMouseOver,
    handleClick,
    onPopupClose,
    opennedMarker,
  } = useMarkerHover()
  if(!Array.isArray(pins)) {
    console.error('Pins is not an array', pins)
    return null
  }
  return pins?.map((pin) => {
    return (
      <Marker
        key={`${pin.id}_${pin.latitude}_${pin.longitude}`}
        id={pin.id}
        icon={getIcon(pin, activeMarker)}
        position={[pin.latitude, pin.longitude]}
        zIndexOffset={activeMarker === pin.id ? 1000 : 0}
        eventHandlers={{
          mouseover: handleMouseOver,
          mouseout: handleMouseOut,
          click: handleClick,
        }}
        riseOnHover
      >
        <ConservationPopup
          id={pin.id}
          onPopupClose={onPopupClose}
          isOpen={opennedMarker === pin.id}
        />
      </Marker>
    )
  })
}

const compare = (prevProps, nextProps) => {
  if(!isEqual(prevProps.pins, nextProps.pins)) {
    return false
  }
  return true
}

export default memo(Markers, compare)
