import { calculateDiscountPercent } from '../../utils'
import { FormattedMessage } from 'react-intl'

export default function PromotionCorner({
  kind,
  price,
  compareAtPrice,
  title,
  description,
  value,
}) {
  function getTextByKind() {
    let label

    switch (kind) {
      case 10:
        label = (
          <span className='label-text'>
            -{calculateDiscountPercent(price, compareAtPrice)}%
          </span>
        )
        break
      case 20:
        label = <span className='label-text'>-{value}%</span>
        break
      case 30:
        label = (
          <span className='label-text free-nights'>
            <FormattedMessage
              id='night_qty'
              values={{ qty: value }}
              defaultMessage='{qty, plural, one {# night} other {# nights}}'
            />
          </span>
        )
        break
      case 40:
        label = (
          <span className='label-text label-gift'>
            <FormattedMessage id='gift' defaultMessage='Gift' />
          </span>
        )
        break
    }

    return label
  }

  return (
    <span className='label-corner'>
      {getTextByKind()}
      <span className='details'>
        <FormattedMessage id='details' defaultMessage='Details' />
      </span>
      <div className='description-promo'>
        <p>{title}</p>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      <span className='close-btn-corner'>X</span>
    </span>
  )
}
