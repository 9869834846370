/* eslint-disable  react/jsx-indent */
/* eslint-disable  react/jsx-indent-props */

import { useState } from 'react'
import find from 'lodash/find'
import get from 'lodash/get'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import store from 'store2'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useLAItineraries, useInsurances, useTrips } from '../../hooks'
import { generateMonthPicker, parseQuery } from 'utils'
import { LiveaboardPricingOnRequest } from './LiveaboardPricingOnRequest'

import ItineraryPopup from '../popups/ItineraryPopup'
import LANextPrevButtonBlock from './pricing/LANextPrevButtonBlock'
import LAGroupedProducts from './pricing/LAGroupedProducts'
import LATableHead from './pricing/LATableHead'
import LAPlaceholderBlock from './pricing/LAPlaceholderBlock'

const msg = defineMessages({
  durationTitle: {
    id: 'night_qty',
    defaultMessage: '{qty, plural, one {# night} other {# nights}}',
  },
})

const setFilterObject = (queryParams) => {
  let date = moment().add(1, 'M').format('YYYY-MM-DD')
  if(store('date')) {
    date = store('date')[0]
  } else if(
    store('departure_date') &&
    typeof store('departure_date') === 'object'
  ) {
    date = store('departure_date').from
  }
  if(queryParams.trip_date) {
    date = queryParams.trip_date
  }

  let filterObject = {
    from: moment(date).startOf('M').format('YYYY-MM-DD'),
  }

  if(queryParams.itinerary_id) {
    filterObject = {
      id: 'all',
    }
  }
  return { filterObject, date }
}

const LiveaboardPricing = ({
  monthsAmountWithProducts,
  itinerariesLength,
  slug,
  title,
  currency,
  includeFreeDiveInsurance,
  onRequest,
  id,
  background,
  countrySlug,
  isCheckout,
  onSelect,
  isWidget,
}) => {
  const intl = useIntl()
  const itineraries = useLAItineraries(slug)
  const insurances = useInsurances(includeFreeDiveInsurance)
  const monthes = generateMonthPicker(monthsAmountWithProducts, true)
  const queryParams = parseQuery() || {}
  const roomParams = store('roomParams')
  const defaultTotalGuests = get(roomParams, 'totalGuests')
  const [duration] = useState(
    itinerariesLength.map((item) => ({
      id: item,
      checked: false,
      title: intl.formatMessage(msg.durationTitle, { qty: item }),
    })),
  )

  const { filterObject, date } = setFilterObject(queryParams)

  const [params, setParams] = useState({
    page: 1,
    departure_date: isCheckout ? moment().format('YYYY-MM-DD') : date,
    page_size: 4,
    itinerary: queryParams.itinerary_id,
    is_promotion: false,
    is_available: false,
  })
  const selectedMonthItem = find(monthes, filterObject)

  const [totalGuests, setTotalGuests] = useState(defaultTotalGuests)
  const [departureDateLess, setDepartureDateLess] = useState(false)
  const [departureDateMonth, setDepartureDateMonth] = useState(
    isCheckout ? monthes[1] : selectedMonthItem,
  )

  const { groupedProducts, next, previous, isLoading } = useTrips(
    departureDateMonth,
    params,
    departureDateLess,
    slug,
    title,
    currency,
    roomParams,
    isCheckout,
  )

  if(onRequest) {
    return <LiveaboardPricingOnRequest id={id} title={title} />
  }

  const renderProducts = () => {
    if(isLoading) {
      return <LAPlaceholderBlock blockSize={params.page_size} />
    }

    if(isEmpty(groupedProducts)) {
      return (
        <div className='no-products'>
          <FormattedMessage
            id='no_trips_match_criteria'
            defaultMessage='No trips match your criteria'
          />
        </div>
      )
    }
    return (
      <LAGroupedProducts
        groupedProducts={groupedProducts}
        departureDateMonth={departureDateMonth}
        isCheckout={isCheckout}
        params={params}
        background={background}
        currency={currency}
        shopSlug={slug}
        shopTitle={title}
        countrySlug={countrySlug}
        insurances={insurances}
        onSelect={onSelect}
        totalGuests={totalGuests}
        queryParams={queryParams}
      />
    )
  }

  return (
    <div className={`la-pricing-table ${isLoading && 'loading'}`}>
      <ItineraryPopup />
      <div promotion-tooltip='promotion-tooltip'>
        <LATableHead
          params={params}
          setParams={setParams}
          setDepartureDateLess={setDepartureDateLess}
          departureDateMonth={departureDateMonth}
          setDepartureDateMonth={setDepartureDateMonth}
          setTotalGuests={setTotalGuests}
          itineraries={itineraries}
          duration={duration}
          monthes={monthes}
          isCheckout={isCheckout}
        />
        {renderProducts()}
      </div>
      {get(departureDateMonth, 'id') !== 'all' && (
        <LANextPrevButtonBlock
          departureDateLess={departureDateLess}
          setDepartureDateLess={setDepartureDateLess}
          params={params}
          setParams={setParams}
          next={next}
          previous={previous}
        />
      )}
    </div>
  )
}

export default LiveaboardPricing
