import { defineMessages, useIntl } from 'react-intl'
import { isStaff } from '../utils'

export const SORT_OPTIONS = defineMessages({
  padi_default: {
    id: 'padi_default',
    defaultMessage: 'PADI default',
    value: 'padi_default',
  },
  distance: {
    id: 'distance_closest_first',
    defaultMessage: 'Distance (closest first)',
    value: 'distance',
  },
})

export const SORT_BY_STAFF = defineMessages({
  price: {
    id: 'price_low_to_hight',
    defaultMessage: 'Price (low to high)',
    value: 'price',
  },
})

export const GROUP_BY = defineMessages({
  boats: {
    id: 'activity',
    defaultMessage: 'Activity',
    value: 'activity',
    icon: 'dive-center-icon',
  },
  dates: {
    id: 'date',
    defaultMessage: 'Date',
    value: 'date',
    icon: 'calendar-icon',
  },
})

export const SORT_BY = isStaff()
  ? { ...SORT_OPTIONS, ...SORT_BY_STAFF }
  : SORT_OPTIONS

export const filtersKeys = [
  'departure_date',
  'departure_date_custom',
  'routes',
  'marine_life_critters',
  'marine_life_group_critters',
  'experiences',
  'instant_confirmation',
  'departure_times',
  'languages',
  'shop_parameters',
  'diving_intensity',
  'rating',
  'parameters',
  'themes',
  'harbours',
  'duration',
  'price',
  'is_promotion',
  'sponsors',
  'view',
  'ordering',
  'shop',
  'tag',
  'shop_id',
  'date',
  'standard_name_ids',
  'params',
  'organiserTitle',
  'shop_params',
  'point',
  'distance',
  'partner',
]

export const filtersToClean = [
  'routes',
  'marine_life_critters',
  'marine_life_group_critters',
  'experiences',
  'instant_confirmation',
  'departure_times',
  'languages',
  'shop_parameters',
  'diving_intensity',
  'rating',
  'parameters',
  'themes',
  'harbours',
  'duration',
  'price',
  'is_promotion',
  'sponsors',
  'tag',
  'shop_params',
  'standard_name_ids',
  'params',
  'organiserTitle',
  'point',
  'distance',
]

const Options = ({ translatedObj }) => {
  const intl = useIntl()
  const optionsList = Object.keys(translatedObj)
  return optionsList.map((item) => {
    const elemObject = translatedObj[item]
    return (
      <option value={elemObject.value} key={elemObject.id}>
        {intl.formatMessage(elemObject)}
      </option>
    )
  })
}

export const TranslatedOptions = Options

const PADI_SCUBA_DIVER = '5'
const OPEN_WATER_DIVER = '6'
const OPEN_WATER_DIVER_ELEARNING = '60'
const OPEN_WATER_DIVER_REFERRAL = '61'
const ADVANCED_OPEN_WATER_DIVER = '9'
const ADVANCED_OPEN_WATER_DIVER_ELEARNING = '74'
const SCUBA_REFRESHER = '7'
const RESCUE_DIVER = '10'
export const preselectedCourses = [
  PADI_SCUBA_DIVER,
  OPEN_WATER_DIVER,
  OPEN_WATER_DIVER_ELEARNING,
  OPEN_WATER_DIVER_REFERRAL,
  ADVANCED_OPEN_WATER_DIVER,
  ADVANCED_OPEN_WATER_DIVER_ELEARNING,
  SCUBA_REFRESHER,
  RESCUE_DIVER,
]
