import { FormattedMessage } from 'react-intl'
import { target } from '../../../utils'
import { ShopSlider } from '../../search'
import ShopTags from './ShopTags'
import useMarkerHover from '../hooks/useMarkerHover'
import styles from '../styles/ShopList.module.scss'
import cx from 'classnames'

export default function DiveSiteItemCard({ item }) {
  const { setActiveMarker } = useMarkerHover()
  const highlightPinOnMap = () => setActiveMarker(item.id)
  const removeHighlight = () => setActiveMarker(false)
  const { maximumDepth, marineLife } = item

  return (
    <a
      href={item.travelUrl}
      className={styles.container}
      target={target}
      onMouseEnter={highlightPinOnMap}
      onMouseLeave={removeHighlight}
    >
      <p className={styles.title}>{item.title}</p>
      <div className={styles.pictureContainer}>
        <ShopSlider
          imgPath='800x800'
          currentPhotos={item.images}
          shopUrl={item.travelUrl}
        />
      </div>
      <ShopTags tags={item.types} isArrayList />
      <div className={styles.detailsRow}>
        <div className={styles.details}>
          <p className={styles.detailsTitle}>
            <FormattedMessage
              id='marine_life_dsl_card'
              defaultMessage='Marine life'
            />
          </p>
          <p className={styles.detailsInfo}>
            {marineLife.length ? marineLife.join(', ') : '–'}
          </p>
        </div>
        <div className={styles.details}>
          <p className={styles.detailsTitle}>
            <FormattedMessage id='max_depth' defaultMessage='Max depth' />
          </p>
          {maximumDepth ? (
            <p className={styles.detailsInfo}>
              {Math.round(maximumDepth.FEET.value)} {maximumDepth.FEET.title} /{' '}
              {maximumDepth.METERS.value} {maximumDepth.METERS.title}
            </p>
          ) : (
            '–'
          )}
        </div>
      </div>
      <div className={styles.btnContainer}>
        <button className={cx(styles.btn, 'btn-guides-blue')}>
          <FormattedMessage id='view_details' defaultMessage='View Details' />
        </button>
      </div>
    </a>
  )
}
