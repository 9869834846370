import { Fragment } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import DateFormat from '../../helpers/DateFormat'
import PriceConverter from '../../helpers/PriceConverter'

const msg = defineMessages({
  tooltipCharter: {
    id: 'tooltip.charter',
    defaultMessage:
      'Want some more information on prices and conditions? Just click to start our our live chat',
  },
  tooltipAvailability: {
    id: 'tooltip.availability',
    defaultMessage:
      'Availability can change rapidly. Write us in our live chat now and we will double check the availability for you',
  },
})

export default function TripRow({
  trip,
  showItinerary,
  selectedParams,
  currency,
  handleClick,
}) {
  const intl = useIntl()
  const noSpacesForSelectedGuests =
    selectedParams.totalGuests > trip.availableSpots
  const onLinkClick = (e) => {
    e.stopPropagation()
    handleClick(trip)
  }
  const onItineraryClick = (e) => {
    e.stopPropagation()
    showItinerary(trip)
  }

  const onlyFewSpotsLeft = !!(
    trip.availableSpots &&
    trip.availableSpots < 5 &&
    !noSpacesForSelectedGuests
  )
  const hasSpots = !!(
    trip.availableSpots &&
    trip.availableSpots >= 5 &&
    !noSpacesForSelectedGuests
  )
  return (
    <div
      onClick={onLinkClick}
      className={`row${
        trip.grossPrice < trip.compareAtPrice ? ' is-deal' : ''
      }${!trip.availableSpots ? ' sold-out-label' : ''} ${
        noSpacesForSelectedGuests && trip.availableSpots ? 'no-space' : ''
      }`}
      key={trip.id}
    >
      <div className='cell date'>
        <span className='day'>
          <DateFormat date={trip.startDate} format='D' />
        </span>
        <span className='month'>
          <DateFormat date={trip.startDate} format='MMM' />
        </span>
      </div>
      <div className='cell mobile-group-cell'>
        <div className='cell dives-duration'>
          <span className='duration'>
            <FormattedMessage
              id='duration_period'
              defaultMessage='{ nights } nights/{ days } days'
              values={{
                nights: trip.duration,
                days: trip.duration + 1,
              }}
            />
          </span>
          <span className='dives'>
            {trip.numberOfDivesMax ? (
              <FormattedMessage
                id='dives_min_max'
                defaultMessage='{ divesMin } - { divesMax } dives'
                values={{
                  divesMin: trip.numberOfDivesMin,
                  divesMax: trip.numberOfDivesMax,
                }}
              />
            ) : (
              <FormattedMessage
                id='dives_min'
                defaultMessage='{ divesMin } dives'
                values={{
                  divesMin: trip.numberOfDivesMin,
                }}
              />
            )}
          </span>
        </div>
        <div className='cell itinerary' onClick={onItineraryClick}>
          <i className='font-icons route-icon' />
          <span>{trip.title}</span>
        </div>
      </div>
      <div className='cell availability'>
        {onlyFewSpotsLeft && (
          <FormattedMessage
            id='spots_left'
            values={{ qty: trip.availableSpots }}
            defaultMessage='Only { qty, plural, one {# spot } other {# spots } } left'
          />
        )}
        {hasSpots && (
          <span className='color-green'>
            <FormattedMessage id='available' defaultMessage='Available' />
          </span>
        )}
        {noSpacesForSelectedGuests && trip.availableSpots ? (
          <span className='color-yellow'>
            <FormattedMessage
              id='no_availability_for_your_number'
              defaultMessage='No availability for your # of guests'
            />
          </span>
        ) : (
          ''
        )}
      </div>
      <div className='cell pricing'>
        {trip.availableSpots && !trip.isCharter ? (
          <Fragment>
            {trip.grossPrice < trip.compareAtPrice ? (
              <span className='old-price'>
                <PriceConverter
                  amount={trip.compareAtPrice}
                  currency={currency}
                />
              </span>
            ) : (
              ''
            )}
            <span className='price'>
              <PriceConverter amount={trip.grossPrice} currency={currency} />
              <span className='price-type'>
                / <FormattedMessage defaultMessage='trip' />
              </span>
            </span>
            {trip.grossPrice < trip.compareAtPrice ? (
              <span className='deal-label'>
                <FormattedMessage id='save' defaultMessage='Save' />
                &nbsp;
                <PriceConverter
                  amount={trip.compareAtPrice - trip.grossPrice}
                  currency={currency}
                  noCurrency
                />
              </span>
            ) : (
              ''
            )}
          </Fragment>
        ) : (
          ''
        )}
        {trip.availableSpots && trip.isCharter ? (
          <span className='charter'>
            <FormattedMessage id='charter_only' defaultMessage='Charter only' />
          </span>
        ) : (
          ''
        )}
        {trip.availableSpots === 0 ? (
          <span className='sold-out'>
            <FormattedMessage id='sold_out' defaultMessage='Sold out' />
          </span>
        ) : (
          ''
        )}
      </div>
      <div className='cell actions'>
        {trip.availableSpots > 0 && trip.isCharter ? (
          <span
            className='charter-icon tooltip-wrapper-block'
            onClick={(e) => {
              e.stopPropagation()
              document.getElementById('livechat').click()
            }}
            data-tip={intl.formatMessage(msg.tooltipCharter)}
          >
            ?
          </span>
        ) : (
          ''
        )}
        {trip.availableSpots === 0 ? (
          <span
            className='sold-out-btn tooltip-wrapper-block'
            data-tip={intl.formatMessage(msg.tooltipAvailability)}
            onClick={(e) => {
              e.stopPropagation()
              document.getElementById('livechat').click()
            }}
          >
            ?
          </span>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
