import { FormattedMessage } from 'react-intl'
import MobileStickyHeader from './MobileStickyHeader'

export default function StickyHeader(props) {
  if(window.innerWidth < 768) {
    return <MobileStickyHeader {...props} />
  }

  const { locationTitle, typeOfSearch, selectedDates } = props

  return (
    <div
      className='pt-sticky-top fixed'
      sticky-top='sticky-top'
      clickable='true'
      trigger='#la-main-filters'
    >
      <div className='search-wrapper'>
        <div className='back-section'>
          <h5 className='title'>{locationTitle}</h5>
          <div className='details'>
            <span>{typeOfSearch},&nbsp;</span>
            <span>{selectedDates}</span>
            <a className='action-edit pointer'>
              <FormattedMessage id='edit' defaultMessage='Edit' />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
