const descriptionLines = [
  {
    id: 1,
    styles: {
      height: '34px',
      width: '40%',
    },
  },
  {
    id: 2,
    styles: {
      height: '28px',
      width: '30%',
    },
  },
  {
    id: 3,
    styles: {
      height: '14px',
      width: '55%',
    },
  },
  {
    id: 4,
    styles: {
      height: '14px',
      width: '60%',
    },
  },
  {
    id: 5,
    styles: {
      height: '14px',
      width: '70%',
    },
  },
  {
    id: 6,
    styles: {
      height: '14px',
      width: '62%',
    },
  },
]
export default function PlaceholderBoat() {
  return (<div className='boat-placeholder placeholder-animation'>
    <div className='boat-placeholder__info'>
      <div className='boat-placeholder__image' />
      <div className='boat-placeholder__description'>
        <div className='boat-placeholder__text'>
          {
            descriptionLines.map(item => {
              return (<div key={item.id} style={item.styles} className='boat-placeholder__line' />)
            })
          }
        </div>
        <div className='boat-placeholder__price'>
          <div className='boat-placeholder__line' style={{ width: '80%', height: '24px' }} />
          <div className='boat-placeholder__line gray' style={{ width: '90%', height: '48px' }} />
        </div>
      </div>
    </div>
    <div className='boat-placeholder__trips'>
      <div className='boat-placeholder__line' />
      <div className='boat-placeholder__line' />
      <div className='boat-placeholder__line' />
      <div className='boat-placeholder__line' />
      <div className='boat-placeholder__next'>
        <div className='button-placeholder' />
        <div className='button-placeholder' />
      </div>
    </div>
  </div>)
}
