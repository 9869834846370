import { useMemo } from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

const messages = defineMessages({
  hours: {
    id: 'activity.duration.hours',
    defaultMessage:
      'Duration { duration, plural, one {# hour} other {# hours}}',
  },
  days: {
    id: 'activity.duration.days',
    defaultMessage: 'Duration { duration, plural, one {# day} other {# days}}',
  },
})

export default function ActivityDuration({ duration, durationType }) {
  return useMemo(() => {
    if(!durationType) {
      return null
    }
    return (
      <FormattedMessage
        {...messages[durationType.value === 10 ? 'hours' : 'days']}
        values={{
          duration,
        }}
      />
    )
  }, [duration, durationType])
}
