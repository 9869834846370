import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get'
import Inclusions from '../../helpers/Inclusions'
import { useState } from 'react'

function ItineraryOptionalExtras({
  itinerary,
  currency,
  itineraryCurrency,
  disableClick,
}) {
  if(!itinerary.isNewModelUsed) {
    return null
  }

  const [isOpen, setOpen] = useState(disableClick)
  const handleClick = () => {
    if(disableClick) {
      return
    }
    setOpen(!isOpen)
  }

  return (
    <div className='itinerary-section collapsed-section'>
      <h4 onClick={handleClick} className={isOpen ? 'active' : ''}>
        <FormattedMessage
          id='optional_extras'
          defaultMessage='Optional extras'
        />
      </h4>
      {isOpen && (
        <div className='itinerary-section-details'>
          <Inclusions
            showPrices
            startDate={itinerary.startDate}
            payedInAdvance
            type='optionalInAdvance'
            currency={itineraryCurrency || currency}
            items={itinerary.optionalInAdvance}
          />
          <Inclusions
            showPrices
            startDate={itinerary.startDate}
            type='optionalBookableAdvancePaidOnBoard'
            currency={itineraryCurrency || currency}
            items={itinerary.optionalBookableAdvancePaidOnBoard}
          />
          <Inclusions
            showPrices
            startDate={itinerary.startDate}
            type='optionalOnBoard'
            currency={itineraryCurrency || currency}
            items={itinerary.optionalOnBoard}
          />
          <i className='note-text'>
            <FormattedMessage
              id='note.price'
              defaultMessage='Note: all listed prices are per person'
            />
          </i>
        </div>
      )}
    </div>
  )
}

export default connect((state) => ({
  itineraryCurrency: get(state, 'itineraryPopupData.currency'),
}))(ItineraryOptionalExtras)
