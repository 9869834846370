import styles from '../styles/ShopList.module.scss'
import cx from 'classnames'

export default function ShopLink({ shop }) {
  if(!shop) {
    return null
  }

  if(shop.diveCenterPage) {
    return (
      <a
        href={shop.diveCenterPage}
        target='_blank'
        className={cx(styles.shopRow, styles.shopLink)}
      >
        <i className={cx('dsl-icons dsl-icons--store', styles.shopIcon)} />{' '}
        {shop.title}
      </a>
    )
  }
  return (
    <span className={styles.shopRow}>
      <i
        className={cx(
          'dsl-icons',
          {
            'dsl-icons--store': shop.kind !== 110,
            'dsl-icons--snorkel-mask': shop.kind === 110,
          },
          styles.shopIcon
        )}
      />{' '}
      {shop.title}
    </span>
  )
}
