import styles from '../styles/Tag.module.scss'
import cx from 'classnames'
import { defineMessages, useIntl } from 'react-intl'
import { Fragment } from 'react'
import { ADAPTIVE_SERVICE_FACILITIES, ECO_CENTER } from './constants'

const messages = defineMessages({
  [ADAPTIVE_SERVICE_FACILITIES]: {
    id: 'adaptive_service_tooltip',
    defaultMessage:
      'A PADI Adaptive Services Facility is an operation that meets the needs of divers with physical, psychological or mental challenges in its diver training programs and other dive activities',
  },
  [ECO_CENTER]: {
    defaultMessage:
      'PADI Eco Center is a Green Fins Member and part of AWARE’s Adopt the Blue network',
  },
})

export default function Tag({ tag }) {
  const intl = useIntl()
  const isShowTooltip = [ADAPTIVE_SERVICE_FACILITIES, ECO_CENTER].includes(
    tag?.id
  )
  const handleClick = (e) => {
    if(isShowTooltip) {
      e.stopPropagation()
    }
  }
  return (
    <div
      onClick={handleClick}
      className={cx(styles.tag)}
      data-tip={isShowTooltip ? intl.formatMessage(messages[tag?.id]) : ''}
    >
      <span className={styles.tagTitle}>{tag?.title}</span>
      {isShowTooltip && (
        <Fragment>
          <i className={cx(styles.icon, 'dsl-icons dsl-icons--information')} />
        </Fragment>
      )}
    </div>
  )
}
