import { useMemo, useState, useEffect, useRef, Fragment } from 'react'
import get from 'lodash/get'
import FilterItem from './FilterItem'
import { FormattedMessage } from 'react-intl'

export default function FilterGroup({
  filter,
  selectedFilters,
  updateCheckbox,
  defaultFiltersState,
  diveResort,
  groupTrips,
}) {
  const isInitialMount = useRef(true)
  const [isVisible, setIsVsibile] = useState(
    defaultFiltersState[filter.query_name]
  )
  const [isExpanded, setExpanded] = useState(false)
  const itemsLength = get(filter, 'items.length')

  useEffect(() => {
    if(isInitialMount.current && selectedFilters[filter.query_name]) {
      setIsVsibile(true)
    }
    setTimeout(() => {
      isInitialMount.current = false
    }, 300)
  }, [selectedFilters[filter.query_name]])

  const toggleVisibility = () => {
    setIsVsibile(!isVisible)
  }
  const toggleViewAll = () => setExpanded(!isExpanded)
  if(!itemsLength) {
    return null
  }

  const arraySliceOption = useMemo(() => {
    if(filter.query_name === 'duration' && itemsLength > 5) {
      return [isExpanded ? 0 : 5, isExpanded ? itemsLength : 10]
    }

    return [0, isExpanded ? itemsLength : 5]
  }, [isExpanded])

  const filtersSliced = useMemo(() => filter.items.slice(...arraySliceOption), [
    arraySliceOption,
  ])
  return (
    <div className='filter-row'>
      <p
        className={`title ${isVisible ? 'active' : ''}`}
        onClick={toggleVisibility}
      >
        {filter.title}
      </p>
      {filter.description && (
        <p className='filter-description'>
          <FormattedMessage
            id='book_with_confidence_filter_desc'
            defaultMessage='Explore packages which include full refund up to 8 days prior to trip start'
          />
        </p>
      )}
      {isVisible && (
        <ul className='fade-onload'>
          {filtersSliced.map((item) => (
            <FilterItem
              item={item}
              key={item.id}
              filterName={filter.query_name}
              selectedFilters={selectedFilters}
              updateCheckbox={updateCheckbox}
            />
          ))}
          {filter.query_name === 'price' && !diveResort && !groupTrips && (
            <FilterItem
              item={{
                id: 'promotion',
                title: (
                  <FormattedMessage
                    id='only_special_deals'
                    defaultMessage='Only special deals'
                  />
                ),
              }}
              filterName='is_promotion'
              selectedFilters={selectedFilters}
              updateCheckbox={updateCheckbox}
            />
          )}
          {itemsLength > 5 ? (
            <li className='fade-onload show-all' onClick={toggleViewAll}>
              {isExpanded ? (
                <Fragment>
                  <FormattedMessage
                    id='search.show_less'
                    defaultMessage='Show less'
                  />
                  <i className='font-icons top-arrow-icon' />
                </Fragment>
              ) : (
                <Fragment>
                  <FormattedMessage
                    id='search.show_all'
                    defaultMessage='Show all {quantity}'
                    values={{
                      quantity: filter.items.length,
                    }}
                  />
                  <i className='font-icons bottom-arrow-icon' />
                </Fragment>
              )}
            </li>
          ) : (
            ''
          )}
        </ul>
      )}
    </div>
  )
}
