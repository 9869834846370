import { FormattedMessage } from 'react-intl'

export default function StudentAmenities() {
  return (
    <p className='dives'>
      <i className='font-icons dive-center-icon' />
      <FormattedMessage
        id='incl_open_water'
        defaultMessage='Incl. PADI Open Water Course'
      />
    </p>
  )
}
