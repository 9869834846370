import { useMemo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import setQuery from 'set-query-string'
import each from 'lodash/each'
import find from 'lodash/find'
import filter from 'lodash/filter'
import map from 'lodash/map'
import { guestPickerKeys } from '../../../pages/SearchPageDr/'
import { prepareGuestSplitting } from '../../resort/ResortPricing'
import { generateMonthPicker, parseQuery } from 'utils'
import {
  setActivePage,
  setSearchFilters,
} from '../../../actions/search-actions'
import get from 'lodash/get'
import store from 'store2'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import { preselectedCourses } from '../../../pages/searchData'

const months = generateMonthPicker()
const mapStateToProps = (state) => state.searchFilters

const simpleValueFilters = [
  'view',
  'departure_date_custom',
  'ordering',
  'shop_id',
  'divers',
  'nonDivers',
  'students',
  'guests_split',
  'rooms',
  'nights',
  'dateStart',
  'dateTo',
  'shop',
  'point',
  'date',
  'near_by',
  'organiserTitle',
]
export function searchOnInitHook(filtersKeys, pageType, activityType) {
  const dispatch = useDispatch()

  useEffect(() => {
    let selectedParams = parseQuery()
    let filterObject = {}

    if(pageType === 'liveaboard') {
      if(!selectedParams.departure_date) {
        const departureDate = store('departure_date')
        if(
          departureDate &&
          departureDate.id !== 'available' &&
          departureDate.id !== 'all'
        ) {
          // setQuery({ departure_date: store('departure_date').id })
          selectedParams.departure_date = store('departure_date').id
        } else if(store('date')) {
          let dateObj = store('departure_date_custom') || store('date')[0]
          let date = moment(dateObj).startOf('month').format('YYYY-MM-DD')
          let month = filter(months, { from: date })
          if(month.length) {
            selectedParams.departure_date = month[0].id
          }
        }
      }
    } else if(pageType === 'resort') {
      if(!selectedParams.dateFrom || !selectedParams.dateTo) {
        const date = store('date')
        if(date && date[0] && date[1]) {
          // setQuery({
          //   dateStart: date[0],
          //   dateTo: date[1],
          // })
          selectedParams.dateStart = date[0]
          selectedParams.dateTo = date[1]
        }
      }
    } else if(pageType === 'activites') {
      if(!selectedParams.date) {
        const date = store('date')
        if(date && date[0]) {
          // setQuery({ date: date[0] })
          selectedParams.date = date[0]
        }
      }
      if(activityType === 'courses') {
        if(store('standardNameIds')) {
          selectedParams.standard_name_ids = store('standardNameIds')
        } else {
          selectedParams.standard_name_ids = preselectedCourses
        }
        setQuery(selectedParams)
      }
    }

    const roomParams = store.get('roomParams') || {}
    let totalGuests = 0

    if(roomParams) {
      totalGuests +=
        roomParams.divers + roomParams.nonDivers + roomParams.students
      filterObject = {
        ...roomParams,
      }
      filterObject.guests_split = totalGuests
        ? get(
          roomParams,
          'guest_split',
          get(roomParams, 'details', [roomParams]),
        )
        : undefined
    }

    if(selectedParams) {
      each(selectedParams, function(val, key) {
        if(filtersKeys.indexOf(key) >= 0) {
          if(key === 'departure_date') {
            let departure = find(months, {
              id: val,
            })
            if(departure) {
              filterObject[key] = departure.from
              filterObject['departure_date_month'] = departure
            }
          } else if(key === 'organiserTitle' && val) {
            filterObject[key] = decodeURIComponent(val)
          } else if(key === 'is_promotion') {
            filterObject[key] = {
              promotion: val,
            }
          } else if(simpleValueFilters.includes(key)) {
            filterObject[key] = val
          } else if(Array.isArray(val)) {
            filterObject[key] = {}
            each(val, function(v) {
              filterObject[key][v] = true
            })
          } else {
            filterObject[key] = {}
            filterObject[key][val] = true
          }
        }
      })
    }

    if(filterObject.dateStart && filterObject.dateTo && !filterObject.nights) {
      filterObject.nights = moment(filterObject.dateTo).diff(
        filterObject.dateStart,
        'd',
      )
    }

    dispatch(setActivePage(selectedParams.page ? +selectedParams.page : 1))
    dispatch(setSearchFilters(filterObject))
  }, [])
}

export function searchFiltersHook(
  filtersKeys,
  filtersToClean,
  pageType,
  activityType,
) {
  const dispatch = useDispatch()
  const searchFilters = useSelector(mapStateToProps)
  const [isMounted, setMounted] = useState(false)

  const updateParams = (params, clearPage) => {
    if(clearPage) {
      params.page = 1
    }
    dispatch(setSearchFilters(params))
  }

  const updateOrdering = ({ target }) => {
    let item = target.value
    if(searchFilters.ordering === item) {
      const firstLetter = get(item, '[0]', '')
      if(firstLetter === '-') {
        item = item.substr(1)
      } else {
        item = '-' + item
      }
    }

    updateParams({
      ordering: item,
    })
  }

  const showListView = () => {
    updateParams({ view: 'list' }, true)
  }

  const triggerApi = () => {
    updateParams({
      getNewResults: !searchFilters.getNewResults,
    })
  }

  const clearSelectedFilters = () => {
    let cleanObj = {}
    each(filtersToClean, function(filter) {
      cleanObj[filter] = undefined
    })
    updateParams(cleanObj, true)
    store.remove('standardNameIds')
  }

  const clearDates = () => {
    updateParams(
      {
        departure_date: undefined,
        departure_date_month: undefined,
      },
      true,
    )
  }

  const handleGroupSelect = ({ target }) => {
    updateParams({ view: target.value }, true)
  }

  const data = useMemo(() => {
    let filtersCount = 0
    let obj = {}
    let departureDateData
    let guests = {}
    if(!isEmpty(searchFilters)) {
      each(searchFilters, function(val, key) {
        if(filtersKeys.indexOf(key) >= 0) {
          if(simpleValueFilters.includes(key) && val) {
            obj[key] = val

            if(key === 'point') {
              obj.distance = 50000 // meters
              filtersCount++
            }
          } else if(key === 'departure_date') {
            let departure = find(months, {
              from: val,
            })
            if(departure) {
              obj[key] = departure.id
              departureDateData = departure
            } else {
              obj[key] = undefined
            }
          } else if(key === 'organiserTitle') {
            obj[key] = val ? decodeURIComponent(val) : undefined
          } else if(key === 'is_promotion') {
            obj[key] = val ? val.promotion : undefined
          } else {
            if(typeof val === 'object' && val) {
              obj[key] = filter(
                map(val, function(v, k) {
                  if(v) {
                    return k
                  }
                  return null
                }),
                function(v) {
                  return v || typeof v === 'number'
                },
              )
            } else {
              obj[key] = val || undefined
            }

            if(key === 'resort_size' || key === 'liveaboard_size') {
              const operatorSize = filter(window.OPERATOR_SIZE, (v) =>
                val ? val[v.id] : false,
              )
              const roomNumbers = operatorSize.reduce((acc, val) => {
                acc[0] =
                  acc[0] === undefined || val.total_number_of_rooms_min < acc[0]
                    ? val.total_number_of_rooms_min
                    : acc[0]
                acc[1] =
                  acc[1] === undefined || val.total_number_of_rooms_max > acc[1]
                    ? val.total_number_of_rooms_max
                    : acc[1]
                return acc
              }, [])
              obj.total_number_of_rooms_min = roomNumbers[0]
              obj.total_number_of_rooms_max = roomNumbers[1]
            }
          }

          if(
            (Array.isArray(obj[key]) && obj[key].length) ||
            (key === 'is_promotion' && obj[key])
          ) {
            filtersCount++
          }
        } else if(guestPickerKeys.indexOf(key) >= 0) {
          guests[key] =
            key === 'guests_split' && val ? prepareGuestSplitting(val) : val
        }
      })

      if(guests.divers || guests.nonDivers || guests.students) {
        guests.totalGuests = guests.divers + guests.nonDivers + guests.students
      }

      if(isMounted) {
        dispatch(setActivePage(1))
        setQuery({ page: 1, ...obj })
        if(obj.standard_name_ids && activityType === 'courses') {
          store('standardNameIds', obj.standard_name_ids)
        }
      }

      if(pageType !== 'dive-center') {
        if(departureDateData) {
          obj.departure_date = departureDateData.from

          store('departure_date', departureDateData)
          store('date', [departureDateData.from, departureDateData.to])
        } else if(obj.dateStart || obj.dateTo) {
          store('departure_date_custom', obj.dateStart)
          store('date', [
            obj.dateStart,
            obj.dateTo ||
              moment(obj.dateStart).add(7, 'd').format('YYYY-MM-DD'),
          ])
          store.remove('departure_date')
        } else if(obj.date) {
          store('date', [
            obj.date,
            moment(obj.date).add(7, 'days').format('YYYY-MM-DD'),
          ])
          store('departure_date_custom', obj.date)
          store.remove('departure_date')
        } else {
          store.remove('departure_date')
          store.remove('departure_date_custom')
          store.remove('date')
        }
      }
      setMounted(true)
    }
    return {
      transformedFilters: {
        ...obj,
        ...guests,
      },
      guests,
      filtersCount,
      updateOrdering,
      updateParams,
      showListView,
      triggerApi,
      clearSelectedFilters,
      clearDates,
      handleGroupSelect,
    }
  }, [searchFilters])

  return data
}
