import { FormattedMessage } from 'react-intl'
import styles from '../styles/DiveShopLocatorFilters.module.scss'
import { FormCheckboxStyled } from '../../forms/final-form-fields/FormCheckbox'
import allFiltersStyles from './dropdown/AllFiltersDropdown.module.scss'

import PropTypes from 'prop-types'

export default function DiveCoursesAdditionalFilters({
  handleToggleClick,
  allFiltersView,
  isMobile,
}) {
  const stylesObject = allFiltersView ? allFiltersStyles : styles
  return (
    <div className={styles.diveCourseAddFilters}>
      { !isMobile && <span className={styles.separator} /> }
      <FormCheckboxStyled
        name='bookable_online'
        className={stylesObject.checkbox}
        onChange={handleToggleClick}
        labelEn='Bookable Online'
        label={
          <FormattedMessage defaultMessage='Bookable Online' />
        }
      />
      <FormCheckboxStyled
        name='instant_confirmation'
        className={stylesObject.checkbox}
        onChange={handleToggleClick}
        labelEn='Instant Confirmation'
        label={
          <FormattedMessage
            defaultMessage='Instant Confirmation'
          />
        }
      />
    </div>
  )
}

DiveCoursesAdditionalFilters.propTypes = {
  handleToggleClick: PropTypes.func,
  allFiltersView: PropTypes.bool,
}
