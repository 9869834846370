import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { createUrl } from '../../../localized-urls'
import { generateQueryForWidget } from '../../../utils'

function ItineraryModalFooter({ itinerary, itineraryPopupData }) {
  const { hideFooter, current, shopSlug, countrySlug } = itineraryPopupData
  if(hideFooter) {
    return null
  }

  const showCabinPrice = itinerary.id && !itinerary.isCharter && !current
  const showTipSchedule = !itinerary.id && !itinerary.isCharter && !current
  return (
    <div className='itinerary-page__modal-footer'>
      {!!showCabinPrice && (
        <a
          className='btn-red'
          href={createUrl(
            'checkout_la',
            { location: countrySlug, slug: shopSlug },
            generateQueryForWidget('?productId=' + itinerary.id)
          )}
        >
          <FormattedMessage id='cabin_prices' defaultMessage='Cabin prices' />
        </a>
      )}
      {!!showTipSchedule && (
        <a
          className='btn-red'
          href={createUrl(
            'checkout_la',
            {
              location: countrySlug,
              slug: shopSlug,
            },
            generateQueryForWidget()
          )}
        >
          <FormattedMessage id='trip_schedule' defaultMessage='Trip schedule' />
        </a>
      )}
    </div>
  )
}

export default connect((state) => ({
  itineraryPopupData: state.itineraryPopupData,
}))(ItineraryModalFooter)
