import { defineMessages, useIntl } from 'react-intl'
import {
  AllFilters,
  DiveCenterAdditionalFilters,
  LocationSearch,
} from './filters'
import DropDown from './filters/Dropdown'
import cx from 'classnames'
import styles from './styles/DiveShopLocatorFilters.module.scss'
import { useMemo, useCallback } from 'react'
import { Form, FormSpy } from 'react-final-form'
import useLocatorFilters from './hooks/useLocatorFilters'
import useWindowDimensions from './hooks/useWindowDimensions'
import useIsMapMoved from './hooks/useIsMapMoved'
import { pushToDataLayer } from '../../gtm'
import FormRangeDatepicker from '../forms/final-form-fields/FormRangeDatePicker/FormRangeDatepicker'

import PropTypes from 'prop-types'
import DiveCoursesAdditionalFilters from './filters/DiveCoursesAdditionalFilters'
import PriceRangeFilter from './filters/PriceRangeFilter'

const messages = defineMessages({
  searchCourses: {
    id: 'search_courses',
    defaultMessage: 'Search for courses',
  },
  searchLanguages: {
    id: 'search_for_ds_lang',
    defaultMessage: 'Search for dive shop languages',
  },
  searchCritters: {
    id: 'search_critters',
    defaultMessage: 'Search for marine life critters',
  },
  mapArea: {
    id: 'mapArea',
    defaultMessage: 'Map area',
  },
  closeToMe: {
    id: 'closeToMe',
    defaultMessage: 'Close to me',
  },
})

export const getConfig = (filter, pageType) => {
  const intl = useIntl()
  if(filter.name === 'courses' && pageType !== 'courses') {
    return {
      withSearch: true,
      icon: 'dsl-icons--book',
      searchPlaceholder: intl.formatMessage(messages.searchCourses),
    }
  } else if(filter.name === 'languages') {
    return {
      withSearch: true,
      icon: 'dsl-icons--translate',
      searchPlaceholder: intl.formatMessage(messages.searchLanguages),
    }
  } else if(filter.query_name === 'marine_life_sightings') {
    return {
      withSearch: true,
      icon: 'dsl-icons--turtle',
      searchPlaceholder: intl.formatMessage(messages.searchCritters),
    }
  }
  return {}
}

const normalizeData = (value, filters, defaultCourseFilter) => {
  [
    'params',
    'courses',
    'dive_site_types',
    'water_entries',
    'marine_life_sightings',
    'experiences',
    'events',
    'categories',
    'languages',
    'certification',
    'standard_name_ids',
    'maximum_depth',
    'course_info_type',
    'course_info_id',
    'included_extra_ids',
  ].forEach((key) => {
    const filter = filters?.find((filter) => filter.query_name === key)
    if(value[key] && !Array.isArray(value[key])) {
      if(filter && filter.is_multiple === 'true') {
        value[key] = [value[key]]
      }
    }
    if(key === 'courses' && !value[key] && defaultCourseFilter?.id && !filter?.is_multiple) {
      value[key] = `${defaultCourseFilter?.id}`
    }
  })
  if(value.date_from && value.date_to) {
    value.date = [value.date_from, value.date_to]
  }
  if(value.price_from && value.price_to) {
    value.price = [value.price_from, value.price_to].map((price) => parseInt(price, 10))
  }
  return value
}

const getMobileLocationText = (locationTitle) => {
  const intl = useIntl()
  const { isMapMoved } = useIsMapMoved()

  if(isMapMoved) {
    return intl.formatMessage(messages.mapArea)
  }

  if(window.isNearBy) {
    return intl.formatMessage(messages.closeToMe)
  }

  return locationTitle
}

export default function DiveShopLocationFilters({
  price,
  filters,
  ordering,
  locationTitle,
  pageType,
  allUrl,
}) {
  const { formatMessage } = useIntl()
  const { width } = useWindowDimensions()
  const isSmallDevice = width <= 1024
  const {
    filters: selectedFilters,
    setFiltersParams,
    clearFilters,
    defaultCourseFilter,
  } = useLocatorFilters(true, pageType, filters)
  const handleChange = ({ values }) => {
    setFiltersParams(values)
  }
  const initialValues = useMemo(() => {
    return normalizeData(selectedFilters, filters, defaultCourseFilter)
  }, [])

  const isShowDatepicker = ['professional', 'conservation', 'courses'].includes(pageType)
  const mobileLocationText = getMobileLocationText(locationTitle)
  const handleToggleClick = useCallback((value, sectionTitle) => {
    pushToDataLayer({
      event: 'interaction',
      eventCategory: 'Dive Shop',
      eventAction: 'Search - Filter',
      eventLabel: `${sectionTitle} - ${value ? 'True' : 'False'}`,
      eventValue: '0',
      eventNonInteraction: 'false',
    })
  }, [])
  return (
    <Form
      onSubmit={() => {}}
      initialValues={initialValues}
      render={({
        handleSubmit,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy subscription={{ values: true }} onChange={handleChange} />
          {isSmallDevice ? (
            <div className={cx(styles.filters, styles.mobileFilters)}>
              <LocationSearch
                pageType={pageType}
                selectedFilters={selectedFilters}
                locationTitle={mobileLocationText}
                allUrl={allUrl}
              />
              {pageType === 'courses' &&
                (<PriceRangeFilter
                  price={price}
                  filterName='price'
                  isMobile
                />)
              }
              {isShowDatepicker && (
                <FormRangeDatepicker
                  name='date'
                  placeholder={formatMessage({
                    defaultMessage: 'Select date',
                    id: 'select_date',
                  })}
                />
              )}
              <AllFilters
                filters={filters}
                pageType={pageType}
                clearFilters={clearFilters}
                handleToggleClick={handleToggleClick}
                isMobile
              />
              <div className={styles.additionalRow}>
                {pageType !== 'courses' &&
                  <DropDown
                    placeholder={ordering.title}
                    queryName={ordering.query_name}
                    items={ordering.items}
                    titleEn={ordering.title_en}
                    last
                  />
                }
              </div>
              <DiveCoursesAdditionalFilters isMobile />
            </div>
          ) : (
            <div className={cx(styles.filters, styles.desktopFilters)}>
              <LocationSearch
                pageType={pageType}
                selectedFilters={selectedFilters}
                locationTitle={locationTitle}
                allUrl={allUrl}
              />
              <span className={styles.separator} />
              <AllFilters
                filters={filters}
                clearFilters={clearFilters}
                pageType={pageType}
                handleToggleClick={handleToggleClick}
              />
              {filters.map((filter, index) => (
                <DropDown
                  placeholder={filter.title}
                  titleEn={filter.title_en}
                  key={filter.title}
                  queryName={filter.query_name}
                  items={filter.items}
                  {...getConfig(filter, pageType)}
                  last={index + 1 === filters.length}
                  isMultiple={filter?.is_multiple === 'true'}
                />
              ))}
              {pageType === 'courses' && (
                <PriceRangeFilter
                  price={price}
                  filterName='price'
                />
              )}
              <div className={styles.additionalRow}>
                {pageType !== 'courses' &&
                <DropDown
                  placeholder={ordering.title}
                  queryName={ordering.query_name}
                  items={ordering.items}
                  titleEn={ordering.title_en}
                  last
                />
                }

                {isShowDatepicker && (
                  <FormRangeDatepicker
                    name='date'
                    placeholder={formatMessage({
                      defaultMessage: 'Select date',
                      id: 'select_date',
                    })}
                  />
                )}

                {pageType === 'courses'
                  ? <DiveCoursesAdditionalFilters />
                  : <DiveCenterAdditionalFilters pageType={pageType} />
                }
              </div>
            </div>
          )}
        </form>
      )}
    />
  )
}

DiveShopLocationFilters.propTypes = {
  filters: PropTypes.array,
  ordering: PropTypes.string,
  locationTitle: PropTypes.string,
  pageType: PropTypes.string,
  allUrl: PropTypes.string,
}
