import API from '../../../../api'
import uniqBy from 'lodash/uniqBy'
import { useEffect, useState } from 'react'

let controller, signal
export default function usePins(
  map,
  isActivityEndpoint,
  searchType,
  selectedParams
) {
  const [pins, setPins] = useState([])
  const [isInitialMount, setInitialMount] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const getPins = () => {
    if(controller) {
      controller.abort()
    }

    if('AbortController' in window) {
      controller = new window.AbortController()
      signal = controller.signal
    }
    setLoading(true)

    const currentBounds = map.getBounds()

    let point
    let distance
    let bounds
    let api
    if(isActivityEndpoint) {
      api = API(`/api/adventure/v1/search/${searchType}/map/`, signal)
      bounds = {
        top_right: `${currentBounds._northEast.lat},${currentBounds._northEast.lng}`,
        bottom_left: `${currentBounds._southWest.lat},${currentBounds._southWest.lng}`,
      }
    } else {
      api = API(`search/resorts/geo/`, signal)
      bounds = {
        leftBound: `${currentBounds._southWest.lat},${currentBounds._southWest.lng}`,
        rightBound: `${currentBounds._northEast.lat},${currentBounds._northEast.lng}`,
      }
    }

    if(selectedParams) {
      // when map was moved we should show other pins
      point = isInitialMount ? selectedParams.point : undefined
      distance = isInitialMount ? selectedParams.distance : undefined
    }

    return api
      .get({
        ...bounds,
        ...selectedParams,
        point,
        distance,
      })
      .then((data) => {
        setPins(uniqBy([...pins, ...data], 'shopId'))
        setLoading(false)
        setInitialMount(false)
      })
      .catch((error) => {
        if(
          window.DOMException &&
          error.code === window.DOMException.ABORT_ERR
        ) {
          console.warn('Request canceled.')
        } else {
          setLoading(false)
        }
      })
  }

  useEffect(() => {
    getPins()
    document.body.classList.add('hide-footer', 'hide-header')

    if(selectedParams?.point) {
      const center = selectedParams.point.split(',').map((item) => +item)
      map.setView(center, 10)
    }

    return () => {
      document.body.classList.remove('hide-footer', 'hide-header')
    }
  }, [])

  return {
    pins,
    isLoading,
    getPins,
  }
}
