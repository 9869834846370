import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import PriceConverter from '../helpers/PriceConverter'

export default function PriceVariants({ shop, selectedParams, totalGuests, isAllParamsSelected }) {
  if(!shop.errors) {
    shop.errors = []
  }
  return (<div className='prices'>
    { !shop.priceSum && !shop.errors.length ? (
      <div className='price'>
        <span className='sold-out capitalize'>
          <FormattedMessage id='sold_out' defaultMessage='Sold out' />
        </span>
      </div>
    ) : '' }
    {
      !shop.priceSum && shop.errors.length ? (
        <div className='price' ng-show=''>
          <span className='no-package'>{ shop.errors[0] }</span>
        </div>
      ) : ''
    }
    {
      isAllParamsSelected && shop.minimalStay > selectedParams.duration
        ? (<div className='price'>
          <span className='sold-out capitalize'>
            <FormattedMessage id='min_stay_of' values={{
              minimalStay: shop.minimalStay,
            }} defaultMessage='Min stay of { minimalStay, plural, one {# night } other {# nights} }' />
          </span>
        </div>) : ''
    }
    {
      shop.priceSum ? (
        <div className='price'>
          <p className='cur-price'>
            <small>
              <FormattedMessage id='from' defaultMessage='From' />&nbsp;
            </small>
            <strong>
              <PriceConverter amount={shop.priceSum / (totalGuests || 1)} />
            </strong>
          </p>
          <p className='price-info'>
            { !selectedParams.dateStart || !totalGuests ? (
              <FormattedMessage id='for_7_days_per_diver' values={{
                numberOfDays: selectedParams.nights || 7,
              }} defaultMessage='for {numberOfDays} days per diver incl. diving' />
            ) : ''
            }
            {/* {
              !totalGuests && selectedParams.dateStart ? (
                <FormattedMessage id='per_day_per_diver' defaultMessage='per day per diver incl. diving' />
              ) : ''
            } */}
          </p>

          {
            isAllParamsSelected ? (
              <p className='price-info'>
                { shop.divesInPackageKind === 30 ? (
                  <Fragment>
                    { selectedParams.divers && !selectedParams.students ? (
                      <FormattedMessage id='price.divers_without_students_unlim'
                        values={
                          {
                            nights: shop.nights || selectedParams.nights,
                          }
                        }
                        defaultMessage='{nights, plural, one {# night} other {# nights}} incl. unlimited shore dives' />) : '' }
                    { selectedParams.divers && selectedParams.students ? (
                      <FormattedMessage id='price.divers_and_students_unlim'
                        values={
                          {
                            nights: shop.nights || selectedParams.nights,
                          }
                        }
                        defaultMessage='{nights, plural, one {# night} other {# nights}} incl. unlimited shore dives/diver, 1 diving course/student' />) : '' }
                    { !selectedParams.divers && selectedParams.students ? (
                      <FormattedMessage id='price.non_divers_and_students'
                        values={
                          {
                            nights: shop.nights || selectedParams.nights,
                          }
                        }
                        defaultMessage='{nights, plural, one {# night} other {# nights}} incl. diving course' />) : '' }
                  </Fragment>
                ) : (
                  <Fragment>
                    { selectedParams.divers && !selectedParams.students ? (
                      <FormattedMessage id='price.divers_without_students'
                        values={
                          {
                            nights: shop.nights || selectedParams.nights,
                            numberOfDives: shop.numberOfDives / selectedParams.divers,
                          }
                        }
                        defaultMessage='{nights, plural, one {# night} other {# nights}} incl. {numberOfDives, plural, one {# dive} other {# dives}}/diver' />) : '' }
                    { selectedParams.divers && selectedParams.students ? (
                      <FormattedMessage id='price.divers_and_students'
                        values={
                          {
                            nights: shop.nights || selectedParams.nights,
                            numberOfDives: shop.numberOfDives / selectedParams.divers,
                          }
                        }
                        defaultMessage='{nights, plural, one {# night} other {# nights}} incl. {numberOfDives, plural, one {# dive} other {# dives}}/diver, 1 diving course/student' />) : '' }
                    { !selectedParams.divers && selectedParams.students ? (
                      <FormattedMessage id='price.non_divers_and_students'
                        values={
                          {
                            nights: shop.nights || selectedParams.nights,
                          }
                        }
                        defaultMessage='{nights, plural, one {# night} other {# nights}} incl. diving course' />) : '' }
                  </Fragment>
                ) }
              </p>
            ) : ''
          }
        </div>
      ) : ''
    }
  </div>)
}
