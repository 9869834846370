import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import DateFormat from '../../helpers/DateFormat'

const msg = defineMessages({
  departureDate: {
    id: 'startDate',
    defaultMessage: 'Start date',
  },
  departureHarbour: {
    id: 'departureHarbour',
    defaultMessage: 'Departure harbour',
  },
  departureTimeOnBoat: {
    id: 'checkInTimeOnBoat',
    defaultMessage: 'Check in times on the boat',
  },
  recommendedAirport: {
    id: 'recommendedAirport',
    defaultMessage: 'Recommended airport',
  },
  returnDate: {
    id: 'endDate',
    defaultMessage: 'End date',
  },
  returnHarbour: {
    id: 'arrivalHarbour',
    defaultMessage: 'Arrival harbour',
  },
  returnTimeOnBoat: {
    id: 'checkOutTimeOnBoat',
    defaultMessage: 'Check out times on the boat',
  },
})

function Details({
  title,
  date,
  harbour,
  checkStart,
  checkEnd,
  checkOther,
  airportTitle,
  airportIata,
  type,
}) {
  const intl = useIntl()
  return (
    <div className='departure-information__item'>
      <h4>{title}</h4>
      <ul>
        {date && (
          <li>
            <i
              className='font-icons calendar-icon'
              data-tip={intl.formatMessage(msg[`${type}Date`])}
            />
            <span>
              <DateFormat date={date} format='DD MMM YYYY' />
            </span>
          </li>
        )}
        {!!harbour && (
          <li>
            <i
              className='font-icons icon-anchor'
              data-tip={intl.formatMessage(msg[`${type}Harbour`])}
            />
            <span>{harbour}</span>
          </li>
        )}
        {checkStart && (
          <li>
            <i
              className='font-icons icon-clock'
              data-tip={intl.formatMessage(msg[`${type}TimeOnBoat`])}
            />
            <span>
              {checkStart.substr(0, 5)}
              {!!checkEnd && ` – ${checkEnd.substr(0, 5)}`}
            </span>
          </li>
        )}
        {!!checkStart && !!checkOther && (
          <li>
            <i
              className='font-icons icon-clock'
              data-tip={intl.formatMessage(msg[`${type}TimeOnBoat`])}
            />
            <span>{checkOther}</span>
          </li>
        )}
        {!!airportTitle && (
          <li>
            <i
              className='font-icons icon-plane'
              data-tip={intl.formatMessage(msg.recommendedAirport)}
            />
            <span>{`${airportTitle} (${airportIata})`}</span>
          </li>
        )}
      </ul>
    </div>
  )
}

export default function ItineraryLogistic({ itinerary }) {
  return (
    <div className='departure-information'>
      <Details
        harbour={itinerary.harbourDepartureTitle || itinerary.portDeparture}
        date={itinerary.startDate}
        checkStart={itinerary.checkInFrom}
        checkEnd={itinerary.checkInTo}
        checkOther={itinerary.checkInOther}
        airportTitle={itinerary.airportDepartureTitle}
        airportIata={itinerary.airportDepartureIata}
        type='departure'
        title={<FormattedMessage id='departure' defaultMessage='Departure' />}
      />
      <Details
        harbour={itinerary.harbourArrivalTitle || itinerary.portArrival}
        date={itinerary.endDate}
        checkStart={itinerary.guestDisembarkFrom}
        checkEnd={itinerary.guestDisembarkTo}
        checkOther={itinerary.guestDisembarkOther}
        airportTitle={itinerary.airportArrivalTitle}
        airportIata={itinerary.airportArrivalIata}
        type='return'
        title={<FormattedMessage id='return' defaultMessage='Return' />}
      />
    </div>
  )
}
