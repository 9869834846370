import { useRef, useState } from 'react'
import Autosuggest from 'react-autosuggest'
import { defineMessages, useIntl } from 'react-intl'
import API from '../../../api'
import { getHighlightedText } from '../../helpers/Typeahead'
import styles from '../styles/LocationSearch.module.scss'
import NearByBtn from './NearByBtn'
import cx from 'classnames'
import { pushToDataLayer } from '../../../gtm'
import { createURL, parseQuery, serializeData } from '../../../utils'
import omit from 'lodash/omit'
import LoadingIndicator from '../../helpers/LoaderIndicator'

const messages = defineMessages({
  search: {
    id: 'search_for_place',
    defaultMessage: 'Search for a place or a dive shop',
  },
  nearBy: {
    id: 'nearby',
    defaultMessage: 'Nearby',
  },
  seeAllMatch: {
    id: 'see_all_match_in_search',
    defaultMessage: 'See all matching shops',
  },
})

const locatorUrlFields = [
  'dslUrl',
  'conservationSearchUrl',
  'professionalSearchUrl',
]

const generateUrl = (url, selectedFilters, suggestType) => {
  const query = parseQuery()
  const params = serializeData(
    omit(query, ['place_id', 'shop_title_autosuggest']),
  )

  if(suggestType === 'nearby') {
    return `${url}?${params.toString()}`
  }

  if(suggestType === 'seeAll') {
    return `${url}?shop_title_autosuggest=${selectedFilters.shop_title_autosuggest}&${params}`
  }

  if(selectedFilters && selectedFilters.source && !url.includes('source')) {
    if(url.includes('?')) {
      return `${url}&source=${selectedFilters.source}`
    }
    return `${url}?source=${selectedFilters.source}`
  }
  return url
}

const renderSuggestion = (suggestion, { query }) => {
  const isShopUrl =
    suggestion.pageUrl && !suggestion.isNearBy && !suggestion.isSeeAll
  const isDiveSiteLanding = suggestion.diveSiteLanding
  const isLocationUrl =
    !suggestion.pageUrl && !suggestion.isNearBy && !suggestion.isSeeAll
  if(!suggestion.title) {
    return null
  }
  return (
    <div
      className={cx('typeahead-item', {
        [styles.seeAllItem]: suggestion.isSeeAll,
      })}
    >
      <div className='typeahead-title'>
        <span className={styles.locationTitle}>
          <i
            className={cx(styles.icon, {
              'font-icons':
                !suggestion.isNearBy &&
                !suggestion.isSeeAll &&
                !isDiveSiteLanding,
              'dsl-icons dsl-icons--gps': suggestion.isNearBy,
              'location-icon': isLocationUrl && !isDiveSiteLanding,
              'dsl-icons dsl-icons--scuba-flag': isDiveSiteLanding,
              'icon-dive-center': isShopUrl && suggestion.kind !== 10,
              'liveaboard-icon': isShopUrl && suggestion.kind === 10,
              [styles.searchIcon]: suggestion.isSeeAll,
              'padi-icons padi-icons--search': suggestion.isSeeAll,
            })}
          />
          <div
            className={cx(styles.titleContainer, {
              'nearby-text': suggestion.isNearBy,
            })}
          >
            {getHighlightedText(suggestion.title, query)}
            {suggestion.formattedAddress && (
              <span className='formatted-address'>
                {suggestion.formattedAddress}
              </span>
            )}
          </div>
        </span>
      </div>
    </div>
  )
}

const getSuggestionValue = (suggestion) => {
  return suggestion.title
}

const getApiUrl = (pageType) => {
  switch (pageType) {
    case 'dive-sites':
      return 'dive-guide/autosuggest/dive-sites/'
    case 'conservation':
      return '/api/adventure/v1/search/conservation/autosuggest/'
    case 'professional':
      return '/api/adventure/v1/search/professional/autosuggest/'
    case 'courses':
      return 'dsl/courses/autosuggest/'
    default:
      return 'search/dsl/autosuggest/'
  }
}

export default function LocationSearch({
  locationTitle,
  selectedFilters,
  allUrl,
  pageType,
}) {
  const intl = useIntl()
  const controller = useRef()
  const signal = useRef()
  const [value, setValue] = useState(locationTitle)
  const [loading, setLoading] = useState(false)
  const nearByItem = {
    title: intl.formatMessage(messages.nearBy),
    isNearBy: true,
  }
  const seeAllItem = {
    title: intl.formatMessage(messages.seeAllMatch),
    seeAllUrl: createURL(allUrl),
    isSeeAll: true,
  }

  const inputRef = useRef()
  const [suggestions, setSuggestions] = useState(
    window.nearByPage ? [nearByItem] : [],
  )

  const inputProps = {
    placeholder: intl.formatMessage(messages.search),
    value,
    autoComplete: 'off',
    onChange: (e, { newValue }) => {
      setValue(newValue)
    },
    onFocus: (e) => {
      setValue('')
      e.currentTarget.focus()
    },
    onBlur: () => {
      setValue(locationTitle)
    },
  }

  const focusInput = () => {
    try {
      inputRef.current.input.focus()
    } catch (err) {
      console.error(err)
    }
  }
  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }
  const onSuggestionsFetchRequested = (data) => {
    if(data?.value?.length < 2) {
      return
    }

    if(controller.current !== undefined) {
      controller.current.abort()
    }

    if('AbortController' in window) {
      controller.current = new window.AbortController()
      signal.current = controller.current.signal
    }
    const queryParams = omit(parseQuery(), 'shop_title_autosuggest')
    setLoading(true)
    API(getApiUrl(pageType), signal.current)
      .get({
        ...queryParams,
        q: data.value,
      })
      .then((items) => {
        if(pageType === 'dive-sites') {
          const diveSiteLanding = items.diveSites.map((item) => {
            item.diveSiteLanding = true
            return item
          })
          setSuggestions([
            window.nearByPage ? nearByItem : {},
            ...items.places,
            ...items.gplaces,
            ...diveSiteLanding,
          ])
          return
        }
        const isShowSeeAllBtn = items?.shops?.length > 3 && value !== ''
        const sugg = [
          window.nearByPage ? nearByItem : {},
          ...items.places,
          ...items.gplaces,
          ...(items.shops || []),
        ]
        setSuggestions(isShowSeeAllBtn ? [...sugg, seeAllItem] : sugg)
      }).finally(() => {
        setLoading(false)
      })
  }
  const onSuggestionSelected = (e, { suggestion }) => {
    if(pageType === 'dive-shops') {
      pushToDataLayer({
        event: 'interaction',
        eventCategory: 'Dive Shop',
        eventAction: 'Search',
        eventLabel: suggestion.title,
        eventValue: '0',
        eventNonInteraction: 'false',
      })
    }

    const filteredFilters = omit(selectedFilters, ['shop_title_autosuggest', 'page'])

    if(suggestion.isNearBy) {
      window.location.href = generateUrl(
        window.nearByPage,
        filteredFilters,
        'nearby',
      )
      return
    }
    if(suggestion.isSeeAll) {
      window.location.href = generateUrl(
        suggestion.seeAllUrl,
        {
          shop_title_autosuggest: inputRef.current.input.value,
        },
        'seeAll',
      )
      return
    }

    locatorUrlFields.forEach((field) => {
      if(suggestion[field]) {
        window.location.href = generateUrl(suggestion[field], filteredFilters)
      }
    })

    if(suggestion.pageUrl) {
      window
        .open(generateUrl(suggestion.pageUrl, filteredFilters), '_blank')
        ?.focus()
    }
    if(suggestion.diveSitesUrl) {
      window.location.href = generateUrl(
        suggestion.diveSitesUrl,
        filteredFilters,
      )
    }
    if(suggestion.padiCoursesSearchUrl) {
      window.location.href = generateUrl(
        suggestion.padiCoursesSearchUrl,
        filteredFilters,
      )
    }
  }
  return (
    <div className={styles.container}>
      <NearByBtn onClick={focusInput} />
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        shouldRenderSuggestions={() => true}
        inputProps={inputProps}
        ref={inputRef}
      />
      {loading && <LoadingIndicator />}
    </div>
  )
}
