import ShopItemCard from './ShopItemCard'
import styles from '../styles/ShopList.module.scss'
import { FormattedMessage } from 'react-intl'
import DiveSiteItemCard from './DiveSiteItemCard'
import ConservationCard from './ConservationCard'
import { useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import CourseCardSelector from './courseCards/CourseCardSelector'

import PropTypes from 'prop-types'

const Card = ({
  pageType,
  item,
  isElearningView,
}) => {
  switch (pageType) {
    case 'dive-sites':
      return <DiveSiteItemCard key={item.id} item={item} />
    case 'conservation':
      return <ConservationCard item={item} />
    case 'professional':
      return <ConservationCard item={item} isPro />
    case 'courses':
      return <CourseCardSelector trip={item} />
    default:
      return (
        <ShopItemCard
          key={item.id}
          shop={item}
          isElearningView={isElearningView}
        />
      )
  }
}

export default function DslList({
  items,
  isElearningView,
  pageType,
  isLoading,
}) {
  useEffect(() => {
    if(items?.length) {
      ReactTooltip.rebuild()
    }
  }, [items?.length])
  return (
    <div className={styles.list}>
      {!items?.length && !isLoading && (
        <h3 className={styles.noResults}>
          <FormattedMessage
            id='no_results_found'
            defaultMessage='No Results Found'
          />
        </h3>
      )}
      {items?.map((item) => (
        <Card
          key={item.id || item.activityId}
          item={item}
          pageType={pageType}
          isElearningView={isElearningView}
        />
      ))}
    </div>
  )
}

DslList.propTypes = {
  items: PropTypes.array,
  isElearningView: PropTypes.bool,
  pageType: PropTypes.string,
  isLoading: PropTypes.bool,
}

Card.propTypes = {
  pageType: PropTypes.string,
  item: PropTypes.object,
  isElearningView: PropTypes.bool,
}
