import PromotionLabel from '../../helpers/PromotionLabel'
import { connect } from 'react-redux'

function ItineraryPromotion({ itineraryPopupData }) {
  const { promotion, price, compareAtPrice } = itineraryPopupData

  if(!promotion) {
    return null
  }

  return (
    <div className='deal-information' id='deal-section'>
      <PromotionLabel
        promotion={promotion}
        price={price}
        compareAtPrice={compareAtPrice}
      />
      <div className='deal-information__description wysiwyg'>
        <h4>{promotion.title}</h4>
        <div
          className='wysiwyg fade-onload'
          ng-class="{'expanded': showDeals }"
          hide-content='hide-content'
          trigger='showDeals'
          height='150'
          dangerouslySetInnerHTML={{ __html: promotion.description }}
        />
      </div>
    </div>
  )
}

export default connect((state) => ({
  itineraryPopupData: state.itineraryPopupData,
}))(ItineraryPromotion)
