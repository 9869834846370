import { useState, useEffect, useRef } from 'react'
import without from 'lodash/without'
import { isMobile } from '../../utils'

const InlineMultiselect = ({ variants, onSelect, title }) => {
  const multiselectRef = useRef(null)
  const [selectedItems, setSelectedItems] = useState([])

  useEffect(() => {
    let isActive = false
    let ev = isMobile() ? 'touchend' : 'click'
    let $elem = multiselectRef.current
    let clickingCallback = function(el, p) {
      $elem.classList.add('open')
      isActive = true
    }
    $elem.addEventListener(ev, clickingCallback)
    document.addEventListener(ev, function(e) {
      if(!$elem.contains(e.target) && isActive) {
        $elem.classList.remove('open')
        isActive = false
      }
    })
    updateSelectedItems()
  }, [])

  const updateSelectedItems = () => {
    let selectedItems = []

    variants.forEach((item) => {
      if(item.checked) {
        selectedItems.push(item.id)
      }
    })
    setSelectedItems(selectedItems)
  }

  const changeEvent = (variant, value) => {
    let items = [...selectedItems]
    let id = variant.id

    if(value) {
      items.push(id)
      variant.checked = true
    } else {
      items = without(items, id)
      variant.checked = false
    }
    setSelectedItems(items)
    if(typeof onSelect === 'function') {
      onSelect(items)
    }
  }

  return (
    <div className='multi-select white' ref={multiselectRef}>
      <span className='dropdown-toggle'>
        {title}&nbsp;
        {!!selectedItems.length && <i>({selectedItems.length})</i>}
      </span>
      <ul
        aria-labelledby='dropdownMenu'
        className='ititnerary-filter dropdown-menu'
      >
        {variants.map((variant) => {
          return (
            <li key={variant.id}>
              <a>
                <label className='checkbox-wrap'>
                  <input
                    type='checkbox'
                    checked={variant.checked}
                    onChange={(e) => changeEvent(variant, e.target.checked)}
                  />
                  <span />
                  <p>{variant.title}</p>
                </label>
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default InlineMultiselect
