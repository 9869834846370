import { FormattedMessage, useIntl } from 'react-intl'

const TranslatedOptions = ({ translatedObj }) => {
  const intl = useIntl()
  const optionsList = Object.keys(translatedObj)
  return optionsList.map((item) => {
    const elemObject = translatedObj[item]
    return (
      <option value={elemObject.value} key={elemObject.id}>
        {intl.formatMessage(elemObject)}
      </option>
    )
  })
}

const Options = ({ items }) => {
  return items.map((item) => (
    <option value={item.value} key={item.value}>
      {item.title}
    </option>
  ))
}

export const SortingItem = ({ title, value, onChange, options, className }) => (
  <div className={className}>
    <label className='bold'>{title}:</label>
    <select onChange={onChange} value={value}>
      {Array.isArray(options) ? (
        <Options items={options} />
      ) : (
        <TranslatedOptions translatedObj={options} />
      )}
    </select>
  </div>
)

export default function SortingFilters({
  updateOrdering,
  updateViewBy,
  orderingValue,
  sortByItems,
  viewByValue,
  viewByItems,
}) {
  return (
    <div className='results-actions display-flex items-center content-end hidden-xs'>
      <SortingItem
        title={<FormattedMessage id='sort_by' defaultMessage='Sort by' />}
        onChange={updateOrdering}
        value={orderingValue}
        className='search-ordering'
        options={sortByItems}
      />
      {viewByItems && (
        <SortingItem
          className='results-group-by'
          title={<FormattedMessage id='view_by' defaultMessage='View by' />}
          onChange={updateViewBy}
          value={viewByValue}
          options={viewByItems}
        />
      )}
    </div>
  )
}
