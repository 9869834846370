import { createRoot } from 'react-dom/client'
import { createStore, combineReducers } from 'redux'
import { configure } from '../api'
import { Provider } from 'react-redux'
import { FormattedMessage, IntlProvider } from 'react-intl'
import {
  selectedPin,
  mapFilters,
  mapBounds,
  page,
  isMapMoved,
  userCoordinates,
  opennedMarker,
  shopInfo,
  isMapVisible,
} from '../actions/dive-guide-actions'
import {
  selectedCurrency,
  setCurrency,
  pageLoader,
} from '../actions/main-actions'
import {
  currencyDirective,
  convertDjangoRenderedPrice,
  hideMobileAppPromotion,
  diveGuidesHeaderFooter,
  showModalOnClick,
  collapseItems,
  breadcrumbsToggle,
  fitDslNav,
} from '../directives'
import ErrorHandler from '../components/ErrorHandler'
import { getLanguage, getTranslations } from 'utils'
import { toast } from 'react-toastify'
import 'babel-polyfill'
import 'whatwg-fetch'
import 'react-toastify/dist/ReactToastify.css'
import PadiSearch from '../components/dive-guides/PadiSearch'
import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import 'finally-polyfill'
import {
  DiveShopLocatorList,
  DiveShopLocatorMap,
  DiveShopLocationFilters,
} from '../components/dsl'
import DiveCoursesListTitle from '../components/dsl/list/DiveCoursesListTitle'

if(process.env.SENTRY_KEY) {
  Sentry.init({
    dsn: process.env.SENTRY_KEY,
    ignoreErrors: [
      'AbortError',
      'UnhandledRejection',
      'Unexpected token o in JSON at position 1',
      'Anonymous function(/gtm.js)',
      "undefined is not an object (evaluating 'd.appendChild')",
      'chrome-extension',
      'gtm',
      'Illegal invocation',
      "Can't find variable: setIOSParameters",
      '(app-modern)',
      'd is undefined',
    ],
  })
}

let store = createStore(
  combineReducers({
    pageLoader,
    selectedPin,
    mapFilters,
    selectedCurrency,
    mapBounds,
    page,
    isMapMoved,
    userCoordinates,
    opennedMarker,
    shopInfo,
    isMapVisible,
  }),
)

let language = getLanguage()
let messages = {}
toast.configure()

window.store = store
configure(store)

// window.fx = fx

if(language !== 'en') {
  getTranslations(language, renderApp)
} else {
  renderApp()
}

function renderApp(translatedStrings) {
  if(translatedStrings) {
    messages = translatedStrings
  }
  renderElement('padi-search', PadiSearch)
  renderElement('padi-search-mobile', PadiSearch)

  let ordering
  if(window.ordering) {
    ordering = {
      items: [...window.ordering],
      query_name: 'ordering',
      key: 'value',
      title_en: 'Sort by',
      title: <FormattedMessage id='sort_by' defaultMessage='Sort by' />,
    }
  }

  if(window.dsPage) {
    window.filters?.forEach((filter) => {
      if(filter.query_name === 'marine_life_sightings') {
        filter.items.forEach((item) => {
          item.is_search = 'true'
        })
      }
    })
  }
  renderElement('dsl-title', DiveCoursesListTitle, {
    isNearby: window.isNearBy,
    locationTitle: window.locationTitle,
  })

  renderElement('dsl-list', DiveShopLocatorList, {
    locationType: window.locationType,
    locationSlug: window.locationSlug,
    locationId: window.locationId,
    pageType: window.pageType,
  })

  renderElement('dsl-map', DiveShopLocatorMap, {
    location: {
      type: window.locationType,
      id: window.locationId,
    },
    locationBounds: window.locationBounds,
    pageType: window.pageType,
    countryCode: window.countryCode,
  })

  renderElement('dsl-filters', DiveShopLocationFilters, {
    filters: window.filters,
    locationTitle: window.locationTitle,
    allUrl: window.allUrl,
    pageType: window.pageType,
    isDiveSite: window.dsPage,
    ordering,
    countryCode: window.countryCode,
    price: window.adventurePrice,
  })

  hideMobileAppPromotion()
  currencyDirective(function(currency) {
    store.dispatch(setCurrency(currency))
  })
  collapseItems()
  convertDjangoRenderedPrice(renderElement)
  diveGuidesHeaderFooter()
  showModalOnClick(renderElement)
  breadcrumbsToggle()
  fitDslNav()
}

function renderElement(elem, ComponentToRender, props) {
  let element
  if(typeof elem === 'string') {
    element = document.getElementById(elem)
  } else {
    element = elem
  }

  if(element) {
    const root = createRoot(element)
    root.render(
      <IntlProvider
        locale={language === 'ach' ? 'en' : language}
        messages={messages}
        textComponent='span'
      >
        <Provider store={store}>
          <ErrorHandler>
            <ComponentToRender {...props} />
          </ErrorHandler>
        </Provider>
      </IntlProvider>,
    )
  }
}
