import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import styles from '../styles/DiveShopLocatorFilters.module.scss'
import { FormCheckboxStyled } from '../../forms/final-form-fields/FormCheckbox'
import allFiltersStyles from './dropdown/AllFiltersDropdown.module.scss'

export default function DiveCenterAdditionalFilters({
  handleToggleClick,
  allFiltersView,
  pageType,
}) {
  if(pageType !== 'dive-shops') {
    return null
  }
  const stylesObject = allFiltersView ? allFiltersStyles : styles
  return (
    <Fragment>
      <span className={styles.separator} />
      <FormCheckboxStyled
        name='padi_five_star'
        className={stylesObject.checkbox}
        onChange={handleToggleClick}
        labelEn='PADI 5 Star'
        label={
          <FormattedMessage id='padi_5_star' defaultMessage='PADI 5 Star' />
        }
      />
      <FormCheckboxStyled
        name='instructor'
        className={stylesObject.checkbox}
        onChange={handleToggleClick}
        labelEn='Instructor Training'
        label={
          <FormattedMessage
            id='instructor_training'
            defaultMessage='Instructor Training'
          />
        }
      />
      <FormCheckboxStyled
        name='open_now'
        className={stylesObject.checkbox}
        onChange={handleToggleClick}
        labelEn='Open now'
        label={<FormattedMessage id='open_now' defaultMessage='Open Now' />}
      />
    </Fragment>
  )
}
