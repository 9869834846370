import { useDispatch, useSelector } from 'react-redux'
import { setPage, updateFilters } from '../../../actions/dive-guide-actions'

export default function usePagination() {
  const dispatch = useDispatch()
  const page = useSelector((state) => state.page)
  const setNextPage = () => {
    const nextPage = page + 1
    dispatch(updateFilters({ page: nextPage }))
    dispatch(setPage(nextPage))
    window.scrollTo(0, 0)
  }
  const setPrevPage = () => {
    const prevPage = page > 1 ? page - 1 : 1
    dispatch(updateFilters({ page: prevPage }))
    dispatch(setPage(prevPage))
    window.scrollTo(0, 0)
  }
  const setCurrentPage = (page) => {
    dispatch(setPage(page))
    dispatch(updateFilters({ page }))
  }
  return {
    page,
    setNextPage,
    setPrevPage,
    setCurrentPage,
  }
}
