import { Fragment, useEffect, useState } from 'react'
import styles from './PriceFilter.module.scss'
import cx from 'classnames'
import { useForm, useFormState } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import FormRangeSelector from '../../forms/final-form-fields/FormRangeSelector'
import PropTypes from 'prop-types'
import { useOutsideClick } from '../../../hooks/useOutsideClick'

export default function PriceRangeFilter({
  price,
  filterName,
  isMobile,
}) {
  const {
    change,
  } = useForm()
  const { values } = useFormState()
  const [isSelected, setIsSelected] = useState(false)
  const [rangeValues, setRangeValues] = useState([Math.ceil(Number(price?.min)), Math.ceil(Number(price?.max))])
  const [isShown, setIsShown] = useState(false)

  const { ref } = useOutsideClick(() => setIsShown(false))

  useEffect(() => {
    if(values && values['price']?.length) {
      setIsSelected(true)
    } else {
      setIsSelected(false)
    }
  }, [values])

  const onReset = () => {
    change('price', [])
    setRangeValues([Math.ceil(Number(price?.min)), Math.ceil(Number(price?.max))])
  }

  const handleFilterClick = () => {
    setIsShown(!isShown)
  }

  return (<Fragment>
    <div className={styles.container}
      ref={ref}
    >
      <div className={cx(styles.button, {
        [styles.selected]: isSelected,
        [styles.mobile]: isMobile,
      })}
        onClick={handleFilterClick}
      >
        <span className={styles.title}>
          <FormattedMessage defaultMessage='Price' />
          <i className={cx('dsl-icons dsl-icons--arrow-dropdown')} />
        </span>
      </div>
      <div className={cx(styles.selectContainer, styles.rangeContainer, { [styles.show]: isShown })}
      >
        <div className={cx(styles.rangeTitle)}>
          <span>
            <span>{rangeValues?.[0]}</span>
            &nbsp;-&nbsp;
            <span>{rangeValues?.[1]}</span>
            &nbsp;
            <span className={styles.rangeCurrency}>{price?.currency}</span>

          </span>
          <span
            onClick={onReset}
            className={cx(styles.resetButton)}
          ><FormattedMessage defaultMessage='Reset' /></span>
        </div>
        <FormRangeSelector name={filterName}
          min={Math.ceil(Number(price?.min))}
          max={Math.ceil(Number(price?.max))}
          step={1}
          setRangeValues={setRangeValues}
          key={filterName}
        />

      </div>
    </div>
  </Fragment>)
}

PriceRangeFilter.propTypes = {
  price: PropTypes.object,
  filterName: PropTypes.string,
}
