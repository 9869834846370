exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".cardHeader--1C1Ec{display:flex;flex-direction:column}.title--qWt_M{font-size:16px;font-weight:700;color:rgba(0,0,0,.87);margin-bottom:0}.card--2WCMt{display:flex;flex-direction:column;border:1px solid #e0e0e0;box-sizing:border-box;border-radius:4px;padding:16px;width:calc(50% - 12px);cursor:pointer;transition:all .15s linear}@media (min-width:1024px) and (max-width:1150px){.card--2WCMt{width:100%}}@media (max-width:550px){.card--2WCMt{width:100%}}.card--2WCMt:active,.card--2WCMt:focus,.card--2WCMt:hover{text-decoration:none;box-shadow:0 0 6px rgba(0,0,0,.16);border-color:#0070d3}.shopLink--2n-M1{display:flex;align-items:center;gap:3px}.shopLink--2n-M1 i{color:#4c4c4c;font-size:18px;line-height:24px}.shopLink--2n-M1 a{font-size:12px;color:#0070d3}.imgContainer--3vJs5{position:relative;width:calc(100% + 32px);margin-top:16px;margin-left:-16px;height:172px;max-height:172px;overflow:hidden}.imgContainer--3vJs5 img{width:100%;height:172px;-o-object-fit:cover;object-fit:cover}.details--3YRMq{display:flex;flex-direction:column;gap:8px;padding:24px 8px}.details--3YRMq p{margin-bottom:0;font-size:12px;color:rgba(0,0,0,.6)}.detailsHeader--1LFVo{font-size:10px;font-weight:700;letter-spacing:1px;color:rgba(0,0,0,.84);text-transform:uppercase}.btnContainer--3h0uH{display:flex;justify-content:space-between;align-items:center;margin-top:auto}.bookableBtn--2aKQ4{text-transform:uppercase}.price--2RamB{font-size:16px;font-weight:700;color:rgba(0,0,0,.87);display:flex;align-items:center;gap:4px}.notAvailableShop--3Dt6-{margin-left:auto}", ""]);

// Exports
exports.locals = {
	"cardHeader": "cardHeader--1C1Ec",
	"title": "title--qWt_M",
	"card": "card--2WCMt",
	"shopLink": "shopLink--2n-M1",
	"imgContainer": "imgContainer--3vJs5",
	"details": "details--3YRMq",
	"detailsHeader": "detailsHeader--1LFVo",
	"btnContainer": "btnContainer--3h0uH",
	"bookableBtn": "bookableBtn--2aKQ4",
	"price": "price--2RamB",
	"notAvailableShop": "notAvailableShop--3Dt6-"
};