import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUserCoordinates } from '../../../actions/dive-guide-actions'
import { getCoordinates } from '../../../utils'

export default function useUserCoordinates(tryToGetCoordinates) {
  const [waitingForCoords, setWaitingForCoords] = useState(
    window.isNearBy && tryToGetCoordinates
  )
  const dispatch = useDispatch()
  const userCoordinates = useSelector((state) => state.userCoordinates)
  useEffect(() => {
    if(!userCoordinates && window.isNearBy && tryToGetCoordinates) {
      getCoordinates(
        (coords) => {
          dispatch(
            setUserCoordinates({
              lat: coords.latitude,
              lng: coords.longitude,
            })
          )
          setWaitingForCoords(false)
        },
        () => setWaitingForCoords(false)
      )
    }
  }, [userCoordinates, tryToGetCoordinates])

  return {
    waitingForCoords,
    userCoordinates,
  }
}
