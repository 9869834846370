/* eslint-disable camelcase */
import React, { Component } from 'react'
export default class FiltersWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = {
      title: '',
      filtersToggler: {
        price: true,
        duration: true,
        diving_intensity: true,
        experience: true,
      },
      monthes: props.monthes,
    }

    this.toggleFilter = this.toggleFilter.bind(this)
  }

  toggleFilter(filterId) {
    let filtersToggler = Object.assign({}, this.state.filtersToggler)

    filtersToggler[filterId] = !filtersToggler[filterId]

    this.setState({
      filtersToggler,
    })
  }

  updateMonth = (selectedOption) => {
    if(!selectedOption) {
      this.clearDates()
    } else {
      this.props.updateParams({
        departure_date_month: selectedOption,
        departure_date: selectedOption.from,
      })
    }
  }

  handleGuestsChange = ({
    guests_split,
    divers,
    nonDivers,
    students,
    rooms,
  }) => {
    this.props.updateParams({
      guests_split: divers || nonDivers || students ? guests_split : undefined,
      divers,
      nonDivers,
      students,
      rooms,
    })
  }

  render() {
    const childrenWithProps = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, {
        ...this.state,
        ...this.props,
        updateMonth: this.updateMonth,
        toggleFilter: this.toggleFilter,
        handleGuestsChange: this.handleGuestsChange,
      })
    )
    return childrenWithProps
  }
}
