import { FormattedMessage } from 'react-intl'
import { Popup } from 'react-leaflet'
import get from 'lodash/get'
import styles from './../styles/MapPopup.module.scss'
import { defaultShopPicture } from '../../../config'
import usePinInfo from '../hooks/usePinInfo'
import { Loader } from '../../helpers'
import ShopPicture from '../../helpers/ShopPicture'
import moment from 'moment'
import { ActivityDuration } from '../../search'
import ShopLink from '../list/ShopLink'

export default function ProPopup({ id, onPopupClose, isOpen }) {
  const { pinInfo, isLoading } = usePinInfo(id, 'professional', isOpen)
  const image = get(pinInfo, 'picture.800x800') || defaultShopPicture
  return (
    <Popup onClose={onPopupClose}>
      <Loader isLoading={isLoading} />
      <a
        href={pinInfo.activityPage}
        className={styles.container}
        target='_blank'
      >
        <div className={styles.wrapper}>
          <p className={styles.title}>{pinInfo.title}</p>
          <ShopLink shop={pinInfo?.diveCenter} />
        </div>
        <ShopPicture image={image} className={styles.shopImage} />
        <div className={styles.wrapper}>
          <div className={styles.details}>
            <p className={styles.detailsTitle}>
              <FormattedMessage
                id='date_and_time'
                defaultMessage='Date & Time'
              />
            </p>
            <p className={styles.detailsInfo}>
              {pinInfo.date
                ? moment(`${pinInfo.date}T${pinInfo.startTime}`).format('lll')
                : '–'}
            </p>
          </div>
          <div className={styles.details}>
            <p className={styles.detailsTitle}>
              <FormattedMessage id='duration' defaultMessage='Duration' />
            </p>
            <p className={styles.detailsInfo}>
              <ActivityDuration
                duration={pinInfo?.duration?.duration}
                durationType={pinInfo?.duration?.durationType}
              />
            </p>
          </div>
          <span className={styles.viewDetails}>
            <FormattedMessage id='view_details' defaultMessage='View Details' />
          </span>
        </div>
      </a>
    </Popup>
  )
}
