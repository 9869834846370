import get from 'lodash/get'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import PaymentAndCancellation from '../../helpers/PaymentsAndCancellation'

function ItineraryPaymentInfo({ itinerary, currency }) {
  const [isOpen, setOpen] = useState(false)
  if(!itinerary.paymentInformation) {
    return null
  }

  const handleClick = () => setOpen(!isOpen)
  return (
    <div className='itinerary-section itinerary-logistics collapsed-section'>
      <h4 onClick={handleClick} className={isOpen ? 'active' : ''}>
        <FormattedMessage
          id='payment_and_cancellation'
          defaultMessage='Payment & Cancellation terms'
        />
      </h4>
      {isOpen && (
        <div className='itinerary-section-details'>
          <PaymentAndCancellation
            currency={currency}
            paymentInformation={itinerary.paymentInformation}
            milestones={itinerary.milestones}
            isCustomCancellation={itinerary.isCustomCancellation}
            cancellationMilestones={itinerary.cancellationMilestones}
            customCancellationText={itinerary.customCancellationText}
          />
        </div>
      )}
    </div>
  )
}

export default connect((state) => ({
  currency: get(state, 'itineraryPopupData.currency'),
}))(ItineraryPaymentInfo)
