import PricingRow from '../PricingRow'
import { DateFormat } from '../../helpers'
import get from 'lodash/get'

const LAGroupedProducts = ({
  groupedProducts,
  departureDateMonth,
  params,
  background,
  currency,
  shopSlug,
  shopTitle,
  countrySlug,
  insurances,
  totalGuests,
  onSelect,
  isCheckout,
  queryParams,
  isWidget,
}) => Object.keys(groupedProducts).map((key) => {
  return (
    <div
      itemProp='offers'
      key={key}
      itemScope='itemscope'
      itemType='http://schema.org/Offer'
      className='month-group'
    >
      {(get(departureDateMonth, 'id') === 'all' || params.is_available || isCheckout) && (
        <div className='group-title'>
          <DateFormat
            date={groupedProducts[key][0].startDate}
            format='MMMM YYYY'
          />
        </div>
      )}
      {groupedProducts[key].map((product) => {
        return (
          <PricingRow
            product={product}
            key={product.id}
            background={background}
            currency={currency}
            shopSlug={shopSlug}
            shopTitle={shopTitle}
            countrySlug={countrySlug}
            totalGuests={totalGuests}
            onSelect={onSelect}
            insurance={insurances[0]}
            queryParams={queryParams}
          />
        )
      })}
    </div>
  )
})

export default LAGroupedProducts
