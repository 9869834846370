import { Fragment, useState } from 'react'
import get from 'lodash/get'
import RatingStars from '../../helpers/RatingStars'

const SecondLevelItems = ({
  items,
  sectionTitle,
  filterName,
  selectedFilters,
  updateCheckbox,
}) => {
  const [isExpanded, setExpanded] = useState(false)
  const handleClick = () => {
    setExpanded(!isExpanded)
  }
  return (
    <Fragment>
      <p
        className={`second-level-arrow ${isExpanded ? 'active' : ''}`}
        onClick={handleClick}
      >
        {sectionTitle}
      </p>
      {isExpanded && (
        <ul className='fade-onload'>
          {items.map((subitem) => {
            return (
              <ChecboxLabel
                key={subitem.id}
                item={subitem}
                showTitle
                filterName={filterName}
                updateCheckbox={updateCheckbox}
                selectedFilters={selectedFilters}
              />
            )
          })}
        </ul>
      )}
    </Fragment>
  )
}

export const ChecboxLabel = ({
  item,
  showTitle,
  filterName,
  updateCheckbox,
  selectedFilters,
}) => {
  const onChange = (e) =>
    updateCheckbox(filterName, item.id, e.target.checked, item)

  if(!selectedFilters[filterName]) {
    selectedFilters[filterName] = {}
  }
  const isChecked = !!get(
    selectedFilters,
    `[${filterName}][${item.id}]`,
    get(selectedFilters, `[${item.query_name}][${item.id}]`),
    false
  )

  return (
    <label className='checkbox-wrap'>
      <input type='checkbox' onChange={onChange} checked={isChecked} />
      <span />
      {showTitle && (
        <Fragment>
          {filterName === 'stars' ? (
            <RatingStars rating={item.id} maxStars={6} className='stars' />
          ) : (
            <p>{item.title}</p>
          )}
        </Fragment>
      )}
    </label>
  )
}

export default function FilterItem({
  item,
  updateCheckbox,
  filterName,
  selectedFilters,
}) {
  const subItemsLength = get(item, 'subitems.length', 0)
  const queryName =
    subItemsLength > 0 ? 'marine_life_group_critters' : filterName
  return (
    <li className={`fade-onload ${subItemsLength > 1 ? 'two-level-list' : ''}`}>
      <ChecboxLabel
        item={item}
        filterName={queryName}
        showTitle={subItemsLength <= 1}
        selectedFilters={selectedFilters}
        updateCheckbox={updateCheckbox}
      />
      {subItemsLength > 1 && (
        <SecondLevelItems
          items={item.subitems}
          sectionTitle={item.title}
          updateCheckbox={updateCheckbox}
          selectedFilters={selectedFilters}
          filterName={filterName}
        />
      )}
    </li>
  )
}
