import { FormattedMessage } from 'react-intl'
import styles from '../styles/SelectedShopTitle.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import omit from 'lodash/omit'
import useLocatorFilters from '../hooks/useLocatorFilters'
import { setFilters, setOpennedMarker } from '../../../actions/dive-guide-actions'

import PropTypes from 'prop-types'

export default function SelectedShopTitle({ isLoading }) {
  const dispatch = useDispatch()
  const { filters } = useLocatorFilters(true, window.pageType)
  const shopInfo = useSelector((state) => state.shopInfo)
  const handleChange = () => {
    dispatch(setFilters(omit(filters, ['shop_id'])))
    dispatch(setOpennedMarker(null))
  }
  if(!shopInfo || isLoading) {
    return null
  }

  return (
    <div className={styles.listTitle}>
      <div className={styles.header}>
        <p>
          {shopInfo.title}
        </p>
        <div className={styles.backButton} onClick={handleChange}>
          <i className='dsl-icons dsl-icons--back' />
          <FormattedMessage defaultMessage='Back to search results' />
        </div>
      </div>
      <div className={styles.address}>
        <img src={`${window.staticPath}travel_dist/images/padi_logo.svg`} />
        {shopInfo.membershipLevel} &nbsp;
        {shopInfo.shortAddress}
      </div>
      <div className={styles.info}>
        <span className={styles.title}>
          <FormattedMessage defaultMessage='Languages' />
        </span>
        <span>{
          shopInfo?.languages?.join(', ')
        }</span>
      </div>
      <div className={styles.info}>
        <span className={styles.title}>
          <FormattedMessage defaultMessage='Hours' />
        </span>
        <span>{shopInfo?.openHour}</span>
      </div>
    </div>
  )
}

SelectedShopTitle.propTypes = {
  isLoading: PropTypes.bool,
}
