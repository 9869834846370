import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { MealPlanIcon } from '../resort/pricing/partials'
import EcoLabels from '../resort/EcoLabels'
import { handleEventBubbling } from '../../dom-handlers'
import TwoDatesFormat from '../helpers/TwoDatesFormat'
import { DiverAmenities, StudentAmenities } from './amenities'
import get from 'lodash/get'
import messages from '../../messages'

const getDiveCenterType = (type) => {
  switch (+type) {
    case 10:
      return <FormattedMessage id='onsite' defaultMessage='Onsite' />
    case 20:
      return (
        <FormattedMessage
          id='walking_distance'
          defaultMessage='Walking distance'
        />
      )
    case 30:
      return (
        <FormattedMessage
          id='offsite_free_pickup'
          defaultMessage='Offsite (Free pickup)'
        />
      )
    case 40:
      return (
        <FormattedMessage
          id='offsite_paid_pickup'
          defaultMessage='Offsite (Paid pickup)'
        />
      )
    default:
      return ''
  }
}

function ResortAmenities({ isAllParamsSelected, shop, selectedParams }) {
  const intl = useIntl()
  const locationType = useMemo(
    () => getDiveCenterType(shop.resortLocationType),
    [shop.resortLocationType]
  )
  const hasEcoLabels = !!get(shop, 'diveCenterEcoLabels.length')

  return (
    <div className='half amenities-container'>
      <div
        className={`amenities${
          shop.numberOfReviews > 2 || shop.tripAdvisor ? ' have-rating' : ''
        }${shop.minimalStay > selectedParams.nights ? ' minimal-stay' : ''}
      `}
      >
        {!!isAllParamsSelected && !!shop.errorMsg && (
          <p className='dates'>
            <i
              className='tooltip-wrapper-block font-icons icon-warning'
              data-for='global'
              data-tip={shop.errorMsg}
            />
            <TwoDatesFormat dateFrom={shop.dateStart} dateTo={shop.dateEnd} />
          </p>
        )}
        <p>
          <i className='font-icons location-icon' />
          {shop.locationTitle}
        </p>
        <p>
          <MealPlanIcon mealPlan={shop.mealPlan} />
          <span>
            {shop.mealPlanTitle || (
              <FormattedMessage id='room_only' defaultMessage='Room only' />
            )}
          </span>
        </p>
        {!!shop.stars && (
          <p>
            <i className='font-icons icon-star-outline' />
            <FormattedMessage
              id='star_hotel'
              values={{ stars: shop.stars }}
              defaultMessage='{ stars } star hotel'
            />
          </p>
        )}
        {shop.bookWithConfidence && (
          <div className='book-with-confidence'>
            <p className='blue' data-for='global'>
              <FormattedMessage
                id='book-with-confidence'
                defaultMessage='Book with confidence'
              />
              <i className='font-icons info-icon' />
            </p>
            <p>{intl.formatMessage(messages.bookWithConfidence)}</p>
          </div>
        )}
      </div>
      <div
        className={`dc-info amenities${hasEcoLabels ? ' w_labels' : ''}`}
        onClick={handleEventBubbling}
      >
        <span className='dc-title'>{shop.diveCenterTitle}</span>
        <p>
          <i className='padi-globe' />
          {shop.diveCenterHasFiveStar ? (
            <FormattedMessage
              id='padi_5_star_dc'
              defaultMessage='PADI 5 star dive center'
            />
          ) : (
            <FormattedMessage
              id='padi_dive_center'
              defaultMessage='PADI dive center'
            />
          )}
        </p>
        <p>
          <i className='font-icons location-icon' />
          {locationType}
        </p>
        {selectedParams.divers > 0 && (
          <DiverAmenities shop={shop} selectedParams={selectedParams} />
        )}
        {selectedParams.students > 0 && <StudentAmenities />}
        {hasEcoLabels && (
          <div className='eco-labels'>
            <EcoLabels labelsList={shop.diveCenterEcoLabels} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ResortAmenities
