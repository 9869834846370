exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".header--2oa3w{border-bottom:1px solid #e0e0e0;padding:16px;font-size:20px;justify-content:space-between;display:flex;align-items:center;color:rgba(0,0,0,.87)}.closeBtn--1JsKS{display:none}@media (max-width:768px){.closeBtn--1JsKS{display:inline-block}}.content--2_x5C{display:flex;flex-direction:column;overflow-y:auto;height:calc(100% - 101px)}@media (max-width:768px){.content--2_x5C{overflow-y:auto}}.checkbox--jr5ri{padding:0 16px;margin:15px 0}.footer--21fbD{display:flex;align-items:center;justify-content:flex-end;border-top:1px solid #e0e0e0;padding:16px}@media (max-width:768px){.footer--21fbD{padding:8px 16px}}.clearAll--25Su7{color:#0070d3;font-weight:700;font-size:12px}.applyBtn--3A1z3{margin-right:auto}@media (min-width:769px){.applyBtn--3A1z3{display:none}}", ""]);

// Exports
exports.locals = {
	"header": "header--2oa3w",
	"closeBtn": "closeBtn--1JsKS",
	"content": "content--2_x5C",
	"checkbox": "checkbox--jr5ri",
	"footer": "footer--21fbD",
	"clearAll": "clearAll--25Su7",
	"applyBtn": "applyBtn--3A1z3"
};