import { FormattedMessage } from 'react-intl'

export default function TripTitle({ product, showItinerary, isGroupTrip }) {
  const openPopupAndScrollTo = (scrollTo) => () => {
    showItinerary(scrollTo)
  }
  return (
    <div className='td-itinerary content-left'>
      <div onClick={showItinerary} className='name'>
        {product.itinerary.title}
      </div>
      {product.title !== product.itinerary.title && (
        <span className='itinerary-name'>{product.title}</span>
      )}
      <div className='detail-links'>
        <a onClick={showItinerary}>
          <FormattedMessage
            id='itinerary_details'
            defaultMessage='Itinerary details'
          />
        </a>
        <a onClick={openPopupAndScrollTo('#logistics')}>
          <FormattedMessage
            id='how_to_get_there'
            defaultMessage='How to get there'
          />
        </a>
        {!isGroupTrip && (
          <a onClick={openPopupAndScrollTo('#included-section')}>
            <FormattedMessage
              id='whats_included'
              defaultMessage='What`s included'
            />
          </a>
        )}
      </div>
    </div>
  )
}
