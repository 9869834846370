import Proptypes from 'prop-types'
import styles from './Cards.module.scss'
import CardHeader from './CardHeader'
import { FormattedMessage, useIntl } from 'react-intl'
import CourseButton from './CourseButton'
import { openSalesForcePopup } from '../../../dive-guides/gtm-events'
import cx from 'classnames'

export default function NotAvailableActivityCard({
  diveCourseInfo,
  shopTitle,
  shopUrl,
  shopLocation,
  shopCountry,
  shopEmail,
  affiliateId,
  shopId,
  highlightPinOnMap,
  removeHighlight,
}) {
  const intl = useIntl()
  const shopInfo = {
    id: shopId,
    email: shopEmail,
    affiliateId: affiliateId,
    title: shopTitle,
    locationTitle: shopLocation,
    countryTitle: shopCountry,
  }
  return (
    <div
      onMouseEnter={highlightPinOnMap}
      onMouseLeave={removeHighlight}
      className={cx(styles.card, 'btn-contact')}
      onClick={() => openSalesForcePopup(shopInfo, intl, false, false)}
    >
      <CardHeader
        courseTitle={diveCourseInfo.title}
        shopTitle={shopTitle}
        shopUrl={shopUrl}
      />
      <div className={styles.imgContainer}>
        <img
          src={diveCourseInfo?.picture?.['800x800']}
          alt={diveCourseInfo.title}
        />
      </div>
      <div className={styles.details}>
        <p className={styles.detailsHeader}>
          <FormattedMessage defaultMessage='MAKE AN ENQUIRY' />
        </p>
        <FormattedMessage
          defaultMessage='This product is not a bookable product and it is not available via PADI.com at this time.'
          tagName='p'
        />
        <FormattedMessage
          defaultMessage='You can make an enquiry to prices and availability by using our Contact Shop form.'
          tagName='p'
        />
      </div>
      <div className={styles.btnContainer}>
        <CourseButton
          text={<FormattedMessage defaultMessage='Contact Shop' />}
          type='notAvailable'
          className={styles.notAvailableShop}
        />
      </div>
    </div>
  )
}

NotAvailableActivityCard.propTypes = {
  diveCourseInfo: Proptypes.object.isRequired,
  shopTitle: Proptypes.string.isRequired,
  shopUrl: Proptypes.string.isRequired,
  shopLocation: Proptypes.string.isRequired,
  shopCountry: Proptypes.string.isRequired,
  shopEmail: Proptypes.string.isRequired,
  affiliateId: Proptypes.string.isRequired,
  shopId: Proptypes.string.isRequired,
  highlightPinOnMap: Proptypes.func.isRequired,
  removeHighlight: Proptypes.func.isRequired,
}
