import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { checkOverlay } from './helpers'

function toggleFiltersContainerVisibility(removeVisibility) {
  const filterListContainer = document.querySelector('#filter-list')

  if(filterListContainer) {
    if(removeVisibility) {
      filterListContainer.classList.remove('visible')
    } else {
      filterListContainer.classList.toggle('visible')
    }
  }
  checkOverlay('#filter-list')
}

export const triggerFilter = (e) => {
  e.preventDefault()
  toggleFiltersContainerVisibility()
}

export default function MobileFilterToggle({ filtersCount }) {
  useMemo(() => {
    window.onresize = function() {
      if(window.innerWidth >= 767) {
        toggleFiltersContainerVisibility(true)
      }
    }
  }, [])

  return (
    <a
      className={`results-action ${filtersCount ? 'active' : ''}`}
      onClick={triggerFilter}
    >
      <i className='font-icons icon-filters' />
      <FormattedMessage id='filter' defaultMessage='Filter' />
      {filtersCount ? <small>&nbsp;({filtersCount})</small> : ''}
    </a>
  )
}
