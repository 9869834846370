import './schedule-dates.scss'
import DateFormat from '../../helpers/DateFormat'
import { FormattedMessage, defineMessages } from 'react-intl'
import PriceConverter from '../../helpers/PriceConverter'
import moment from 'moment'
import get from 'lodash/get'
import ActivityInclusions from '../../adventures/ActivityInclusions'
import InstantConfirmation from '../../adventures/InstantConfirmation'
import DiveSitesList from '../../adventures/DiveSitesList'
import { useMemo } from 'react'
import { AdventureOriginalTitle } from '../../adventures'

const messages = defineMessages({
  advancedDivers: {
    id: 'advanced_only',
    defaultMessage: 'Advanced divers only',
  },
  advanced: {
    id: 'advanced',
    defaultMessage: 'Advanced',
  },
})

const AdvancedDiver = ({ text }) => {
  return (
    <span className='dive-site-info advanced-divers'>
      <i className='font-icons icon-warning' />
      <span className='dive-site-text'>
        <FormattedMessage {...text} />
      </span>
    </span>
  )
}

const DiveSites = ({ sites }) => {
  const dsLength = get(sites, 'length')
  if(!dsLength) {
    return null
  }
  return (
    <span className='dive-site-info'>
      <i className='font-icons icon-dive-sites' />
      <span>
        <DiveSitesList sites={sites} />
      </span>
    </span>
  )
}

const Row = ({ item, searchPageSchedule }) => {
  const startTime = moment(`1970-01-01T${item.startTime}`)
  const endTime = moment(`1970-01-01T${item.startTime}`).add(
    item.durationHours,
    'hours'
  )
  const dateDifference = endTime.diff(startTime, 'days')
  const price = get(item, 'minimumPrice.originValue')
  const currency = get(item, 'minimumPrice.originCurrency')

  const onClick = () => {
    window.location.href = `${item.activityPage}${
      window.isWidget ? '&widget=true' : ''
    }`
  }

  const getIcon = (type) =>
    useMemo(() => {
      switch (type) {
        case 'course':
          return 'icon-course'
        case 'snorkelling':
          return 'icon-snorkeling'
        case 'dive_trip':
          return 'icon-diver'
        case 'social_event':
          return 'icon-champagne'
      }
    }, [type])
  return (
    <div
      className={`schedule-row ${
        searchPageSchedule ? 'search-page-schedule' : ''
      }`}
      onClick={onClick}
    >
      <div className='schedule-row__time'>
        <span>{startTime.format('HH:mm')}</span>
        <span className='delimeter'>|</span>
        <span>
          {dateDifference ? (
            <FormattedMessage
              id='days'
              defaultMessage='{qty, plural, one {# day} other {# days}}'
              values={{
                qty: dateDifference,
              }}
            />
          ) : (
            endTime.format('HH:mm')
          )}
        </span>
      </div>
      <div className='schedule-row__icon'>
        <i className={`icon font-icons ${getIcon(item.activityType)}`} />
      </div>
      <div className='schedule-row__trip'>
        <span className='schedule-row__trip__title'>
          {item.title}
          <AdventureOriginalTitle
            originalTitle={item.originalTitle}
            title={item.title}
          />
        </span>
        <span className='schedule-row__trip__inclusions'>
          <ActivityInclusions item={item} />
          &nbsp;
          {item.diveCenter && <DiveSites sites={item.sites} />}
          {item.isAdvanced && <AdvancedDiver text={messages.advanced} />}
        </span>
      </div>
      <div className='schedule-row__info'>
        {item.diveCenter ? (
          <span className='dive-center-info'>
            <strong className='dc-name'>{item.diveCenter.title}</strong>
            <span className='dc-location'>{item.diveCenter.locationTitle}</span>
          </span>
        ) : (
          <DiveSites sites={item.sites} />
        )}
        {item.isAdvanced && <AdvancedDiver text={messages.advancedDivers} />}
      </div>
      <div className='schedule-row__price'>
        <span className='from'>
          <FormattedMessage id='from' defaultMessage='From' />
        </span>
        <span className='pricing'>
          {item.instantConfirmation && <InstantConfirmation />}
          <PriceConverter amount={price} currency={currency} />
        </span>
      </div>
    </div>
  )
}

export default function ScheduleDates({ items, date, searchPageSchedule }) {
  return (
    <div className='schedule-dates'>
      <div className='schedule-dates__header'>
        <span className='schedule-dates__date'>
          <DateFormat date={date} />
        </span>
      </div>
      {items.map((item) => (
        <Row
          item={item}
          key={item.id || item.activityId}
          searchPageSchedule={searchPageSchedule}
        />
      ))}
    </div>
  )
}
