import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import get from 'lodash/get'
import find from 'lodash/find'
import each from 'lodash/each'
import API from 'api'
import { setDepartureDate } from '../actions/dive-operator-actions'
import { trackGA4Ecommerce } from '../gtm'
import { convertCurrency } from '../components/helpers'
import { roundPrice, generateSeoStringForGuests } from '../utils'

let controller, signal

const useTrips = (
  departureDateMonth,
  params,
  departureDateLess,
  slug,
  title,
  currency,
  roomParams,
  isCheckout,
) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [groupedProducts, setGroupedProducts] = useState({})
  const [next, setNext] = useState(null)
  const [previous, setPrevious] = useState(null)

  useEffect(() => {
    setIsLoading(true)

    if('AbortController' in window) {
      controller = new window.AbortController()
      signal = controller.signal
    }

    const departureMonth = get(departureDateMonth, 'id')

    const filters = {
      ...params,
      departure_date: undefined,
    }

    if(departureDateLess) {
      filters.departure_date_less_then = params.departure_date
    } else {
      filters.departure_date = params.departure_date
    }

    dispatch(setDepartureDate(params.departure_date))

    if(
      departureMonth === 'available' ||
      departureMonth === 'all' ||
      isCheckout ||
      params.is_available
    ) {
      filters.page_size = 1000
    }

    API(`shop/${slug}/trips/`, signal)
      .get(filters)
      .then((trips) => {
        if(departureDateLess) {
          trips.results.reverse()
        }
        let groupedProducts = {}
        each(trips.results, (trip, index) => {
          if(find(trip.tags, { title: 'Sale' })) {
            trip.isSale = true
          }

          const month = moment(trip.startDate).month()
          const year = moment(trip.startDate).year()
          const dateGroup = `${year}_${month}`

          trip.iterator = index + 1

          if(!groupedProducts[dateGroup]) {
            groupedProducts[dateGroup] = []
          }

          groupedProducts[dateGroup].push(trip)

          trackGA4Ecommerce('view_item', {
            currency: 'USD',
            value: Number(roundPrice(convertCurrency(trip.price, currency, 'USD'))),
            items: [
              {
                item_name: title,
                item_id: trip.id.toString(),
                price: Number(roundPrice(
                  convertCurrency(trip.price, currency, 'USD'),
                )),
                item_brand: 'PADI',
                item_category: 'PADI Travel',
                item_category2: 'Liveaboard',
                item_variant: `${trip.itinerary.title} / ${moment(trip.startDate)
                  .utc()
                  .format('DD MMM')}-${moment(trip.endDate)
                  .utc()
                  .format('DD MMM')} / ${generateSeoStringForGuests(
                  roomParams,
                )}`,
                item_list_name: 'PADI Travel - Liveaboard Listing',
                quantity: 1,
              },
            ],
          }, 'PADI Travel - Liveaboards')
        })

        setGroupedProducts(groupedProducts)
        setNext(trips.next)
        setPrevious(trips.previous)
        setIsLoading(false)
      })
    return () => {
      controller.abort()
    }
  }, [params, departureDateLess, departureDateMonth])
  return {
    groupedProducts,
    next,
    previous,
    isLoading,
    controller,
  }
}

export default useTrips
