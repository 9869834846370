import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { MobileFilterToggle, MobileSelectorList } from '.'

import '../../components/search/styles/mobile-filters.scss'

function MobileFilters({
  updateParams,
  filtersCount,
  handleSortSelect,
  selectedSorting,
  sortOptions,
  hasMap = true,
}) {
  const [isChoosingSorting, setChoosingSorting] = useState(false)
  const showMap = () => updateParams({ view: 'map' })

  const toggleSortingView = () => {
    setChoosingSorting((prev) => !prev)
  }

  return (
    <div className='results-mobile-filters visible-xs'>
      <div className='results-actions'>
        <MobileFilterToggle filtersCount={filtersCount} />
        {hasMap && (
          <a className='results-action' id='js-action-map' onClick={showMap}>
            <i className='font-icons map-icon' />
            <FormattedMessage id='map' defaultMessage='Map' />
          </a>
        )}
        <a
          className='results-action results-select'
          onClick={toggleSortingView}
        >
          <i className='font-icons icon-sorting' />
          <FormattedMessage id='sort_by' defaultMessage='Sort by' />
        </a>
      </div>
      {isChoosingSorting && (
        <MobileSelectorList
          id='ordering'
          options={sortOptions}
          title={<FormattedMessage id='sort_by' defaultMessage='Sort by' />}
          selectedValue={selectedSorting}
          onSubmit={handleSortSelect}
          onClose={toggleSortingView}
        />
      )}
    </div>
  )
}

export default MobileFilters

MobileFilters.propTypes = {
  updateParams: PropTypes.func.isRequired,
  filtersCount: PropTypes.number.isRequired,
  handleSortSelect: PropTypes.func.isRequired,
  selectedSorting: PropTypes.string,
  sortOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
}
