import { Fragment } from 'react'
import RatingStars from '../../../helpers/RatingStars'
import ReviewLabel from '../../../helpers/ReviewLabel'
import { FormattedMessage } from 'react-intl'
import DateFormat from '../../../helpers/DateFormat'
import PriceVariants from '../../../search-dr/PriceVariants'
import { target, generateQueryForWidget } from 'utils'
import { MealPlanIcon } from '../../../resort/pricing/partials'
import useSearchPinInfo from './useSearchPinInfo'
import { Loader } from '../../../helpers'

export default function MapPopup({
  selectedParams,
  shopId,
  isAllParamsSelected,
  totalGuests,
  closePopup,
  isOpen,
}) {
  const { pinInfo: selectedShop, isLoading } = useSearchPinInfo(
    shopId,
    'resorts',
    isOpen
  )

  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      {selectedShop && (
        <Fragment>
          <div className='shop-popup-close' onClick={closePopup}>
            <span className='font-icons close-icon' />
          </div>
          <div
            className='shop-popup-image'
            style={{
              backgroundImage: `url(${selectedShop.background.GridSearchResultDesktop})`,
            }}
          />
          <div className='shop-popup-data'>
            <p className='shop-title'>{selectedShop.title}</p>
            <RatingStars rating={selectedShop.stars} />
            <ReviewLabel
              scubaTribe={selectedShop.scubaTribe}
              shopKind={selectedShop.kind}
              padiRating={selectedShop.numberOfReviews}
              tripAdvisor={selectedShop.tripAdvisor}
              urlParams={{
                location:
                  selectedShop.referenceLocationCountrySlug ||
                  selectedShop.countrySlug,
                slug: selectedShop.slug || selectedShop.shopSlug,
              }}
            />
            <div
              className={`amenities
          ${
        selectedShop.numberOfReviews > 2 || selectedShop.tripAdvisor
          ? 'have-rating'
          : ''
        }
          ${
        selectedShop.minimalStay > selectedParams.nights
          ? 'minimal-stay'
          : ''
        }`}
            >
              <p>
                <i className='font-icons calendar-icon' />
                <DateFormat
                  date={selectedParams.dateStart}
                  format='MMM DD YYYY'
                />{' '}
                -
                <DateFormat date={selectedParams.dateTo} format='MMM DD YYYY' />
              </p>
              <p>
                <MealPlanIcon mealPlan={selectedShop.mealPlan} />
                {selectedShop.mealPlanTitle}
              </p>
              {!!selectedParams.divers && (
                <p>
                  <i className='font-icons dive-center-icon' />
                  <FormattedMessage
                    id='incl_number_of_dives'
                    values={{ numberOfDives: selectedShop.numberOfDives }}
                    defaultMessage='Incl. {numberOfDives, plural, one {# dive} other {# dives}}'
                  />
                </p>
              )}
            </div>
            <PriceVariants
              shop={selectedShop}
              selectedParams={selectedParams}
              totalGuests={totalGuests}
              isAllParamsSelected={isAllParamsSelected}
            />
            <a
              className='btn-red'
              target={target}
              href={generateQueryForWidget(selectedShop.url)}
            >
              <FormattedMessage id='view_resort' defaultMessage='View resort' />
            </a>
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}
