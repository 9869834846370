import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

const MobileSelectorList = ({
  id,
  title,
  selectedValue,
  options,
  onSubmit,
  onClose,
}) => {
  const [isVisible, setVisible] = useState(false)
  const [selected, setSelected] = useState(selectedValue)
  const intl = useIntl()
  useEffect(() => {
    toogleVisibility()
    return () => toogleVisibility()
  }, [])
  const toogleVisibility = () => {
    setVisible((prev) => !prev)
  }
  const handleSubmit = (e) => {
    toogleVisibility()
    onSubmit(e)
  }
  const onChange = (e) => {
    setSelected(e.target.value)
  }
  const handleClosing = (e) => {
    e.preventDefault()
    const animateVisibility = new Promise((resolve) => {
      toogleVisibility()
      setTimeout(() => {
        resolve()
      }, 400)
    })
    animateVisibility.then(() => {
      onClose()
    })
  }
  return (
    <div className={`select-list ${isVisible ? 'visible' : ''}`} id={id}>
      <p className='select-header'>
        <span>{title}</span>
        <button className='btn-close btn-borderless' onClick={handleClosing}>
          <i className='font-icons close-icon' />
        </button>
      </p>
      <div className='select-wrapper'>
        {Object.values(options).map((item) => (
          <div className='radio-btn-wrapper' id={item.value} key={item.value}>
            <label
              className={`select-btn ${
                item.value === selected ? 'active' : ''
              }`}
            >
              <input
                type='radio'
                name={id}
                onChange={onChange}
                value={item.value}
                checked={item.value === selected}
              />
              {item.title ? item.title : intl.formatMessage(item)}
            </label>
          </div>
        ))}
      </div>
      <button className='btn-red' value={selected} onClick={handleSubmit}>
        <FormattedMessage id='view_results' defaultMessage='View results' />
      </button>
    </div>
  )
}
export default MobileSelectorList
