/* eslint-disable camelcase */
import { createRef, useMemo, useState } from 'react'
import GuestPicker from './../forms/GuestPicker'
import DestinationTypeahead from '../helpers/DestinationTypeahead'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import TravelDatepicker from '../helpers/TravelDatepicker'
import { TrustPilotBlock, FilterList } from '../search'
import { addParamToUrl } from '../../utils'

const message = defineMessages({
  placeholder: {
    id: 'homepage.search.placeholder',
    defaultMessage: 'Where do you want to go?',
  },
})

FiltersDr.propTypes = {
  pk: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  model: PropTypes.string.isRequired,
  updateParams: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  triggerApi: PropTypes.func.isRequired,
  clearSelectedFilters: PropTypes.func.isRequired,
  filtersCount: PropTypes.number.isRequired,
  isWidget: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  hideRange: PropTypes.bool,
  trustpilotLocale: PropTypes.string,
}

const ExpandedFilters = {
  price: true,
  stars: true,
  meal_plan: true,
  rating: true,
}

export default function FiltersDr({
  pk,
  model,
  updateParams,
  selectedFilters,
  items,
  triggerApi,
  clearSelectedFilters,
  filtersCount,
  hideRange,
  trustpilotLocale,
  isWidget,
  title,
}) {
  const filtersRef = createRef()
  const intl = useIntl()
  const [showMobileDestination, setShowMobileDestinations] = useState(false)
  const handleDatesChange = ({ dateFrom, dateTo, duration }) => {
    updateParams({
      dateStart: dateFrom,
      dateTo,
      nights: duration,
    })
  }

  const handleGuestsChange = ({
    guests_split,
    divers,
    nonDivers,
    students,
    rooms,
  }) => {
    if(!selectedFilters.dateStart && !selectedFilters.dateTo) {
      document.getElementById('datepicker').click()
    }
    updateParams({
      guests_split: divers || nonDivers || students ? guests_split : null,
      divers,
      nonDivers,
      students,
      rooms,
    })
  }

  const toggleMobileDestinations = (state) => {
    setShowMobileDestinations(state)
  }

  const destinationSelectCallback = (suggestion) => {
    const mapQuery = selectedFilters.view === 'map' ? 'view=map' : ''
    if(suggestion.modelType === 'PopupLink') {
      window.location.href = addParamToUrl(suggestion.url, mapQuery)
      return true
    }
    if(suggestion.diveResortSearchUrl) {
      window.location.href = addParamToUrl(
        suggestion.diveResortSearchUrl,
        mapQuery
      )
    }
  }

  const showListView = () => updateParams({ view: 'resorts' })
  const showMapView = () => updateParams({ view: 'map' })

  const apiUrl = useMemo(
    () => `search/autosuggest/${model.toLowerCase()}/${pk}/dive-resorts/`,
    [model, pk]
  )

  return (
    <div
      ref={filtersRef}
      className={`filters${
        selectedFilters.view === 'map' ? ' map-full-width' : ''
      }`}
    >
      {selectedFilters.view === 'map' ? (
        <button className='btn-red btn-go-to-list' onClick={showListView}>
          <i className='font-icons list-icon' />
          <FormattedMessage
            id='go_to_list_view'
            defaultMessage='Go to list view'
          />
        </button>
      ) : (
        <div className='filter-list go-to-map' onClick={showMapView}>
          <span>
            <i className='font-icons location-icon' />
            <FormattedMessage id='go_to_map' defaultMessage='Go to map' />
          </span>
        </div>
      )}

      <div className='main-filters' id='la-main-filters'>
        <p className='title'>
          <FormattedMessage id='search' defaultMessage='Search' />
        </p>
        <div
          className={`search-block ${
            showMobileDestination ? ' mobile-destination' : ''
          }`}
        >
          <label className='w_icon'>
            <i className='font-icons field-icon location-icon' />
            <DestinationTypeahead
              onFocusClear
              placeholder={intl.formatMessage(message.placeholder)}
              isWidget={isWidget}
              kind={20}
              isActiveMobile
              inputValue={title}
              handlePopupItems
              mobileDestination={toggleMobileDestinations}
              onSelect={destinationSelectCallback}
              popupId='country-list-popup'
            />
          </label>
        </div>
        <div
          className={`form-group w_icon${
            !selectedFilters.departure_date_month ? ' has-error' : ''
          }`}
        >
          <GuestPicker onSelect={handleGuestsChange} showIcon />
        </div>
        <div
          className={`form-group w_icon${
            !selectedFilters.dateStart && selectedFilters.dateTo
              ? ' has-error'
              : ''
          }`}
        >
          <i className='font-icons field-icon location-icon' />
          <TravelDatepicker
            id='datepicker'
            callback={handleDatesChange}
            className='gray-input'
            minDuration={1}
            maxDuration={21}
            range
            datesInOneField
            minDurationForStudents={5}
            validateStudentsPackage
            students={selectedFilters.students}
            dateFrom={selectedFilters.dateStart}
            dateTo={selectedFilters.dateTo}
            updateDuration
            guestsSplit={selectedFilters.guests_split}
          />
        </div>
        <button
          className='btn-red hidden-xs'
          id='search-btn'
          onClick={triggerApi}
        >
          <i className='font-icons find-icon' />
          <FormattedMessage id='search' defaultMessage='Search' />
        </button>
      </div>
      <FilterList
        updateParams={updateParams}
        selectedFilters={selectedFilters}
        items={items}
        diveResort
        expandedFilters={ExpandedFilters}
        clearSelectedFilters={clearSelectedFilters}
        filtersCount={filtersCount}
        organiserPlaceholder='type_a_name'
        url={apiUrl}
        label={
          <FormattedMessage id='resortName' defaultMessage='Resort name' />
        }
        showTripOrganiser
        hideRange={hideRange}
      />
      <TrustPilotBlock trustpilotLocale={trustpilotLocale} />
    </div>
  )
}
