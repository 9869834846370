import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { target } from '../../../../utils'
import CardHeader from './CardHeader'
import { ShopSlider } from '../../../search'
import get from 'lodash/get'
import { defaultShopPicture } from '../../../../config'
import CourseButton from './CourseButton'

import styles from '../../styles/ShopList.module.scss'
import { PriceConverter } from '../../../helpers'
import cardStyles from './Cards.module.scss'
import cx from 'classnames'

function getDuration(duration) {
  if(duration > 24) {
    return (<FormattedMessage defaultMessage='{units, plural, one {# day} other {# days}}'
      values={{ units: Math.ceil(duration / 24) }}
    />)
  }
  return (<FormattedMessage defaultMessage='{duration, plural, one {# hour} other {# hours}}'
    values={{ duration }}
  />)
}

export default function BookableAdventureCard({
  item,
  highlightPinOnMap,
  removeHighlight,
}) {
  const {
    activityPage,
    shopUrl,
    shopTitle,
    adventure,
  } = item

  const image = get(item, 'adventure.picture.800x800') || defaultShopPicture
  return (
    <a
      href={activityPage}
      className={cx(styles.container, 'btn-bookable')}
      target={target}
      onMouseEnter={highlightPinOnMap}
      onMouseLeave={removeHighlight}
      // onClick={trackGtmEvent}
    >
      <CardHeader courseTitle={adventure?.title} shopTitle={shopTitle} shopUrl={shopUrl} />
      <div className={styles.pictureContainer}>
        <ShopSlider
          apiUrl={`/api/adventure/v1/${adventure?.id}/images/`}
          background={image}
          replacePhotos
          shopUrl={shopUrl}
        />
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.details}>
          <p className={styles.detailsTitle}>
            <FormattedMessage defaultMessage='Starts' />
          </p>
          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
          <p className={styles.detailsInfo}>{adventure?.scheduleType?.title || '–'}</p>
        </div>
        <div className={styles.details}>
          <p className={styles.detailsTitle}>
            <FormattedMessage
              defaultMessage='Duration'
            />
          </p>
          <p className={styles.detailsInfo}>
            {getDuration(adventure?.durationHours)}
          </p>
        </div>
        <div className={styles.details}>
          <p className={styles.detailsTitle}>
            <FormattedMessage
              defaultMessage='Included'
            />
          </p>
          <p className={styles.detailsInfo}>
            {adventure?.isElearning
              ? <FormattedMessage defaultMessage='eLearning' />
              : <FormattedMessage defaultMessage='Paper manuals' />
            }
            ,<br />
            <FormattedMessage defaultMessage='instruction + {dives, plural, one {# dive} other {# dives }}'
              values={{ dives: adventure?.numberOfDives }}
            />
          </p>
        </div>
        <div className={styles.details}>
          <p className={styles.detailsTitle}>
            <FormattedMessage id='languages' defaultMessage='Languages' />
          </p>
          <p className={styles.detailsInfo}>
            {adventure?.staffLanguages?.map((lang) => lang.title)?.join(', ')}
          </p>
        </div>
      </div>
      <div className={cx(cardStyles.btnContainer)}>
        <span className={cardStyles.price}>
          <PriceConverter amount={adventure?.minimumPrice?.targetValue}
            currency={adventure?.minimumPrice?.targetCurrency}
            to={adventure?.minimumPrice?.targetCurrency}
          />

          {adventure.instantConfirmation &&
            // eslint-disable-next-line formatjs/no-literal-string-in-jsx
            <img src={`${window.staticPath}travel_dist/images/instant-conf.svg`} alt='Instant Confirmation' />}
        </span>
        <CourseButton text={<FormattedMessage defaultMessage='Book Online' />}
          className={cardStyles.bookableBtn}
          type='bookable'
        />
      </div>

    </a>
  )
}

BookableAdventureCard.propTypes = {
  item: PropTypes.object.isRequired,
  highlightPinOnMap: PropTypes.func.isRequired,
  removeHighlight: PropTypes.func.isRequired,
}
