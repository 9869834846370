import { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get'
import Inclusions from '../../helpers/Inclusions'

function ItineraryWhatsNotIncluded({
  itinerary,
  disableClick,
  itineraryCurrency,
  currency,
}) {
  const [isOpen, setOpen] = useState(disableClick)
  const handleClick = () => {
    if(disableClick) {
      return
    }
    setOpen(!isOpen)
  }
  if(!itinerary.isNewModelUsed && itinerary.whatsNotIncluded) {
    return (
      <div className='itinerary-section itinerary-logistics collapsed-section'>
        <h4 onClick={handleClick} className={isOpen ? 'active' : ''}>
          <FormattedMessage
            id='whats_not_included'
            defaultMessage='What`s not included'
          />
        </h4>
        {isOpen && (
          <Fragment>
            <div
              className='circled-list'
              dangerouslySetInnerHTML={{
                __html: itinerary.whatsNotIncluded,
              }}
            />
            <i className='note-text'>
              <FormattedMessage
                id='note.price'
                defaultMessage='Note: all listed prices are per person'
              />
            </i>
          </Fragment>
        )}
      </div>
    )
  }

  return (
    <div className='itinerary-section collapsed-section'>
      <h4 onClick={handleClick} className={isOpen ? 'active' : ''}>
        <FormattedMessage
          id='whats_not_included'
          defaultMessage='What`s not included'
        />
      </h4>
      {isOpen && (
        <div className='itinerary-section-details'>
          <Inclusions
            showPrices
            startDate={itinerary.startDate}
            type='mandatoryInAdvance'
            currency={itineraryCurrency || currency}
            payedInAdvance
            items={itinerary.mandatoryInAdvance}
          />
          <Inclusions
            showPrices
            startDate={itinerary.startDate}
            type='mandatoryOnBoard'
            currency={itineraryCurrency || currency}
            items={itinerary.mandatoryOnBoard}
          />
          <i className='note-text'>
            <FormattedMessage
              id='note.price'
              defaultMessage='Note: all listed prices are per person'
            />
          </i>
        </div>
      )}
    </div>
  )
}

export default connect((state) => ({
  itineraryCurrency: get(state, 'itineraryPopupData.currency'),
}))(ItineraryWhatsNotIncluded)
