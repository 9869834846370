import { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import store from 'store2'
import moment from 'moment'
import setQuery from 'set-query-string'
import isEmpty from 'lodash/isEmpty'
import pickBy from 'lodash/pickBy'
import get from 'lodash/get'
import filter from 'lodash/filter'
import each from 'lodash/each'
import isArray from 'lodash/isArray'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { FiltersDr } from '../../components'
import { parseQuery } from '../../utils'
import ResortComponent from '../../components/search-dr/ResortComponent'
import { prepareGuestSplitting } from '../../components/resort/ResortPricing'
import {
  SearchTypesSelector,
  SearchTypesMobileSelector,
  SearchMap,
  BackListViewComponent,
  MobileFilters,
  StickyHeader,
} from '../../components/search'
import {
  SORT_BY,
  filtersKeys,
  valueFilter,
  filtersToClean,
} from './searchData'
import TwoDatesFormat from '../../components/helpers/TwoDatesFormat'
import { TranslatedOptions } from '../searchData'

export const guestPickerKeys = [
  'divers',
  'nonDivers',
  'students',
  'guests_split',
  'rooms',
]
class SearchPageComponent extends Component {
  constructor(props) {
    super(props)

    const date = store('date')
    const roomParams = store.get('roomParams') || {}
    const duration = store('duration')
    let selectedParams = parseQuery()
    if(date && !selectedParams.dateStart && !selectedParams.dateTo) {
      setQuery({ dateStart: date[0], dateTo: date[1] })
    }
    if(duration && duration > 0) {
      setQuery({ nights: store('duration') })
    }

    let filtersCount = 0
    let filterObject = {}
    let totalGuests = 0

    if(selectedParams) {
      each(selectedParams, function(val, key) {
        if(filtersKeys.indexOf(key) >= 0) {
          if(valueFilter.indexOf(key) >= 0) {
            filterObject[key] = key === 'nights' && val ? +val : val
          } else if(key === 'organiserTitle' && val) {
            filterObject[key] = decodeURIComponent(val)
          } else if(isArray(val)) {
            filterObject[key] = {}
            each(val, function(v) {
              filterObject[key][v] = true
            })
            filtersCount++
          } else {
            filterObject[key] = {}
            filterObject[key][val] = true
            filtersCount++
          }
        }
      })
    }

    if(roomParams) {
      totalGuests +=
        roomParams.divers + roomParams.nonDivers + roomParams.students
    }

    this.state = {
      selectedParams: {
        ordering: SORT_BY['feature'].value,
        view: 'resorts',
        ...selectedParams,
        nights: selectedParams.nights ? +selectedParams.nights : undefined,
        divers: roomParams.divers,
        students: roomParams.students,
        nonDivers: roomParams.nonDivers,
        rooms: roomParams.rooms,
        guests_split: totalGuests
          ? prepareGuestSplitting(
            get(
              roomParams,
              'guest_split',
              get(roomParams, 'details', [roomParams])
            )
          )
          : null,
      },
      locationCoordinates: this.props.locationCoordinates,
      getNewData: false,
      selectedFilters: filterObject,
      numberOfBoats: this.props.totalCount || 0,
      numberOfTrips: 0,
      numberOfBoatsParent: 0,
      filtersCount,
    }
  }

  updateParams = (params, clearPage) => {
    const { selectedParams } = this.state
    let filtersCount = 0

    if(clearPage) {
      this.setState({
        page: 1,
      })
      setQuery({ page: 1 })
    }

    this.setState(
      {
        selectedFilters: {
          ordering: SORT_BY['feature'].value,
          view: 'resorts',
          ...this.state.selectedFilters,
          ...params,
        },
      },
      () => {
        let obj = {}
        let guests = {
          divers: selectedParams.divers,
          students: selectedParams.students,
          nonDivers: selectedParams.nonDivers,
          rooms: selectedParams.rooms,
          guests_split: selectedParams.guests_split,
        }
        each(this.state.selectedFilters, function(val, key) {
          if(filtersKeys.indexOf(key) >= 0) {
            if(valueFilter.indexOf(key) >= 0) {
              obj[key] = key === 'nights' && val ? +val : val
            } else if(key === 'is_promotion' && val.promotion) {
              obj[key] = val.promotion
            } else if(key === 'shop_id' || key === 'organiserTitle') {
              obj[key] = val
            } else {
              obj[key] = filter(
                map(val, function(v, k) {
                  if(v) {
                    return +k
                  }

                  return null
                }),
                function(v) {
                  return v || typeof v === 'number'
                }
              )

              if(key === 'resort_size') {
                let resortSizeOptions = filter(window.RESORT_SIZE, function(
                  v
                ) {
                  return obj[key].indexOf(v.id) !== -1
                })
                let roomNumbers = resortSizeOptions.reduce(function(acc, val) {
                  acc[0] =
                    acc[0] === undefined ||
                    val.total_number_of_rooms_min < acc[0]
                      ? val.total_number_of_rooms_min
                      : acc[0]
                  acc[1] =
                    acc[1] === undefined ||
                    val.total_number_of_rooms_max > acc[1]
                      ? val.total_number_of_rooms_max
                      : acc[1]
                  return acc
                }, [])

                obj.total_number_of_rooms_min = roomNumbers[0]
                obj.total_number_of_rooms_max = roomNumbers[1]
              }
            }

            if(
              (isArray(obj[key]) && obj[key].length) ||
              (key === 'is_promotion' && obj[key])
            ) {
              filtersCount++
            }
          } else if(guestPickerKeys.indexOf(key) >= 0) {
            guests[key] =
              key === 'guests_split' && val ? prepareGuestSplitting(val) : val
          }
        })
        setQuery({ ...obj, page: 1 })

        obj = pickBy(obj, function(val, key) {
          if(typeof val === 'object') {
            return !isEmpty(val)
          }
          if(typeof val === 'number') {
            return true
          }
          return val
        })

        if(obj.dateStart) {
          store('departure_date_custom', obj.dateStart)
          store('duration', obj.nights)
          store('date', [
            obj.dateStart,
            moment(obj.dateStart)
              .add(obj.nights || 30, 'd')
              .format('YYYY-MM-DD'),
          ])
          store.remove('departure_date')
        } else {
          store.remove('departure_date')
          store.remove('departure_date_custom')
          store.remove('date')
        }
        this.setState({
          selectedParams: {
            ...this.removeEmptyProperties(obj),
            ...this.removeEmptyProperties(guests),
          },
          filtersCount,
        })
      }
    )
  }

  removeEmptyProperties = (obj) => {
    return pickBy(obj, function(value, key) {
      return !isEmpty(value) || !isNil(value)
    })
  }

  clearSelectedFilters = () => {
    let cleanObj = {}
    each(filtersToClean, function(filter) {
      cleanObj[filter] = undefined
    })

    setQuery(cleanObj)

    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        ...cleanObj,
      },
      selectedParams: {
        ...this.state.selectedFilters,
        ...cleanObj,
      },
      filtersCount: 0,
    })
  }

  clearDates = () => {
    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        departure_date_month: null,
      },
      selectedParams: {
        ...this.state.selectedFilters,
        departure_date: undefined,
        departure_date_month: undefined,
      },
    })

    setQuery({ departure_date: null })
    store.remove('departure_date')
    store.remove('departure_date_custom')
    store.remove('date')
  }

  updateOrdering = (event) => {
    let item = event.target.value
    if(this.state.selectedParams.ordering === item) {
      if(item[0] === '-') {
        item = item.substr(1)
      } else {
        item = '-' + item
      }
    }

    this.updateParams({
      ordering: item,
    })
  }

  isActiveFilter = (item) => {
    let className = 'option '
    let selectedOption = this.state.selectedParams.ordering
    let indexOf = item.value.indexOf(
      selectedOption && selectedOption[0] === '-'
        ? selectedOption.substring(1)
        : selectedOption
    )

    if(indexOf !== -1 && indexOf <= 1) {
      className += 'active '
    }
    if(item.value.indexOf('-') === 0) {
      className += 'desc'
    }

    return className
  }

  triggerApi = () => {
    this.setState({
      getNewData: !this.state.getNewData,
    })
  }

  setNumberOfItems = (number, type) => {
    let obj = { [type]: number }

    this.setState(obj)
  }

  setNumberOfBoatsParent = (number) => {
    this.setState({
      numberOfBoatsParent: number,
    })
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#f00'
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  onMapMove = (locationCoordinates) => {
    this.setState({
      locationCoordinates,
    })
  }

  showListView = () => {
    this.updateParams({ view: 'resorts' })
  }

  isAllParamsSelected() {
    const { selectedParams } = this.state
    return (
      selectedParams.dateStart &&
      selectedParams.nights &&
      selectedParams.guests_split &&
      selectedParams.divers + selectedParams.students + selectedParams.nonDivers
    )
  }

  get selectedDatesInfo() {
    const { dateStart, dateTo, nights } = this.state.selectedParams

    return (
      <Fragment>
        {!dateStart || !dateTo ? (
          <FormattedMessage id='any_date' defaultMessage='any date' />
        ) : (
          <Fragment>
            <TwoDatesFormat dateFrom={dateStart} dateTo={dateTo} />
            &nbsp;
            {', '}
            <FormattedMessage
              id='night_info_qty'
              values={{ qty: nights }}
              defaultMessage='{qty, plural, =0 {} one {# night} other {# nights}}'
            />
          </Fragment>
        )}
      </Fragment>
    )
  }

  render() {
    const { intl } = this.props
    const { selectedParams, locationCoordinates } = this.state
    const totalGuests =
      selectedParams.divers +
      selectedParams.nonDivers +
      selectedParams.students
    return (
      <div className='search-page-la search-page-dr'>
        {selectedParams.view === 'map' && (
          <BackListViewComponent onClose={this.showListView} />
        )}
        <StickyHeader
          locationTitle={this.props.title}
          selectedDates={this.selectedDatesInfo}
          typeOfSearch={
            <FormattedMessage id='dive_resorts' defaultMessage='Dive resorts' />
          }
        />
        <ReactTooltip effect='solid' id='global' />
        <div
          className={`search-wrapper ${
            selectedParams.view === 'map' ? 'full-width' : ''
          }`}
        >
          <div className='main-content'>
            <div className='results-head results-sort-wrapper display-flex content-s-b items-center'>
              <h1 className='hidden-xs'>
                <FormattedMessage
                  id='search.title.resorts'
                  values={{
                    qty: this.state.numberOfBoats,
                    location: this.props.inTitle,
                  }}
                  defaultMessage='{ qty, plural, one {# dive resort } other {# dive resorts } } { location }'
                />
              </h1>
              <div className='shop-types-search-page'>
                <SearchTypesSelector
                  activePage={window.typesData && window.typesData.activePage}
                />
                <SearchTypesMobileSelector
                  activePage={window.typesData && window.typesData.activePage}
                />
              </div>
              <div className='results-actions display-flex items-center content-end hidden-xs'>
                <div className='search-ordering'>
                  <label className='bold'>
                    <FormattedMessage id='sort_by' defaultMessage='Sort by' />:
                  </label>
                  <select onChange={this.updateOrdering}>
                    <TranslatedOptions intl={intl} translatedObj={SORT_BY} />
                  </select>
                </div>
              </div>
            </div>
            <MobileFilters
              updateParams={this.updateParams}
              filtersCount={this.state.filtersCount}
              handleSortSelect={this.updateOrdering}
              selectedSorting={this.state.selectedParams.ordering}
              sortOptions={SORT_BY}
            />
            <FiltersDr
              triggerApi={this.triggerApi}
              isWidget={this.props.isWidget}
              selectedParams={this.state.selectedParams}
              selectedFilters={this.state.selectedFilters}
              items={this.props.filters}
              title={this.props.title}
              updateParams={this.updateParams}
              clearDates={this.clearDates}
              clearSelectedFilters={this.clearSelectedFilters}
              trustpilotLocale={this.props.trustpilotLocale}
              filtersCount={this.state.filtersCount}
              model={this.props.model}
              pk={this.props.pk}
              hideRange
            />
            <div className='result-list'>
              {selectedParams.view === 'map' ? (
                <SearchMap
                  showListView={this.showListView}
                  totalGuests={totalGuests}
                  isAllParamsSelected={this.isAllParamsSelected()}
                  selectedParams={selectedParams}
                  locationCoordinates={locationCoordinates}
                  onMapMove={this.onMapMove}
                />
              ) : (
                <Fragment>
                  {selectedParams.students && +selectedParams.nights < 5 ? (
                    <div className='warning-message'>
                      <div className='warning-message__wrapper'>
                        <p className='warning-message__text'>
                          <i className='font-icons icon-warning' />
                          <FormattedMessage
                            id='open_water_course_duration'
                            defaultMessage='The PADI Open Water Course usually takes 4 days. We recommend you stay at least 5 nights. Please contact our staff if you want to book a shorter trip to make sure it is possible.'
                          />
                        </p>
                        <button
                          className='btn-red'
                          onClick={() =>
                            document.getElementById('livechat').click()
                          }
                        >
                          <FormattedMessage
                            id='contact_us'
                            defaultMessage='Contact us'
                          />
                        </button>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <ResortComponent
                    isAllParamsSelected={this.isAllParamsSelected()}
                    setNumberOfItems={this.setNumberOfItems}
                    selectedParams={selectedParams}
                    getNewData={this.state.getNewData}
                    numberOfBoats={this.state.numberOfBoats}
                    {...this.props}
                    totalGuests={totalGuests}
                  />
                  {this.props.totalCount < 10 ? (
                    <div className='top-level-items'>
                      <h2>
                        <FormattedMessage
                          id='search.other_dr'
                          defaultMessage='{qty} other {qty, plural, one {dive resort} other {dive resorts}} {location}'
                          values={{
                            qty: this.state.numberOfBoatsParent,
                            location: this.props.parentTitle,
                          }}
                        />
                      </h2>
                      <div className='update-search-wrapper'>
                        <a href={this.props.parentUrl} className='btn-red'>
                          <i className='font-icons find-icon' />
                          <FormattedMessage
                            id='update_search'
                            defaultMessage='Update search'
                          />
                        </a>
                        <FormattedMessage
                          id='search.dive_resorts_in'
                          defaultMessage='Dive resorts {location}'
                          values={{ location: this.props.parentTitle }}
                        />
                      </div>
                      <ResortComponent
                        isAllParamsSelected={this.isAllParamsSelected()}
                        totalGuests={totalGuests}
                        getNewData={this.state.getNewData}
                        parentElement
                        setNumberOfItems={this.setNumberOfBoatsParent}
                        selectedParams={selectedParams}
                        slug={this.props.parentSlug}
                        model={this.props.parentModel}
                        pk={this.props.parentPk}
                        liveaboardDetails={this.props.parentLiveaboardDetails}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SearchPageComponent.propTypes = {
  intl: PropTypes.object.isRequired,
}

export default injectIntl(
  connect((state) => ({
    numberOfTrips: state.numberOfTrips,
    pageLoader: state.pageLoader,
  }))(SearchPageComponent)
)
