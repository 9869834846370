import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setFilters } from '../../../actions/dive-guide-actions'
import omit from 'lodash/omit'

import { parseQuery } from '../../../utils'

export default function useSetLocatorFiltersQuery() {
  const dispatch = useDispatch()

  useEffect(() => {
    const queryParams = parseQuery()
    if(queryParams) {
      dispatch(setFilters(omit(queryParams, 'page')))
    }
  }, [])
}
