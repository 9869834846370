import { FormattedMessage } from 'react-intl'
import moment from 'moment'

const LANextPrevButtonBlock = ({
  departureDateLess, next, previous, setParams, setDepartureDateLess, params,
}) => {
  const onPageChange = (next) => {
    let page
    let newDepartureDateLess = departureDateLess

    if(departureDateLess) {
      page = next ? params.page - 1 : params.page + 1
    } else {
      page = next ? params.page + 1 : params.page - 1
    }
    if(page < 1) {
      page = 1

      if(!previous && checkPreviousTrips()) {
        newDepartureDateLess = !departureDateLess
      }
    }

    setParams((prevParams) => ({ ...prevParams, page }))
    setDepartureDateLess(newDepartureDateLess)
  }

  const checkPreviousTrips = () => {
    return (
      moment(params.departure_date).startOf('month') > moment().startOf('month')
    )
  }

  return (
    <div className='next-prev-btns-wrapper'>
      <div className='btns-wrap'>
        {((departureDateLess && next) || (!departureDateLess && (previous || checkPreviousTrips()))) && (
          <div
            onClick={() => onPageChange(false)}
            className='paging-btn prev'
          >
            <i className='font-icons left-arrow-icon' />
            <span className='visible-xs'>
              <FormattedMessage id='previous' defaultMessage='Previous' />
            </span>
            <span className='hidden-xs'>
              <FormattedMessage
                id='previous_4'
                defaultMessage='Previous 4 trips'
              />
            </span>
          </div>
        ) }
        {departureDateLess || (!departureDateLess && next) ? (
          <div
            onClick={() => onPageChange(true)}
            className='paging-btn next'
          >
            <span className='visible-xs'>
              <FormattedMessage id='next' defaultMessage='Next' />
            </span>
            <span className='hidden-xs'>
              <FormattedMessage id='next_4' defaultMessage='Next 4 trips' />
            </span>
            <i className='font-icons right-arrow-icon' />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>)
}

export default LANextPrevButtonBlock
