import { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useIntl, FormattedMessage } from 'react-intl'
import { target, generateQueryForWidget } from 'utils'
import { createUrl } from '../../localized-urls'
import PriceConverter, { convertCurrency } from '../helpers/PriceConverter'
import ShopSlider from './ShopSlider'
import PromotionCorner from '../helpers/PromotionCorner'
import ReviewLabel from '../helpers/ReviewLabel'
import BoatAmenities from './BoatAmenities'
import BookingRequest from '../operator/BookingRequest'
import messages from '../../messages'
import { trackGA4Ecommerce } from '../../gtm'
import { roundPrice } from '../../utils'

BoatDetails.propTypes = {
  shop: PropTypes.object.isRequired,
  insurance: PropTypes.object,
  isWidget: PropTypes.bool,
}

export default function BoatDetails({ shop, insurance, isWidget }) {
  const intl = useIntl()
  const shopUrl = useMemo(() => {
    return createUrl(
      'la_page',
      {
        location: shop.referenceLocationCountrySlug || shop.countrySlug,
        slug: shop.slug || shop.shopSlug,
      },
      isWidget ? generateQueryForWidget() : null,
    )
  }, [])
  const handleClick = (e) => {
    e.stopPropagation()
    window.open(shopUrl, target)
    trackGA4Ecommerce(
      'select_item',
      {
        currency: 'USD',
        items: [
          {
            item_id: shop.id.toString(),
            item_name: shop.title,
            item_brand: 'PADI',
            item_category: 'PADI Travel',
            item_category2: 'Liveaboard',
            index: 1,
            item_list_name: 'Travel Vacations - Liveaboard Listing',
            price: Number(
              roundPrice(
                convertCurrency(shop.minimumPrice, shop.currency, 'USD'),
              ),
            ),
            quantity: 1,
          },
        ],
      },
      'PADI Travel - Liveaboards',
    )
  }

  return (
    <div className='boat-info'>
      <div className='photo-slider'>
        <ShopSlider
          slug={shop.slug}
          shopUrl={shopUrl}
          background={shop.backgrounds.GridSearchResultDesktop}
          handleClick={handleClick}
        />
        <span
          className={`heart ${shop.isWishlist ? 'active' : ''}`}
          data-shop-slug={shop.slug}
          data-type='Shop'
          data-in-wishlist={shop.isWishlist}
          data-wishlist-id={shop.isWishlist}
        />
        {shop.promotions && !!shop.promotions.length && (
          <PromotionCorner {...shop.promotions[0]} />
        )}
      </div>
      <div className='shop-info' onClick={handleClick}>
        <div className='shop-head'>
          <a className='shop-title' target={target} href={shopUrl}>
            <span>{shop.title}</span>
          </a>
          <ReviewLabel
            scubaTribe={shop.scubaTribe}
            shopKind={shop.kind}
            padiRating={shop.numberOfReviews}
            tripAdvisor={shop.tripadvisor}
            urlParams={{
              location: shop.referenceLocationCountrySlug || shop.countrySlug,
              slug: shop.slug || shop.shopSlug,
            }}
          />
          <BoatAmenities
            insurance={insurance}
            parameters={shop.parameters}
            includeFreeDiveInsurance={shop.includeFreeDiveInsurance}
          />
          {shop.bookWithConfidence && (
            <div className='book-with-confidence'>
              <p className='blue'>
                <FormattedMessage
                  id='book-with-confidence'
                  defaultMessage='Book with confidence'
                />
              </p>
              <p>{intl.formatMessage(messages.bookWithConfidence)}</p>
            </div>
          )}
        </div>

        <div className='shop-desc'>
          <p className='shop-desc-text'>
            {shop.teaser && shop.teaser.slice(0, 333)}
            {shop.isInproperDates && shop.isAvailable && (
              <span className='dates-warn'>
                <i className='font-icons icon-warning' />
                <FormattedMessage
                  id='dates_not_match_search'
                  defaultMessage='Dates do not match your search criteria'
                />
              </span>
            )}
            {!shop.isAvailable && (
              <span className='dates-warn'>
                <i className='font-icons icon-warning' />
                <FormattedMessage
                  id='no_trips_with_availability'
                  defaultMessage='No trips with availability for your selected month'
                />
              </span>
            )}
          </p>
          <div className='prices'>
            {!shop.bookingOnRequest ? (
              <Fragment>
                {!!shop.minimumPrice && (
                  <div className='price'>
                    <p className='cur-price'>
                      <small>
                        <FormattedMessage id='from' defaultMessage='From' />
                      </small>
                      <strong>
                        <PriceConverter amount={shop.minimumPrice} />
                      </strong>
                    </p>
                    <p className='price-info'>
                      <FormattedMessage id='per_day' defaultMessage='per day' />
                    </p>
                  </div>
                )}
                <button className='btn-red'>
                  <FormattedMessage id='view_boat' defaultMessage='View boat' />
                </button>
              </Fragment>
            ) : (
              <BookingRequest
                showText={false}
                shopId={shop.shopId}
                shopTitle={shop.title}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
