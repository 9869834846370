const bodyItems = [
  {
    id: 1,
    styles: {
      height: '22px',
      width: '16%',
      marginRight: '5px',
    },
  },
  {
    id: 2,
    styles: {
      height: '22px',
      width: '10%',
      marginRight: '5px',
    },
  },
  {
    id: 3,
    styles: {
      height: '45px',
      width: '35%',
      marginRight: '5px',
    },
  },
  {
    id: 4,
    styles: {
      height: '22px',
      width: '10%',
      marginRight: '5px',
    },
  },
  {
    id: 5,
    styles: {
      height: '22px',
      width: '15%',
    },
  },
]

export default function PlaceholderTrips() {
  return (<div className='trip-placeholder placeholder-animation'>
    <div className='trip-placeholder__header'>
      <div className='trip-placeholder__text' />
    </div>
    <div className='trip-placeholder__body'>
      { bodyItems.map((item) => {
        return (<div key={item.id} className='trip-placeholder__text' style={item.styles} />)
      })}
    </div>
    <div className='trip-placeholder__body'>
      { bodyItems.map((item) => {
        return (<div key={item.id} className='trip-placeholder__text' style={item.styles} />)
      })}
    </div>
  </div>)
}
