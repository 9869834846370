import { StyledCheckbox } from '../../forms'
import styles from '../styles/MapLayoutToggle.module.scss'
import cx from 'classnames'
import useLocatorFilters from '../hooks/useLocatorFilters'

export default function MapLayoutToggle({ pageType }) {
  const {
    isSateliteView,
    secondaryPinsVisible,
    setFiltersParams,
  } = useLocatorFilters()
  const onLayoutChange = () => {
    setFiltersParams({ mapView: isSateliteView ? undefined : 'satelite' })
  }
  const onSecondaryPinsViewChange = () => {
    setFiltersParams({
      secondaryPinsVisible: secondaryPinsVisible ? undefined : 'true',
    })
  }
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <i className={cx(styles.icon, 'dsl-icons dsl-icons--layers')} />
        <StyledCheckbox onChange={onLayoutChange} checked={isSateliteView} />
      </div>
      <div className={styles.row}>
        <i
          className={cx(styles.icon, {
            'font-icons icon-dive-sites': pageType === 'dive-shops',
            'dsl-icons dsl-icons--store': pageType !== 'dive-shops',
          })}
        />
        <StyledCheckbox
          onChange={onSecondaryPinsViewChange}
          checked={secondaryPinsVisible}
        />
      </div>
    </div>
  )
}
