import { useState, useEffect } from 'react'
import usePagination from './usePagination'
import API from '../../../api'
import useBounds from './useBounds'
import useLocatorFilters from './useLocatorFilters'
import useIsMapMoved from './useIsMapMoved'
import useUserCoordinates from './useUserCoordinates'
import { pushToDataLayer } from 'gtm'
import useWindowDimensions from './useWindowDimensions'
import { usePreviousEffect } from '../../../hooks'
import { convertCurrency } from '../../helpers'
import { courseType } from '../list/courseCards/CourseCardSelector'

const PAGE_SIZE = 20
let controller
let signal

const getListApiEntryPoint = ({ pageType, type, id }) => {
  switch (pageType) {
    case 'dive-sites':
      return `dive-guide/${type}/${id}/dive-sites/`
    case 'conservation':
      return `/api/adventure/v1/search/${
        type === 'world' ? 'location' : type
      }/${id}/conservation/`
    case 'professional':
      return `/api/adventure/v1/search/${
        type === 'world' ? 'location' : type
      }/${id}/professional/`
    case 'courses':
      return `dsl/courses/${type}/${id}/`
    default:
      return `dsl/dive-shops/${type}/${id}/`
  }
}

export default function useLocatorList({ locationType, locationId, pageType }) {
  const { isMapMoved } = useIsMapMoved()
  const { width } = useWindowDimensions()
  const { userCoordinates } = useUserCoordinates()
  const { page, setCurrentPage } = usePagination()
  const { filters } = useLocatorFilters(false, pageType)
  const [isLoading, setIsLoading] = useState(true)
  const [items, setItems] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [isUpdatedPossible, setIsUpdatePossible] = useState(false)
  const [updateListRequired, setIsUpdateRequired] = useState(false)
  const { bounds, rightBound, leftBound } = useBounds()
  const refreshList = () => {
    setIsUpdatePossible(false)
    setIsUpdateRequired(!updateListRequired)
  }
  const hideUpdateButton = () => setIsUpdatePossible(false)

  usePreviousEffect(
    ([prevBounds]) => {
      if(JSON.stringify(prevBounds) !== JSON.stringify(bounds)) {
        if(width >= 1024) {
          fetchData()
        } else if(isMapMoved) {
          setIsUpdatePossible(true)
        }
      } else {
        fetchData()
      }
    },
    [
      bounds,
      page,
      JSON.stringify(filters),
      updateListRequired,
      userCoordinates,
    ],
    () => controller?.abort(),
  )

  function fetchData() {
    let type = locationType
    let id = locationId
    if('AbortController' in window) {
      controller = new window.AbortController()
      signal = controller.signal
    }
    setIsLoading(true)
    const filtersData = {
      page,
      page_size: PAGE_SIZE,
      ...filters,
    }
    if(isMapMoved || type === 'gplace') {
      filtersData.bottom_left = leftBound
      filtersData.top_right = rightBound
      type = 'world'
      id = 'all'
    }
    if(pageType === 'courses' && filtersData?.shop_id) {
      type = 'world'
      id = 'all'
    }

    if(window.isNearBy && !isMapMoved) {
      filtersData.bottom_left = undefined
      filtersData.top_right = undefined
      filtersData.distance = window.distance

      if(userCoordinates) {
        filtersData.point = `${userCoordinates.lat},${userCoordinates.lng}`
      }
    }
    API(getListApiEntryPoint({ pageType, type, id }), signal)
      .get(filtersData)
      .then((listItems) => {
        setItems(listItems.results)
        setTotalPages(Math.ceil(listItems.count / PAGE_SIZE))
        setIsLoading(false)
      })
      .catch((e) => {
        if(e.status === 404 && page !== 1) {
          setCurrentPage(1)
        }
      })
  }

  useEffect(() => {
    if(items?.length && pageType === 'dive-shops') {
      setTimeout(() => {
        items.forEach((shop) => {
          pushToDataLayer({
            event: 'impression',
            eventCategory: 'Dive Shop',
            eventAction: 'Impression',
            eventLabel: shop.affiliateId,
            eventValue: '0',
            eventNonInteraction: 'true',
          })
        })
      }, 100)
    }

    if(items?.length && pageType === 'courses') {
      const GAitemsValue = items.reduce((acc, course) => {
        if(course?.adventure?.minimumPrice) {
          const { originCurrency, originValue } = course.adventure.minimumPrice
          return acc + convertCurrency(originValue, originCurrency, 'USD')
        } else if(course?.diveCourseInfo?.price) {
          const { originCurrency, originValue } = course.diveCourseInfo.price
          return acc + convertCurrency(originValue, originCurrency, 'USD')
        }
        return acc
      }, 0)
      const GAitems = items.map((course) => {
        const price = course?.adventure
          ? convertCurrency(course?.adventure?.minimumPrice?.originValue, course?.adventure?.minimumPrice?.originCurrency, 'USD')
          : convertCurrency(course?.diveCourseInfo?.price?.originValue, course?.diveCourseInfo?.price?.originCurrency, 'USD')
        const { isElearningCard } = courseType(course)
        const advType = `${course?.adventure ? 'Courses (Adventures)' : isElearningCard ? 'eLearning' : 'Contact Shop'}`
        return {
          item_id: `${course?.adventure ? course?.adventure?.id : course?.diveCourseInfo?.id}`,
          item_name: `${course?.diveCourseInfo?.title}`,
          item_brand: 'PADI',
          item_category: 'PADI Travel',
          item_category2: advType,
          item_category3: `${course?.diveCourseInfo?.title}`,
          item_category4: `${course?.shopId}`,
          item_category5: '',
          item_variant: '',
          bundle_name: '',
          is_bundle: false,
          price: Number(price.toFixed(2)),
          quantity: 1,
          item_list_name: 'PADI Travel Adventures - Product Page',
        }
      })
      setTimeout(() => {
        pushToDataLayer({
          event: 'view_item_list',
          ecommerce: {
            currency: 'USD',
            value: Number(GAitemsValue.toFixed(2)),
            items: GAitems,
          },

        })
      }, 100)
    }
  }, [items, pageType])

  return {
    items,
    totalPages,
    isElearningView: filters.source === 'learning',
    isLoading,
    isUpdatedPossible,
    refreshList,
    hideUpdateButton,
  }
}
