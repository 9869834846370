import { FormattedMessage } from 'react-intl'
import ShopSlider from './ShopSlider'
import EcoLabels from '../resort/EcoLabels'
import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { generateQueryForWidget } from '../../utils'

export default function DiveCenterDetails({ diveCenter }) {
  const handleClick = (e) => {
    e.stopPropagation()
    window.open(
      generateQueryForWidget(diveCenter.url),
      window.isWidget ? '_blank' : '_self',
    )
  }

  const {
    pk,
    title,
    locationTitle,
    starLabel,
    adventuresLanguages,
    numberOfInstructors,
    nitrox,
    ecoLabels,
    adventuresCount,
  } = diveCenter

  return (
    <div className='dive-resort-content'>
      <div className='photo-slider'>
        <ShopSlider apiUrl={`/api/adventure/v1/dive-centers/${pk}/images/`} />
      </div>
      <div onClick={handleClick} className='resort-info resort-info--activity'>
        <div className='result-head'>
          <p className='resort-title dive-center-title'>{title}</p>
        </div>
        <div className='activity-wrapper dive-center-wrapper'>
          <div className='half amenities-container'>
            <div className='amenities'>
              <p>
                <i className='font-icons location-icon' />
                {locationTitle}
              </p>
              {starLabel && (
                <p>
                  <i className='padi-globe' />
                  <span>{starLabel}</span>
                </p>
              )}
            </div>
            <div className='amenities amenities-bottom'>
              {adventuresLanguages.length && (
                <p className='activity-inclusion'>
                  <i className='font-icons chat-icon2' />
                  <span className='text'>
                    {adventuresLanguages.map((language, index) => (
                      <Fragment key={language}>
                        {language}
                        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                        {index + 1 !== adventuresLanguages.length && ', '}
                      </Fragment>
                    ))}
                  </span>
                </p>
              )}
              {Boolean(numberOfInstructors) && (
                <p className='activity-inclusion'>
                  <i className='font-icons check-icon' />
                  {numberOfInstructors}
                  <FormattedMessage
                    id='padi_instructors'
                    defaultMessage='PADI instructors'
                  />
                </p>
              )}
              {nitrox && (
                <p className='activity-inclusion'>
                  <i className='font-icons check-icon' />
                  {nitrox}
                </p>
              )}
            </div>
          </div>
          <div className='activity-right-part'>
            <div>
              {ecoLabels && (
                <div className='eco-labels'>
                  <EcoLabels labelsList={ecoLabels} />
                </div>
              )}
            </div>
            <div className='activity-buttons-container'>
              {adventuresCount > 0 && (
                <FormattedMessage
                  id='bookable_activities'
                  values={{
                    count: adventuresCount,
                  }}
                  defaultMessage='{count, plural, one {# bookable activity} other {# bookable activites}}'
                />
              )}
              <button className='btn-red activity-btn'>
                <FormattedMessage
                  id='view_details'
                  defaultMessage='View Details'
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

DiveCenterDetails.propTypes = {
  diveCenter: PropTypes.object,
}
