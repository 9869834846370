exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".btn--1bYLT{display:flex;align-items:center;justify-content:center;padding:8px 24px;font-size:12px;font-weight:700;letter-spacing:.5px;cursor:pointer}.btn--1bYLT.notAvailable--Ka3gD{background-color:#fff;color:#0070d3;border:1px solid #0070d3;border-radius:4px}.btn--1bYLT.bookable--1ERK_{background-color:#f23d4e}.btn--1bYLT.bookable--1ERK_,.btn--1bYLT.elearning--3N72i{color:#fff;border-radius:4px;border-color:transparent}.btn--1bYLT.elearning--3N72i{background-color:#0070d3}", ""]);

// Exports
exports.locals = {
	"btn": "btn--1bYLT",
	"notAvailable": "notAvailable--Ka3gD",
	"bookable": "bookable--1ERK_",
	"elearning": "elearning--3N72i"
};