import { FormattedMessage } from 'react-intl'
import './styles/no-results.scss'

export default function NoSearchResults() {
  return (
    <div className='no-search-results'>
      <FormattedMessage
        id='no_results_match_criteria'
        defaultMessage='No results match your search criteria'
      />
    </div>
  )
}
