import { useEffect, useState } from 'react'
import API from 'api'

export default function insuranceHook() {
  const [insurance, setInsurance] = useState([])
  useEffect(() => {
    API('booking/insurances/')
      .get()
      .then((insurance) => setInsurance(insurance))
  }, [])

  return insurance
}
