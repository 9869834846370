import { Fragment } from 'react'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import { getTextFromInfo } from 'utils'
import PropTypes from 'prop-types'

const msg = defineMessages({
  nightsAboard: {
    id: 'nightsAboard',
    defaultMessage: 'Number of nights aboard',
  },
  approxNumDives: {
    id: 'approxNumDives',
    defaultMessage: 'Approximate number of dives',
  },
  requirements: {
    id: 'requirements',
    defaultMessage: 'Requirements',
  },
})

export default function ItineraryMainInfo({ itinerary }) {
  const intl = useIntl()
  const minimumRequirements =
    itinerary.minimalNumberOfDives ||
    itinerary.requiredCertification ||
    itinerary.experienceRequired
  return (
    <Fragment>
      <div className='main-title'>
        {!!itinerary.shopTitle && (
          <span className='shop-title'>{itinerary.shopTitle}</span>
        )}
        <h4 className='title'>{itinerary.title}</h4>
      </div>
      <div className='main-information'>
        <div className='main-information__section'>
          {!!itinerary.length && (
            <p className='main-information__item'>
              <i
                className='font-icons icon-moon itinerary-tooltip'
                data-tip={intl.formatMessage(msg.nightsAboard)}
              />
              <FormattedMessage
                id='qty_nights'
                values={{ qty: itinerary.length }}
                defaultMessage='{qty, plural, one {# night} other {# nights}}'
              />
            </p>
          )}
          {!!itinerary.totalNumberOfDives && (
            <p className='main-information__item'>
              <i
                className='font-icons divers-icon itinerary-tooltip'
                data-tip={intl.formatMessage(msg.approxNumDives)}
              />
              {itinerary.totalNumberOfDivesMax ? (
                <FormattedMessage
                  id='min_max_dives'
                  values={{
                    divesMin: itinerary.totalNumberOfDives,
                    divesMax: itinerary.totalNumberOfDivesMax,
                  }}
                  defaultMessage='Approx. { divesMin } - { divesMax } dives'
                />
              ) : (
                <FormattedMessage
                  id='min_dives'
                  values={{ divesMin: itinerary.totalNumberOfDives }}
                  defaultMessage='{ divesMin } dives'
                />
              )}
            </p>
          )}
        </div>
        {!!minimumRequirements && (
          <div className='main-information__section'>
            <div className='main-information__item'>
              <i
                className='font-icons icon-warning itinerary-tooltip'
                data-tip={intl.formatMessage(msg.requirements)}
              />
              {!!itinerary.minimalNumberOfDives && (
                <p>
                  <FormattedMessage
                    id='dives_recommended'
                    values={{
                      numberOfDives: itinerary.minimalNumberOfDives,
                    }}
                    defaultMessage='{numberOfDives}+ dives required'
                  />
                </p>
              )}
              {!!itinerary.requiredCertification && (
                <p>
                  {getTextFromInfo(
                    itinerary.requiredCertification,
                    'shop',
                    'ITINERARY_CERTIFICATION_CHOICES',
                  ) || ''}
                </p>
              )}
              {!!itinerary.experienceRequired && (
                <p>{itinerary.experienceRequired}</p>
              )}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  )
}

ItineraryMainInfo.propTypes = {
  itinerary: PropTypes.shape({
    shopTitle: PropTypes.string,
    title: PropTypes.string,
    length: PropTypes.number,
    totalNumberOfDives: PropTypes.number,
    totalNumberOfDivesMax: PropTypes.number,
    minimalNumberOfDives: PropTypes.number,
    requiredCertification: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    experienceRequired: PropTypes.string,
  }).isRequired,
}
