import { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get'
import Inclusions from '../../helpers/Inclusions'

function ItineraryWhatsIncluded({
  itinerary,
  expadedOnInit,
  disableClick,
  insurance,
}) {
  const [isOpen, setOpen] = useState(disableClick ? true : expadedOnInit)
  const handleClick = () => {
    if(disableClick) {
      return
    }
    setOpen(!isOpen)
  }
  if(!itinerary.isNewModelUsed && itinerary.whatsIncluded) {
    return (
      <div
        className='itinerary-section itinerary-logistics collapsed-section'
        id='included-section'
      >
        <h4 onClick={handleClick} className={isOpen ? 'active' : ''}>
          <FormattedMessage
            id='whats_included'
            defaultMessage='What`s included'
          />
        </h4>
        {isOpen && (
          <Fragment>
            <div
              className='circled-list'
              dangerouslySetInnerHTML={{ __html: itinerary.whatsIncluded }}
            />
            <i className='note-text'>
              <FormattedMessage
                id='note.price'
                defaultMessage='Note: all listed prices are per person'
              />
            </i>
          </Fragment>
        )}
      </div>
    )
  }

  return (
    <div className='itinerary-section collapsed-section' id='included-section'>
      <h4 onClick={handleClick} className={isOpen ? 'active' : ''}>
        <FormattedMessage
          id='whats_included'
          defaultMessage='What`s included'
        />
      </h4>
      {isOpen && (
        <div className='itinerary-section-details'>
          <Inclusions
            included
            startDate={itinerary.startDate}
            items={itinerary.whatsIncludedNew}
            insurance={insurance}
          />
          <i className='note-text'>
            <FormattedMessage
              id='note.price'
              defaultMessage='Note: all listed prices are per person'
            />
          </i>
        </div>
      )}
    </div>
  )
}

export default connect((state) => ({
  insurance: get(state, 'itineraryPopupData.insurance'),
  expadedOnInit:
    get(state, 'itineraryPopupData.elementToScroll') === '#included-section',
}))(ItineraryWhatsIncluded)
