import { Marker } from 'react-leaflet'
import { diveShopBookable, diveShopBookableSelected } from '../../../config'
import isEqual from 'lodash/isEqual'
import { memo } from 'react'
import useMarkerHover from '../../dsl/hooks/useMarkerHover'

const getIcon = (pin, activeMarker, opennedMarker) => {
  if(activeMarker === pin.shopId || opennedMarker === pin.shopId) {
    return diveShopBookableSelected
  }
  return diveShopBookable
}

function Markers({ pins, eventHandlers }) {
  const { activeMarker, opennedMarker } = useMarkerHover()

  return pins.map((pin) => {
    let position
    if(pin.coordinates) {
      position = [pin.coordinates.latitude, pin.coordinates.longitude]
    } else {
      position = [pin.latitude, pin.longitude]
    }
    return (
      <Marker
        key={pin.shopId}
        id={pin.shopId}
        icon={getIcon(pin, activeMarker, opennedMarker)}
        position={position}
        eventHandlers={eventHandlers}
      />
    )
  })
}

const compare = (prevProps, nextProps) => {
  if(!isEqual(prevProps.pins, nextProps.pins)) {
    return false
  }
  return true
}

export default memo(Markers, compare)
