import { Marker } from 'react-leaflet'
import ShopPopup from './ShopPopup'
import {
  dslFiveStarPin,
  dslFiveStarPinSelected,
  dslShopPin,
  dslShopPinSelected,
} from '../../../config'
import useMarkerHover from '../hooks/useMarkerHover'
import { memo } from 'react'
import isEqual from 'lodash/isEqual'

const getIcon = (pin, activeMarker) => {
  const isFiveStar = pin.isFiveStar
  if(activeMarker === pin.id) {
    return isFiveStar ? dslFiveStarPinSelected : dslShopPinSelected
  }
  return isFiveStar ? dslFiveStarPin : dslShopPin
}

function Markers({ pins }) {
  const {
    activeMarker,
    handleMouseOut,
    handleMouseOver,
    handleClick,
    onPopupClose,
    opennedMarker,
  } = useMarkerHover()
  if(!Array.isArray(pins)) {
    console.error('Pins is not an array', pins)
    return null
  }
  return pins?.map((pin) => {
    return (
      <Marker
        key={pin.id}
        id={pin.id}
        icon={getIcon(pin, activeMarker)}
        position={[pin.latitude, pin.longitude]}
        eventHandlers={{
          mouseover: handleMouseOver,
          mouseout: handleMouseOut,
          click: handleClick,
        }}
        zIndexOffset={activeMarker === pin.id ? 1000 : 0}
        riseOnHover
      >
        <ShopPopup
          shopId={pin.id}
          onPopupClose={onPopupClose}
          isOpen={opennedMarker === pin.id}
        />
      </Marker>
    )
  })
}

const compare = (prevProps, nextProps) => {
  if(!isEqual(prevProps.pins, nextProps.pins)) {
    return false
  }
  return true
}

export default memo(Markers, compare)
