import { useState } from 'react'
import API from 'api'
import groupBy from 'lodash/groupBy'
import { getAffiliateId } from '../utils'
import { useDispatch } from 'react-redux'
import { showLoader } from '../actions/main-actions'
import { formatDate } from '../components/helpers/DateFormat'

export default function useDueNow() {
  const [dueNowData, setDue] = useState()
  const dispatch = useDispatch()

  function getDueNowData(shopId, selectedInclusions, shopData, discountCoupon) {
    dispatch(showLoader(true))
    const affilaite = getAffiliateId()

    let additionalData = {}
    if(affilaite) {
      additionalData.affiliateId = affilaite.aid
      additionalData.affiliateCampaign = affilaite.cid
    }
    const payload = {
      items: selectedInclusions.map(
        ({ pricing, quantity, guestType, diverNumber, source }) => ({
          pricing: {
            id: pricing,
            source,
          },
          quantity,
          guestType,
          diverNumber,
        }),
      ),
      paymentProvider: 10,
      dateFrom: formatDate(shopData.dateFrom, 'YYYY-MM-DDThh:mm'),
      dateTo: formatDate(shopData.dateTo, 'YYYY-MM-DDThh:mm'),
      shop: shopId,
      roomParams: shopData.roomParams,
      discountCoupon,
      ...additionalData,
    }

    return API('checkout/due-now/')
      .post(payload)
      .then((data) => {
        let itemsMandatory = []
        let itemsExtra = []
        let itemsIncluded = []
        let itemsMandatoryTotal = 0
        let itemsExtraTotal = 0
        let roomParams = []

        try {
          shopData.roomParams.details.forEach((val, i) => {
            val.occupancy = val.divers + val.nonDivers + val.students
            val.index = i + 1
            roomParams.push(val)
          })
        } catch (e) {
          throw new Error('No room params details', e)
        }
        Object.values(groupBy(selectedInclusions, 'pricing')).forEach(
          (items) => {
            const item = {
              ...items[0],
              quantity: 0,
              amount: 0,
            }
            if(!item.divingItem) {
              items.forEach((inclusion) => {
                item.quantity += inclusion.quantity
                item.amount += inclusion.amount
              })
              if(item.isMandatory) {
                itemsMandatory.push(item)
                itemsMandatoryTotal += item.price * item.quantity
              } else if(item.isIncluded) {
                // eslint-disable-next-line standard/computed-property-even-spacing
                item[
                  item.diverNumber ? 'isDiverItem' : 'isNonDiverItem'
                ] = true
                itemsIncluded.push(item)
              } else if(!item.isRoom) {
                itemsExtra.push(item)
                itemsExtraTotal += item.price * item.quantity
              }
            }
          },
        )
        setDue({
          ...data,
          itemsMandatory,
          itemsExtra,
          itemsIncluded,
          itemsExtraLegend: true,
          itemsMandatoryTotal,
          itemsExtraTotal,
          rooms: groupBy(roomParams, 'occupancy'),
          selectedInclusions,
          payload,
        })

        return data
      })
      .finally(() => dispatch(showLoader(false)))
  }

  return {
    dueNowData,
    getDueNowData,
    setDue,
  }
}
