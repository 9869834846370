import styles from '../styles/ShopList.module.scss'
import { target } from '../../../utils'
import { FiveStar } from '../shared'
import { ShopSlider } from '../../search'
import { defaultShopPicture } from '../../../config'
import get from 'lodash/get'
import ShopTags from './ShopTags'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import CardButtons from './CardButtons'

import PropTypes from 'prop-types'

export default function LaCard({
  shop,
  highlightPinOnMap,
  removeHighlight,
  isElearningView,
  trackListingClick,
  trackGtmEvent,
}) {
  const image = get(shop, 'background.800x800') || defaultShopPicture
  const { languages, activities } = shop
  return (
    <a
      href={isElearningView ? shop.padiUrl : shop.url}
      className={styles.container}
      target={target}
      onMouseEnter={highlightPinOnMap}
      onMouseLeave={removeHighlight}
      onClick={trackGtmEvent}
    >
      <FiveStar
        membershipLevel={
          shop.membershipLevel || (
            <FormattedMessage id='padi_boat' defaultMessage='PADI Dive Boat' />
          )
        }
      />
      <p className={styles.title}>{shop.title}</p>
      <p className={styles.address}>{shop.shortAddress}</p>
      <div className={styles.pictureContainer}>
        <ShopSlider slug={shop.slug} background={image} shopUrl={shop.url} />
      </div>
      <ShopTags tags={shop.tags} />
      <div className={styles.detailsRow}>
        <div className={cx(styles.details, styles.fullWidth)}>
          <p className={styles.detailsTitle}>
            <FormattedMessage id='languages' defaultMessage='Languages' />
          </p>
          <p className={styles.detailsInfo}>
            {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
            {languages.length ? languages.join(', ') : '–'}
          </p>
        </div>
        <div className={cx(styles.details, styles.fullWidth)}>
          <p className={styles.detailsTitle}>
            <FormattedMessage
              id='courses_activites'
              defaultMessage='Courses/Activities'
            />
          </p>
          <p className={styles.detailsInfo}>
            {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
            {activities.length ? activities.join(', ') : '–'}
          </p>
        </div>
      </div>
      <CardButtons
        shop={shop}
        isElearningView={isElearningView}
        trackListingClick={trackListingClick}
      />
    </a>
  )
}

LaCard.propTypes = {
  shop: PropTypes.object,
  highlightPinOnMap: PropTypes.func,
  removeHighlight: PropTypes.func,
  isElearningView: PropTypes.bool,
  trackListingClick: PropTypes.func,
  trackGtmEvent: PropTypes.func,
}
