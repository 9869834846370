import { FormattedMessage } from 'react-intl'
import cx from 'classnames'
import styles from '../styles/ShopList.module.scss'

export default function CardButtons({
  shop,
  isElearningView,
  trackListingClick,
}) {
  if(isElearningView) {
    return (
      <div className={styles.btnContainer}>
        <button className={cx(styles.btn, 'btn-guides-blue')}>
          <FormattedMessage
            id='select_this_shop'
            defaultMessage='Select this Shop'
          />
        </button>
        <a
          href={shop.url}
          onClick={trackListingClick}
          className={styles.viewDetailsUrl}
        >
          <FormattedMessage id='view_details' defaultMessage='View Details' />
        </a>
      </div>
    )
  }
  return (
    <div className={styles.btnContainer}>
      <button className={cx(styles.btn, 'btn-guides-blue')}>
        <FormattedMessage id='view_details' defaultMessage='View Details' />
      </button>
    </div>
  )
}
