import styles from '../../styles/DropDown.module.scss'
import CheckboxSelect from './CheckboxSelect'
import { forwardRef, useEffect, useState } from 'react'
import SearchInput from './SearchInput'
import cx from 'classnames'
import RadioButtonSelect from './RadioButtonSelect'

export const filterItems = (items, formValues, searchValue) => {
  if(searchValue) {
    return items.filter((item) =>
      item.title.toLowerCase().includes(searchValue),
    )
  }
  if(formValues) {
    return items.filter(
      (item) => formValues?.includes(`${item.id}`) || item.is_search === 'true',
    )
  }

  return items.filter((item) => item.is_search === 'true')
}

const MultiSelect = forwardRef(
  (
    {
      items = [],
      withSearch,
      searchPlaceholder,
      icon,
      inlineMode,
      title,
      queryName,
      formValues = {},
      isMultiple,
      titleEn,
    },
    ref,
  ) => {
    const [filteredItems, setItems] = useState(
      withSearch ? filterItems(items, formValues[queryName]) : items,
    )
    const SelectComponent = isMultiple ? CheckboxSelect : RadioButtonSelect
    const onSearch = (e) => {
      setItems(() =>
        filterItems(items, formValues[queryName], e.target.value.toLowerCase()),
      )
    }

    // need to check each time, because we have All filters section and need to update if something change here or there
    useEffect(() => {
      if(withSearch) {
        setItems(() => filterItems(items, formValues[queryName]))
      }
    }, [formValues[queryName]])
    return (
      <div
        className={cx(styles.dropDown, { [styles.inlineMode]: inlineMode })}
        ref={ref}
      >
        {title && <p className={styles.title}>{title}</p>}
        {withSearch && (
          <SearchInput
            placeholder={searchPlaceholder}
            icon={icon}
            onChange={onSearch}
          />
        )}
        {filteredItems &&
          filteredItems.map((item) => {
            return (
              <SelectComponent
                title={item.title}
                titleEn={item.title_en}
                value={item.id || item.value}
                key={item.id || item.value}
                queryName={queryName}
                sectionTitle={titleEn}
              />
            )
          })}
      </div>
    )
  },
)

export default MultiSelect
