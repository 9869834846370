import Tag from './Tag'
import get from 'lodash/get'
import styles from '../styles/ShopList.module.scss'

export default function ShopTags({ tags, isArrayList }) {
  if(!get(tags, 'length')) {
    return null
  }
  return (
    <div className={styles.tagList}>
      {tags.map((tag) =>
        isArrayList ? (
          <Tag tag={{ title: tag, id: tag }} key={tag} />
        ) : (
          <Tag tag={tag} key={tag.id} />
        )
      )}
    </div>
  )
}
