import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import pickBy from 'lodash/pickBy'

const {
  activePage,
  searchLa,
  searchDr,
  searchDc,
  searchDives,
  searchCourses,
  searchSnorkeling,
} = window.typesData || {}

const list = [
  {
    type: 'liveaboards',
    title: <FormattedMessage id='liveboards' defaultMessage='Liveaboards' />,
    icon: 'liveaboard-icon',
    link: searchLa,
  },
  {
    type: 'dive_resorts',
    title: <FormattedMessage id='dive_resorts' defaultMessage='Dive resorts' />,
    icon: 'dive-resort-icon',
    link: searchDr,
  },
  {
    type: 'dive_centers',
    title: <FormattedMessage id='dive_centers' defaultMessage='Dive centers' />,
    icon: 'icon-dive-center',
    link: searchDc,
  },
  {
    type: 'dives',
    title: <FormattedMessage id='diving' defaultMessage='Diving' />,
    icon: 'icon-diver',
    link: searchDives,
  },
  {
    type: 'courses',
    title: <FormattedMessage id='courses' defaultMessage='Courses' />,
    icon: 'icon-course',
    link: searchCourses,
  },
  {
    type: 'snorkelling',
    title: <FormattedMessage id='snorkeling' defaultMessage='Snorkeling' />,
    icon: 'icon-snorkeling',
    link: searchSnorkeling,
  },
]

const ShopTypeSelector = ({
  data,
  onClick,
  currentTab,
  nearByPoint,
  isMapView,
  isMobile,
}) => {
  const createLink = (item) => {
    const params = {
      view: isMapView && !isMobile ? 'map' : '',
      point: nearByPoint && nearByPoint,
    }

    const filtered = pickBy(params, (val) => val)
    const queryLine = Object.entries(filtered)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    const link = item.link.includes('?')
      ? `${item.link}&${queryLine}`
      : `${item.link}?${queryLine}`

    return ['snorkelling', 'courses', 'dives'].includes(item.type)
      ? link
      : item.link
  }
  return (
    <a
      className={`shop-type ${currentTab === data.type ? 'active' : ''}`}
      href={createLink(data)}
      onClick={onClick}
    >
      <i className={`font-icons ${data.icon}`} />
      {data.title}
    </a>
  )
}

const SearchTypesList = ({ isMobile, isMapView, nearByPoint }) => {
  return (
    <Fragment>
      {list.map((item) => (
        <ShopTypeSelector
          key={isMobile ? `mobile_${item.type}` : `regular_${item.type}`}
          data={item}
          currentTab={activePage}
          nearByPoint={nearByPoint}
          isMapView={isMapView}
        />
      ))}
    </Fragment>
  )
}

export { SearchTypesList, list, ShopTypeSelector }
