import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get'

export default function ItineraryGoodToKnow({
  itinerary,
  sectionKey = 'goodToKnow',
  disableClick,
  title,
}) {
  const data = get(itinerary, sectionKey)
  if(!data) {
    return null
  }
  const [isOpen, setOpen] = useState(disableClick)
  const handleClick = () => {
    if(disableClick) {
      return
    }
    setOpen(!isOpen)
  }
  return (
    <div className='itinerary-section itinerary-logistics collapsed-section'>
      <h4 onClick={handleClick} className={isOpen ? 'active' : ''}>
        {title || (
          <FormattedMessage id='good_to_know' defaultMessage='Good to know' />
        )}
      </h4>
      {isOpen && (
        <div
          className='description wysiwyg'
          dangerouslySetInnerHTML={{ __html: data }}
        />
      )}
    </div>
  )
}
