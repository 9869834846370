import { useEffect } from 'react'
import {
  LayersControl,
  TileLayer,
  ZoomControl,
  useMap,
  useMapEvents,
} from 'react-leaflet'
import {
  isMainLand,
  sateliteTilesUrl,
  tiandituTilesUrl,
} from '../../../config'
import 'leaflet/dist/leaflet.css'
import Markers from './Markers'
import usePins from './hooks/usePins'
import Loader from '../../helpers/Loader'
import MapPopup from './MapPopup'
import useMarkerHover from '../../dsl/hooks/useMarkerHover'

export default function Map({
  countryCode,
  tileUrl,
  isActivityEndpoint,
  searchType,
  selectedParams,
  initLocation,
  isAllParamsSelected,
  totalGuests,
}) {
  const map = useMap()
  const { pins, isLoading, getPins } = usePins(
    map,
    isActivityEndpoint,
    searchType,
    selectedParams
  )
  const initialBounds = initLocation.map((el) => [el.lat, el.lng])

  useMapEvents({
    dragend: getPins,
    zoomend: getPins,
  })

  useEffect(() => {
    map.fitBounds(initialBounds)
  }, [])

  const {
    handleMouseOut,
    handleMouseOver,
    handleClick,
    onPopupClose,
    opennedMarker,
    isPopupOpen,
  } = useMarkerHover()

  const eventHandlers = {
    mouseover: handleMouseOver,
    mouseout: handleMouseOut,
    click: handleClick,
  }

  return (
    <div className={`search-page-map-wrapper ${isLoading ? 'loading' : ''}`}>
      <Loader isLoading={isLoading} />
      <MapPopup
        activityType={searchType}
        isActivityEndpoint={isActivityEndpoint}
        selectedParams={selectedParams}
        isAllParamsSelected={isAllParamsSelected}
        closePopup={onPopupClose}
        shopId={opennedMarker}
        isOpen={isPopupOpen}
        totalGuests={totalGuests}
      />
      <div className='search-page-map'>
        <LayersControl position='topright'>
          <LayersControl.BaseLayer name='PADI Travel map' checked>
            {isMainLand(countryCode) && <TileLayer url={tiandituTilesUrl} />}
            <TileLayer url={tileUrl} />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name='Satellite'>
            <TileLayer url={sateliteTilesUrl} />
          </LayersControl.BaseLayer>
        </LayersControl>
        <ZoomControl position='topright' />
        <Markers pins={pins} eventHandlers={eventHandlers} />
      </div>
    </div>
  )
}
