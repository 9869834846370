import styles from '../styles/DropDown.module.scss'
import cx from 'classnames'
import MultiSelect from './dropdown/MultiSelect'
import { useMemo, useRef } from 'react'
import { isElementInViewport } from '../../../utils'
import { useForm } from 'react-final-form'
import MultiSelectWithToggle from './dropdown/MultiSelectWithToggle'

import PropTypes from 'prop-types'

export default function DropDown({
  items,
  titleEn,
  placeholder,
  withSearch,
  searchPlaceholder,
  icon,
  queryName,
  last,
  isMultiple,
}) {
  const ref = useRef()
  const form = useForm()
  const formValues = form.getState().values
  const fieldValue = formValues[queryName]
  const { dropdownTitle, isActive } = useMemo(() => {
    if(!fieldValue) {
      return { dropdownTitle: placeholder, isActive: false }
    }

    if(
      ['courses', 'marine_life_sightings'].includes(queryName) &&
      isMultiple &&
      fieldValue.length
    ) {
      return {
        dropdownTitle: `${placeholder} (${fieldValue.length})`,
        isActive: true,
      }
    }

    if(!isMultiple) {
      return {
        dropdownTitle: placeholder,
        isActive: true,
      }
    }

    const filteredItems = items.filter((item) =>
      fieldValue.includes(`${item.id}`),
    )

    if(filteredItems.length) {
      return {
        dropdownTitle: `${filteredItems[0].title}${
          filteredItems.length > 1 ? ` + ${filteredItems.length - 1}` : ''
        }`,
        isActive: true,
      }
    }
    return { dropdownTitle: placeholder, isActive: false }
  }, [fieldValue])
  const handleMenuOpen = () => {
    const dropdown = ref.current
    if(dropdown && !isElementInViewport(dropdown)) {
      dropdown.classList.add(styles.rightAlignDropDown)
    }
  }
  const MultiSelectComponent = ['courses', 'marine_life_sightings'].includes(
    queryName,
  )
    ? MultiSelectWithToggle
    : MultiSelect
  return (
    <div
      className={cx(styles.filterContainer, {
        [styles.lastContainer]: last,
        [styles.active]: isActive,
      })}
      tabIndex={0}
      onFocus={handleMenuOpen}
    >
      {dropdownTitle}
      <i
        className={cx(
          styles.dropDownIcon,
          'dsl-icons dsl-icons--arrow-dropdown',
        )}
      />
      <MultiSelectComponent
        isMultiple={isMultiple}
        items={items}
        ref={ref}
        withSearch={withSearch}
        searchPlaceholder={searchPlaceholder}
        icon={icon}
        titleEn={titleEn}
        queryName={queryName}
        formValues={formValues}
      />
    </div>
  )
}

DropDown.propTypes = {
  items: PropTypes.array,
  titleEn: PropTypes.string,
  placeholder: PropTypes.string,
  withSearch: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  icon: PropTypes.string,
  queryName: PropTypes.string,
  last: PropTypes.bool,
  isMultiple: PropTypes.bool,
}
