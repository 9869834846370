import dropdownStyles from '../../styles/DropDown.module.scss'
import styles from './AllFiltersDropdown.module.scss'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import MultiSelect from './MultiSelect'
import { getConfig } from '../../DiveShopLocationFilters'
import { useForm } from 'react-final-form'
import MultiSelectWithToggle from './MultiSelectWithToggle'
import { useLayoutEffect, useRef } from 'react'
import { getOffset } from '../../../../utils'
import DiveCenterAdditionalFilters from '../DiveCenterAdditionalFilters'

const AllFitlersDropdown = ({
  filters,
  isActive,
  removeFocus,
  clearFilters,
  handleToggleClick,
  pageType,
}) => {
  const ref = useRef()
  const form = useForm()
  const formValues = form.getState().values
  const reset = () => {
    form.reset({})
    clearFilters()
  }
  useLayoutEffect(() => {
    if(isActive) {
      const { top } = getOffset(ref.current)
      ref.current.style.maxHeight = `${window.innerHeight - top}px`
    }
  }, [isActive])

  return (
    <div
      ref={ref}
      className={cx(dropdownStyles.fullsizeDropDown, {
        [dropdownStyles.activeDropDown]: isActive,
      })}
    >
      <div className={styles.header}>
        <FormattedMessage id='all_filters' defaultMessage='All Filters' />
        <i
          className={cx(styles.closeBtn, 'dsl-icons dsl-icons--close')}
          onClick={removeFocus}
        />
      </div>
      <div className={styles.content}>
        {filters.map((filter) => {
          const MultiSelectComponent = [
            'courses',
            'marine_life_sightings',
          ].includes(filter.query_name)
            ? MultiSelectWithToggle
            : MultiSelect
          return (
            <MultiSelectComponent
              title={filter.title}
              key={filter.title}
              titleEn={filter.title_en}
              isMultiple={filter.is_multiple === 'true'}
              queryName={filter.query_name}
              items={filter.items}
              inlineMode
              formValues={formValues}
              {...getConfig(filter)}
            />
          )
        })}

        {pageType !== 'courses' &&
        <DiveCenterAdditionalFilters
          pageType={pageType}
          handleToggleClick={handleToggleClick}
          allFiltersView
        />}
      </div>
      {clearFilters && (
        <div className={styles.footer}>
          <button
            className={cx('blue-btn', styles.applyBtn)}
            onClick={removeFocus}
          >
            <FormattedMessage
              id='apply_filters'
              defaultMessage='Apply filters'
            />
          </button>
          <span className={styles.clearAll} onClick={reset}>
            <FormattedMessage id='clear_all' defaultMessage='Clear all' />
          </span>
        </div>
      )}
    </div>
  )
}

export default AllFitlersDropdown
