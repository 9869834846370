import { Fragment } from 'react'

export default function ShopPicture({ image, className }) {
  return (
    <picture className='relative'>
      {image?.webpUrl && (
        <Fragment>
          <source srcSet={image?.webpUrl} type='image/webp' />
          <source srcSet={image?.url} type='image/jpeg' />
        </Fragment>
      )}
      <img
        alt='Dive operator picture'
        className={className}
        src={image?.url || image}
      />
    </picture>
  )
}
