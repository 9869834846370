import { useState, useCallback } from 'react'

import './styles/mobile-view-toggle.scss'

const MobileToggleButton = ({ isActive, value, iconName, handleClick }) => (
  <button
    className={`toggle-btn ${isActive ? 'active' : ''}`}
    value={value}
    onClick={handleClick}
  >
    <i className={`font-icons ${iconName}`} />
  </button>
)

const MobileViewToggle = ({ groupView, onToggle, groupBy }) => {
  const [view, setView] = useState(groupView)

  const onBtnClick = useCallback((e) => {
    setView(e.target.value)
    onToggle(e)
  }, [])

  return (
    <div className='visible-xs'>
      <div className='mobile-view-toggle toggle'>
        {Object.values(groupBy).map((option) => (
          <MobileToggleButton
            value={option.value}
            key={option.value}
            isActive={view === option.value ? 'active' : ''}
            handleClick={onBtnClick}
            iconName={option.icon}
          />
        ))}
      </div>
    </div>
  )
}

export default MobileViewToggle
