import { FormattedMessage } from 'react-intl'

const BackListViewComponent = ({ onClose }) => {
  return (
    <div className='back-to-list-view-container' id='back-to-list-view'>
      <a className='back-to-list' onClick={onClose}>
        <FormattedMessage
          id='back_to_list_view'
          defaultMessage='Back to list view'
        />
        <button className='close-btn' onClick={onClose}>
          <i className='font-icons close-icon' />
        </button>
      </a>
    </div>
  )
}

export default BackListViewComponent
