import Slider from 'react-slick'
import { useMemo, useState, useRef } from 'react'
import { ShopTypeSelector, list } from './SearchTypesList'

export default function SearchTypesMobileSelector({ activePage, nearByPoint }) {
  const initialSlide = useMemo(() => {
    let activeSlide = list.findIndex((item) => item.type === activePage)
    if(activeSlide > 0 && activeSlide < 3) {
      activeSlide -= 0.5
    } else if(activeSlide >= 3 && activeSlide < 5) {
      activeSlide -= 1.5
    } else if(activeSlide >= 5) {
      activeSlide -= 2.5
    }
    return activeSlide
  }, [])
  const sliderRef = useRef()
  const [currentSlide, setCurrentSlide] = useState(initialSlide)
  // Offset added because we need to show user that there is more items.

  const settings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    infinite: false,
    arrows: false,
    dots: false,
    responsive: [
      { breakpoint: 480, settings: { slidesToShow: 3.5 } },
      { breakpoint: 550, settings: { slidesToShow: 4.5 } },
    ],
    afterChange: (next) => {
      setCurrentSlide(next)
      if(next >= 3) {
        setTimeout(() => {
          sliderRef.current.slickGoTo(2.5)
        }, 100)
      }
    },
    onSwipe: () => {
      if(sliderRef) {
        sliderRef.current.innerSlider.clickable = true
      }
    },
  }

  const overlayClass = useMemo(() => {
    if(Number.isInteger(currentSlide)) {
      return 'gradient-right'
    } else if(!Number.isInteger(currentSlide)) {
      return 'gradient-left'
    } else {
      return ''
    }
  }, [currentSlide])

  return (
    <div className={`shop-types mobile-shop-types-slider ${overlayClass}`}>
      <Slider
        className='slick-pt-custom'
        {...settings}
        ref={sliderRef}
        initialSlide={initialSlide}
      >
        {list.map((item, index) => (
          <ShopTypeSelector
            key={`mobile_${item.type}`}
            data={item}
            currentTab={activePage}
            isBoudary
            isMobile
            nearByPoint={nearByPoint}
          />
        ))}
      </Slider>
    </div>
  )
}
