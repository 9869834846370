import { FormattedMessage } from 'react-intl'
import { isMobile, target } from '../../../utils'
import { ActivityDuration, ShopSlider } from '../../search'
import ShopTags from './ShopTags'
import useMarkerHover from '../hooks/useMarkerHover'
import styles from '../styles/ShopList.module.scss'
import cx from 'classnames'
import ShopLink from './ShopLink'
import moment from 'moment'
import { AdventureOriginalTitle } from '../../adventures'
import { PriceConverter } from '../../helpers'
import {
  handleEventDefault,
  preventNavigationHandler,
} from '../../../dom-handlers'

const MAX_INCLUSIONS = 7

export default function ConservationCard({ item, isPro }) {
  const { setActiveMarker } = useMarkerHover()
  const highlightPinOnMap = () => setActiveMarker(item.activityId)
  const removeHighlight = () => setActiveMarker(false)

  const handleMobileClick = (e) => {
    if(isMobile()) {
      preventNavigationHandler(e)
    }
    handleEventDefault(e)
  }

  const inclusions = item?.includedExtras?.map((extra) => extra.title)
  const slicedInclusions = inclusions?.slice(0, MAX_INCLUSIONS)
  const isShowAll = item?.includedExtras?.length > MAX_INCLUSIONS
  const showInclusions = isPro && Boolean(inclusions?.length)

  const isFree = parseFloat(item?.price?.price) === 0
  return (
    <a
      href={item.activityPage}
      className={cx(styles.container, { [styles.professionalCard]: isPro })}
      target={target}
      onMouseEnter={highlightPinOnMap}
      onMouseLeave={removeHighlight}
    >
      <p className={styles.title}>
        {item.title}

        <AdventureOriginalTitle
          title={item.title}
          originalTitle={item.originalTitle}
        />
      </p>
      <ShopLink shop={item?.diveCenter} />
      <div className={styles.pictureContainer}>
        <ShopSlider
          apiUrl={`/api/adventure/v1/${item.activityId}/images/`}
          shopUrl={item.activityPage}
        />
      </div>
      <ShopTags tags={item.tags} isArrayList />
      <div className={styles.detailsRow}>
        <div className={styles.details}>
          <p className={styles.detailsTitle}>
            <FormattedMessage id='date_and_time' defaultMessage='Date & Time' />
          </p>
          <p className={styles.detailsInfo}>
            {moment(`${item.date}T${item.startTime}`).format('lll')}
          </p>
        </div>
        <div className={styles.details}>
          <p className={styles.detailsTitle}>
            <FormattedMessage id='duration' defaultMessage='Duration' />
          </p>
          <p className={styles.detailsInfo}>
            <ActivityDuration
              duration={item.duration.duration}
              durationType={item.duration.durationType}
            />
          </p>
        </div>
        <div className={styles.details}>
          <p className={styles.detailsTitle}>
            <FormattedMessage id='dive_exp' defaultMessage='Dive Experience' />
          </p>
          <p className={styles.detailsInfo}>
            {item.requirements.minCertification?.value === 10
              ? '–'
              : item.requirements.minCertification.title}
          </p>
        </div>
        <div className={styles.details}>
          <p className={styles.detailsTitle}>
            <FormattedMessage id='languages' defaultMessage='Languages' />
          </p>
          <p className={styles.detailsInfo}>{item.languages.join(', ')}</p>
        </div>
      </div>
      {showInclusions && (
        <div className={styles.inclusionsRow}>
          <p className={styles.inclusionsTitle}>
            <FormattedMessage defaultMessage='Inclusions' />
          </p>
          {slicedInclusions.join(', ')}
          &nbsp;
          {isShowAll && (
            <span
              className={styles.showAll}
              data-tip={inclusions.join(', ')}
              onClick={handleMobileClick}
            >
              <FormattedMessage defaultMessage='Show all' />
            </span>
          )}
        </div>
      )}
      <div className={styles.btnContainer}>
        {isFree ? (
          <span className={styles.price}>
            <FormattedMessage id='free' defaultMessage='Free' />
          </span>
        ) : (
          <div>
            <span className={styles.pricingFrom}>
              <FormattedMessage id='from' defaultMessage='From' />
            </span>
            <span className={styles.price}>
              <PriceConverter
                amount={item?.price?.price}
                currency={item?.price?.currency}
                conversionPercent
              />
              <img
                src={`${window.staticPath}travel_dist/images/instant-conf.svg`}
                className={styles.instanceConfirmation}
                width='16px'
                height='16px'
                alt='Instant confirmation'
              />
            </span>
          </div>
        )}
        <button className={cx(styles.btn, 'btn-guides-red')}>
          <FormattedMessage id='book_online' defaultMessage='Book online' />
        </button>
      </div>
    </a>
  )
}
