import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { useState } from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'

const Section = ({ data, title, renderHtml }) => {
  const isExists = get(data, 'length')
  if(!isExists) {
    return null
  }
  return (
    <div className='description wysiwyg'>
      <p className='bold'>{title}</p>
      {renderHtml ? (
        <div
          dangerouslySetInnerHTML={{
            __html: data,
          }}
        />
      ) : (
        <ul>
          {data.map((item, key) => (
            <li key={key}>{item.contents}</li>
          ))}
        </ul>
      )}
    </div>
  )
}

function ItineraryLogisticDetails({
  itinerary,
  expadedOnInit,
  disableClick,
  title,
}) {
  const [isOpen, setOpen] = useState(disableClick ? true : expadedOnInit)
  const handleClick = () => {
    if(disableClick) {
      return
    }
    setOpen(!isOpen)
  }

  if(itinerary.logistics && !itinerary.useNewLogisticsData) {
    return (
      <div
        className='itinerary-section itinerary-logistics collapsed-section'
        id='logistics'
      >
        <h4 onClick={handleClick} className={isOpen ? 'active' : ''}>
          {title || (
            <FormattedMessage
              id='how_to_get_there'
              defaultMessage='How to get there'
            />
          )}
        </h4>
        {isOpen && (
          <div
            className='description wysiwyg'
            dangerouslySetInnerHTML={{
              __html: itinerary.logistics,
            }}
          />
        )}
      </div>
    )
  } else if(itinerary.descriptions && itinerary.useNewLogisticsData) {
    const beforeTrip = get(itinerary, 'descriptions.beforeTrip', [])
    const onBoard = get(itinerary, 'descriptions.onBoard', [])
    const afterTrip = get(itinerary, 'descriptions.afterTrip', [])
    return (
      <div className='itinerary-section collapsed-section' id='logistics'>
        <h4 onClick={handleClick} className={isOpen ? 'active' : ''}>
          {title || (
            <FormattedMessage
              id='how_to_get_there'
              defaultMessage='How to get there'
            />
          )}
        </h4>
        {isOpen && (
          <div className='itinerary-section-details fade-onload'>
            <Section
              data={beforeTrip}
              title={
                <FormattedMessage
                  id='begining_of_trip'
                  defaultMessage='The beginning of your trip'
                />
              }
            />
            <Section
              data={onBoard}
              title={
                <FormattedMessage
                  id='during_trip'
                  defaultMessage='During your trip'
                />
              }
            />
            <Section
              data={afterTrip}
              title={
                <FormattedMessage
                  id='end_of_trip'
                  defaultMessage='The end of your trip'
                />
              }
            />
            <Section
              data={itinerary.otherLogisticsInfo}
              renderHtml
              title={
                <FormattedMessage id='other_info' defaultMessage='Other info' />
              }
            />
          </div>
        )}
      </div>
    )
  }

  return null
}

export default connect((state) => ({
  expadedOnInit:
    get(state, 'itineraryPopupData.elementToScroll') === '#logistics',
}))(ItineraryLogisticDetails)

ItineraryLogisticDetails.propTypes = {
  itinerary: PropTypes.object,
  expadedOnInit: PropTypes.bool,
  disableClick: PropTypes.bool,
  title: PropTypes.string,
}

Section.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  renderHtml: PropTypes.bool,
}
