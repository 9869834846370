import { useEffect, useRef } from 'react'
import L from 'leaflet'

export default function usePreventMapEventPropagation() {
  const element = useRef(null)

  useEffect(() => {
    if(element.current) {
      L.DomEvent.disableClickPropagation(element.current)
      L.DomEvent.disableScrollPropagation(element.current)
    }
  }, [element.current])

  return { element }
}
