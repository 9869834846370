import { FormattedMessage } from 'react-intl'

export default function OpenWaterCourseWarning() {
  const handleClick = () => {
    document.getElementById('livechat').click()
  }
  return (
    <div className='warning-message'>
      <div className='warning-message__wrapper'>
        <p className='warning-message__text'>
          <i className='font-icons icon-warning' />
          <FormattedMessage
            id='open_water_course_duration'
            defaultMessage='The PADI Open Water Course usually takes 4 days. We recommend you stay at least 5 nights. Please contact our staff if you want to book a shorter trip to make sure it is possible.'
          />
        </p>
        <button className='btn-red' onClick={handleClick}>
          <FormattedMessage id='contact_us' defaultMessage='Contact us' />
        </button>
      </div>
    </div>
  )
}
