import PlaceholderTrips from './PlaceholderTrips'
import ScheduleDates from '../dive-center/schedule/ScheduleDates'

const Loader = () => {
  return [...new Array(10)].map((_, i) => {
    return <PlaceholderTrips key={i} />
  })
}

export default function ActivityDateSorted({ items, loading }) {
  if(loading) {
    return <Loader />
  }
  return (
    <div>
      {items.map((data, index) => (
        <ScheduleDates
          key={index}
          items={data}
          date={data[0].availableDate}
          searchPageSchedule
        />
      ))}
    </div>
  )
}
