import { FormattedMessage } from 'react-intl'

export default function SellingProposition() {
  return (
    <div className='selling-proposition'>
      <div className='selling-proposition__item'>
        <div className='selling-proposition__icon'>
          <span className='circle-icon red'>
            <i className='font-icons discount-icon' />
          </span>
        </div>
        <div className='selling-proposition__item__content'>
          <FormattedMessage
            id='selling_propostion.best_price'
            defaultMessage="<strong>BEST PRICE GUARANTEE</strong> - we match any competitor's price so you can book with confidence."
            values={{
              strong: (chunks) => (
                <strong className='red-text'>{chunks}</strong>
              ),
            }}
          />
        </div>
      </div>
      <div className='selling-proposition__item'>
        <div className='selling-proposition__icon'>
          <span className='circle-icon blue'>
            <i className='font-icons icon-insurance' />
          </span>
        </div>
        <div className='selling-proposition__item__content'>
          <FormattedMessage
            defaultMessage='<strong>EXCLUSIVE BENEFITS</strong> such as FREE DAN dive insurance on most trips - why go elsewhere?'
            values={{
              strong: (chunks) => (
                <strong className='blue-text'>{chunks}</strong>
              ),
            }}
          />
        </div>
      </div>
      <div className='selling-proposition__item'>
        <div className='selling-proposition__icon'>
          <span className='circle-icon green'>
            <i className='font-icons icon-information' />
          </span>
        </div>
        <div className='selling-proposition__item__content'>
          <FormattedMessage
            defaultMessage='<strong>EXPERT ADVICE</strong> from our scuba travel agents on phone, chat and email.'
            values={{
              strong: (chunks) => (
                <strong className='green-text'>{chunks}</strong>
              ),
            }}
          />
        </div>
      </div>
      <div className='selling-proposition__item'>
        <div className='selling-proposition__icon'>
          <img
            src={window.staticPath + 'travel_dist/images/logo_padi_dark.svg'}
            className='hidden-xs hidden-sm'
          />
          <img
            src={window.staticPath + 'travel_dist/images/padi_logo.svg'}
            className='hidden-md hidden-lg'
          />
        </div>
        <div className='selling-proposition__item__content'>
          <FormattedMessage
            id='selling_propostion.secure_marketplace'
            defaultMessage="Secure, seamless online booking on the world's largest dive vacation marketplace."
          />
        </div>
      </div>
    </div>
  )
}
