import { FormattedMessage } from 'react-intl'
import get from 'lodash/get'

export default function NumberOfDives({ product }) {
  const numberOfDivesMax = get(product, 'itinerary.totalNumberOfDivesMax', 0)
  const numberOfDives = get(product, 'itinerary.totalNumberOfDives', 0)
  return (
    <div className='number-of-dives'>
      {numberOfDivesMax ? (
        <FormattedMessage
          id='dives_min_max'
          defaultMessage='{ divesMin } - { divesMax } dives'
          values={{
            divesMin: numberOfDives,
            divesMax: numberOfDivesMax,
          }}
        />
      ) : (
        <FormattedMessage
          id='dives_min'
          defaultMessage='{ divesMin } dives'
          values={{
            divesMin: numberOfDives,
          }}
        />
      )}
    </div>
  )
}
