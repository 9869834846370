import { useEffect, useRef } from 'react'

const usePreviousEffect = (fn, inputs = [], callback) => {
  const previousInputsRef = useRef([...inputs])

  useEffect(() => {
    fn(previousInputsRef.current)
    previousInputsRef.current = [...inputs]
    return callback
  }, inputs)
}

export default usePreviousEffect
