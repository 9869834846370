import { Loader } from '../helpers'
import useLocatorList from './hooks/useLocatorList'
import Pagination from './list/Pagination'
import DslList from './list/DslList'
import MapToggleButton from './list/MapToggleButton'
import UpdateListButton from './list/UpdateListButton'
import ReactTooltip from 'react-tooltip'
import { isMobile } from '../../utils'
import { Fragment } from 'react'

import PropTypes from 'prop-types'

const isMobileDevice = isMobile()

export default function DiveShopLocatorList({
  locationType,
  locationId,
  pageType,
}) {
  const {
    items,
    totalPages,
    isLoading,
    isElearningView,
    isUpdatedPossible,
    refreshList,
    hideUpdateButton,
  } = useLocatorList({ locationType, locationId, pageType })
  return (
    <Fragment>
      <div>
        <Loader isLoading={isLoading} customPosition={items?.length ? { top: '350px' } : {}} />
        <ReactTooltip
          type='dark'
          effect='solid'
          place={isMobileDevice ? 'bottom' : 'right'}
        />
        <DslList
          items={items}
          isLoading={isLoading}
          isElearningView={isElearningView}
          pageType={pageType}
        />
        {!isLoading && totalPages > 1 && <Pagination totalPages={totalPages} />}
        <MapToggleButton />
        {isUpdatedPossible && (
          <UpdateListButton
            refreshList={refreshList}
            closeButton={hideUpdateButton}
          />
        )}
      </div>
    </Fragment>
  )
}

DiveShopLocatorList.propTypes = {
  locationType: PropTypes.string,
  locationId: PropTypes.string,
  pageType: PropTypes.string,
}
