import { FormattedMessage } from 'react-intl'

export default function ProductDuration({ product }) {
  if(!product.duration) {
    return null
  }

  return (
    <div className='period'>
      <FormattedMessage
        id='duration_period'
        defaultMessage='{ nights } nights/{ days } days'
        values={{
          nights: product.duration,
          days: product.duration + 1,
        }}
      />
    </div>
  )
}
