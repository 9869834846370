import { Fragment, useCallback } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import {
  showItineraryPopup,
  setItineraryData,
} from '../../actions/main-actions'
import { parseQuery } from 'utils'
import {
  CharterContactButton,
  ProductDuration,
  NumberOfDives,
  TripPrice,
  TripStatus,
  TripTitle,
} from './pricing'

function PricingRow({
  isGroupTrip,
  product,
  setItineraryData,
  showItineraryPopup,
  currency,
  countrySlug,
  shopSlug,
  shopTitle,
  insurance,
  totalGuests,
  background,
  onSelect,
}) {
  const queryParams = parseQuery() || {}
  const noSpacesForSelectedGuests = !!(
    product.availability && totalGuests > product.availability
  )
  const isNextYear = moment().year() < moment(product.startDate).year()
  const showItinerary = useCallback(
    (elementToScroll) => {
      setItineraryData({
        isCharter: isGroupTrip || product.isCharter,
        itinerarySlug: product.itinerary.slug,
        tripId: product.id,
        dateFrom: product.startDate,
        dateTo: product.endDate,
        duration: product.duration,
        promotion: product.promotion,
        price: product.price,
        compareAtPrice: product.compareAtPrice,
        currency,
        shopSlug,
        countrySlug,
        elementToScroll:
          typeof elementToScroll === 'string' ? elementToScroll : null,
        insurance,
        updateUrl: !isGroupTrip,
        isGroupTrip,
      })
      showItineraryPopup(true)
    },
    [product],
  )

  return (
    <div
      className={`tr ${product.iterator % 2 === 0 ? 'even' : ''} 
        ${!product.availability || noSpacesForSelectedGuests ? 'sold_out' : ''} 
        ${isGroupTrip ? 'group-trip' : ''}
        ${+queryParams.trip_id === product.id ? 'featured' : ''}`}
    >
      <div className='td td-date content-center'>
        <span className='trip-date'>
          {moment(product.startDate).utc().format('D')}
        </span>
        <span className='trip-month'>
          {moment(product.startDate).utc().format('MMM')}
        </span>
        {isNextYear && (
          <span className='trip-year'>
            {moment(product.startDate).utc().format('YYYY')}
          </span>
        )}
      </div>
      <div className='td td-itinerary-information'>
        <TripTitle
          product={product}
          showItinerary={showItinerary}
          isGroupTrip={isGroupTrip}
        />
        <div className='td-duration'>
          <ProductDuration product={product} />
          <NumberOfDives product={product} />
        </div>
      </div>
      {!isGroupTrip && (
        <Fragment>
          <div className='td td-price content-right'>
            <TripPrice
              product={product}
              currency={currency}
              showItinerary={showItinerary}
            />
          </div>
          <div
            className={`td td-actions text-center${
              product.availability === 0 || noSpacesForSelectedGuests
                ? ' no-availability'
                : ''
            }`}
          >
            <div className='mobile-action-button'>
              <a onClick={showItinerary}>
                <FormattedMessage id='itinerary' defaultMessage='Itinerary' />
              </a>
            </div>
            {(!product.isCharter || !product.availability) && (
              <TripStatus
                product={product}
                currency={currency}
                noSpacesForSelectedGuests={noSpacesForSelectedGuests}
                shopSlug={shopSlug}
                countrySlug={countrySlug}
                shopTitle={shopTitle}
                onSelect={onSelect}
                queryParams={queryParams}
              />
            )}
            {product.isCharter && !!product.availability && (
              <CharterContactButton
                product={product}
                shopBackground={background}
                currency={currency}
              />
            )}
          </div>
        </Fragment>
      )}
    </div>
  )
}

export default connect(() => ({}), { showItineraryPopup, setItineraryData })(
  PricingRow,
)
