import { useMemo, Fragment } from 'react'
import ShopSlider from '../search/ShopSlider'
import { handleEventBubbling } from '../../dom-handlers'
import { ReviewLabel } from '../helpers'
import ResortAmenities from './ResortAmenities'
import PriceVariants from './PriceVariants'
import { FormattedMessage } from 'react-intl'
import BookingRequest from '../operator/BookingRequest'
import { target, setGuestParams, generateQueryForWidget } from 'utils'
import PropTypes from 'prop-types'
import { trackGA4Ecommerce } from '../../gtm'

const queriesForWidget = (url, selectedParams, totalGuests, isWidget) => {
  if(isWidget) {
    return generateQueryForWidget(url)
  }

  setGuestParams(selectedParams, totalGuests)
  return url
}

export default function ShopItem({
  shop,
  selectedParams,
  isAllParamsSelected,
  totalGuests,
  isWidget,
  eCommerceListName,
}) {
  const shopUrl = useMemo(
    () => queriesForWidget(shop.url, selectedParams, totalGuests, isWidget),
    [shop.url, selectedParams, totalGuests],
  )
  const handleClick = (e) => {
    e.stopPropagation()
    trackGAnalytics()
    window.open(shopUrl, target)
  }
  const handleTitleClick = (e) => {
    trackGAnalytics()
    handleEventBubbling(e)
  }
  const trackGAnalytics = () => {
    if(shop.viewItem) {
      trackGA4Ecommerce('select_item', {
        currency: 'USD',
        items: [shop.viewItem],
      }, 'PADI Travel - Dive Resorts')
    }
  }
  const jumpToEmptyInput = (e) => {
    e.stopPropagation()
    if(!totalGuests) {
      setTimeout(function() {
        document.querySelector('.rooms-and-guest-selector').click()
      })
    } else if(!selectedParams.nights) {
      document.getElementById('datepicker').click()
    }
  }

  return (
    <div
      className={`dive-resort search-page-item-card ${
        shop.isHighlighted ? 'highlighted' : ''
      }`}
    >
      <div className='dive-resort-content'>
        <div className='photo-slider'>
          <ShopSlider
            slug={shop.slug}
            shopUrl={shopUrl}
            background={shop.background.GridSearchResultDesktop}
          />
          <span
            className={`heart ${shop.isWishlist ? 'active' : ''}`}
            data-shop-slug={shop.slug}
            data-type='Shop'
            data-in-wishlist={shop.isWishlist}
            data-wishlist-id={shop.isWishlist}
          />
        </div>
        <div className='resort-info' onClick={handleClick}>
          <div className='result-head'>
            <a
              className='resort-title'
              target='_blank'
              href={shopUrl}
              onClick={handleTitleClick}
            >
              {shop.title}
            </a>
            <ReviewLabel
              shopKind='20'
              scubaTribe={shop.scubaTribe}
              tripAdvisor={shop.tripAdvisor}
              urlParams={{
                location: shop.referenceLocationCountrySlug || shop.countrySlug,
                slug: shop.slug || shop.shopSlug,
              }}
            />
          </div>
          <div className='dr-row'>
            <ResortAmenities
              selectedParams={selectedParams}
              isAllParamsSelected={isAllParamsSelected}
              shop={shop}
            />
            <div className='half'>
              {!shop.bookingOnRequest ? (
                <Fragment>
                  <PriceVariants
                    shop={shop}
                    selectedParams={selectedParams}
                    totalGuests={totalGuests}
                    isAllParamsSelected={isAllParamsSelected}
                  />
                  {isAllParamsSelected ? (
                    <a className='btn-red' target='_blank'>
                      <FormattedMessage
                        id='view_packages'
                        defaultMessage='View packages'
                      />
                    </a>
                  ) : (
                    <a className='btn-red' onClick={jumpToEmptyInput}>
                      <FormattedMessage
                        id='view_prices'
                        defaultMessage='View prices'
                      />
                    </a>
                  )}
                </Fragment>
              ) : (
                <BookingRequest
                  showText={false}
                  shop={shop.shopId}
                  shopTitle={shop.title}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ShopItem.propTypes = {
  shop: PropTypes.object,
  selectedParams: PropTypes.object,
  isAllParamsSelected: PropTypes.bool,
  totalGuests: PropTypes.number,
  isWidget: PropTypes.bool,
  eCommerceListName: PropTypes.string,
}
