import { useDispatch, useSelector } from 'react-redux'
import { setActivePage } from '../../../actions/search-actions'
import setQuery from 'set-query-string'

const activePageSelector = ({ activePage }) => activePage

export default function paginationHook() {
  const dispatch = useDispatch()
  const page = useSelector(activePageSelector)
  const setPage = (page) => {
    dispatch(setActivePage(page))
    setQuery({ page })
  }

  return {
    page,
    setPage,
  }
}
