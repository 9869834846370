import { useEffect, useState } from 'react'
import API from 'api'
const getEndpoint = (shopId, pinType) => {
  if(pinType === 'dive-centers') {
    return `/api/adventure/v1/search/dive-centers/${shopId}/`
  }
  return `search/resort/${shopId}/`
}

export default function useSearchPinInfo(id, pinType, isOpen) {
  const [pinInfo, setPinInfo] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const getPinInfo = () => {
    if(!isOpen) {
      return null
    }
    setIsLoading(true)
    API(getEndpoint(id, pinType))
      .get()
      .then((info) => {
        setPinInfo(info)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getPinInfo()
  }, [id, isOpen])

  return {
    pinInfo,
    isLoading,
  }
}
