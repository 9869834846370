import dayjs from 'dayjs'
import DatePicker, { DateObject } from 'react-multi-date-picker'
import 'react-multi-date-picker/styles/layouts/mobile.css'
import styles from './InlineRangeCalendar.module.scss'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import formStyles from './Form.module.scss'
import cx from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'

import { Field } from 'react-final-form'
import useWindowDimensions from '../../../dsl/hooks/useWindowDimensions'
import { APP_LANGUAGE } from '../../../../localized-urls'
import { isMobile } from '../../../../utils'
import { FormattedMessage } from 'react-intl'
dayjs.extend(LocalizedFormat)

const formatDate = (date, locale, format) => {
  return dayjs(date)
    .locale(locale.includes('zh') ? 'zh' : locale)
    .format(format || 'DD/MM/YY')
}
const isMobileDevice = isMobile()

const CustomInput = ({
  openCalendar,
  value,
  handleValueChange,
  label,
  handleChange,
  hasError,
  placeholder,
  showCloseIcon,
}) => {
  const formattedDate = useMemo(() => {
    if(value.length === 1) {
      if(!value[0]) {
        return ''
      }
      return formatDate(value[0], APP_LANGUAGE)
    }
    if(value.length === 2) {
      if(value[0] === value[1]) {
        return formatDate(value[0], APP_LANGUAGE)
      }
      return `${formatDate(value[0], APP_LANGUAGE)} - ${formatDate(
        value[1],
        APP_LANGUAGE
      )}`
    }
    return value
  }, [value])
  return (
    <div
      className={cx(styles.wrapper, {
        [formStyles.hasError]: hasError,
        'error-field': hasError,
      })}
    >
      {label && (
        <label htmlFor='range-picker' className={formStyles.label}>
          <strong>{label}</strong>
        </label>
      )}
      <div className='relative'>
        <i
          className={cx(styles.calendarIcon, 'dsl-icons', {
            'dsl-icons--calendar': !value?.length || !showCloseIcon,
            'dsl-icons--close': value?.length && showCloseIcon,
          })}
          onClick={() => showCloseIcon && handleChange(null)}
        />
        <input
          id='range-picker'
          data-test-id='range-picker'
          autoComplete='off'
          style={{}}
          className={cx(formStyles.input, formStyles.dateInput)}
          placeholder={placeholder}
          onClick={openCalendar}
          value={formattedDate}
          onChange={handleValueChange}
        />
      </div>
    </div>
  )
}

function DateRangePicker({
  input,
  meta,
  label,
  onChangeHandler,
  noMargin,
  placeholder,
  clearDateHandle,
  portal,
}) {
  const pickerRef = useRef()
  const [dates, setDates] = useState(input.value)
  const { width } = useWindowDimensions()
  const hasError =
    meta.touched && (meta.error?.[0]?.dateFrom || meta.submitError)
  const handleChange = (values) => {
    if(values === null) {
      input.onChange([])
      clearDateHandle?.()
      onChangeHandler?.([])
      return null
    }
    if(values.length !== 2) {
      return null
    }

    input.onChange(values)
  }
  const value = useMemo(() => {
    if(input.value.length) {
      return [new DateObject(input.value[0]), new DateObject(input.value[1])]
    }
  }, [input.value])

  const [portalTarget, setPortalTaget] = useState()

  const setDateHandler = (values) => {
    const [start, end] = values
    const dateStart = new DateObject(start).format('YYYY-MM-DD')
    const dateEnd = new DateObject(end).format('YYYY-MM-DD')
    setDates(end ? [dateStart, dateEnd] : [dateStart, dateStart])
  }

  useEffect(() => {
    if(isMobileDevice) {
      handleChange(dates)
    }
  }, [dates])

  const confirmDatesHandler = () => {
    handleChange(dates)
    pickerRef?.current?.closeCalendar()
  }

  const closeDateHandler = () => {
    pickerRef?.current?.closeCalendar()
  }

  useEffect(() => {
    if(!portal) {
      return
    }
    const portalDiv = document.createElement('div')

    /**
     * This ID is optional and has been added
     * to better recognize it in the DOM tree.
     */
    portalDiv.id = 'myPortalDiv'

    document.body.appendChild(portalDiv)

    setPortalTaget(portalDiv)

    return () => document.body.removeChild(portalDiv)
  }, [portal])

  return (
    <div className={styles.datePicker}>
      <DatePicker
        numberOfMonths={width >= 768 ? 2 : 1}
        className={cx('custom-calendar', { 'rmdp-mobile': isMobileDevice })}
        range
        ref={pickerRef}
        render={
          <CustomInput
            hasError={hasError}
            label={label}
            noMargin={noMargin}
            placeholder={placeholder}
            handleChange={handleChange}
            handleValueChange={isMobileDevice && (() => handleChange(dates))}
            // eslint-disable-next-line react/jsx-key
          />
        }
        portal={portal}
        portalTarget={portalTarget}
        arrow={false}
        value={value}
        onChange={setDateHandler}
        showOtherDays
        offsetY={12}
      >
        {!isMobileDevice && (
          <div className={styles.buttonsContainer}>
            <div>
              <span
                onClick={() => {
                  handleChange(null)
                }}
                className={styles.clearAll}
              >
                <FormattedMessage defaultMessage='Clear all' id='clear_all' />
              </span>
            </div>
            <div className={styles.rightSide}>
              <button
                type='button'
                onClick={closeDateHandler}
                className={cx(styles.datePickerButton, 'blue-btn-invert')}
              >
                <FormattedMessage defaultMessage='Cancel' id='cancel' />
              </button>
              <button
                type='button'
                onClick={confirmDatesHandler}
                className={cx(styles.datePickerButton, 'blue-btn')}
              >
                <FormattedMessage defaultMessage='OK' id='OK' />
              </button>
            </div>
          </div>
        )}
      </DatePicker>
    </div>
  )
}

export default function FormRangeDatepicker(props) {
  const { name } = props
  return <Field name={name} component={DateRangePicker} {...props} />
}
