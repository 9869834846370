import { useEffect, useState } from 'react'
import API from '../../../../api'
import omit from 'lodash/omit'

export function useActivityInfo(selectedParams, shopId, activityType) {
  const [activity, setActivity] = useState(false)
  const [activitiesList, setActivitiesList] = useState([])
  const [currentActivity, setCurrentActivity] = useState(0)
  const [isLoading, setLoading] = useState(true)
  const getActivityInfo = () => {
    setCurrentActivity(0)
    setLoading(true)
    API(`/api/adventure/v1/search/dive-centers/${shopId}/map/${activityType}/`)
      .get(omit(selectedParams, ['point', 'distance']))
      .then((data) => {
        setActivitiesList(data.results)
        setActivity(data.results[currentActivity])
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getActivityInfo()
  }, [shopId])

  return {
    activitiesList,
    activity,
    currentActivity,
    setCurrentActivity,
    isLoading,
    setActivity,
  }
}
