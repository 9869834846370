/**
 * @function checkOverlay
 * @param  {String} componentSelector
 *
 */

export function checkOverlay(componentSelector) {
  if(document.querySelector(componentSelector).classList.contains('visible')) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = 'visible'
  }
}

export const autosuggestStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: 2,
    border: '1px solid #dde3e6',
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: 40,
  }),
  clearIndicator: (styles) => ({
    ...styles,
    cursor: 'pointer',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 9999,
  }),
  option: (styles, state) => {
    return {
      ...styles,
      backgroundColor: state.isFocused ? '#f23d4e' : null,
      color: state.isFocused ? '#fff' : null,
      '&:active': {
        backgroundColor: '#ce4028',
        color: '#fff',
      },
    }
  },
}
