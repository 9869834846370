import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import BookingEnquirePopup from '../../popups/BookingEnquirePopup'

export default function CharterContactButton({
  product,
  shopBackground,
  currency,
}) {
  const [isVisible, setModalVisibility] = useState(false)

  const showModal = () => setModalVisibility(true)
  const hideModal = () => setModalVisibility(false)

  return (
    <div className='product-status-wrapper'>
      <button className='btn-red' onClick={showModal}>
        <FormattedMessage id='contact_us' defaultMessage='Contact us' />
      </button>
      <BookingEnquirePopup
        closeModal={hideModal}
        isOpen={isVisible}
        title={
          <FormattedMessage
            id='group_booking.title'
            defaultMessage='Group booking enquire'
          />
        }
        shop={{ ...product, background: shopBackground }}
      />
    </div>
  )
}
