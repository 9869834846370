import { useEffect, useMemo, useState } from 'react'
import Autosuggest from 'react-autosuggest'
import debounce from 'lodash/debounce'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import API from '../../api'
import { getHighlightedText } from '../helpers/Typeahead'

const msg = defineMessages({
  name_or_location: {
    id: 'name_or_location',
    defaultMessage: 'Name or location',
  },
  type_a_name: {
    id: 'type_a_name',
    defaultMessage: 'Type a name',
  },
  near_me: {
    id: 'near_me',
    defaultMessage: 'Near me',
  },
  radius_km: {
    id: 'radius_km',
    defaultMessage: '50 km radius',
  },
})

const renderIcon = (type) => {
  if(type === 'near_by') {
    return 'best-choice-icon'
  } else if(type === 'place') {
    return 'location-icon'
  }
  return 'dive-center-icon'
}

const renderSuggestion = (suggestion, { query }) => {
  if(!suggestion.title.length) {
    return ''
  }

  return (
    <div className={`typeahead-item ${suggestion.last ? 'last' : ''}`}>
      <div className='img-wrap'>
        <i className={`font-icons ${renderIcon(suggestion.objectType)}`} />
      </div>
      <div className='typeahead-title'>
        <span className='location-title'>
          {getHighlightedText(suggestion.title, query)}
        </span>
        <span className='location-text'>
          {getHighlightedText(suggestion.name, query) || ' '}
        </span>
      </div>
    </div>
  )
}

let controller, signal
export default function TripOrganiser({
  hideRange,
  inputValue,
  onSuggestionSelect,
  disabled,
  url,
  placeholder,
}) {
  const intl = useIntl()
  const [value, setValue] = useState('')
  const nearMeChoice = useMemo(() => {
    if(!hideRange) {
      return {
        id: 'near_by',
        searchParam: 'near_by',
        objectType: 'near_by',
        title: intl.formatMessage(msg.near_me),
        name: intl.formatMessage(msg.radius_km),
      }
    }

    return {
      id: '',
      searchParam: '',
      objectType: '',
      title: '',
      name: '',
    }
  }, [hideRange])

  useEffect(() => {
    if(inputValue) {
      setValue(inputValue)
    }
  }, [inputValue])

  const [suggestions, setSuggestions] = useState([nearMeChoice])

  const getSuggestionValue = (suggestion) => {
    if(suggestion.objectType === 'near_by') {
      navigator.geolocation.getCurrentPosition(
        (location) => {
          if(typeof onSuggestionSelect === 'function') {
            onSuggestionSelect({
              [suggestion.searchParam]: `${location.coords.latitude},${location.coords.longitude}`,
              organiserTitle: intl.formatMessage(msg.near_me),
              isNearBy: true,
              place_id: undefined,
              organiser_pk: undefined,
              shop_id: undefined,
            })
          }
        },
        () => {
          toast.error(
            <FormattedMessage
              id='cant_get_location'
              defaultMessage='Unfortunately we can`t receive your coordinates'
            />,
            {
              position: toast.POSITION.TOP_CENTER,
            }
          )
          onSuggestionSelect({
            [suggestion.searchParam]: `${50},${35}`,
            organiserTitle: intl.formatMessage(msg.near_me),
            isNearBy: true,
            place_id: undefined,
            organiser_pk: undefined,
            shop_id: undefined,
          })
        },
        {
          enableHighAccuracy: true,
          timeout: 3000,
          maximumAge: 0,
        }
      )
    } else if(hideRange) {
      onSuggestionSelect({
        shop_id: suggestion.pk,
        organiserTitle: suggestion.title,
      })
    } else {
      onSuggestionSelect({
        shop_id: undefined,
        place_id: undefined,
        organiser_pk: undefined,
        [suggestion.searchParam]: suggestion.pk,
        organiserTitle: suggestion.title,
      })
    }
    return suggestion.title
  }

  const onSuggestionsFetchRequested = debounce(({ value }) => {
    if(controller !== undefined) {
      // Cancel the previous request
      controller.abort()
    }

    if('AbortController' in window) {
      controller = new window.AbortController()
      signal = controller.signal
    }

    return API(url, signal)
      .get({
        search: value,
      })
      .then((items) => {
        items.push(nearMeChoice)
        setSuggestions(items)
      })
  }, 300)

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([nearMeChoice])
  }

  const onChange = (_, { newValue }) => {
    setValue(newValue)

    if(newValue.length === 0) {
      onSuggestionsClearRequested()
      onSuggestionSelect({
        place_id: undefined,
        shop_id: undefined,
        organiser_pk: undefined,
        organiserTitle: undefined,
        near_by: undefined,
      })
    }
  }

  const onFocus = (e) => {
    try {
      e.currentTarget.setSelectionRange(0, value.length)
    } catch (err) {
      // Non IOs option if not supported, e.g. Chrome
      e.currentTarget.select()
    }
  }

  const inputProps = useMemo(
    () => ({
      placeholder: intl.formatMessage(msg[placeholder]),
      value,
      type: 'search',
      autoComplete: 'off',
      disabled,
      onChange,
      onFocus,
    }),
    [value, disabled]
  )

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
    />
  )
}
