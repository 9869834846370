import { Marker } from 'react-leaflet'
import {
  dslProfessionalPin,
  dslProfessionalPinSelected,
} from '../../../config'
import useMarkerHover from '../hooks/useMarkerHover'
import { memo } from 'react'
import isEqual from 'lodash/isEqual'
import ProPopup from './ProPopup'

const getIcon = (pin, activeMarker) => {
  return activeMarker === pin.id
    ? dslProfessionalPinSelected
    : dslProfessionalPin
}

function Markers({ pins }) {
  const {
    activeMarker,
    handleMouseOut,
    handleMouseOver,
    handleClick,
    onPopupClose,
    opennedMarker,
  } = useMarkerHover()
  return pins?.map((pin) => {
    return (
      <Marker
        key={pin.id}
        id={pin.id}
        icon={getIcon(pin, activeMarker)}
        position={[pin.latitude, pin.longitude]}
        zIndexOffset={activeMarker === pin.id ? 1000 : 0}
        riseOnHover
        eventHandlers={{
          mouseover: handleMouseOver,
          mouseout: handleMouseOut,
          click: handleClick,
        }}
      >
        <ProPopup
          id={pin.id}
          onPopupClose={onPopupClose}
          isOpen={opennedMarker === pin.id}
        />
      </Marker>
    )
  })
}

const compare = (prevProps, nextProps) => {
  return isEqual(prevProps.pins, nextProps.pins)
}

export default memo(Markers, compare)
