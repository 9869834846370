import { FormattedMessage } from 'react-intl'
import cx from 'classnames'
import styles from '../styles/Pagination.module.scss'
import usePagination from '../hooks/usePagination'

export default function Pagination({ totalPages }) {
  const { page, setPrevPage, setNextPage } = usePagination()
  const onNextClick = () => {
    if(page < totalPages) {
      setNextPage()
    }
  }

  return (
    <div className={styles.pagination}>
      <i
        className={cx(
          styles.arrow,
          { [styles.notActive]: page === 1 },
          'padi-icons padi-icons--carret-left'
        )}
        onClick={setPrevPage}
      />
      <span className={styles.currentPage}>{page}</span>
      <span className={styles.text}>
        <FormattedMessage id='of' defaultMessage='of' />
        &nbsp;{totalPages}
      </span>
      <i
        className={cx(
          styles.arrow,
          { [styles.notActive]: page === totalPages },
          'padi-icons padi-icons--carret-right'
        )}
        onClick={onNextClick}
      />
    </div>
  )
}
