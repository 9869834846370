import { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { target, generateQueryForTrip } from 'utils'
import { createUrl } from '../../../localized-urls'
import { DateFormat, PriceConverter } from '../../helpers'
import {
  showItineraryPopup,
  setItineraryData,
} from '../../../actions/main-actions'

export default function Row({
  trip,
  isWidget,
  currency,
  insurance,
  eCommerceListName,
}) {
  const dispatch = useDispatch()
  const showSpotsNumber = !!(trip.availableSpots && trip.availableSpots < 5)
  const handleImpression = () => {
    if(trip.impression) {
      window.dataLayer.push({
        event: 'productClick',
        ecommerce: {
          currencyCode: 'USD',
          click: {
            actionField: {
              list: eCommerceListName,
            },
            products: [trip.impression],
          },
        },
      })
    }
  }
  const handleClick = () => {
    handleImpression()
    window.open(
      createUrl(
        'la_page',
        {
          location: trip.referenceLocationCountrySlug || trip.countrySlug,
          slug: trip.slug || trip.shopSlug,
        },
        generateQueryForTrip(trip, isWidget)
      ),
      target
    )
  }

  const openItineraryPopup = (e) => {
    e.preventDefault()
    e.stopPropagation()
    handleImpression()
    dispatch(
      setItineraryData({
        isCharter: trip.isCharter,
        itinerarySlug: trip.itinerarySlug,
        tripId: trip.id,
        dateFrom: trip.startDate,
        dateTo: trip.endDate,
        duration: trip.duration,
        currency,
        shopSlug: trip.slug || trip.shopSlug,
        countrySlug: trip.referenceLocationCountrySlug || trip.countrySlug,
        insurance: trip.includeFreeDiveInsurance && insurance,
      })
    )
    dispatch(showItineraryPopup(true))
  }

  return (
    <div
      onClick={handleClick}
      className={`row${
        trip.grossPrice < trip.compareAtPrice ? ' is-deal' : ''
      }`}
    >
      <div className='date'>
        <DateFormat date={trip.startDate} format='D MMM' />
        &nbsp;
        <span className='hidden-xs'>&gt;</span>
      </div>
      <div className='date'>
        <DateFormat date={trip._endDate} format='D MMM' />
      </div>
      <div className='number-of-nights'>
        {trip.duration}&nbsp;
        <FormattedMessage id='nights' defaultMessage='nights' />
      </div>
      <div className='title'>
        <p className='bold'>{trip.shopTitle}</p>
        <a className='pointer' onClick={openItineraryPopup}>
          <i className='font-icons route-icon'>&nbsp;</i>
          {trip.itineraryTitle}
        </a>
      </div>
      <div className='availability'>
        {showSpotsNumber && (
          <FormattedMessage
            id='spots_left_plural'
            values={{
              spots: trip.availableSpots,
            }}
            defaultMessage='{spots, plural, one {# spot} other {# spots}} left'
          />
        )}
      </div>
      <div className='pricing'>
        {trip.isCharter ? (
          <span className='charter'>
            <FormattedMessage id='charter_only' defaultMessage='Charter only' />
          </span>
        ) : (
          <Fragment>
            <span className='from'>
              <FormattedMessage id='from' defaultMessage='From' />
              &nbsp;
            </span>
            <span className='price'>
              <PriceConverter
                currency={trip.currency}
                amount={trip.grossPrice}
              />
            </span>
            <span className='price-type hidden-xs'>
              / <FormattedMessage defaultMessage='trip' />
            </span>
            <span className='price-type visible-xs'>
              <FormattedMessage id='per_trip' defaultMessage='per trip' />
            </span>
          </Fragment>
        )}
      </div>
    </div>
  )
}
