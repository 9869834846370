import './placeholder.sass'

export default function PlaceholderPricing() {
  return (<div className='la-pricing-placeholder'>
    <div className='la-pricing-placeholder__dates'>
      <div className='day' />
      <div className='month' />
    </div>
    <div className='la-pricing-placeholder__information'>
      <div className='la-pricing-placeholder__title'>
        <div className='title' />
        <div className='itinerary-buttons'>
          <div />
          <div />
          <div />
        </div>
      </div>
      <div className='la-pricing-placeholder__duration'>
        <div />
        <div />
      </div>
    </div>
    <div className='la-pricing-placeholder__pricing'>
      <div />
    </div>
    <div className='la-pricing-placeholder__action'>
      <div className='button' />
      <div className='available' />
    </div>
  </div>)
}
