exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".checkboxWrap--Jhcn2{display:flex;align-items:center;cursor:pointer;color:rgba(0,0,0,.6);margin-bottom:12px;padding:6px 0}.title--1JV1t{text-align:left;text-overflow:ellipsis;overflow:hidden;margin-bottom:0;max-width:calc(100% - 37px)}.inputCheckbox--3zQJK{display:none}.inputCheckbox--3zQJK:checked+.checkMark--dxO-R{background-color:#0070d3;border:none}.inputCheckbox--3zQJK:checked+.checkMark--dxO-R:before{font-family:icomoon;content:\"\\e958\";position:absolute;top:50%;left:50%;color:#fff;font-size:10px;transform:translate(-50%,-50%)}.checkMark--dxO-R{display:flex;align-items:center;justify-content:center;position:relative;width:18px;height:18px;margin-right:19px;border:2px solid rgba(0,0,0,.6);background-color:#fff;border-radius:2px;cursor:pointer}", ""]);

// Exports
exports.locals = {
	"checkboxWrap": "checkboxWrap--Jhcn2",
	"title": "title--1JV1t",
	"inputCheckbox": "inputCheckbox--3zQJK",
	"checkMark": "checkMark--dxO-R"
};