import get from 'lodash/get'
import { FormattedMessage } from 'react-intl'
import Slider from 'react-slick'

const sliderConfig = {
  slidesToShow: 3,
  slidesToScroll: 3,
  infinite: false,
  lazyLoad: true,
  dots: true,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
}

export default function ItineraryMarineLife({ marineLife }) {
  const marineLifeExists = get(marineLife, 'length', false)
  if(!marineLifeExists) {
    return null
  }
  return (
    <div
      className='marine-life-pictures itinerary-section'
      style={{ width: '100%' }}
    >
      <h4>
        <FormattedMessage
          id='iconic_marine_life_you_could_encounter'
          defaultMessage='Iconic marine life you could encounter'
        />
      </h4>
      <Slider {...sliderConfig}>
        {marineLife.map((critter) => {
          return (
            <div className='marine-life-pictures__item' key={critter.title}>
              <div className='img-wrapper'>
                <img src={critter.thumbnails.WizardSpeciesSmall} />
              </div>
              <span className='title'>{critter.title}</span>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}
