import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { parseQuery } from '../utils'
import { setSelectedRoom } from '../actions/resort-checkout-actions'

export default function useSelectedRoom() {
  const dispatch = useDispatch()
  const selectedRoom = useSelector((state) => state.selectedRoom)
  useEffect(() => {
    const query = parseQuery()
    if(query && (query.rate || query.packages) && query.room) {
      dispatch(
        setSelectedRoom({
          rate: query.rate,
          packages: query.packages,
          room: query.room,
          mealPlan: query.mealPlan,
        })
      )
    }
  }, [])

  return selectedRoom
}
