import { Field } from 'react-final-form'
import RangeSlider from 'react-range-slider-input'
import 'react-range-slider-input/dist/style.css'
import styles from './RangeSelector.module.scss'
import { useEffect, useState } from 'react'
import cx from 'classnames'

function RangeSelector({ input, min, max, step, setRangeValues }) {
  const [values, setValues] = useState([Number(min), Number(max)])

  const onChange = (v) => {
    setValues(v)
    setRangeValues(v)
  }

  useEffect(() => {
    if(input.value?.length) {
      setValues(input.value)
      setRangeValues(input.value)
    }
    if(!input.value?.length) {
      setValues([Number(min), Number(max)])
    }
  }, [input.value])

  const onDragEnd = () => {
    input.onChange(values)
  }

  return (
    <RangeSlider
      className={cx(styles.rangeSlider, 'no-global-styles')}
      min={min}
      max={max}
      step={step}
      value={values}
      onInput={onChange}
      onThumbDragEnd={onDragEnd}
      onRangeDragEnd={onDragEnd}
    />
  )
}

export default function FormRangeSelector(props) {
  const { name } = props
  return (<Field name={name}
    component={RangeSelector} {...props} />)
}
