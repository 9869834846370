import styles from '../styles/FiveStar.module.scss'

export default function FiveStar({ membershipLevel }) {
  if(!membershipLevel) {
    return null
  }
  return (
    <p className={styles.fiveStar}>
      <img
        alt='PADI logo'
        src={`${window.staticPath}travel_dist/images/padi-globe.png`}
        className={styles.padiLogo}
      />
      {membershipLevel}
    </p>
  )
}
