import { FormattedMessage } from 'react-intl'
import styles from '../styles/ShopList.module.scss'

import PropTypes from 'prop-types'

export default function UpdateListButton({ refreshList, closeButton }) {
  return (
    <div className={styles.updateListButton} id='refresh-btn'>
      <FormattedMessage
        id='update_list_to_match'
        defaultMessage='Update list to match adjusted map?'
      />
      <button className='blue-btn' onClick={refreshList}>
        <FormattedMessage id='ok_caps' defaultMessage='OK' />
      </button>
      <button className='blue-btn-invert' onClick={closeButton}>
        <FormattedMessage id='no' defaultMessage='No' />
      </button>
    </div>
  )
}

UpdateListButton.propTypes = {
  refreshList: PropTypes.func,
  closeButton: PropTypes.func,
}
