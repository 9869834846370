import styles from '../../styles/DropDown.module.scss'
import CheckboxSelect from './CheckboxSelect'
import { forwardRef, Fragment, useEffect, useState } from 'react'
import SearchInput from './SearchInput'
import cx from 'classnames'
import { useForm, useFormState } from 'react-final-form'
import RadioButtonSelect from './RadioButtonSelect'

import PropTypes from 'prop-types'

const SelectItem = (props) => {
  const { isMultiple } = props
  return isMultiple ? <CheckboxSelect {...props} value={props.id} /> : <RadioButtonSelect {...props} value={props.id} />
}

SelectItem.propTypes = {
  isMultiple: PropTypes.bool,
}

const ToggleContainer = ({
  item,
  subitems,
  queryName,
  sectionTitle,
  isMultiple,
}) => {
  const { values } = useFormState()
  const [isVisble, setIsVisible] =
    useState(isMultiple ? false : subitems?.some((subitem) => values[queryName] === `${subitem.id}`))
  return (
    <Fragment>
      <p
        className={cx(styles.toggleTitle, { [styles.toggleActive]: isVisble })}
        onClick={() => setIsVisible(!isVisble)}
      >
        {item.title} <i className='font-icons bottom-arrow-icon' />
      </p>
      {subitems &&
        isVisble &&
        subitems.map((item) => {
          return (
            <SelectItem {...item} key={item.id}
              queryName={queryName}
              sectionTitle={sectionTitle}
              isMultiple={isMultiple}
            />)
        })}
    </Fragment>
  )
}

const SelectedItems = ({
  selectedItems = [],
  queryName,
}) => {
  const form = useForm()
  const handleRemove = (id) => () => {
    const values = form.getState().values[queryName]
    form.change(
      queryName,
      values.filter((item) => item !== `${id}`),
    )
  }
  return (
    <div className={styles.selectedItemsContainer} tabIndex='-1'>
      {selectedItems.map((item) => {
        return (
          <div
            key={item.id + item.title}
            className={styles.selectedItemsPill}
            onClick={handleRemove(item.id)}
          >
            {item.title}
            <i
              className={cx(
                styles.selectedItemsIcon,
                'dsl-icons dsl-icons--close-fill',
              )}
            />
          </div>
        )
      })}
    </div>
  )
}

export const filterSelectedItems = (items, formValues, isMultiple) => {
  const selectedItems = []
  if(formValues && isMultiple) {
    items.forEach((item) =>
      item.subitems.forEach((subitem) => {
        if(formValues.includes(`${subitem.id}`)) {
          selectedItems.push(subitem)
        }
      }),
    )
    return selectedItems
  }
}

export const filterSearchItems = (items, searchValue) => {
  const selectedItems = []
  if(searchValue) {
    items.forEach((item) =>
      item.subitems.forEach((subitem) => {
        if(subitem.title.toLowerCase().includes(searchValue)) {
          selectedItems.push(subitem)
        }
      }),
    )

    return selectedItems
  }
}

const MultiSelectWithToggle = forwardRef(
  (
    {
      items = [],
      withSearch,
      searchPlaceholder,
      icon,
      inlineMode,
      title,
      queryName,
      formValues = {},
      titleEn,
      isMultiple,
    },
    ref,
  ) => {
    const [selectedItems, setSelectedItems] = useState(
      filterSelectedItems(items, formValues[queryName], isMultiple),
    )
    const [searchItems, setSearchItems] = useState([])
    const onSearch = (e) => {
      setSearchItems(() =>
        filterSearchItems(items, e.target.value.toLowerCase()),
      )
    }

    // need to check each time, because we have All filters section and need to update if something change here or there
    useEffect(() => {
      setSelectedItems(() => filterSelectedItems(items, formValues[queryName], isMultiple))
    }, [formValues[queryName]])

    console.log(queryName, selectedItems)
    return (
      <div
        className={cx(styles.dropDown, { [styles.inlineMode]: inlineMode })}
        ref={ref}
      >
        {title && <p className={styles.title}>{title}</p>}
        {withSearch && (
          <SearchInput
            placeholder={searchPlaceholder}
            icon={icon}
            onChange={onSearch}
          />
        )}
        <SelectedItems selectedItems={selectedItems} queryName={queryName} />
        {searchItems &&
          searchItems.map((item) => {
            return (
              <SelectItem {...item} key={item.id}
                queryName={queryName}
                sectionTitle={titleEn}
                isMultiple={isMultiple}
              />)
          })}
        {items &&
          items.map((item) => {
            return (
              <ToggleContainer
                key={item.title}
                item={item}
                subitems={item.subitems}
                sectionTitle={titleEn}
                queryName={queryName}
                isMultiple={isMultiple}
              />
            )
          })}
      </div>
    )
  },
)

export default MultiSelectWithToggle

MultiSelectWithToggle.propTypes = {
  items: PropTypes.array,
  withSearch: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  icon: PropTypes.string,
  inlineMode: PropTypes.bool,
  title: PropTypes.string,
  queryName: PropTypes.string,
  formValues: PropTypes.object,
  titleEn: PropTypes.string,
  isMultiple: PropTypes.bool,
}
