import { useRef, useState, useMemo, Fragment, useEffect } from 'react'
import Slider from 'react-slick'
import API from 'api'
import get from 'lodash/get'
import { target } from 'utils'
import PropTypes from 'prop-types'
import { dummyImagePath } from '../../config'

const dslPlaceholder = {
  caption: 'PADI',
  src: dummyImagePath,
}

function CustomArrows({ type, onClick }) {
  const handleClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    onClick()
  }
  return (
    <div
      className={`custom-slick-button ${type === 'next' ? 'next' : 'prev'}`}
      onClick={handleClick}
    >
      <i
        className={`font-icons ${
          type === 'next' ? 'right-arrow-icon' : 'left-arrow-icon'
        }`}
      />
    </div>
  )
}

const getSettings = (photosLength) => ({
  dots: true,
  dotsClass: 'custom-paging',
  customPaging: (index) => {
    return (
      <Fragment>
        {index + 1}/{photosLength}
      </Fragment>
    )
  },
  arrows: true,
  nextArrow: <CustomArrows type='next' />,
  prevArrow: <CustomArrows type='prev' />,
  slidesToShow: 1,
  lazyLoad: 'ondemand',
  fade: true,
  responsive: [
    {
      breakpoint: 1025,
      settings: { arrows: true, slidesToShow: 1, slidesToScroll: 1 },
    },
  ],
})

ShopSlider.propTypes = {
  background: PropTypes.string,
  currentPhotos: PropTypes.arrayOf(PropTypes.object),
  apiUrl: PropTypes.string,
  slug: PropTypes.string,
  shopUrl: PropTypes.string,
  replacePhotos: PropTypes.bool,
}

export default function ShopSlider({
  background,
  currentPhotos,
  apiUrl,
  slug,
  shopUrl,
  replacePhotos,
  imgPath,
  handleClick,
}) {
  let isMounted = true
  const [photos, setPhotos] = useState(setCurrentPhotos())
  const settings = useMemo(() => getSettings(photos.length), [photos])
  const sliderRef = useRef()

  useEffect(() => {
    getPhotos()
    return () => {
      isMounted = false
    }
  }, [apiUrl, slug])

  function setCurrentPhotos() {
    if(currentPhotos) {
      return currentPhotos
    } else if(typeof background === 'string') {
      return [{ url: background }]
    } else if(typeof background === 'object') {
      return [background]
    }
    return []
  }

  function getPhotos() {
    if(sliderRef && sliderRef.current) {
      sliderRef.current.slickGoTo(0)
    }
    if(!slug && !apiUrl) {
      return false
    }
    API(apiUrl || `shop/${slug}/images/`)
      .get()
      .then((data) => {
        if(isMounted) {
          const results = data.results || data
          if(replacePhotos && results.length) {
            setPhotos(results)
          } else {
            setPhotos([...setCurrentPhotos(), ...results])
          }
        }
      })
  }

  // const handleClick = (e) => {
  //   e.stopPropagation()
  //   e.preventDefault()
  //
  //   shopUrl && window.open(shopUrl, target)
  // }

  if(!photos.length) {
    return <img {...dslPlaceholder} className='img fade-onload' />
  }

  return (
    <Slider className='slick-slider' {...settings} ref={sliderRef}>
      {photos.map((photo) => {
        const url =
          photo?.url || get(photo, 'picture.medium') || get(photo, imgPath)
        return (
          <picture className='relative' key={url} onClick={handleClick}>
            {photo?.webpUrl && (
              <Fragment>
                <source srcSet={photo.webpUrl} type='image/webp' />
                <source srcSet={url} type='image/jpeg' />
              </Fragment>
            )}
            <img
              alt='Dive operator picture'
              className='img fade-onload'
              src={url}
            />
          </picture>
        )
      })}
    </Slider>
  )
}
