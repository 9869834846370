import { FormattedMessage, useIntl } from 'react-intl'
import get from 'lodash/get'
import messages from '../../messages'

function BoatAmenities({
  parameters,
  includeFreeDiveInsurance,
  bookWithConfidence,
  insurance,
}) {
  const intl = useIntl()
  const insuranceTooltip = intl.formatMessage(
    messages.diveInsuranceDescription,
    { price: get(insurance, 'limitPrice', 'n/a') }
  )
  return (
    <div className='amenities-top'>
      {parameters.slice(0, 2).map((param) => {
        if(param.id !== 191) {
          return (
            <p key={param.id}>
              <i
                className={`font-icons am-icon${
                  param.id === 206 || param.id === 205 ? ' nitrox-icon' : ''
                }${param.id === 49 || param.id === 48 ? ' icon-wifi' : ''}`}
              />
              {param.title}
            </p>
          )
        }
      })}
      {includeFreeDiveInsurance && (
        <p className='green tooltip-wrapper-block' data-tip={insuranceTooltip}>
          <i className='font-icons icon-insurance am-icon' />
          <FormattedMessage
            id='free_insurance'
            defaultMessage='Free dive insurance'
          />
          <i className='font-icons info-icon' />
        </p>
      )}
    </div>
  )
}

export default BoatAmenities
