exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".header--2_cBH{display:flex;justify-content:space-between;align-items:center;gap:5px}.header--2_cBH p{font-size:32px;font-weight:700;margin-bottom:0}.backButton--2KWvj{display:flex;align-items:center;gap:5px;font-size:12px;cursor:pointer}.backButton--2KWvj i{font-size:20px}.listTitle--2Zapj{flex-direction:column}.address--mzoQM,.listTitle--2Zapj{display:flex;gap:5px}.address--mzoQM{align-items:center;font-size:16px;color:rgba(0,0,0,.6)}.address--mzoQM img{width:16px;height:16px}.info--2OdVX{display:flex;align-items:center;gap:5px;font-size:12px;color:rgba(0,0,0,.6)}.info--2OdVX .title--256dF{color:rgba(0,0,0,.87)}", ""]);

// Exports
exports.locals = {
	"header": "header--2_cBH",
	"backButton": "backButton--2KWvj",
	"listTitle": "listTitle--2Zapj",
	"address": "address--mzoQM",
	"info": "info--2OdVX",
	"title": "title--256dF"
};