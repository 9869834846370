import { useState, useRef, useMemo, useEffect, Fragment } from 'react'
// import {connect} from "react-redux";
import groupBy from 'lodash/groupBy'
import each from 'lodash/each'
import map from 'lodash/map'
import API from 'api'
import moment from 'moment'

import { FormattedMessage } from 'react-intl'
import DateFormat from './../helpers/DateFormat'
import Pagination from 'react-js-pagination'
import OperatorSwitch from './OperatorSwitch'
import PlaceholderTrips from './PlaceholderTrips'
import { Row } from './dates-view'
import { paginationHook } from './hooks'
import NoSearchResults from './NoSearchResults'
import { convertCurrency } from '../helpers/PriceConverter'
import { trackEcommerce } from 'gtm'
import { roundPrice } from 'utils'

let controller
let signal

const eCommerceListName = 'Travel Vacations - Liveaboard Listing (trips)'
const PAGE_SIZE = 50

const Loader = () => {
  return useMemo(() => {
    return (
      <Fragment>
        {[...new Array(10)].map((_, i) => {
          return <PlaceholderTrips key={i} />
        })}
      </Fragment>
    )
  }, [])
}

export default function TripsComponent({
  selectedParams,
  model,
  pk,
  numberOfTrips,
  setNumberOfItems,
  resortDetails,
  insurance,
  isWidget,
}) {
  const { page, setPage } = paginationHook()
  const [isLoading, setLoading] = useState(true)
  const [trips, setTrips] = useState([])
  const tripContainerRef = useRef()

  useEffect(() => {
    getTrips(page)
    return () => controller && controller.abort()
  }, [selectedParams, page])

  const getTrips = (pageNumber) => {
    if('AbortController' in window) {
      controller = new window.AbortController()
      signal = controller.signal
    }

    setLoading(true)
    return API(`search/${model.toLowerCase()}/${pk}/trips/`, signal)
      .get({
        infinity_fx35: true,
        page: pageNumber || page,
        page_size: PAGE_SIZE,
        ...selectedParams,
      })
      .then((trips) => {
        const impressions = []
        let groupedByDates = groupBy(trips.results, 'startDate')
        let obj = {}
        let position = 0

        each(groupedByDates, function(val, key) {
          let i = 0

          each(val, function(v, index) {
            position++
            if(!v.availableSpots) {
              i++
            } else {
              if(!obj[key]) {
                obj[key] = []
              }

              obj[key].push(v)
            }

            v.impression = {
              name: v.shopTitle,
              id: v.id,
              price: roundPrice(
                convertCurrency(v.grossPrice, v.currency, 'USD')
              ),
              brand: `${v.shopTitle}|${v.affiliateId || 'Non-PADI Operator'}`,
              category: 'Liveaboard',
              variant: `${v.title} / ${moment(v.startDate).format(
                'DD MMM'
              )} - ${moment(v.endDate).format('DD MMM')}`,
              position,
            }

            impressions.push({
              ...v.impression,
              list: eCommerceListName,
            })
          })

          if(obj[key] && obj[key][0]) {
            obj[key][0].totalSpotsSells = i
          }
        })

        setNumberOfItems(trips.count, 'numberOfTrips')
        setTrips(map(obj))
        setLoading(false)

        trackEcommerce('impression', {
          currencyCode: 'USD',
          impressions,
        })
      })
  }

  const onPageChange = (page) => {
    window.scrollTo(0, tripContainerRef.current.offsetTop)
    setPage(page)
  }

  if(isLoading) {
    return <Loader />
  }
  return (
    <div ref={tripContainerRef}>
      {!trips.length && <NoSearchResults />}
      {trips.map((dateTrip, index) => {
        return (
          <div className='date-list' key={index}>
            <div className='header'>
              <div className='date'>
                <DateFormat date={dateTrip[0].startDate} />
              </div>
              {!!dateTrip[0].totalSpotsSells && (
                <div>
                  <FormattedMessage
                    id='fully_booked_spots'
                    defaultMessage='{ spots } fully booked { spots, plural, one {trip} other {trips}} hidden'
                    values={{
                      spots: dateTrip[0].totalSpotsSells,
                    }}
                  />
                </div>
              )}
            </div>
            {dateTrip.map((trip) => (
              <Row
                trip={trip}
                key={trip.id}
                isWidget={isWidget}
                insurance={insurance}
                eCommerceListName={eCommerceListName}
              />
            ))}
          </div>
        )
      })}
      {!isLoading && !!resortDetails.quantity && (
        <OperatorSwitch {...resortDetails} />
      )}
      <div className='pagination-wrap'>
        <Pagination
          activePage={page}
          innerClass='custom-pagination'
          itemsCountPerPage={PAGE_SIZE}
          itemClassPrev='prev'
          itemClassNext='next'
          prevPageText=''
          nextPageText=''
          itemClassFirst='hidden'
          itemClassLast='hidden'
          totalItemsCount={numberOfTrips}
          pageRangeDisplayed={5}
          onChange={onPageChange}
        />
      </div>
    </div>
  )
}
