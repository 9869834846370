import { FormattedMessage } from 'react-intl'

export default function ItineraryHighlights({ description }) {
  if(!description) {
    return null
  }
  return (
    <div className='itinerary-highlights itinerary-section'>
      <h4 translate='translate'>
        <FormattedMessage
          id='itineraryHighlights'
          defaultMessage='Itinerary highlights'
        />
      </h4>
      <div
        className='description wysiwyg'
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  )
}
