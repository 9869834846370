import { FormattedMessage } from 'react-intl'
import BookingRequest from '../operator/BookingRequest'

export const LiveaboardPricingOnRequest = ({ id, title }) => {
  return (
    <div className='la-pricing-table la-pricing-table--request'>
      <div promotion-tooltip='promotion-tooltip'>
        <div className='tr thead display-flex'>
          <div className='td td-date '>
            <FormattedMessage id='start_date' defaultMessage='Start date' />
          </div>
          <div className='td td-itinerary'>
            <FormattedMessage id='itinerary' defaultMessage='Itinerary' />
          </div>
          <div className='td td-duration'>
            <FormattedMessage id='duration' defaultMessage='Duration' />
          </div>
          <div className='td td-price'>
            <FormattedMessage
              id='price_per_trip_person'
              defaultMessage='Price per trip / person'
            />
          </div>
        </div>
      </div>
      <div className='month-group'>
        <BookingRequest showText={false} shopId={id} shopTitle={title} />
      </div>
      <div className='next-prev-btns-wrapper' />
    </div>
  )
}
