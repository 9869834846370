import { useState } from 'react'
import API from 'api'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import each from 'lodash/each'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import clone from 'lodash/clone'
import { getRelativePos } from '../utils'

function bindItinerary(days) {
  if(!days.length) {
    return []
  }

  var notEmpty = filter(days, function(val) {
    return !isEmpty(val.description)
  })

  if(!notEmpty.length) {
    return []
  }

  if(notEmpty.length > 1) {
    for(var i = 0; i < notEmpty.length; i++) {
      if(i === 0) {
        notEmpty[i].dayStart = 1

        if(notEmpty[i + 1]) {
          notEmpty[i].dayFinish = notEmpty[i + 1].dayStart - 1
        }
      } else if(i !== 0 && notEmpty[i + 1]) {
        notEmpty[i].dayFinish = notEmpty[i + 1].dayStart - 1
      } else if(i !== 0 && !notEmpty[i + 1]) {
        notEmpty[i].dayFinish = days.length
      }
    }

    return notEmpty
  } else {
    return [
      {
        dayStart: 1,
        dayFinish: days.length,
        description: clone(notEmpty[0]).description,
      },
    ]
  }
}
export default function useItinerary({
  countrySlug,
  shopSlug,
  itinerarySlug,
  isCharter,
  isGroupTrip,
  tripId,
  dateFrom,
  dateTo,
  elementToScroll,
  duration,
  ref,
}) {
  const [itinerary, setItinerary] = useState({})
  const [isLoading, setLoading] = useState(true)

  function getItineraryDetails() {
    setLoading(true)
    return API(`shop/${countrySlug}/${shopSlug}/itineraries/${itinerarySlug}/`)
      .get()
      .then((itinerary) => {
        let description = {
          beforeTrip: [],
          onBoard: [],
          afterTrip: [],
        }

        each(itinerary.descriptions, (item) => {
          if(item.section === 10) {
            description.beforeTrip.push(item)
          } else if(item.section === 20) {
            description.onBoard.push(item)
          } else if(item.section === 30) {
            description.afterTrip.push(item)
          }
        })

        if(isGroupTrip) {
          itinerary.isNewModelUsed = false
          itinerary.whatsIncluded = false
          itinerary.whatsNotIncluded = false
          itinerary.paymentInformation = false
        }
        let normalizedFromDate
        if(dateFrom) {
          normalizedFromDate =
            dateFrom.indexOf('T') !== -1
              ? moment(dateFrom).utc().format('YYYY-MM-DD')
              : moment(dateFrom).format('YYYY-MM-DD')
        }

        setItinerary({
          ...itinerary,
          id: tripId || null,
          isCharter: isCharter || null,
          startDate: normalizedFromDate,
          endDate:
            dateTo ||
            (dateFrom
              ? moment(normalizedFromDate)
                .add(duration, 'd')
                .format('YYYY-MM-DD')
              : null),
          days: bindItinerary(itinerary.days),
          descriptions: description,
        })

        const scrollTo = document.querySelector(elementToScroll)
        if(scrollTo && ref.current) {
          ref.current.scrollTop = getRelativePos(scrollTo).top
        }
        setLoading(false)
        ReactTooltip.rebuild()
      })
  }
  return {
    getItineraryDetails,
    itinerary,
    isLoading,
  }
}
