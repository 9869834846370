import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import SelectedShopTitle from './SelectedShopTitle'
import { Fragment } from 'react'

export default function DiveCoursesListTitle({ isNearby, locationTitle }) {
  const selectedShopPin = useSelector((state) => state.opennedMarker)
  return (
    selectedShopPin
      ? (<SelectedShopTitle />)
      : (<Fragment>
        <h1 className='dive-shop-locator__title'>
          {isNearby
            ? (<FormattedMessage defaultMessage='PADI courses near you' />)
            : (
              <FormattedMessage
                defaultMessage='PADI courses around {location}'
                values={{ location: locationTitle }}
              />
            )
          }
        </h1>
        <div className='dive-shop-locator__description'>
          <p>
            <FormattedMessage defaultMessage='You can book your full course with Dive Shops that show “Book Online”, or you can “Buy eLearning” and contact the shop separately to book and pay for the rest of your course.' />
          </p>
        </div>
      </Fragment>)
  )
}
