import ActivityDetails from './ActivityDetails'
import { Fragment, useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Loader } from '../helpers'
import { useActivityInfo } from './searchMap/hooks/useActivityInfo'

const ActivityPopupPagination = ({
  showPrevious,
  showNext,
  activityType,
  isFirstItemSelected,
  isLastItemSelected,
}) => {
  const message = useMemo(() => {
    let text = ''
    switch (activityType) {
      case 'dive-trips':
        text = (
          <FormattedMessage
            id='next_dive_trip'
            defaultMessage='Next dive trip'
          />
        )
        break
      case 'snorkelling':
        text = (
          <FormattedMessage
            id='next_snorkeling_trips'
            defaultMessage='Next snorkeling trip'
          />
        )
        break
      case 'courses':
        text = (
          <FormattedMessage id='next_course' defaultMessage='Next course' />
        )
        break
    }
    return text
  }, [])
  return (
    <div className='activity-popup-pagination'>
      <i
        className={`left-arrow-icon font-icons ${
          isFirstItemSelected ? 'disabled' : ''
        }`}
        onClick={showPrevious}
      />
      {message}
      <i
        className={`right-arrow-icon font-icons ${
          isLastItemSelected ? 'disabled' : ''
        }`}
        onClick={showNext}
      />
    </div>
  )
}

export default function ActivityMapPopup({
  shopId,
  activityType,
  closePopup,
  selectedParams,
  isOpen,
}) {
  if(!isOpen) {
    return null
  }

  const {
    activitiesList,
    activity,
    setCurrentActivity,
    setActivity,
    currentActivity,
    isLoading,
  } = useActivityInfo(selectedParams, shopId, activityType)

  useEffect(() => {
    setActivity(activitiesList[currentActivity])
  }, [currentActivity])

  const activitiesQuantity = activitiesList.length
  const isFirstItemSelected = currentActivity <= 0
  const isLastItemSelected = currentActivity >= activitiesQuantity - 1

  const showPrevious = () => {
    setCurrentActivity(
      isFirstItemSelected ? activitiesQuantity - 1 : currentActivity - 1
    )
  }

  const showNext = () => {
    setCurrentActivity(isLastItemSelected ? 0 : currentActivity + 1)
  }
  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <div className='shop-popup-close' onClick={closePopup}>
        <span className='font-icons close-icon' />
      </div>
      <div className='dive-resort'>
        {activity && <ActivityDetails activity={activity} wishlisthLeftAlign />}
        {activitiesQuantity > 1 && (
          <ActivityPopupPagination
            showPrevious={showPrevious}
            showNext={showNext}
            activityType={activityType}
            isLastItemSelected={isLastItemSelected}
            isFirstItemSelected={isFirstItemSelected}
          />
        )}
      </div>
    </Fragment>
  )
}
