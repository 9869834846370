import styles from '../styles/MapFullSizeToggle.module.scss'
import cx from 'classnames'

export default function MapFullSizeToggle({ map }) {
  const handleClick = () => {
    document
      .querySelector('.dive-shop-locator__content')
      .classList.toggle('full-map')
    document.querySelector('.footer').classList.toggle('hidden')
    setTimeout(() => {
      if(map) {
        map.invalidateSize()
      }
    }, 301)
  }
  return (
    <div className={styles.container} onClick={handleClick}>
      <i className={cx(styles.icon, 'dsl-icons dsl-icons--arrow-expand')} />
    </div>
  )
}
