import { Fragment, useMemo } from 'react'
import propTypes from 'prop-types'
import BookableAdventureCard from './BookableAdventureCard'
import ElearingAdventureCard from './ElearingAdventureCard'
import NotAvailableActivityCard from './NotAvailableActivityCard'
import useMarkerHover from '../../hooks/useMarkerHover'

export const courseType = (trip) => {
  // Paper course card (to be displayed if following conditions are met:
  // course offered = True, course type eLearning = False (from the PADI course info table),
  // OR course offered = True, course offered as eLearning = False,
  // OR course offered = True, course offered as eLearning = True, “on PADI.com = False)
  const isElearningCardType = trip?.diveCourseInfo.isElearning

  const isElearningCard =
    !trip?.adventure &&
    trip?.isElearning &&
    trip?.onPadiCom &&
    isElearningCardType
  const isPaperCard = !trip?.adventure && !isElearningCardType
  const isPaperCard2 = !trip?.adventure && !trip?.isElearning
  const isPaperCard3 =
    !trip?.adventure && trip?.isElearning && !trip?.onPadiCom

  return {
    isElearningCard,
    isPaperCard,
    isPaperCard2,
    isPaperCard3,
  }
}
const CourseCardDsl = ({ trip }) => {
  const { setActiveMarker } = useMarkerHover()
  const {
    adventure,
    diveCourseInfo,
    activityPage,
    shopTitle,
    shopUrl,
    shopCountry,
    shopLocation,
    shopId,
    shopEmail,
    affiliateId,
  } = trip
  const highlightPinOnMap = () => setActiveMarker(shopId)
  const removeHighlight = () => setActiveMarker(false)

  const {
    isElearningCard,
    isPaperCard,
    isPaperCard2,
    isPaperCard3,
  } = useMemo(() => courseType(trip), [trip])

  return (
    <Fragment>
      {adventure && (
        <BookableAdventureCard
          activityPage={activityPage}
          item={trip}
          highlightPinOnMap={highlightPinOnMap}
          removeHighlight={removeHighlight}
        />
      )}
      {isElearningCard && (
        <ElearingAdventureCard
          activityPage={activityPage}
          diveCourseInfo={diveCourseInfo}
          shopTitle={shopTitle}
          shopUrl={shopUrl}
          shopCountry={shopCountry}
          shopLocation={shopLocation}
          highlightPinOnMap={highlightPinOnMap}
          removeHighlight={removeHighlight}
        />
      )}
      {(isPaperCard || isPaperCard2 || isPaperCard3) && (
        <NotAvailableActivityCard
          shopId={shopId}
          shopLocation={shopLocation}
          shopCountry={shopCountry}
          shopEmail={shopEmail}
          affiliateId={affiliateId}
          diveCourseInfo={diveCourseInfo}
          shopTitle={shopTitle}
          shopUrl={shopUrl}
          highlightPinOnMap={highlightPinOnMap}
          removeHighlight={removeHighlight}
        />
      )}
    </Fragment>
  )
}

export default CourseCardDsl

CourseCardDsl.propTypes = {
  trip: propTypes.shape({
    adventure: propTypes.bool,
    diveCourseInfo: propTypes.shape({
      isElearning: propTypes.bool,
    }),
    activityPage: propTypes.string,
    shopTitle: propTypes.string,
    shopUrl: propTypes.string,
    shopCountry: propTypes.string,
    shopLocation: propTypes.string,
    shopId: propTypes.string,
    shopEmail: propTypes.string,
    affiliateId: propTypes.string,
  }),
}
