/* eslint-disable react/no-did-update-set-state */
import { useEffect, useRef, useState, useMemo } from 'react'
import get from 'lodash/get'
import API from '../../api'
import { getOffset } from 'utils'
import groupBy from 'lodash/groupBy'
import each from 'lodash/each'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import { getProductCategory } from '../dive-guides/gtm-events'
import { convertCurrency } from '../helpers/PriceConverter'
import { paginationHook } from './hooks'
import ActivityComponent from './ActivityComponent'
import ActivityDateSorted from './ActivityDateSorted'
import Pagination from 'react-js-pagination'
import { trackGA4Ecommerce } from '../../gtm'

const ACTIVITY_TYPES = {
  'dive-trips': 'dive_trip',
  courses: 'course',
}
let controller
let signal

export default function ActivityList({
  model,
  pk,
  point,
  gPlacePoint,
  distance,
  activityType,
  selectedCurrency,
  selectedParams,
  setNumberOfItems,
  numberOfTrips,
  isDiveCenter,
  isDateView,
}) {
  const {
    page,
    setPage,
  } = paginationHook()
  const [isLoading, setLoading] = useState(true)
  const [shops, setShops] = useState([])
  const [sortedByDate, setSortedByDate] = useState([])
  const tripContainerRef = useRef()

  const PAGE_SIZE = useMemo(() => (isDateView ? 50 : 10), [isDateView])

  useEffect(() => {
    getTrips(page)
    return () => controller && controller.abort()
  }, [selectedParams, page])

  const getTrips = () => {
    if('AbortController' in window) {
      controller = new window.AbortController()
      signal = controller.signal
    }
    setLoading(true)
    let fakeModel, fakePk
    const isGPlace = model.toLowerCase() === 'gplace'

    if(isGPlace) {
      fakeModel = 'continent'
      fakePk = 'all'
    }

    return API(
      `/api/adventure/v1/search/${fakeModel || model.toLowerCase()}/${
        fakePk || pk
      }/${activityType}/`,
      signal,
      null,
      true,
      selectedCurrency,
    )
      .get({
        ...selectedParams,
        point:
          isGPlace && !point && gPlacePoint
            ? `${gPlacePoint.lat},${gPlacePoint.lng}`
            : point,
        distance: isGPlace ? '50000' : distance,
        page,
        page_size: PAGE_SIZE,
      })
      .then((data) => {
        const shops = data.results
        setNumberOfItems(data.count, 'numberOfTrips')
        let obj = {}

        if(activityType !== 'dive-centers') {
          // TODO: will be removed when analytics for DC will be added
          const items = []

          const {
            type,
            productList,
          } = getProductCategory(activityType)
          shops.forEach((product, index) => {
            const {
              price,
              currency,
            } = product.price
            product.viewItem = {
              item_name: product.titleEn || product.title,
              item_id: product.activityId.toString(),
              price: Number(convertCurrency(
                price,
                currency,
                'USD',
              ).toFixed(2)),
              item_brand: 'PADI',
              item_category: 'PADI Travel',
              item_category2: type,
              item_list_name: productList,
              index: index + 1,
            }
            items.push({
              ...product.viewItem,
            })
          })
          trackGA4Ecommerce('view_item_list', {
            currency: 'USD',
            items,
          }, 'PADI Travel - Adventures')
        }
        if(isDateView) {
          const groupedByDates = groupBy(orderBy(shops, 'date'), 'date')
          each(groupedByDates, function(val, key) {
            each(val, function(v) {
              if(!obj[key]) {
                obj[key] = []
              }

              let durationHours = get(v, 'duration.duration')
              const durationType = get(v, 'duration.durationType.value')

              if(durationType !== 10) {
                // Convert days to hours
                durationHours = durationHours * 24
              }
              // TODO: Sometimes backend will change API response. Maybe...
              obj[key].push({
                activityType: ACTIVITY_TYPES[activityType],
                ...v,
                availableDate: v.date,
                compareAtPrice: {
                  originCurrency: v.compareAtPrice.currency,
                  originValue: v.compareAtPrice.price,
                },
                minimumPrice: {
                  originCurrency: v.price.currency,
                  originValue: v.price.price,
                },
                diveCenterTitle: v.diveCenter.title,
                durationHours,
              })
            })
          })
          setSortedByDate(map(obj))
        } else {
          setShops(shops)
        }
        setLoading(false)
      })
      .catch((error) => {
        if(
          window.DOMException &&
          error.code === window.DOMException.ABORT_ERR
        ) {
          console.warn('Request canceled.')
        } else if(
          error.status === 404 &&
          get(error, 'error.detail') === 'Invalid page.'
        ) {
          onPageChange(1)
        } else {
          setSortedByDate([])
          setShops([])
          setLoading(false)
        }
      })
  }

  const onPageChange = (page) => {
    if(tripContainerRef.current) {
      window.scrollTo(0, getOffset(tripContainerRef.current).top - 50)
    }
    setPage(page)
  }

  return (
    <div ref={tripContainerRef}>
      {isDateView ? (
        <ActivityDateSorted items={sortedByDate} loading={isLoading} />
      ) : (
        <ActivityComponent
          loading={isLoading}
          items={shops}
          isDiveCenter={isDiveCenter}
          activityType={activityType}
        />
      )}
      {numberOfTrips > PAGE_SIZE && (
        <div className='pagination-wrap'>
          <Pagination
            activePage={page}
            innerClass='custom-pagination'
            itemsCountPerPage={PAGE_SIZE}
            itemClassPrev='prev'
            itemClassNext='next'
            prevPageText=''
            nextPageText=''
            itemClassFirst='hidden'
            itemClassLast='hidden'
            totalItemsCount={numberOfTrips}
            pageRangeDisplayed={5}
            onChange={onPageChange}
          />
        </div>
      )}
    </div>
  )
}
