import ActivityMapPopup from '../ActivityMapPopup'
import DcMapPopup from './DcMapPopup.js'
import ResortMapPopup from './hooks/ResortMapPopup'
import usePreventMapEventPropagation from './hooks/usePreventMapEventPropagation'

export default function MapPopup(props) {
  const { element } = usePreventMapEventPropagation()
  const { activityType, isActivityEndpoint, isOpen } = props
  if(!isOpen) {
    return null
  }

  let popup = <ResortMapPopup {...props} />
  if(isActivityEndpoint) {
    if(activityType === 'dive-centers') {
      popup = <DcMapPopup {...props} />
    } else {
      popup = <ActivityMapPopup {...props} />
    }
  }
  return (
    <div className='shop-info-popup' ref={element}>
      {popup}
    </div>
  )
}
