import { useEffect, useState, useRef } from 'react'
import moment from 'moment/moment'
import ReactTooltip from 'react-tooltip'
import API from 'api'
import { roundPrice } from 'utils'
import { convertCurrency } from '../../helpers'
import { trackGA4Ecommerce } from '../../../gtm'

const PAGE_SIZE = 4

export const eCommerceListName = 'Travel Vacations - Liveaboard Listing'

export default function useBoatsList(model, modelId, id, shopTitle, shopAffiliateId, selectedParams) {
  const isMounted = useRef(false)
  const [trips, setTrips] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [departureDateLess, setDepartureDateLess] = useState(false)
  const [page, setPage] = useState(1)

  const getTrips = () => {
    setLoading(true)

    let filters = {
      ...selectedParams,
      page,
      page_size: PAGE_SIZE,
    }

    if(departureDateLess) {
      filters.departure_date_less_then = selectedParams.departure_date
      filters.departure_date = undefined
    } else {
      filters.departure_date = selectedParams.departure_date
    }

    return API(
      `search/liveaboards/${id}/${model}/${modelId}/trips/`,
    )
      .get(filters)
      .then(({ results }) => {
        const viewItemsList = []
        if(departureDateLess && results) {
          results.reverse()
        }
        results.forEach((trip, index) => {
          const startDate = moment(trip.startDate).utc()
          trip.viewItem = {
            item_id: trip.id.toString(),
            item_name: shopTitle,
            item_variant: `${trip.title} / ${startDate.format(
              'DD MMM',
            )} - ${startDate.add(trip.duration, 'days').format('DD MMM')}`,
            item_brand: 'PADI',
            item_category: 'PADI Travel',
            item_category2: 'Liveaboard',
            item_list_name: eCommerceListName,
            index: index + 1,
            quantity: 1,
            price: Number(roundPrice(convertCurrency(trip.grossPrice, trip.currency, 'USD'))),
          }
          viewItemsList.push(trip.viewItem)
        })

        trackGA4Ecommerce('view_item_list', {
          currency: 'USD',
          items: viewItemsList,
        }, 'PADI Travel - Liveaboards')
        if(isMounted) {
          setLoading(false)
          setTrips(results)
          ReactTooltip.rebuild()
        }
      })
  }

  useEffect(() => {
    isMounted.current = true
    getTrips()
    return () => {
      isMounted.current = false
    }
  }, [page, departureDateLess])
  return {
    trips,
    isLoading,
    isMounted: isMounted.current,
    setPage,
    setDepartureDateLess,
    departureDateLess,
    page,
  }
}
