import { Marker } from 'react-leaflet'
import {
  dslShopPin,
  dslShopPinSelected,
  eLearningOnlyPin,
  eLearningOnlyPinSelected,
} from '../../../config'
import useMarkerHover from '../hooks/useMarkerHover'
import { memo } from 'react'
import isEqual from 'lodash/isEqual'
import usePinInfo from '../hooks/usePinInfo'
import { useDispatch } from 'react-redux'
import { setIsMapVisible } from '../../../actions/dive-guide-actions'
import PropTypes from 'prop-types'

const getIcon = (pin, activeMarker) => {
  if(pin.hasBookableCourses) {
    return activeMarker === pin.id ? dslShopPinSelected : dslShopPin
  } else {
    return activeMarker === pin.id
      ? eLearningOnlyPinSelected
      : eLearningOnlyPin
  }
}

function Markers({ pins, pageType }) {
  const dispatch = useDispatch()
  const {
    activeMarker,
    handleMouseOut,
    handleMouseOver,
    handleClick,
    opennedMarker,
  } = useMarkerHover(pageType)

  usePinInfo(opennedMarker, 'dive-shops', true)

  const courseMarkerClick = (e) => {
    dispatch(setIsMapVisible(false))
    const map = document.getElementById('dsl-map')
    if(map) {
      map.classList.remove('visible')
      document.body.classList.toggle('body-hidden-mobile')
    }
    handleClick(e)
  }

  if(!Array.isArray(pins)) {
    console.error('Pins is not an array', pins)
    return null
  }

  return pins?.map((pin) => {
    return (
      <Marker
        key={pin.id}
        id={pin.id}
        icon={getIcon(pin, activeMarker)}
        position={[pin.latitude, pin.longitude]}
        eventHandlers={{
          mouseover: handleMouseOver,
          mouseout: handleMouseOut,
          click: courseMarkerClick,
        }}
        zIndexOffset={activeMarker === pin.id ? 1000 : 0}
        riseOnHover
      />
    )
  })
}

const compare = (prevProps, nextProps) => {
  if(!isEqual(prevProps.pins, nextProps.pins)) {
    return false
  }
  return true
}

export default memo(Markers, compare)

Markers.propTypes = {
  pins: PropTypes.array,
  pageType: PropTypes.string,
}
