/* eslint-disable  indent */
import { Fragment, useRef, useMemo, useState, useEffect } from 'react'
import clone from 'lodash/clone'
import setQuery from 'set-query-string'
import API from 'api'
import { roundPrice } from 'utils'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import each from 'lodash/each'
import get from 'lodash/get'
import { defineMessages, useIntl } from 'react-intl'
import Pagination from 'react-js-pagination'
import SellingPropositionDr from './SellingPropositionDr'
import OperatorSwitchDr from './OperatorSwitchDr'

import PlaceholderResort from './PlaceholderResort'
import ShopItem from './ShopItem'
import paginationHook from '../search/hooks/paginationHook'
import NoSearchResults from '../search/NoSearchResults'
import PropTypes from 'prop-types'
import { trackGA4Ecommerce } from '../../gtm'

const PAGE_SIZE = 10

const msg = defineMessages({
  customizeDives: {
    id: 'tooltip.customize_dives',
    defaultMessage:
      'You can customize the number of dives for each diver during the checkout process. The package price will be adjusted dynamically',
  },
  warningDatesNotExact: {
    id: 'warningDatesNotExact',
    defaultMessage:
      'Dates are not an exact match with your search criteria. Only specific start dates are possible. Go to the resort page and open the date picker to see which ones.',
  },
  warningDurationNotExact: {
    id: 'warningDurationNotExact',
    defaultMessage:
      'Duration is not an exact match with your search criteria. Only specific durations are possible. Go to the resort page and open the date picker to see which ones.',
  },
  warningDatesAndDurationNotExact: {
    id: 'warningDatesAndDurationNotExact',
    defaultMessage:
      'Dates & duration are not an exact match with your search criteria. Only specific start dates & durations are possible. Go to the resort page and open the date picker to see which ones.',
  },
})

let controller
let signal

const eCommerceListName = 'PADI Travel Dive Resorts - Listing'

const packageWarningMsg = (intl, shop, selectedParams) => {
  const resortDateStart = moment(shop.dateStart).utc().format('YYYY-MM-DD')
  // Date start are the same but duration is not equal
  if(
    resortDateStart !== selectedParams.dateStart &&
    shop.nights === +selectedParams.nights
  ) {
    shop.errorMsg = intl.formatMessage(msg.warningDatesNotExact)
    // Date start is not the same but duration is equal
  } else if(
    shop.nights !== +selectedParams.nights &&
    resortDateStart === selectedParams.dateStart
  ) {
    shop.errorMsg = intl.formatMessage(msg.warningDurationNotExact)
    // Date start and duration not equal
  } else if(
    shop.nights !== +selectedParams.nights &&
    resortDateStart !== selectedParams.dateStart
  ) {
    shop.errorMsg = intl.formatMessage(msg.warningDatesAndDurationNotExact)
  }
  if(shop.errorMsg) {
    if(!shop.unmutableURL) {
      shop.unmutableURL = clone(shop.url)
    }

    shop.url =
      shop.unmutableURL +
      '?date_from=' +
      resortDateStart +
      '&duration=' +
      shop.nights
  }

  return shop
}

const Loader = () => {
  return useMemo(
    () =>
      [...new Array(10)].map((_, i) => {
        return <PlaceholderResort key={i} />
      }),
    [],
  )
}

export default function ResortComponent({
  model,
  pk,
  selectedParams,
  setNumberOfItems,
  totalGuests = 1,
  isAllParamsSelected,
  numberOfTrips,
  liveaboardDetails,
  parentElement,
  isWidget,
}) {
  const intl = useIntl()
  const { page, setPage } = paginationHook()
  const [isLoading, setLoading] = useState(true)
  const [shops, setShops] = useState([])
  const tripContainerRef = useRef()

  const getTrips = () => {
    setLoading(true)
    const highlightSlug = window.highlightedShopSlug
      ? `${window.highlightedShopSlug}/`
      : ''

    if('AbortController' in window) {
      controller = new window.AbortController()
      signal = controller.signal
    }
    return API(
      `search/${model.toLowerCase()}/${pk}/resorts/${highlightSlug}`,
      signal,
    )
      .get({
        page_size: PAGE_SIZE,
        ...selectedParams,
        page,
      })
      .then((data) => {
        const items = []
        setNumberOfItems(data.count, 'numberOfTrips')
        each(data.results, (shop, index) => {
          if(shop.dateStart) {
            shop.dateEnd = moment(shop.dateStart)
              .utc()
              .add(shop.nights, 'd')
              .format('YYYY-MM-DD')
            shop = packageWarningMsg(intl, shop, selectedParams)
          }

          shop.viewItem = {
            item_name: shop.title,
            item_id: shop.shopId.toString(),
            price: Number(roundPrice(shop.priceSum / totalGuests)),
            item_brand: 'PADI',
            item_category: 'PADI Travel',
            item_category2: 'Dive Resort',
            item_category3: '',
            item_category4: '',
            item_category5: '',
            rate_type: shop.mealPlanTitle,
            item_variant: shop.diveCenterTitle,
            index: index + 1,
            item_list_name: eCommerceListName,
          }
          items.push(shop.viewItem)
        })
        setShops(data.results)
        setLoading(false)
        ReactTooltip.rebuild()
        trackGA4Ecommerce(
          'view_item_list',
          {
            currency: 'USD',
            items,
          },
          'PADI Travel - Dive Resorts',
        )
      })
      .catch((error) => {
        if(
          error.status === 404 &&
          get(error, 'error.detail') === 'Invalid page.'
        ) {
          onPageChange(1)
        } else if(
          window.DOMException &&
          error.code === window.DOMException.ABORT_ERR
        ) {
          console.warn(error.code)
        } else {
          setShops([])
          setLoading(false)
        }
      })
  }
  const onPageChange = (page) => {
    if(tripContainerRef.current) {
      window.scrollTo(0, tripContainerRef.current.offsetTop)
    }
    setQuery({ page })
    setPage(page)
  }

  useEffect(() => {
    getTrips()
    return () => controller && controller.abort()
  }, [selectedParams, page])

  if(isLoading) {
    return <Loader />
  }

  return (
    <div ref={tripContainerRef} className='results'>
      <ReactTooltip effect='solid' id='global' />
      {!shops.length && <NoSearchResults />}
      {shops.map((shop, index) => {
        const showBanner =
          index === 2 || (shops.length < 3 && shops.length === index + 1)
        return (
          <Fragment key={shop.shopId}>
            <ShopItem
              shop={shop}
              selectedParams={selectedParams}
              isWidget={isWidget}
              isAllParamsSelected={isAllParamsSelected}
              totalGuests={totalGuests}
              eCommerceListName={eCommerceListName}
            />
            {!parentElement && showBanner && <SellingPropositionDr />}
          </Fragment>
        )
      })}
      {!isLoading && !!liveaboardDetails.quantity && (
        <OperatorSwitchDr {...liveaboardDetails} />
      )}
      {numberOfTrips > 10 && (
        <div className='pagination-wrap'>
          <Pagination
            activePage={page}
            innerClass='custom-pagination'
            itemsCountPerPage={PAGE_SIZE}
            itemClassPrev='prev'
            itemClassNext='next'
            prevPageText=''
            nextPageText=''
            itemClassFirst='hidden'
            itemClassLast='hidden'
            totalItemsCount={numberOfTrips}
            pageRangeDisplayed={5}
            onChange={onPageChange}
          />
        </div>
      )}
    </div>
  )
}

ResortComponent.propTypes = {
  model: PropTypes.string,
  pk: PropTypes.string,
  selectedParams: PropTypes.object,
  setNumberOfItems: PropTypes.func,
  totalGuests: PropTypes.number,
  isAllParamsSelected: PropTypes.number,
  numberOfTrips: PropTypes.number,
  liveaboardDetails: PropTypes.object,
  parentElement: PropTypes.string,
  isWidget: PropTypes.bool,
}
