import { Fragment, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { generateQueryForTrip, setGuestParams, target } from 'utils'
import PropTypes from 'prop-types'
import { createUrl } from '../../localized-urls'
import {
  showItineraryPopup,
  setItineraryData,
} from '../../actions/main-actions'
import { FormattedMessage } from 'react-intl'
import Loader from '../helpers/Loader'
import moment from 'moment'
import { NoTrips, TripRow } from './la-trip'
import useBoatsList from './hooks/useBoatsList'

const checkPreviousTrips = (date) => {
  return moment(date).startOf('month') > moment().startOf('month')
}

BoatTrips.propTypes = {
  id: PropTypes.number.isRequired,
  model: PropTypes.string.isRequired,
  modelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  selectedParams: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  shopSlug: PropTypes.string.isRequired,
  countrySlug: PropTypes.string.isRequired,
  isWidget: PropTypes.bool,
  insurance: PropTypes.object,
  showItineraryPopup: PropTypes.func.isRequired,
  setItineraryData: PropTypes.func.isRequired,
  shopTitle: PropTypes.string.isRequired,
  shopAffiliateId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

function BoatTrips({
  id,
  model,
  modelId,
  selectedParams,
  currency,
  shopSlug,
  countrySlug,
  isWidget,
  insurance,
  showItineraryPopup,
  setItineraryData,
  shopTitle,
  shopAffiliateId,
}) {
  const {
    trips,
    isLoading,
    setPage,
    setDepartureDateLess,
    departureDateLess,
    page,
  } = useBoatsList(
    model,
    modelId,
    id,
    shopTitle,
    shopAffiliateId,
    selectedParams,
  )

  const showButtons = useMemo(() => trips.count || trips.previousTrips, [
    trips.count,
    trips.previousTrips,
  ])
  const showPrevButton = useMemo(() => {
    return (
      (departureDateLess && trips.next) ||
      (!departureDateLess &&
        (trips.previous || checkPreviousTrips(selectedParams.departure_date)))
    )
  }, [
    trips.next,
    trips.previous,
    departureDateLess,
    selectedParams.departure_date,
  ])
  const showNextButton = useMemo(
    () => departureDateLess || (!departureDateLess && trips.next),
    [trips.next, departureDateLess],
  )

  const onPageChange = (next) => {
    if(isLoading) {
      return false
    }
    let currentPage
    if(departureDateLess) {
      currentPage = next ? page - 1 : page + 1
    } else {
      currentPage = next ? page + 1 : page - 1
    }

    if(currentPage < 1) {
      currentPage = 1
      if(
        !trips.previous &&
        checkPreviousTrips(selectedParams.departure_date)
      ) {
        setDepartureDateLess(!departureDateLess)
      }
    } else {
      setDepartureDateLess(departureDateLess)
    }
    setPage(currentPage)
  }
  const showItinerary = useCallback((trip) => {
    handleGA4Event(trip)
    setItineraryData({
      isCharter: trip.isCharter,
      itinerarySlug: trip.itinerarySlug,
      tripId: trip.id,
      dateFrom: trip.startDate,
      dateTo: trip.endDate,
      duration: trip.duration,
      currency,
      shopSlug,
      countrySlug,
      insurance,
    })
    showItineraryPopup(true)
  }, [])

  const handleClick = useCallback((trip) => {
    setGuestParams(selectedParams)
    handleGA4Event(trip)
    window.open(
      createUrl(
        'la_page',
        {
          location: countrySlug,
          slug: shopSlug,
        },
        generateQueryForTrip(trip, isWidget),
      ),
      target,
    )
  }, [])

  const handleGA4Event = (trip) => {
    if(trip.viewItem) {
      window.dataLayer.push({
        event: 'select_item',
        padi_checkout_name: 'PADI Travel - Liveaboards',
        ecommerce: {
          currency: 'USD',
          items: [trip.viewItem],
        },
      })
    }
  }

  const prevClick = () => onPageChange(false)
  const nextClick = () => onPageChange(true)

  return (
    <Fragment>
      <div className={`pricing${isLoading ? ' loading' : ''}`}>
        <Loader />
        <ReactTooltip effect='solid' className='react-small-tooltip' />
        {!trips.length && !isLoading && (
          <NoTrips
            departureDate={selectedParams.departure_date}
            previousTrips={selectedParams.previousTrips}
          />
        )}
        {trips.map((trip) => (
          <TripRow
            key={trip.id}
            trip={trip}
            selectedParams={selectedParams}
            handleClick={handleClick}
            currency={currency}
            showItinerary={showItinerary}
          />
        ))}
      </div>
      {!!showButtons && (
        <div className='next-month'>
          {showPrevButton && (
            <div className='prev' onClick={prevClick}>
              <i className='font-icons left-arrow-icon' />
              <span className='visible-xs'>
                <FormattedMessage id='previous' defaultMessage='Previous' />
              </span>
              <span className='hidden-xs'>
                <FormattedMessage
                  id='prev_4_trips'
                  defaultMessage='Previous 4 trips'
                />
              </span>
            </div>
          )}
          {showNextButton && (
            <div className='next' onClick={nextClick}>
              <span className='visible-xs'>
                <FormattedMessage id='next' defaultMessage='Next' />
              </span>
              <span className='hidden-xs'>
                <FormattedMessage
                  id='next_4_trips'
                  defaultMessage='Next 4 trips'
                />
              </span>
              <i className='font-icons right-arrow-icon' />
            </div>
          )}
        </div>
      )}
    </Fragment>
  )
}

export default connect(null, {
  showItineraryPopup,
  setItineraryData,
})(BoatTrips)
