import { FormattedMessage } from 'react-intl'

export default function TrustPilotBlock({ trustpilotLocale }) {
  return (
    <div className='shadow-block'>
      <p className='title text-center'>
        <FormattedMessage
          id='padi_traveler_reviews'
          defaultMessage='PADI travel reviews'
        />
      </p>
      <div className='trust-pilot text-center'>
        <div
          className='trustpilot-widget'
          data-locale={trustpilotLocale}
          data-template-id='53aa8912dec7e10d38f59f36'
          data-businessunit-id='55eeddbb0000ff00058313b3'
          data-style-height='140px'
          data-style-width='100%'
          data-theme='light'
          data-stars='5'
          data-schema-type='Organization'
        >
          <a
            href='https://www.trustpilot.com/review/travel.padi.com'
            target='_blank'
          >
            Trustpilot
          </a>
        </div>
      </div>
    </div>
  )
}
