import { Fragment, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styles from '../styles/ShopList.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setIsMapVisible } from '../../../actions/dive-guide-actions'

export default function MapToggleButton() {
  const dispatch = useDispatch()
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const isMapVisible = useSelector((state) => state.isMapVisible)

  const onClick = () => {
    const map = document.getElementById('dsl-map')
    if(map) {
      map.classList.toggle('visible')
      document.body.classList.toggle('body-hidden-mobile')
      dispatch(setIsMapVisible(!isMapVisible))
    }
  }
  const toggleMenu = () => {
    document.getElementById('dsl-nav').classList.toggle('active')
    setIsMenuVisible((val) => !val)
  }

  useEffect(() => {
    document.body.classList.toggle('body-hidden-mobile')
  }, [])
  return (
    <div className={styles.buttonContainer}>
      <button
        onClick={toggleMenu}
        className={styles.toggleButton}
        type='button'
      >
        <i className='dsl-icons dsl-icons--dots-vertical ' />
        {isMenuVisible ? (
          <FormattedMessage id='hide_menu' defaultMessage='Close menu' />
        ) : (
          <FormattedMessage id='view_menu' defaultMessage='View Menu' />
        )}
      </button>
      <button className={styles.toggleButton} id='toggle-btn' onClick={onClick}>
        {isMapVisible ? (
          <Fragment>
            <i className='dsl-icons dsl-icons--list' />
            <FormattedMessage id='view_list' defaultMessage='View List' />
          </Fragment>
        ) : (
          <Fragment>
            <i className='dsl-icons dsl-icons--map' />
            <FormattedMessage id='view_map' defaultMessage='View map' />
          </Fragment>
        )}
      </button>
    </div>
  )
}
