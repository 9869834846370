import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import ShopSlider from './ShopSlider'
import EcoLabels from '../resort/EcoLabels'
import PriceConverter from './../helpers/PriceConverter'
import get from 'lodash/get'
import AvailableDates from '../adventures/AvailableDate'
import InstantConfirmation from '../adventures/InstantConfirmation'
import ActivityDuration from './ActivityDuration'
import { useMemo } from 'react'
import { ActivityInclusionsSearchPage } from '../adventures/ActivityInclusions'
import DiveSitesList from '../adventures/DiveSitesList'
import { target, generateQueryForWidget } from 'utils'
import { WishlistButton } from '../helpers'
import { AdventureOriginalTitle } from '../adventures'
import { trackGA4Ecommerce } from '../../gtm'

const DiveSites = ({ activity }) => {
  const { diveSiteType, sites } = activity
  const type = get(diveSiteType, 'value')
  const dsLength = get(sites, 'length', 0)
  return useMemo(() => {
    let template = ''
    if(type === 10) {
      template = (
        <p>
          <i className='font-icons icon-dive-sites' />
          <FormattedMessage
            id='dive_site_determinated'
            defaultMessage='Dive site to be determined on dive day'
          />
        </p>
      )
    } else if(dsLength) {
      template = (
        <p>
          <i className='font-icons icon-dive-sites' />
          <DiveSitesList sites={sites} />
        </p>
      )
    }
    return template
  }, [sites, diveSiteType])
}

function ActivityDetails({
  activity,
  activityType,
  wishlisthLeftAlign,
}) {
  const handleClick = (e) => {
    trackGA4Ecommerce('select_item', {
      currency: 'USD',
      items: [activity.viewItem],
    }, 'PADI Travel - Adventures')
    window.open(generateQueryForWidget(activity.activityPage), target)
  }
  const { compareAtPrice, price, duration } = activity
  const ecoLabelsCount = get(activity, 'diveCenter.ecoLabels.length', 0)
  const isCourse = activityType === 'courses'
  const isSnorkelling = activityType === 'snorkelling'
  return (
    <div className='dive-resort-content'>
      <div className='photo-slider'>
        <ShopSlider
          handleClick={handleClick}
          shopUrl={activity.activityPage}
          apiUrl={`/api/adventure/v1/${activity.activityId}/images/`}
        />
        <WishlistButton
          isInWishlist={activity.isInWishlist}
          type='Activity'
          id={activity.activityId}
          wishlisthLeftAlign={wishlisthLeftAlign}
        />
      </div>
      <div onClick={handleClick} className='resort-info resort-info--activity'>
        <div className='activity-wrapper'>
          <div className='activity-left-part'>
            <p className='resort-title activity-title'>
              {activity.title}
              <AdventureOriginalTitle
                originalTitle={activity.originalTitle}
                title={activity.title}
              />
            </p>
            <div className='amenities'>
              {!isCourse && <DiveSites activity={activity} />}
              {activity.isAdvanced && (
                <p className='red-text'>
                  <i className='font-icons icon-warning' />
                  <FormattedMessage
                    id='advanced_divers_only'
                    defaultMessage='Advanced divers only'
                  />
                </p>
              )}
            </div>
            <div
              className={`dc-info amenities amenities-bottom ${
                ecoLabelsCount > 2 ? 'with-eco-labels' : ''
              }`}
            >
              <strong className='dc-title'>{activity.diveCenter.title}</strong>
              <p>
                <i className='font-icons location-icon' />
                {activity.diveCenter.locationTitle}
              </p>
              {activity.diveCenter.starLabel && (
                <p>
                  <i className='padi-globe' />
                  {activity.diveCenter.starLabel}
                </p>
              )}
              {!!ecoLabelsCount && (
                <div className='eco-labels'>
                  <EcoLabels labelsList={activity.diveCenter.ecoLabels} />
                </div>
              )}
            </div>
          </div>
          <div className='activity-right-part'>
            <div>
              <div className='activity-wrapper-for-mobile'>
                <div className='activity-pricing'>
                  {compareAtPrice && compareAtPrice.price > price.price ? (
                    <p className='activity-old-price'>
                      <PriceConverter
                        amount={compareAtPrice.price}
                        currency={compareAtPrice.currency}
                        conversionPercent
                      />
                    </p>
                  ) : (
                    ''
                  )}
                  <div className='activity-cur-price'>
                    <small>
                      <FormattedMessage id='from' defaultMessage='From' />
                    </small>
                    <strong className='current-price-wrapper'>
                      {activity.instantConfirmation && <InstantConfirmation />}
                      <span className='current-price'>
                        <PriceConverter
                          amount={price.price}
                          currency={price.currency}
                          conversionPercent
                        />
                      </span>
                    </strong>
                  </div>
                </div>
                <div className='activity-mobile-details'>
                  <p className='activity-inclusion'>
                    <ActivityInclusionsSearchPage
                      item={activity}
                      activityType={activityType}
                    />
                  </p>
                  <p className='available-date'>
                    <AvailableDates date={activity.date} />
                  </p>
                </div>
              </div>
              <button className='btn-red activity-btn'>
                <FormattedMessage
                  id='view_details'
                  defaultMessage='View Details'
                />
              </button>
              <div className='activity-inclusions-wrapper'>
                {!isSnorkelling && !!activity.numberOfDives && (
                  <div>
                    <p className='activity-inclusion'>
                      <i className='font-icons check-icon' />
                      <FormattedMessage
                        id='activity.numderOfDives'
                        values={{
                          numberOfDives: activity.numberOfDives,
                        }}
                        defaultMessage='Incl. { numberOfDives, plural, one {# dive} other {# dives}}'
                      />
                    </p>
                    {duration && (
                      <p className='activity-inclusion'>
                        <i className='font-icons check-icon' />
                        <ActivityDuration
                          duration={duration.duration}
                          durationType={duration.durationType}
                        />
                      </p>
                    )}
                  </div>
                )}
                {activity.cancellationPolicy && (
                  <p className='activity-inclusion'>
                    <i className='font-icons check-icon' />
                    <span>
                      {activity.cancellationPolicy.title}
                      {activity.cancellationPolicy.value !== 10 && (
                        <FormattedMessage
                          id='free_cancellation'
                          defaultMessage='- free cancellation'
                        />
                      )}
                    </span>
                  </p>
                )}
                <p className='available-date'>
                  <AvailableDates date={activity.date} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect((state) => ({
  selectedCurrency: state.selectedCurrency,
}))(ActivityDetails)
