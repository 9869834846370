import cx from 'classnames'
import styles from './CourseButton.module.scss'

export default function CourseButton({
  text,
  className,
  type,
  onClick,
}) {
  return (
    <button onClick={onClick}
            className={cx(styles.btn, className, {
              [styles.notAvailable]: type === 'notAvailable',
              [styles.bookable]: type === 'bookable',
              [styles.elearning]: type === 'elearning',
            })}
    >
      {text}
    </button>
  )
}
