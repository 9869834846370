import CardHeader from './CardHeader'
import styles from './Cards.module.scss'
import { FormattedMessage } from 'react-intl'
import CourseButton from './CourseButton'
import { PriceConverter } from '../../../helpers'

import PropTypes from 'prop-types'
import { target } from '../../../../utils'
import cx from 'classnames'
import { pushToDataLayer } from '../../../../gtm'

export default function ElearingAdventureCard({
  diveCourseInfo,
  shopTitle,
  shopUrl,
  activityPage,
  shopCountry,
  shopLocation,
  highlightPinOnMap,
  removeHighlight,
}) {
  const trackGTMEvent = () => {
    pushToDataLayer({ ecommerce: null })
    pushToDataLayer({
      event: 'behavior',
      store_id: diveCourseInfo?.id,
      shop_name: shopTitle,
      shop_country: shopCountry,
      shop_location: shopLocation,
    })
  }
  return (
    <a
      onMouseEnter={highlightPinOnMap}
      onMouseLeave={removeHighlight}
      href={activityPage}
      className={cx(styles.card, 'btn-elearning')}
      target={target}
      onClick={trackGTMEvent}
    >
      <CardHeader
        courseTitle={diveCourseInfo.title}
        shopTitle={shopTitle}
        shopUrl={shopUrl}
      />
      <div className={styles.imgContainer}>
        <img
          src={diveCourseInfo?.picture?.['800x800']}
          alt={diveCourseInfo.title}
        />
      </div>
      <div className={styles.details}>
        <p className={styles.detailsHeader}>
          <FormattedMessage defaultMessage='ABOUT ELEARNING' />
        </p>
        <FormattedMessage
          defaultMessage='Once you have purchased your eLearning product, please contact the Dive Shop to arrange a date to complete your course.'
          tagName='p'
        />
        <FormattedMessage
          defaultMessage='Additional costs are payable directly to the Dive Shop.'
          tagName='p'
        />
      </div>
      <div className={styles.btnContainer}>
        <span className={styles.price}>
          <PriceConverter
            amount={diveCourseInfo?.price?.targetValue}
            currency={diveCourseInfo?.price?.targetCurrency}
            to={diveCourseInfo?.price?.targetCurrency}
          />
        </span>

        <CourseButton
          text={<FormattedMessage defaultMessage='Buy eLearning' />}
          type='elearning'
        />
      </div>
    </a>
  )
}

ElearingAdventureCard.propTypes = {
  diveCourseInfo: PropTypes.object.isRequired,
  shopTitle: PropTypes.string.isRequired,
  shopUrl: PropTypes.string.isRequired,
  activityPage: PropTypes.string.isRequired,
  shopCountry: PropTypes.string.isRequired,
  shopLocation: PropTypes.string.isRequired,
  highlightPinOnMap: PropTypes.func.isRequired,
  removeHighlight: PropTypes.func.isRequired,
}
