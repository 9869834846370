import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setMapBounds } from '../../../actions/dive-guide-actions'

export default function useBounds(locationBounds) {
  const dispatch = useDispatch()
  const bounds = useSelector((state) => state.mapBounds || {})
  const { northeast, southwest } = bounds
  const leftBound = `${southwest.lat},${southwest.lng}`
  const rightBound = `${northeast.lat},${northeast.lng}`
  useEffect(() => {
    if(locationBounds) {
      setBounds(locationBounds)
    }
  }, [locationBounds])

  const setBounds = (bounds) => {
    dispatch(setMapBounds(bounds))
  }
  return {
    bounds,
    leftBound,
    rightBound,
    setBounds,
  }
}
