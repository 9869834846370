import { useMemo } from 'react'
import moment from 'moment'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { triggerDatepicker } from '../../utils'

const messages = defineMessages({
  courses: {
    id: 'search.title.courses',
    defaultMessage:
      '{ qty, plural, one {# course } other {#  courses } } { location }',
  },
  coursesNearBy: {
    id: 'search.title.courses.near_by',
    defaultMessage:
      '{ qty, plural, one {# course } other {#  courses } } nearby (50km radius)',
  },
  'dive-trips': {
    id: 'search.title.dives',
    defaultMessage:
      '{ qty, plural, one {# dive trip } other {# dive trips } }  { location }',
  },
  'dive-tripsNearBy': {
    id: 'search.title.dives.near_by',
    defaultMessage:
      '{ qty, plural, one {# dive trip } other {#  dive trips } } nearby (50km radius)',
  },
  snorkelling: {
    id: 'search.title.snorkelling',
    defaultMessage:
      '{ qty, plural, one {# snorkeling trip } other {# snorkeling trips } } { location }',
  },
  snorkellingNearBy: {
    id: 'search.title.snorkelling.near_by',
    defaultMessage:
      '{ qty, plural, one {# snorkeling trip } other {#  snorkeling trips } } nearby (50km radius)',
  },
})

export default function ActivitesPageTitle({
  activityType,
  inTitle,
  isNearBy,
  selectedDate,
  numberOfItems,
}) {
  const intl = useIntl()
  const title = useMemo(() => {
    const values = {
      qty: numberOfItems,
      location: inTitle,
    }

    return intl.formatMessage(
      messages[isNearBy ? `${activityType}NearBy` : activityType],
      values
    )
  }, [activityType, inTitle, isNearBy, numberOfItems])

  return (
    <h1>
      {title}&nbsp;
      {selectedDate && (
        <a onClick={triggerDatepicker}>
          <FormattedMessage
            id='on_date'
            values={{
              date: moment(selectedDate).format('MMM D'),
            }}
            defaultMessage='on {date}'
          />
        </a>
      )}
    </h1>
  )
}
