import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import PriceConverter from '../../helpers/PriceConverter'
import PromotionLabel from '../../helpers/PromotionLabel'

export default function TripPrice({ product, currency, showItinerary }) {
  const handleClick = () => showItinerary('#deal-section')
  const showPromotion = useMemo(
    () =>
      product.promotion &&
      product.availability > 0 &&
      product.promotion.kind > 20,
    [product]
  )
  const showOldPrice = useMemo(
    () =>
      product.compareAtPrice > product.price &&
      product.availability > 0 &&
      product.promotion &&
      product.promotion.kind !== 30 &&
      product.promotion.kind !== 40,
    [product]
  )

  if(product.isCharter) {
    return (
      <div className='charter-only'>
        <FormattedMessage id='charter_only' defaultMessage='Charter only' />
      </div>
    )
  }

  return (
    <div className='price'>
      <PromotionLabel
        currency={currency}
        promotion={product.promotion}
        price={product.price}
        compareAtPrice={product.compareAtPrice}
      />
      <div className='standart-price'>
        <span className='from'>
          <FormattedMessage id='from' defaultMessage='From' />
        </span>
        <i>
          <PriceConverter amount={product.price} currency={currency} />
        </i>
        <small className='price-tip'>
          <FormattedMessage id='per_trip' defaultMessage='per trip' />
        </small>
      </div>
      {showOldPrice && (
        <div className='price-information'>
          <span className='old-price'>
            <PriceConverter
              amount={product.filteredDiscount || product.compareAtPrice}
              currency={currency}
            />
          </span>
          <i className='font-icons info-icon' onClick={handleClick} />
        </div>
      )}
      {showPromotion && (
        <div className='price-information'>
          <span className='discount-title'>
            {product.promotion.title} &nbsp;
            <i className='font-icons info-icon' onClick={handleClick} />
          </span>
        </div>
      )}
    </div>
  )
}
