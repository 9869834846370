exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".pagination--3KOqw{color:#002547;margin-top:24px}.currentPage--3Nvs3,.pagination--3KOqw{display:flex;align-items:center;justify-content:center;font-size:16px}.currentPage--3Nvs3{border:1px solid #8494a4;border-radius:4px;width:46px;height:46px;margin-left:20px}.text--2HUwd{display:inline-block;padding:0 8px;margin-right:12px}.arrow--26Z62{cursor:pointer;color:#002547;font-size:20px}.arrow--26Z62.notActive--8XP5T{color:#8494a4;cursor:not-allowed}", ""]);

// Exports
exports.locals = {
	"pagination": "pagination--3KOqw",
	"currentPage": "currentPage--3Nvs3",
	"text": "text--2HUwd",
	"arrow": "arrow--26Z62",
	"notActive": "notActive--8XP5T"
};