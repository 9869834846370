exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".container--3ElLl{display:block;max-width:246px;cursor:pointer}.container--3ElLl:focus,.container--3ElLl:hover{text-decoration:none}.container--3ElLl:focus .viewDetails--HZeAj,.container--3ElLl:hover .viewDetails--HZeAj{text-decoration:underline}.wrapper--1pxdW{padding:16px}.title--OxYpb{font-size:16px;font-weight:700;color:rgba(0,0,0,.87)}.address--2D25t{font-size:12px;line-height:16px;letter-spacing:.5px;color:rgba(0,0,0,.6);margin-bottom:0}.shopImage--2rIqW{min-width:247px;-o-object-fit:cover;object-fit:cover;height:123px;max-width:100%}.details--3Wxw7{display:flex;flex-direction:column;margin-bottom:16px}.details--3Wxw7:last-of-type{margin-bottom:0}.detailsTitle--2regx{font-size:10px;font-weight:700;color:rgba(0,0,0,.87);text-transform:uppercase}.detailsInfo--24xlx{font-size:12px;font-weight:500;color:rgba(0,0,0,.6)}.viewDetails--HZeAj{color:#0070d3;font-weight:800;font-size:12px;margin-top:32px;display:inline-block}", ""]);

// Exports
exports.locals = {
	"container": "container--3ElLl",
	"viewDetails": "viewDetails--HZeAj",
	"wrapper": "wrapper--1pxdW",
	"title": "title--OxYpb",
	"address": "address--2D25t",
	"shopImage": "shopImage--2rIqW",
	"details": "details--3Wxw7",
	"detailsTitle": "detailsTitle--2regx",
	"detailsInfo": "detailsInfo--24xlx"
};