import { useState, useEffect } from 'react'
import API from 'api'

const useInsurances = (
  isIncludeFreeDiveInsurances,
) => {
  const [insurances, setInsurances] = useState([])
  useEffect(() => {
    if(isIncludeFreeDiveInsurances) {
      API(`booking/insurances/`)
        .get()
        .then((insurances) => {
          setInsurances(insurances)
        })
    }
  }, [])

  return insurances
}

export default useInsurances
