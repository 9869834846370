import { FormattedMessage } from 'react-intl'
import { Popup } from 'react-leaflet'
import { FiveStar } from '../shared'
import get from 'lodash/get'
import styles from './../styles/MapPopup.module.scss'
import { defaultShopPicture } from '../../../config'
import { pushToDataLayer } from 'gtm'
import usePinInfo from '../hooks/usePinInfo'
import { Loader } from '../../helpers'
import ShopPicture from '../../helpers/ShopPicture'

export default function ShopPopup({ shopId, onPopupClose, isOpen }) {
  const { pinInfo, isLoading } = usePinInfo(shopId, 'dive-shops', isOpen)
  const image = get(pinInfo, 'background.800x800', defaultShopPicture)
  const { activities } = pinInfo

  const trackGtmEvent = () => {
    pushToDataLayer({
      event: 'interaction',
      eventCategory: 'Dive Shop',
      eventAction: 'Profile - Map Click',
      eventLabel: pinInfo.affiliateId,
      eventValue: '0',
      eventNonInteraction: 'false',
    })
  }

  return (
    <Popup onClose={onPopupClose}>
      <Loader isLoading={isLoading} />
      <a
        href={pinInfo.url}
        onClick={trackGtmEvent}
        className={styles.container}
        target='_blank'
      >
        <div className={styles.wrapper}>
          <FiveStar membershipLevel={pinInfo.membershipLevel} />
          <p className={styles.title}>{pinInfo.title}</p>
          <p className={styles.address}>{pinInfo.shortAddress}</p>
        </div>
        <ShopPicture image={image} className={styles.shopImage} />
        <div className={styles.wrapper}>
          <div className={styles.details}>
            <p className={styles.detailsTitle}>
              <FormattedMessage
                id='courses_activites'
                defaultMessage='Courses/Activities'
              />
            </p>
            <p className={styles.detailsInfo}>
              {activities && activities.length ? activities.join(', ') : '–'}
            </p>
          </div>
          {pinInfo.kind !== 10 && (
            <div className={styles.details}>
              <p className={styles.detailsTitle}>
                <FormattedMessage id='hours' defaultMessage='Hours' />
              </p>
              <p className={styles.detailsInfo}>{pinInfo.openHour || '–'}</p>
            </div>
          )}
          <span className={styles.viewDetails}>
            <FormattedMessage
              id='view_details_msg'
              defaultMessage='View details'
            />
          </span>
        </div>
      </a>
    </Popup>
  )
}
