import { MapContainer } from 'react-leaflet'
import Map from './Map'
import { useMemo } from 'react'
import { getMapTileUrl } from 'config'
import { getLanguage } from '../../../utils'

export const MAP_ZOOM = 6
const language = getLanguage()

export default function SearchMap(props) {
  const { zoom = MAP_ZOOM, countryCode } = props

  const tileUrl = useMemo(
    () =>
      getMapTileUrl({
        isSateliteView: false,
        countryCode,
        language,
        isLight: true,
      }),
    [language]
  )
  return (
    <MapContainer
      center={[47, 8]}
      zoom={zoom}
      style={{ height: '100%' }}
      zoomControl={false}
      worldCopyJump
    >
      <Map {...props} tileUrl={tileUrl} />
    </MapContainer>
  )
}
