import { useState } from 'react'
// import { isMobile } from '../../../utils'
import get from 'lodash/get'
import { useDispatch, useSelector } from 'react-redux'
import {
  setSelectedPin,
  setOpennedMarker,
} from '../../../actions/dive-guide-actions'

// const isMobileDevice = isMobile()

export default function useMarkerHover(pageType) {
  const dispatch = useDispatch()
  const [isPopupOpen, setPopupOpen] = useState(false)
  const activeMarker = useSelector((state) => state.selectedPin)
  const opennedMarker = useSelector((state) => state.opennedMarker)
  // const isHovered = useRef(false)
  const setActiveMarker = (id) => dispatch(setSelectedPin(id))
  const setOpennedShop = (id) => dispatch(setOpennedMarker(id))
  const handleMouseOver = (e) => {
    const id = get(e, 'target.options.id')
    if(id) {
      setActiveMarker(id)
    }
    // if(!isMobileDevice) {
    //   isHovered.current = true
    //   e.target.openPopup()
    // }
  }
  const handleClick = (e) => {
    const id = get(e, 'target.options.id')
    if(id) {
      setOpennedShop(id)
      setPopupOpen(true)
      setActiveMarker(id)
    }
  }
  const onPopupClose = () => {
    setPopupOpen(false)
    setActiveMarker(false)
  }
  const handleMouseOut = (e) => {
    // if(isMobileDevice) {
    //   return false
    // }
    // const popup = document.querySelector('.leaflet-popup')
    // const listener = () => {
    //   isHovered.current = false
    //   setTimeout(() => {
    //     if(!isHovered.current) {
    //       e.target.closePopup()
    //       setActiveMarker(false)
    //     }
    //   }, 300)
    // }

    // if(popup && popup.matches(':hover')) {
    //   popup.addEventListener('mouseleave', listener)
    //   return
    // }
    // isHovered.current = false
    // popup && popup.removeEventListener('mouseleave', listener)
    // setTimeout(() => {
    //   if(!isHovered.current) {
    // e.target.closePopup()
    if(!isPopupOpen) {
      setActiveMarker(false)
    }
    //   }
    // }, 300)
  }

  return {
    handleMouseOut,
    handleMouseOver,
    activeMarker,
    handleClick,
    onPopupClose,
    setActiveMarker,
    setOpennedShop,
    isPopupOpen,
    opennedMarker,
  }
}
