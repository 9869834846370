import { useDispatch, useSelector } from 'react-redux'
import { setMapMoved } from '../../../actions/dive-guide-actions'
import { isAutosuggestInQuery } from '../../../utils'

export default function useIsMapMoved() {
  const dispatch = useDispatch()
  const isMapMoved = useSelector((state) => state.isMapMoved)
  const setIsMapMoved = () => {
    if(isAutosuggestInQuery()) {
      return null
    }
    dispatch(setMapMoved(true))
  }

  return {
    isMapMoved,
    setIsMapMoved,
  }
}
