import { FormattedMessage } from 'react-intl'
import { Popup } from 'react-leaflet'
import get from 'lodash/get'
import styles from './../styles/MapPopup.module.scss'
import { defaultShopPicture } from '../../../config'
import usePinInfo from '../hooks/usePinInfo'
import { Loader } from '../../helpers'
import ShopPicture from '../../helpers/ShopPicture'

export default function DsPopup({ diveSiteId, onPopupClose, isOpen }) {
  const { pinInfo, isLoading } = usePinInfo(diveSiteId, 'dive-sites', isOpen)
  const image = get(pinInfo, 'background.800x800') || defaultShopPicture
  return (
    <Popup onClose={onPopupClose}>
      <Loader isLoading={isLoading} />
      <a href={pinInfo.travelUrl} className={styles.container} target='_blank'>
        <div className={styles.wrapper}>
          <p className={styles.title}>{pinInfo.title}</p>
        </div>
        <ShopPicture image={image} className={styles.shopImage} />
        <div className={styles.wrapper}>
          <div className={styles.details}>
            <p className={styles.detailsTitle}>
              <FormattedMessage id='dive_type' defaultMessage='DIVE TYPE' />
            </p>
            <p className={styles.detailsInfo}>{pinInfo.types}</p>
          </div>
          <span className={styles.viewDetails}>
            <FormattedMessage id='view_details' defaultMessage='View Details' />
          </span>
        </div>
      </a>
    </Popup>
  )
}
