import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

export default function NoTrips({ departureDate, previousTrips }) {
  return (<div className='no-trips'>
    {departureDate ? (
      <Fragment>
        {previousTrips ? (
          <FormattedMessage
            id='no_available_trips_month'
            defaultMessage='No trips in this month'
          />
        ) : (
          <FormattedMessage
            id='no_available_trips_dates'
            defaultMessage='No trips in chosen dates'
          />
        )}
      </Fragment>
    ) : (
      <FormattedMessage
        id='no_available_trips'
        defaultMessage='No available trips'
      />
    )}
  </div>)
}
