import get from 'lodash/get'

export default function DiveSitesList({ sites }) {
  const dsLength = get(sites, 'length', 0)
  return sites.map((site, index) => {
    return (
      <span key={site.id}>
        {site.title}
        {index + 1 !== dsLength ? ', ' : ''}
      </span>
    )
  })
}
