import { connect } from 'react-redux'
import { Component, Fragment } from 'react'
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl'
import { isMobile } from 'utils'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import ReactTooltip from 'react-tooltip'

const msg = defineMessages({
  greenFinsTooltip: {
    id: 'green_fins_tooltip',
    title: 'Green Fins',
    defaultMessage:
      "Green Fins is the first and world's only assessed environmental set of standards for SCUBA diving and snorkelling centres. Members have agreed to follow the Code of Conduct, have undergone a training session followed by an assessment of their dive centre at least once a year to ensure that they are minimising their environmental impact.",
  },
  awareTooltip: {
    id: 'aware_tooltip',
    title: '100% Aware',
    defaultMessage:
      '100% AWARE Dive Centers and Resorts are committed to engaging divers in education and conservation efforts, and ensure all their student divers receive the Project AWARE version of the PADI certification card. All donations received support ocean protection efforts and actions worldwide.',
  },
  greenStarTooltip: {
    id: 'green_star_tooltip',
    title: 'Green Star',
    defaultMessage:
      'The PADI Green Star™ Dive Center Award is granted to PADI Dive Centers and Resorts that demonstrate a dedication to conservation across a wide range of business functions, including water conservation, energy use, environmentally friendly transportation practices, use of sustainable materials, conservation leadership and a donation to conservation through Project AWARE.',
  },
})

class EcoLabels extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ecoModalOpen: false,
      selectedTooltip: msg.greenFinsTooltip,
    }
  }

  triggerModal = (isOpen) => {
    this.setState({
      ecoModalOpen: isOpen,
    })
  }

  selectToolTip = (toolTip) => {
    if(isMobile()) {
      this.setState(
        {
          selectedTooltip: toolTip,
        },
        () => this.triggerModal(true)
      )
    } else {
      return false
    }
  }

  render() {
    if(!this.props.labelsList || this.props.labelsList.length < 1) {
      return null
    }
    return (
      <Fragment>
        <ReactTooltip effect='solid' />
        <ul>
          {this.props.labelsList.includes('green star') && (
            <li
              className='eco-gs'
              onClick={() => this.selectToolTip(msg.greenStarTooltip)}
              data-tip={this.props.intl.formatMessage(msg.greenStarTooltip)}
            />
          )}
          {this.props.labelsList.includes('100% aware') && (
            <li
              className='eco-aware'
              onClick={() => this.selectToolTip(msg.awareTooltip)}
              data-tip={this.props.intl.formatMessage(msg.awareTooltip)}
            />
          )}
          {this.props.labelsList.includes('green fins') && (
            <li
              className='eco-gf'
              onClick={() => this.selectToolTip(msg.greenFinsTooltip)}
              data-tip={this.props.intl.formatMessage(msg.greenFinsTooltip)}
            />
          )}
        </ul>
        <Modal
          isOpen={this.state.ecoModalOpen}
          onRequestClose={() => this.triggerModal(false)}
          ariaHideApp={false}
          className='Modal react-popup form-popup'
          overlayClassName='Overlay'
          contentLabel='Booking enquire popup'
        >
          <span
            className='close-btn'
            onClick={() => this.triggerModal(false)}
          />
          <h2>{this.state.selectedTooltip.title}</h2>
          <div className='wysiwyg'>
            <FormattedMessage {...this.state.selectedTooltip} />
          </div>
        </Modal>
      </Fragment>
    )
  }
}

EcoLabels.propTypes = {
  labelsList: PropTypes.array,
  intl: PropTypes.object.isRequired,
}

export default injectIntl(connect()(EcoLabels))
