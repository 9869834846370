import useMarkerHover from '../hooks/useMarkerHover'
import { pushToDataLayer } from 'gtm'
import DcCard from './DcCard'
import LaCard from './LaCard'

import PropTypes from 'prop-types'

export default function ShopItemCard({ shop, isElearningView }) {
  const { setActiveMarker } = useMarkerHover()
  const highlightPinOnMap = () => setActiveMarker(shop.id)
  const removeHighlight = () => setActiveMarker(false)

  const trackGtmEvent = () => {
    if(isElearningView) {
      pushToDataLayer({
        event: 'interaction',
        eventCategory: 'Dive Shop',
        eventAction: 'PADI - eLearning Click',
        eventLabel: shop.affiliateId,
        eventValue: '0',
        eventNonInteraction: 'false',
      })
    } else {
      trackListingClick()
    }
  }

  const trackListingClick = () => {
    pushToDataLayer({
      event: 'interaction',
      eventCategory: 'Dive Shop',
      eventAction: 'Profile - Listing Click',
      eventLabel: shop.affiliateId,
      eventValue: '0',
      eventNonInteraction: 'false',
    })
  }

  return shop.kind === 10 ? (
    <LaCard
      shop={shop}
      isElearningView={isElearningView}
      highlightPinOnMap={highlightPinOnMap}
      removeHighlight={removeHighlight}
      trackGtmEvent={trackGtmEvent}
      trackListingClick={trackListingClick}
    />
  ) : (
    <DcCard
      shop={shop}
      isElearningView={isElearningView}
      highlightPinOnMap={highlightPinOnMap}
      removeHighlight={removeHighlight}
      trackGtmEvent={trackGtmEvent}
      trackListingClick={trackListingClick}
    />
  )
}

ShopItemCard.propTypes = {
  shop: PropTypes.object.isRequired,
  isElearningView: PropTypes.bool,
}
