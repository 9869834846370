/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import { Component, Fragment, createRef } from 'react'
import Autosuggest from 'react-autosuggest'
import { injectIntl, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { getOffset } from '../../utils'
import API from '../../api'
import messages from '../../messages'

import './autosuggest.sass'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { setInputFocus } from '../../actions/search-actions'
import { getHighlightedText } from '../helpers/Typeahead'
import { NearByBtn } from '../dsl/filters'
import LoadingIndicator from './LoaderIndicator'

const renderSuggestion = (suggestion, { query }) => (
  <div className='typeahead-item'>
    <div
      className={`typeahead-title ${
        suggestion.objectType !== 'point' ? 'without-icon' : ''
      }`}
    >
      <span className='location-title'>
        {suggestion.objectType === 'point' && (
          <i className='font-icons target-icon' />
        )}
        {getHighlightedText(suggestion.title, query)}
      </span>
      {suggestion.formattedAddress && (
        <span className='formatted-address'>{suggestion.formattedAddress}</span>
      )}
    </div>
  </div>
)

const LabelIcon = ({ receivingCoordinates }) => {
  if(receivingCoordinates) {
    return <i className='font-icons field-icon icon-return' />
  }

  return <i className='font-icons field-icon location-icon' />
}

class DestinationTypeahead extends Component {
  constructor(props) {
    super(props)

    if(props.popupId) {
      this.popup = document.getElementById(props.popupId)
    }

    this.inputRef = createRef()
    this.nearMeChoice = {
      id: 'point',
      searchParam: 'point',
      objectType: 'point',
      title: this.props.intl.formatMessage(messages.near_me),
    }
    // Autosuggest is a controlled component. This means that you need to provide an
    // input value and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest, and they are
    // initially empty because the Autosuggest is closed.
    this.state = {
      receivingCoordinates: false,
      value: props.inputValue || '',
      suggestions: props.showNearBy ? [this.nearMeChoice] : [],
      popupVisible: false,
      loading: false,
    }
  }

  controller
  signal

  componentDidMount() {
    const ref = this.inputRef.current.input
    this.inputOffset = getOffset(ref)

    if(this.popup && this.props.handlePopupItems) {
      const urls = this.popup.querySelectorAll('a')
      const toggleButtons = this.popup.querySelectorAll('.option')
      const countriesList = this.popup.querySelectorAll(
        '.country-list-wrapper',
      )
      const nearlyLink = document.getElementById('near-location-link')
      if(urls) {
        [...urls].forEach((item) => {
          item.addEventListener('click', (e) => {
            e.preventDefault()
            const itemKind = +item.getAttribute('kind')
            this.onSuggestionSelected(e, {
              suggestion: {
                modelType: 'PopupLink',
                url: item.getAttribute('href'),
                kind: itemKind,
                title: item.text,
                itemTitle: item.text,
                searchKind: itemKind === 10 ? 'LA' : 'DR',
              },
            })
            if(typeof this.props.mobileDestination === 'function') {
              this.props.mobileDestination(false)
            }
            this.hidePopup()
            this.setState({ value: item.text })
          })
        })
      }

      if(toggleButtons) {
        toggleButtons.forEach((button) => {
          button.addEventListener('click', () => {
            if(!button.classList.contains('active')) {
              toggleButtons.forEach((button) => {
                button.classList.remove('active')
              })
              button.classList.add('active')
              countriesList.forEach((item) => {
                item.classList.toggle('active')
              })
              if(this.props.onToggleButtonClick) {
                this.props.onToggleButtonClick(button.getAttribute('kind'))
              }
            }
          })
        })
      }

      if(nearlyLink) {
        nearlyLink.addEventListener('click', (e) => {
          const suggestionObj = {
            suggestion: this.nearMeChoice,
          }
          this.onSuggestionSelected(e, suggestionObj)
        })
      }

      document.addEventListener('click', (e) => {
        if(this.state.popupVisible) {
          let element = e.target
          let parents = []
          let clickedOutside = true
          while(element) {
            parents.unshift(element)
            element = element.parentNode
          }
          parents.forEach((elem) => {
            if(elem.id === this.props.popupId || elem === ref) {
              clickedOutside = false
            }
          })
          if(clickedOutside) {
            this.hidePopup()
          }
        }
      })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      inputFocus,
      inputValue,
    } = this.props
    if(prevProps.inputValue !== inputValue) {
      this.setState({
        value: inputValue,
      })
    }
    if(inputFocus && inputFocus !== prevProps.inputFocus) {
      setTimeout(() => this.inputRef.current.input.focus(), 150)
    }
  }

  showPopup() {
    if(this.popup) {
      this.inputOffset = getOffset(this.inputRef.current.input)
      this.popup.style.opacity = 1

      if(
        this.inputOffset.left + this.popup.offsetWidth >
        window.innerWidth - 20
      ) {
        this.popup.style.left = `${
          (window.innerWidth - this.popup.offsetWidth) / 2
        }px`
      } else {
        this.popup.style.left = `${this.inputOffset.left - 10}px`
      }
      this.popup.style.top = `${this.inputOffset.top + 50}px`
      this.setState({ popupVisible: true })

      if(window.innerWidth < 767) {
        window.scrollTo(0, 0)
        if(typeof this.props.mobileDestination === 'function') {
          this.props.mobileDestination(true)
        }
        this.popup.style.top = '50px'
        this.popup.style.boxShadow = 'none'
      }
    }
  }

  hidePopup() {
    if(this.popup) {
      this.popup.style.opacity = 0
      this.setState({ popupVisible: false })
      setTimeout(() => {
        this.popup.style.left = '-9999px'
      }, 300)
    }
  }

  onSuggestionSelected = (event, { suggestion }) => {
    if(suggestion && suggestion.id === 'point') {
      this.setState({ receivingCoordinates: true })
      navigator.geolocation.getCurrentPosition(
        (location) => {
          if(typeof this.props.callback === 'function') {
            this.props.callback({
              [suggestion.searchParam]: `${location.coords.latitude},${location.coords.longitude}`,
              isNearBy: true,
            })
          }
          this.setState({ receivingCoordinates: false })
        },
        function() {
          toast.error(
            <FormattedMessage
              id='cant_get_location'
              defaultMessage='Unfortunately we can`t receive your coordinates'
            />,
            { position: toast.POSITION.TOP_CENTER },
          )
          this.setState({ receivingCoordinates: false })
        },
      )
    } else {
      this.props.onSelect && this.props.onSelect(suggestion)
    }
  }

  getSuggestionValue = (suggestion) => {
    return suggestion.title
  }

  onChange = (event, { newValue }) => {
    if(this.popup) {
      if(newValue.length < 1) {
        this.showPopup()
      } else {
        this.hidePopup()
      }
    }

    this.setState({ value: newValue })
  }

  // Autosuggest will call this function every time you need to update
  // suggestions.
  onSuggestionsFetchRequested = ({
    value,
    reason,
  }) => {
    const {
      showNearBy,
      isActivity,
    } = this.props
    if(value.length < 3) {
      this.setState({
        suggestions: showNearBy ? [this.nearMeChoice] : [],
      })

      return false
    }

    let params = {
      q: value,
    }

    if(this.props.type === 'group') {
      /// Ха-ха, ебучий костыль
      params.kind = 999
    }
    if(!isActivity) {
      params.with_gplaces = false
    }
    if(reason !== 'input-focused') {
      if(this.controller !== undefined) {
        // Cancel the previous request
        this.controller.abort()
      }

      if('AbortController' in window) {
        this.controller = new window.AbortController()
        this.signal = this.controller.signal
      }
      this.setState({ loading: true })
      API('search/autosuggest/destinations/', this.signal)
        .get(params)
        .then(({
          places,
          gplaces,
          fleets,
        }) => {
          if(isActivity) {
            this.setState({ suggestions: [...gplaces, ...places] })
          } else {
            this.setState({ suggestions: [...places, ...fleets] })
          }
        }).finally(() => {
          this.setState({ loading: false })
        })
    }
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] })

    if(typeof this.props.mobileDestination === 'function') {
      this.props.mobileDestination(false)
      this.hidePopup()
    }
  }

  render() {
    const {
      value,
      suggestions,
      receivingCoordinates,
    } = this.state
    const {
      placeholder,
      intl,
      withIcon,
      showNearByButton,
    } = this.props
    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: placeholder || intl.formatMessage(messages.search),
      value,
      autoComplete: 'off',
      onChange: this.onChange,
      onBlur: () => {
        if(!this.props.handlePopupItems) {
          this.hidePopup()
        }

        this.props.setInputFocus(false)
      },
      onFocus: (e) => {
        try {
          e.currentTarget.setSelectionRange(0, value.length)
        } catch (err) {
          // Non IOs option if not supported, e.g. Chrome
          e.currentTarget.select()
        }

        if(this.popup && !this.props.onFocusClear) {
          if(value.length < 3) {
            this.showPopup()
          }
        }

        if(this.props.onFocusClear) {
          this.setState(
            {
              value: '',
            },
            () => {
              setTimeout(() => this.showPopup(), 300)
            },
          )
        }
      },
    }

    // Finally, render it!
    return (
      <Fragment>
        {showNearByButton && (
          <NearByBtn
            onClick={() =>
              this.onSuggestionSelected(null, {
                suggestion: {
                  id: 'point',
                  searchParam: 'point',
                },
              })
            }
          />
        )}
        {withIcon && <LabelIcon receivingCoordinates={receivingCoordinates} />}
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={renderSuggestion}
          shouldRenderSuggestions={(v) => {
            return true
          }}
          inputProps={inputProps}
          ref={this.inputRef}
        />&nbsp;
        {this.state.loading &&
          <LoadingIndicator customStyles='typeahead-loader' />}
        {this.props.isActiveMobile &&
          typeof this.props.mobileDestination === 'function' && (
          <span
            onClick={this.onSuggestionsClearRequested}
            className='close-destination-popup close-icon font-icons'
          />
        )}
      </Fragment>
    )
  }
}

DestinationTypeahead.propTypes = {
  intl: PropTypes.object.isRequired,
}

const mapStateToProps = ({ inputFocus }) => ({ inputFocus })

export default compose(
  connect(mapStateToProps, { setInputFocus }),
  injectIntl,
)(DestinationTypeahead)
