import { FormattedMessage } from 'react-intl'
const LACheckbox = ({
  handler,
  label,
  value,
}) => {
  return (
    <label className='checkbox-wrap'>
      <input
        type='checkbox'
        value={value}
        onClick={handler}
      />
      <span className='checkbox-area' />
      <p>
        {typeof label === 'object' && label.id ? (
          <FormattedMessage {...label} />
        ) : (
          label
        )}
      </p>
    </label>)
}

export default LACheckbox
