import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import DestinationTypeahead from '../helpers/DestinationTypeahead'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import FilterList from './FilterList'
import TravelDatepicker from '../helpers/TravelDatepicker'
import messages from '../../messages'
import TrustPilotBlock from './TrustPilotBlock'
import { setIsNearBy } from '../../actions/search-actions'
import { addParamToUrl } from '../../utils'

const message = defineMessages({
  placeholder: {
    id: 'homepage.search.placeholder',
    defaultMessage: 'Where do you want to go?',
  },
  on: {
    id: 'on',
    defaultMessage: 'On',
  },
  labelFromHint: {
    id: 'date_hint',
    defaultMessage: 'Click on the calendar below to select a date',
  },
  labelClearHint: {
    id: 'clear_hint',
    defaultMessage: 'To view activities on all dates',
  },
  allDates: {
    id: 'allDates',
    defaultMessage: 'All dates',
  },
})

export default function FiltersActivity({
  selectedFilters,
  updateParams,
  isWidget,
  items,
  clearSelectedFilters,
  filtersCount,
  triggerApi,
  title,
  isDiveCenter,
  activityUrl,
  activityType,
  trustpilotLocale,
}) {
  const intl = useIntl()
  const isMapView = selectedFilters.view === 'map'
  const [showMobileDestination, setShowMobileDestinations] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    const { point } = selectedFilters
    dispatch(setIsNearBy(!!point))
  }, [selectedFilters.point])

  const handleDatesChange = ({ dateFrom }) => {
    updateParams({
      date: dateFrom,
    })
  }

  const selectNearBy = (suggestion) => {
    updateParams(suggestion)
  }

  const toggleMobileDestinations = (state) => {
    setShowMobileDestinations(state)
  }

  const destinationSelectCallback = useCallback(
    (suggestion) => {
      const mapQuery = isMapView ? 'view=map' : ''

      if(suggestion.modelType === 'PopupLink') {
        window.location.href = addParamToUrl(suggestion.url, mapQuery)
        return true
      }
      switch (activityType) {
        case 'courses':
          window.location.href = addParamToUrl(
            suggestion.courseSearchUrl,
            mapQuery
          )
          break
        case 'dive-trips':
          window.location.href = addParamToUrl(
            suggestion.diveSearchUrl,
            mapQuery
          )
          break
        case 'snorkelling':
          window.location.href = addParamToUrl(
            suggestion.snorkellingSearchUrl,
            mapQuery
          )
          break
        case 'dive-centers':
          window.location.href = addParamToUrl(
            suggestion.diveCenterSearchUrl,
            mapQuery
          )
          break
        default:
          return false
      }
    },
    [isMapView]
  )

  const showActivityView = () => updateParams({ view: 'activity' })
  const showMapView = () => updateParams({ view: 'map' })

  return (
    <div className={`filters ${isMapView ? 'map-full-width' : ''}`}>
      {isMapView ? (
        <button className='btn-red btn-go-to-list' onClick={showActivityView}>
          <i className='font-icons list-icon' />
          <FormattedMessage
            id='go_to_list_view'
            defaultMessage='Go to list view'
          />
        </button>
      ) : (
        <div className='filter-list go-to-map' onClick={showMapView}>
          <span>
            <i className='font-icons location-icon' />
            <FormattedMessage id='go_to_map' defaultMessage='Go to map' />
          </span>
        </div>
      )}
      {!window?.isWidget && (
        <div className='main-filters' id='la-main-filters'>
          <p className='title'>
            <FormattedMessage id='search' defaultMessage='Search' />
          </p>
          <div
            className={`search-block ${
              showMobileDestination ? ' mobile-destination' : ''
            }`}
          >
            <label className='w_icon'>
              <DestinationTypeahead
                placeholder={intl.formatMessage(message.placeholder)}
                isActivity
                mobileDestination={toggleMobileDestinations}
                isActiveMobile
                handlePopupItems
                isWidget={isWidget}
                kind={10}
                withIcon
                inputValue={
                  selectedFilters.point
                    ? intl.formatMessage(messages.near_me)
                    : title
                }
                onFocusClear
                showNearBy={window.innerWidth > 768}
                callback={selectNearBy}
                onSelect={destinationSelectCallback}
                popupId='country-list-popup'
              />
            </label>
          </div>
          {!isDiveCenter && (
            <div className={`form-group w_icon`}>
              <TravelDatepicker
                id='datepicker'
                withIcon
                labelFrom={message.on}
                labelFromHint={intl.formatMessage(message.labelFromHint)}
                callback={handleDatesChange}
                className='gray-input'
                closeOnSelect
                labelClearHint={intl.formatMessage(message.labelClearHint)}
                dateFrom={selectedFilters.date}
                placeholder={message.allDates}
              />
            </div>
          )}
          <button
            className='btn-red hidden-xs'
            id='search-btn'
            onClick={triggerApi}
          >
            <i className='font-icons find-icon' />
            <FormattedMessage id='search' defaultMessage='Search' />
          </button>
        </div>
      )}
      <FilterList
        updateParams={updateParams}
        selectedFilters={selectedFilters}
        items={items}
        clearSelectedFilters={clearSelectedFilters}
        filtersCount={filtersCount}
        showTripOrganiser
        expandedFilters={{
          instant_confirmation: true,
          standard_name_ids: true,
          departure_times: true,
          experiences: true,
        }}
        hideRange
        organiserPlaceholder='type_a_name'
        url={activityUrl}
        label={
          <FormattedMessage
            id='diveCenterName'
            defaultMessage='Dive center name'
          />
        }
      />
      {!isDiveCenter && <TrustPilotBlock trustpilotLocale={trustpilotLocale} />}
    </div>
  )
}
